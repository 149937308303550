import React from 'react';
import classNames from 'classnames';
import style from './add-all-to-playlist.scss';

import { Button, Checkbox, Dialog, Flex, FontIcon, Typewriter } from 'components/ui';
import { getTranslation } from 'utility/common';

const dialogName = 'AddAllToPlaylist';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

export default class AddAllToPlaylist extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
          searchType: null,
          includeMainTracks: true,
          includeAltTracks: false,
          includeEditTracks: false,
          options: {}
        }
    }

    get onClose() {
        const { onClose = () => {} } = this.props;
        return onClose;
    }

    componentDidMount() {
        var options = Common.Dialogs.GetDialogOptions(dialogName);
        this.state.options = options;

        document.addEventListener('keyup', onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    }

    handleCancel() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleCheck(checkType, value) {
      this.setState({ [checkType]: value });
    }

    handleOK() {
      const options = {
        includeMainTracks: this.state.includeMainTracks,
        includeAltTracks: this.state.includeAltTracks,
        includeEditTracks: this.state.includeEditTracks,
        searchType: this.state.options.searchType
      };

      Common.Playlist.AddSearchResults(options);
      Common.Dialogs.ShowDialog(dialogName, false);
      this.onClose();
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        return (
            <Dialog title={getTranslation("Add All to Playlist")} name={dialogName} width={400} height={220} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleCancel.bind(this)}>
                <Flex className='add-all-to-playlist' position='top'>
                    <Flex fixed className='content' position='left'>
                      <div>Please select which tracks to add to the playlist:</div>
                      <div><Checkbox label='Main Tracks' value={this.state.includeMainTracks} onCheck={this.handleCheck.bind(this, 'includeMainTracks')}/></div>
                      <div><Checkbox label='Alt Tracks' value={this.state.includeAltTracks} onCheck={this.handleCheck.bind(this, 'includeAltTracks')} /></div>
                      <div><Checkbox label='Edit Tracks' value={this.state.includeEditTracks} onCheck={this.handleCheck.bind(this, 'includeEditTracks')} /></div>
                    </Flex>
                    <Flex row fixed className='footer' position='bottom-right'>
                        <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                        <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}