import { List, Map, fromJS } from 'immutable'

import * as Actions from './actions'

const createEmptySearchResults = () => {
  return {
    AlbumInfo: {
      Track: {},
      AlbumName: '',
      AlbumCoverArt: '',
      AlbumDescription: ''
    },
    MoreLikeThisResults: {
      LookupTrack: Map(),
      AlternateTracks: List([]),
      IsLoading: false
    },
    PreviousSearches: List(),
    SearchSuggestions: createEmptySearchSuggestions(),
    SearchResults: {
      Total: 0,
      Page: 0,
      Size: 20,
      LastSearch: null,
      Tracks: List()
    }
  }
}

const createEmptySearchSuggestions = () => {
  return {
    Collections: [],
    Genres: [],
    Moods: [],
    LooksLike: [],
    SoundsLike: []
  };
}

const clearMoreLikeThisResults = () => {
  return {
    LookupTrack: null,
    AlternateTracks: List([]),
    IsLoading: true,
    TrackCount: 0
  }
}

const createAlbumInfoResults = (genreId, results, isLoading = false) => {
  return {
    GenreId: genreId,
    AlbumName: results.AlbumName,
    AlbumCoverArt: results.AlbumCoverArt,
    AlbumDescription: results.AlbumDescription,
    SimilarAlbums: List(results.SimilarAlbums),
    SimilarTracks: List(results.Tracks),
    IsLoading: isLoading,
    Page: results.Page,
    Size: results.Size,
    Total: results.Total || 0,
    LastSearch: results.LastSearch,
    LastSearchType: results.LastSearchType
  }
}

const createMoreLikeThisResults = (track, results, isLoading = false) => {
    return {
      LookupTrack: track,
      AlternateTracks: List(results.Tracks),
      IsLoading: isLoading,
      Page: results.Page,
      Size: results.Size,
      Total: results.Total || 0,
      LastSearch: results.LastSearch,
      LastSearchType: results.LastSearchType
  }
}

/**
 * Initial reducer state
 */
export const initialSearchState = Map(createEmptySearchResults());


/**
 * Home view reducer
 */
export const searchReducer = {
  [Actions.SEARCH_ADD_ALT_TRACKS]: (state, action) => {
    const newState = state.toJS();
    const track = newState.SearchResults.Tracks.find(t => t.Id == action.trackId);

    if (track != null) 
      track.AltTracks = action.tracks;

    return Map(newState);
  },

  [Actions.SEARCH_ADD_EDIT_TRACKS]: (state, action) => {
    const newState = state.toJS();
    const track = newState.SearchResults.Tracks.find(t => t.Id == action.trackId);

    if (track != null) 
      track.EditTracks = action.tracks;

    return Map(newState);
  }, 
  
  [Actions.SEARCH_CLEAR_SEARCH_RESULTS]: (state, action) => {
    return Map(createEmptySearchResults());
  },

  [Actions.SEARCH_CLEAR_SHOW_MORE_LIKE_THIS]: (state, action) => {
    return state.setIn(['MoreLikeThisResults', 'AlternateTracks'], List([]));
  },

  [Actions.SEARCH_LOAD_ALBUM_INFO]: (state, action) => {
    return state.set('AlbumInfo', createAlbumInfoResults(action.genreId, action.results, action.isLoading));
  },  

  [Actions.SEARCH_SHOW_MORE_LIKE_THIS]: (state, action) => {
    return state.set('MoreLikeThisResults', createMoreLikeThisResults(action.track, action.results, action.isLoading));
  },

  [Actions.SEARCH_SET_IS_SEARCHING]: (state, action) => {
    return state.set('IsSearching', action.isSearching);
  },

  [Actions.SEARCH_SET_ALT_TRACKS]: (state, action) => {
    action.track.AltTracks = action.tracks;    

    const searchResults = state.toJS().SearchResults;
    const trackIndex = searchResults.Tracks.findIndex(t => t.Id == action.track.Id);
    
    searchResults.Tracks[trackIndex] = action.track;

    return state.set('SearchResults', searchResults);
  },

  [Actions.SEARCH_SET_EDIT_TRACKS]: (state, action) => {
    action.track.EditTracks = action.tracks;

    const searchResults = state.toJS().SearchResults;
    const trackIndex = searchResults.Tracks.findIndex(t => t.Id == action.track.Id);
    
    searchResults.Tracks[trackIndex] = action.track;

    return state.set('SearchResults', searchResults);
  },  

  [Actions.SEARCH_SET_SUGGESTIONS]: (state, action) => {
    if (action.suggestions == null) 
      return state.set('SearchSuggestions', createEmptySearchSuggestions());
    else
      return state.set('SearchSuggestions', action.suggestions);
  },

  [Actions.SEARCH_SET_SEARCH_RESULTS]: (state, action) => {
    const search = state.toJS();  
    
    if (action.searchResults.Page > 1) {
      const tracks = search.SearchResults.Tracks;
      
      action.searchResults.Tracks.forEach(t => {
        tracks.push(t);
      });

      search.SearchResults = Object.assign({}, search.SearchResults, action.searchResults, {
        Tracks: tracks
      });      
    } else {
      search.SearchResults = action.searchResults;
    }

    return Map(search);
  },  

  [Actions.SEARCH_UPDATE_PREVIOUS_SEARCHES]: (state, action) => {
    return state.setIn(['PreviousSearches'], action.searches);
  }
}
