import React from 'react';
import classNames from 'classnames';
import style from './delete-playlist.scss';

import { Button, Dialog, DropDown, Flex, FontIcon, TextField, Typewriter } from 'components/ui';

const dialogName = 'DeletePlaylist';

export default class DeletePlaylist extends React.Component {
    constructor() {
        super(...arguments);

        this.onKeyUp = (event) => {
            if (event.which === 27) {
                Common.Dialogs.ShowDialog(dialogName, false);
            }

            if (event.which === 13) {
                this.handleOK();
            }
        }

        this.state = {
            message: null,
            playlists: [],
            selectedPlaylist: null
        }
    }

    get onClose() {
        const {
            onClose = () => {
            }
        } = this.props;

        return onClose;
    }

    componentWillMount() {
        const playlist = Common.Playlist.GetPlaylist()
        if (playlist != null) {
            this.state.selectedPlaylist = { id: playlist.Id, text: playlist.Title };
        }

        this.state.message = Common.UserInterface.GetRandomMessage('Delete Playlist');

        Common.Playlist.GetPlaylistsAsync()
            .then(playlists => {
                this.state.playlists = playlists.map(p => {
                    return { id: p.Id, text: p.Title }
                });
                this.forceUpdate();
            })
            .catch(err => {
                // TODO: throw error message
            });

        document.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.onKeyUp);
    }

    handleCancel() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleOK() {
        if (this.state.selectedPlaylist != null) {
            Common.Playlist.DeletePlaylist(this.state.selectedPlaylist.id);
        }

        this.onClose();
    }

    handleSelectPlaylist(value) {
        this.setState({ selectedPlaylist: value });
    }

    handleTextChanged(value) {
        this.setState({ title: value });
    }

    render() {
        const {
            onClose = () => {
            }
        } = this.props;

        return (
            <Dialog title='Delete Playlist' name='DeletePlaylist' width={600} height={290} modal={true}
                hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)}
                onEscape={this.handleCancel.bind(this)}>
                <Flex className='delete-playlist' position='top'>
                    <Flex row fixed className='header' position='top'>
                        <FontIcon name='inspector' />
                        <Flex position='left'><Typewriter message={this.state.message} /></Flex>
                    </Flex>
                    <Flex row fixed className='content'>
                        <Flex width={100}>Delete:</Flex>
                        <Flex>
                            <DropDown placeholder='Select playlist to delete' items={this.state.playlists}
                                value={this.state.selectedPlaylist}
                                onSelect={this.handleSelectPlaylist.bind(this)} />
                        </Flex>
                    </Flex>
                    <Flex row fixed className='footer' position='bottom-right'>
                        <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                        <Button type='default' size='md' width='100px'
                            onClick={this.handleCancel.bind(this)}>Cancel</Button>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}