import React from 'react';
import classNames from 'classnames';
import { Button, Dialog, Flex, FontIcon, TextField, Typewriter } from 'components/ui';
import style from './import-playlist.scss';

const dialogName = 'ImportPlaylist';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

class ImportPlaylist extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            file: null,
            statusFileFormat:false,
            message: Common.UserInterface.GetRandomMessage('ImportPlaylist')
        }
    }    
    
    componentDidMount() {
        const _this = this;

        document.addEventListener('keyup', onKeyUp);

        this.fileInput.addEventListener('change', (e) => {
            _this.setState({ file: e.srcElement.files[0] });
        });
    }

    componentWillUnmount() {
        const _this = this;

        this.fileInput.removeEventListener('change', (e) => {
            _this.setState({ file: e.srcElement.files[0] });
        });

        document.removeEventListener('keyup', onKeyUp);
    }

    get onClose() {
        const { onClose = () => {} } = this.props;
        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }
    
    
    handleOK() {
        if(this.state.file.type === 'application/vnd.ms-excel' || this.state.file.type=== 'text/csv'|| this.state.file.type === 'text/xml'){
           this.setState({statusFileFormat:false});
           Common.Playlist.ImportPlaylist(this.state.file);
    }
    else{
          this.setState({statusFileFormat:true});
    }
  }
    handleUpload() {
        this.fileInput.click();
    }

    render() {
        const {      
            onClose = () => {}
        } = this.props;

        const filename = this.state.file ? this.state.file.name : ''

        return (
            <Dialog title='Import Playlist' name='ImportPlaylistDialog' width={600} height={290} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)}>
                <Flex block className='import-playlist' position='top'>
                    <Flex row className='header'>
                        <FontIcon name='inspector' />
                        <Flex position='left'><Typewriter message={ this.state.message } /></Flex>                    
                    </Flex>
                    <Flex className='content'>
                        <Flex row>
                            <input ref={(i) => this.fileInput = i} type='file' name='test' id='test' style={{display: 'none'}} />
                            <Flex className='import-label' width={80} position='right'>File: </Flex>
                            <Flex><TextField ref={(i) => this.textField = i} placeholder='Click here or press the button to choose a file' showClearIcon={false} onClick={this.handleUpload.bind(this)} readonly={true} value={filename} /></Flex>
                            <Button onClick={this.handleUpload.bind(this)}>...</Button>
                        </Flex>
                    </Flex>   
                    <Flex row fixed className='footer' position='right'>
                        <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                        <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>                    
                    </Flex>
                    {this.state.statusFileFormat ?   
                    <div className="file-upload-message">
                        * Please Upload CSV or XML file only
                    </div>                         
                    : ''}                               
                </Flex>            
            </Dialog>
        )
    }
}

export default ImportPlaylist;