import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import {isDiscoveryUser} from 'utility/common'
import { connect } from 'react-redux';
import debounce from 'debounce';
import { fetchAuth } from 'utility/fetchHelpers.js';
import { resetPassword } from 'store/userInterface/actions';
import {
  Button,
  Flex,
  FontIcon,
  TextField
} from 'components/ui';

import {
  AccountDialog,
  AddAllToPlaylist,
  AlbumInfo,
  ChangePasswordDialog,
  ContactUsDialog,
  CreatePlaylist,
  CueSheetMaker,
  DeletePlaylist,
  DuplicatePlaylist,
  ExportPlaylist,
  ExportCueSheetDialog,
  ForgotPasswordDialog,
  HowToDialog,
  ImportCueSheetDialog,
  ImportPlaylist,
  ImportPlaylistDialog,
  LoginDialog,
  MergePlaylist,
  MergePlaylistDialog,
  MoreLikeThisDialog,
  NewCueSheetDialog,
  OpenCueSheetDialog,
  OpenPlaylist,
  Preferences,
  PrintCueSheetDialog,
  RenamePlaylist,
  ResetPasswordDialog,
  SaveCueSheetAsDialog,
  SaveCueSheetDialog,
  SharePlaylist,
  SignUpDialog,
  ProgressDialog,
  TrackHider,
  MultiplePlaylist,
  SurveyDialog,
  QuickTipsDialog,
  EditTrackDialog,
  PrivacyDialog,
  EditProfileDialog
} from 'components/common/dialogs';
import { PlaylistDropDown } from './playlist-drop-down';
import { createEmptyAdvancedSearch } from 'store/userInterface/reducer';
import NavigationMenu from './nav-menu/nav-menu';
import PlaylistExtension from './playlistExtension/playlistExtension';
import SearchExtension from './searchExtension/searchExtension';
import { SuggestionsDropDown } from "./suggestionsDropDown";
import './home.scss';
import featureFlags from '../../../featureFlags';

const volumeUnit = 2.75;

class HomeContainer extends Component {
  constructor(props) {
    super(...arguments);

    this.state = {
      newSearch: false,
      showSavedPlaylist: false,
      searchText: props.SearchText,
      volumeWidth: 100
    }

    Common.TrackPlayer.SetVolume(this.state.volumeWidth / 100);
    this.debouncedSearch = debounce(this.doSearch, 250);
    this.mainViewRef = React.createRef();
    localStorage.setItem('SearchedTrackIds', '');
  }

  componentDidUpdate(prevProps) {
    if (this.props.SearchTextFlag !== prevProps.SearchTextFlag && !this.props.SearchTextFlag) {
      this.setState({ searchText: '' });
    }
    if (this.props.SearchText !== prevProps.SearchText) {
      this.setState({ searchText: this.props.SearchText });
    }
  }

  componentDidMount() {
    if (this.props.location.pathname === "/passwordreset" && this.props.location.search.startsWith("?token=")) {
      const token = this.props.location.search.replace('?token=', '');      // TODO: FIX THIS WITH a REGEX
      this.props.history.push('/');
      this.props.dispatch(resetPassword(token));
      Common.Dialogs.ShowDialog('ResetPasswordDialog', true);
    }

    Common.UserInterface.GetNavigationMenu();
    Common.UserInterface.LoadAllMessages();
    Common.UserInterface.LoadContextMenu('Collection');
    Common.UserInterface.LoadContextMenu('Energy');
    Common.UserInterface.LoadContextMenu('Genre');
    Common.UserInterface.LoadContextMenu('LooksLike');
    Common.UserInterface.LoadContextMenu('Mood');
    Common.UserInterface.LoadContextMenu('Tempo');
    Common.UserInterface.LoadContextMenu('SoundsLike');
    Common.UserInterface.LoadContextMenu('Composer');
    Common.UserInterface.LoadSharedPlaylistsForUser()

    if (featureFlags.ShoppingCart && Common.IsAudiofficina()) {
      Common.UserInterface.LoadCart();
    }

    this.cancelBlur = false;

    if (this.props.history.location.pathname.toLowerCase() === '/trial') {
      const code = this.props.history.location.search.match(/code=([^&]*)/);
      if (code != null) {
        Common.UserInterface.GetTrialPeriod(code[1])
          .then(response => {
            if (response.trialPeriod > 0) {
              Common.Dialogs.ShowDialog('SurveyDialog', true, { code: code[1], trialPeriod: response.trialPeriod })
              this.props.history.push('/');
            }
          });
      }
    }

    //Search data according to filtered data
    if (localStorage.getItem('searchData') !== null) {
      const searchData = JSON.parse(localStorage.getItem('searchData'));
      Common.Search.AdvancedSearch(searchData, 0, Common.GetSearchSize(), true);
    }

    let wentToAdminPage = localStorage.getItem('wentToAdminPage');
    let wentToMainPage = localStorage.getItem('wentToMainPage');
    let showMultiplePlaylist = localStorage.getItem('ShowMultiplePlaylist');

    if ((wentToAdminPage !== null && wentToAdminPage === "true") && wentToMainPage !== null && wentToMainPage === "true") {
      if (localStorage.getItem("selectedPlaylist") !== null) {
        const playlistId = localStorage.getItem("selectedPlaylist");
        Common.Playlist.LoadPlaylist(playlistId,true);
      }
      
      if (showMultiplePlaylist !== null && showMultiplePlaylist === "true") {
        Common.UserInterface.ToggleMultiplePlaylist(true);
      }

      localStorage.removeItem("wentToAdminPage");
      localStorage.removeItem("wentToMainPage");
    }

    let selectedPlaylistView = false; 
    selectedPlaylistView = localStorage.getItem('SelectedPlaylistView')
    if (selectedPlaylistView !== null && selectedPlaylistView === "true") {
      Common.UserInterface.TogglePlaylistExtension(true);
    }
    else {
      Common.UserInterface.TogglePlaylistExtension(false);
    }
  }

  get DialogWindows() {
    const dialogs = [];
    if (this.Dialogs.AlbumInfo) dialogs.push(<AlbumInfo key='AlbumInfo' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.AccountDialog) dialogs.push(<AccountDialog key='AccountDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.ChangePasswordDialog) dialogs.push(<ChangePasswordDialog key='ChangePasswordDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.CueSheet) dialogs.push(<CueSheetMaker key='CueSheet' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.ExportPlaylist) dialogs.push(<ExportPlaylist key='ExportPlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.ExportCueSheet) dialogs.push(<ExportCueSheetDialog level={2} key='ExportCueSheet' onClose={this.handleCloseDialog.bind(this)} />)
    if (this.Dialogs.ImportCueSheet) dialogs.push(<ImportCueSheetDialog level={2} key='ImportCueSheet' onClose={this.handleCloseDialog.bind(this)} />)
    if (this.Dialogs.ImportPlaylistDialog) dialogs.push(<ImportPlaylistDialog key='ImportPlaylistDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.MergePlaylistDialog) dialogs.push(<MergePlaylistDialog key='MergePlaylistDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.MoreLikeThisDialog) dialogs.push(<MoreLikeThisDialog key='MoreLikeThisDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.NewCueSheet) dialogs.push(<NewCueSheetDialog level={2} key='NewCueSheet' onClose={this.handleCloseDialog.bind(this)} />)
    if (this.Dialogs.OpenCueSheet) dialogs.push(<OpenCueSheetDialog level={2} key='OpenCueSheet' onClose={this.handleCloseDialog.bind(this)} />)
    if (this.Dialogs.PrintCueSheet) dialogs.push(<PrintCueSheetDialog level={2} key='PrintCueSheet' onClose={this.handleCloseDialog.bind(this)} />)
    if (this.Dialogs.SaveCueSheet) dialogs.push(<SaveCueSheetDialog level={2} key='SaveCueSheet' onClose={this.handleCloseDialog.bind(this)} />)
    if (this.Dialogs.SaveCueSheetAs) dialogs.push(<SaveCueSheetAsDialog level={2} key='SaveCueSheetAs' onClose={this.handleCloseDialog.bind(this)} />)
    if (this.Dialogs.AddAllToPlaylist) dialogs.push(<AddAllToPlaylist key='AddAllToPlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.CreatePlaylist) dialogs.push(<CreatePlaylist key='CreatePlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.DeletePlaylist) dialogs.push(<DeletePlaylist key='DeletePlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.DuplicatePlaylist) dialogs.push(<DuplicatePlaylist key='DuplicatePlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.ImportPlaylist) dialogs.push(<ImportPlaylist key='ImportPlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.MergePlaylist) dialogs.push(<MergePlaylist key='MergePlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.OpenPlaylist) dialogs.push(<OpenPlaylist key='OpenPlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.SharePlaylist) dialogs.push(<SharePlaylist key='SharePlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.RenamePlaylist) dialogs.push(<RenamePlaylist key='RenamePlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.LoginDialog) dialogs.push(<LoginDialog key='LoginDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.ContactUsDialog) dialogs.push(<ContactUsDialog key='ContactUsDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.HowToDialog) dialogs.push(<HowToDialog key='HowToDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.Preferences) dialogs.push(<Preferences key='Preferences' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.SignUpDialog) dialogs.push(<SignUpDialog key='SignUpDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.ForgotPasswordDialog) dialogs.push(<ForgotPasswordDialog key='ForgotPasswordDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.ResetPasswordDialog) dialogs.push(<ResetPasswordDialog key='ResetPasswordDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.ProgressDialog) dialogs.push(<ProgressDialog key='ProgressDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.TrackHider) dialogs.push(<TrackHider key='TrackHider' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.MultiplePlaylist) dialogs.push(<MultiplePlaylist key='MultiplePlaylist' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.SurveyDialog) dialogs.push(<SurveyDialog key='SurveyDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.QuickTipsDialog) dialogs.push(<QuickTipsDialog key='QuickTipsDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.EditTrackDialog) dialogs.push(<EditTrackDialog key='EditTrackDialog' onClose={this.handleCloseDialog.bind(this)} trackId={this.Dialogs.Options.EditTrackDialog} />);
    if (this.Dialogs.PrivacyDialog) dialogs.push(<PrivacyDialog key='PrivacyDialog' onClose={this.handleCloseDialog.bind(this)} />);
    if (this.Dialogs.EditProfileDialog) dialogs.push(<EditProfileDialog key='EditProfileDialog' onClose={this.handleCloseDialog.bind(this)} />);
    return dialogs;
  }

  get Dialogs() {
    const { Dialogs } = this.props;
    return Dialogs ? Dialogs.toJS() : {};
  }

  get PreviousSearches() {
    const { PreviousSearches } = this.props;
    return PreviousSearches ? PreviousSearches.toJS() : [];
  }

  get Playlist() {
    const { Playlist } = this.props;
    return Playlist ? Playlist.toJS() : {};
  }

  get Search() {
    const { Search } = this.props;
    return Search ? Search.toJS() : {};
  }

  get UserInterface() {
    return this.props.UserInterface;
  }

  get UserDetails() {
    return Common.GetState().UserInterface.toJS().UserDetails || {};
  }

  handleClick() {
    Common.UserInterface.TogglePlaylistExtension(!this.UserInterface.ShowPlaylistExtension);
  }

  handleClosePlaylistDropDown(event) {
    const relatedTarget = event ? event.relatedTarget : null;
    const playlistItemSelector = '.main-header .saved-playlist-item';
    const playlistTabSelector = '.main-header .playlist-tab';
    const playlistTitleSelector = '.main-header .playlist-title';
    const playlistLabelSelector = '.main-header .playlist-label';

    const skip = (relatedTarget && relatedTarget == document.querySelector(playlistItemSelector)) ||
      (relatedTarget == document.querySelector(playlistTabSelector)) ||
      (relatedTarget == document.querySelector(playlistTitleSelector)) ||
      (relatedTarget == document.querySelector(playlistLabelSelector));

    if (skip) return;

    this.setState({ showSavedPlaylist: false });
   
  }

  handleCloseDialog(name) {
    Common.Dialogs.ShowDialog(name, false);
  }

  handlePreferences() {
    Common.Dialogs.ShowDialog('Preferences', true);
  }

  handleLoadPreviousSearch() {
    Common.Search.LoadPreviousSearch();
  }

  handleQuickSearchFocus = () => {
    this.doSearch(this.getSearchFieldText());
    Common.UserInterface.ShowSuggestionsDropDown(true);
  }

  handleQuickSearchKeyUp = e => {
    switch (e.which) {
      case 13:
        this.handleSearchClick(e);
        break;
      case 27:
        Common.UserInterface.ShowSuggestionsDropDown(false);
        break;
      default:
        debounce(Common.UserInterface.ShowSuggestionsDropDown(true), 50);
        break;
    }
  }

  handleQuickSearchBlur = () => {
    setTimeout(() => {
      if (!this.cancelBlur) {
        Common.UserInterface.ShowSuggestionsDropDown(false);
      } else {
        this.cancelBlur = false;
        this.searchField.focus();
      }
    }, 20);
  }

  handleQuickSearchChange = value => {
    this.setState({ searchText: value }, () => {
      this.debouncedSearch(value);
    })
  }

  doSearch = (value) => {
    Common.UserInterface.SetQuickSearchText(value);
    this.state.newSearch = true;
    value = value.replace(/[^\s\w'"&()-]/gi, "");
    if (value.trim() !== '') {
      Common.Search.GetQuickSearchSuggestions(value);
    } else {
      Common.Search.GetQuickSearchSuggestions(null);
    }
  }

  getSearchFieldText = () => {
    return this.searchField.value.toLowerCase();
  }

  handleClearSearch = () => {
    if (Common.TrackPlayer.IsPlaying) Common.TrackPlayer.Stop();
    Common.UserInterface.ClearQuickSearch();
    Common.Search.ClearSearchResults();
    Common.UserInterface.ClearAdvancedSearch();
  }

  handleSearchClick = () => {
    this.searchField.blur();
    if (this.state.searchText.trim() !== '') {
      Common.UserInterface.ClearAdvancedSearch(false);
      const searchQuery = createEmptyAdvancedSearch();
      searchQuery.SearchText = this.state.searchText;
      Common.Search.AdvancedSearch(searchQuery, 0, Common.GetSearchSize(), true);
      this.state.newSearch = false;
      localStorage.setItem('searchData', JSON.stringify(searchQuery));
      Common.Search.SaveQuickSearchSuggestions(this.state.searchText.trim(), 'Keyword');
      return;
    }

    if (Common.UserInterface.HasAdvancedSearchFiltersSelected()) {
      Common.Search.AdvancedSearch(this.UserInterface.AdvancedSearch, 0, Common.GetSearchSize(), true);
      localStorage.setItem('searchData', JSON.stringify(this.UserInterface.AdvancedSearch));
    } else {
      this.handleClearSearch();
    }
    this.state.newSearch = false;
  }

  handleShowSavedPlaylist() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    if (!this.state.showSavedPlaylist) {
      fetchAuth()
        .then(value => {
          this.setState({ showSavedPlaylist: true });
        })
        .catch((err) => {
          Common.Dialogs.ShowDialog('SignUpDialog', true);
        });
    } else {
      this.setState({ showSavedPlaylist: false });
    }
  }

  handleReset() {
    if (Common.TrackPlayer.IsPlaying) Common.TrackPlayer.Stop();
    this.setState({ searchText: '' });
    Common.UserInterface.ClearQuickSearch();
    Common.UserInterface.ClearAdvancedSearch();
    let selectedMultiplePlaylistIds = localStorage.getItem("playlistIds");
    let showMultiplePlaylist = localStorage.getItem('ShowMultiplePlaylist');

    // 08/31/2020 - DO NOT CLEAR OUT AUDITIONED TRACKS
    const auditionedTracks = localStorage.getItem('audition-history')
    
    localStorage.clear();
    localStorage.setItem("audition-history", auditionedTracks)
    localStorage.setItem("playlistIds", selectedMultiplePlaylistIds)
    localStorage.setItem("ShowMultiplePlaylist", showMultiplePlaylist)
  }

  handleSelectFilter(filterType, filterValue) {
    if (filterType === 'keywords') {
      this.handleSearchClick();
    }
    else {
      this.state.newSearch = false;
      this.setState({ searchText: filterValue.Name });
      filterValue.FilterType = filterType;
      Common.UserInterface.ClearAdvancedSearch();
      Common.UserInterface.SetQuickSearchText('');
      Common.UserInterface.AddSearchFilter(filterValue);
      Common.Search.SaveQuickSearchSuggestions(filterValue.Name, 'Suggestion');
    }
  }

  hasSuggestions() {
    const suggestions = this.Search.SearchSuggestions;

    return (
      (suggestions.Collections || []).length > 0 ||
      (suggestions.Genres || []).length > 0 ||
      (suggestions.Moods || []).length > 0 ||
      (suggestions.LooksLike || []).length > 0 ||
      (suggestions.SoundsLike || []).length > 0
    )
  }

  openLink(url) {
    window.open(url, '_blank');
  }

  handleVolumeChange = (event) => {
    this.setState({ volumeWidth: event.target.value });
    Common.TrackPlayer.SetVolume(event.target.value / 100);
  }

  handlePrivacyClick() {
    Common.Dialogs.ShowDialog('PrivacyDialog', true);
  }

  render() {
    const fillStyle = { width: `${this.state.volumeWidth - 1}%` };
    const searchFieldClass = classNames('search-field', {
      'hidden': this.UserInterface.ShowPlaylistExtension
    });

    const searchButtonClass = classNames({
      'execute-search': true,
      'hidden': this.UserInterface.ShowPlaylistExtension
    });

    const previousSearchClass = classNames({
      'previous-search': true,
      'hidden': this.UserInterface.ShowPlaylistExtension
    });

    const previousSearchDisabled = (this.PreviousSearches.length < 2);
    const playlistExtensionClass = classNames({
      'playlist-extension-panel': true,
      'expanded': this.UserInterface.ShowPlaylistExtension
    });
    const searchExtensionClass = classNames({
      'search-extension-panel': true,
      'expanded': !this.UserInterface.ShowPlaylistExtension
    });
    const mainHeaderStyle = { top: (process.env.NAVIGATION_TOP) };
    const mainContentStyle = { top: (process.env.NAVIGATION_TOP + 90) };
    const navigationMenu = (process.env.NAVIGATION_MENU)
      ? <Flex fixed className='main-navbar'><NavigationMenu /></Flex>
      : '';

    const trackHiderMessage = this.UserInterface.AdvancedSearch.ExcludePlaylistTracks
      ? <div className='track-hider-container'>
        <span className='track-hider-indicator' onClick={() => Common.UserInterface.SetSelectedPlaylistTab(1)}>Track Hider On</span>
      </div>
      : '';


    const multiplePlaylistMessage = this.UserInterface.ShowMultiplePlaylist
      ? <div className='multiple-playlist-container'>
        <span className='multiple-playlist-indicator' onClick={() => Common.UserInterface.SetSelectedPlaylistTab(1)}>Multiple Playlist On</span>
      </div>
      : '';

    const suggestionsDropDownElement = this.UserInterface.ShowSuggestionsDropDown && (this.state.searchText || '').length > 0
      ? (<SuggestionsDropDown
        show={true}
        suggestions={this.Search.SearchSuggestions}
        onSelect={this.handleSelectFilter.bind(this)}
        //onSearch={this.handleSearchClick}
        onSearch={this.handleSelectFilter.bind(this)}
        onSearchMouseDown={() => this.cancelBlur = true}
        searchText={this.getSearchFieldText()}
        mainViewRef={this.mainViewRef} />)
      : '';  

    const playlistDropDown = this.state.showSavedPlaylist ? (
      <PlaylistDropDown onCloseMenu={this.handleClosePlaylistDropDown.bind(this)} />) : '';

    const mainHeader = !this.UserInterface.ShowPlaylistExtension
      ? (
        <Flex row fixed className='main-header' style={mainHeaderStyle}>
          <Flex fixed className='logo-area'>            
            <div className={isDiscoveryUser() ? 'logo wb' : 'logo'}></div>            
          </Flex>
          <Flex row className='quick-search-field'>
            <Flex>
              <TextField tooltip = "SEARCH-TEXT-FIELD" ref={(input) => { this.searchField = input }}
                inputClass='quick-search-textfield' height='50px'
                value={this.state.searchText}
                className={searchFieldClass}
                placeholder={this.UserInterface.QuickSearchPlaceholder}
                onKeyUp={this.handleQuickSearchKeyUp}
                onChange={this.handleQuickSearchChange}
                onFocus={this.handleQuickSearchFocus}
                onBlur={this.handleQuickSearchBlur} />
              {suggestionsDropDownElement}
            </Flex>
            <Flex row fixed width={180} position='left'>
              <Button tooltip = "RESET-SEARCH-BUTTON" positionToolTip = "bottom" className={previousSearchClass} type='secondary' size='lg' onClick={this.handleReset.bind(this)}>RESET</Button>
            </Flex>
          </Flex>
          <Flex fixed width={405} style={{ 'display': 'inline-grid', 'alignContent': 'center' }}>
            <div className='volume-slider-container'>
              <FontIcon name='volume-mute-x' size={22} style={{position: 'relative', top: -5}} />
              <div className='volumeRangeContainer'>
                <div className="fillRange" style={fillStyle}></div>
                <div className="blankRange"></div>
                <input className='volumeRange' type="range" name="points"
                  min="0" max="100" value={this.state.volumeWidth}
                  onInput={(e) => this.handleVolumeChange(e)}
                  onChange={(e) => this.handleVolumeChange(e)} />
              </div>
              <FontIcon name='volume-high' size={20} style={{position: 'relative', top: -5}} />
            </div>
            <div className='notification-slider'>
              {trackHiderMessage}
              {multiplePlaylistMessage}
            </div>
          </Flex>
        </Flex>
      )
      : (
        <Flex row fixed className='main-header' style={mainHeaderStyle}>
          <Flex fixed className='logo-area'>
            <div className={isDiscoveryUser() ? 'logo wb' : 'logo'}></div>      
          </Flex>
          <Flex position='left'>
            <div className='playlist-tab' onClick={this.handleShowSavedPlaylist.bind(this)} onMouseLeave={this.handleClosePlaylistDropDown.bind(this)}>
              <div className='playlist-label'>Playlist:</div>
              <div className='playlist-title'>
                {this.Playlist.Title} <FontIcon name={this.state.showSavedPlaylist ? 'chevron-up' : 'chevron-down'} /></div>
            </div>
            {playlistDropDown}
          </Flex>
          <Flex row fixed width={405} style={{ 'display': 'inline-grid', 'alignContent': 'center' }}>
            <Flex row fixed>
              <FontIcon name='volume-mute-x' size={22} style={{position: 'relative', top: -5}} />
              <div className='volumeRangeContainer'>
                <div className="fillRange" style={fillStyle}></div>
                <div className="blankRange"></div>
                <input className='volumeRange' type="range" name="points"
                  min="0" max="100" value={this.state.volumeWidth}
                  onInput={(e) => this.handleVolumeChange(e)}
                  onChange={(e) => this.handleVolumeChange(e)} />
              </div>
              <FontIcon name='volume-high' size={20} style={{position: 'relative', top: -5}} />
            </Flex>
            <Flex className='notification-slider-playlistview'>
              {trackHiderMessage}
              {multiplePlaylistMessage}
            </Flex>
          </Flex>
        </Flex>

      );

   const currentYear = new Date().getFullYear();

    return (
      <Flex fill className='main-view web' setRefCallBack={ref => this.mainViewRef = ref}>
        {navigationMenu}
        {mainHeader}
        <Flex row className='main-content' style={mainContentStyle}>
          <Flex row>
            <Flex className={playlistExtensionClass}>
              <PlaylistExtension isVisible={this.UserInterface.ShowPlaylistExtension} />
            </Flex>
            <Flex className={searchExtensionClass}>
              <SearchExtension isVisible={!this.UserInterface.ShowPlaylistExtension} />
            </Flex>
          </Flex>
        </Flex>
        <Flex row position='left' height={30} className='main-footer'>
          <Flex row position='left'>
            <div>The Supervisor, &#xA9;{currentYear}</div>
            <div><Flex row inline><FontIcon name='app-logo' size={22} /> <span className={'padding-left-5'}>Scorekeepers Music, Inc.</span></Flex>
            </div>
            <div>
              <Flex row inline>
                Follow Us:
                <span className={'padding-left-5'}><FontIcon name='facebook' className={'link'}
                  size={20}
                  onClick={this.openLink.bind(this, 'https://www.facebook.com/ScoreKeepersMusic/')} /></span>
                <span className={'padding-left-5'}><FontIcon name='twitter' className={'link'} size={20}
                  onClick={this.openLink.bind(this, 'https://twitter.com/scorekeepers')} /></span>
                <span className={'padding-left-5'}><FontIcon name='instagram' className={'link'}
                  size={20}
                  onClick={this.openLink.bind(this, 'https://www.instagram.com/scorekeepers_music/')} /></span>
                <span className={'padding-left-5'}><FontIcon name='play' className={'link'}
                  size={20}
                  onClick={this.openLink.bind(this, 'https://www.youtube.com/channel/UCbh3Wni2VTw5ZhBYqQEL_QA?view_as=subscriber')} /></span>
              </Flex>
              <Flex row inline className={'padding-left-40'}>
                <a className="privacy-link" onClick={this.handlePrivacyClick.bind()}>Privacy Statement</a>
              </Flex>
            </div>
          </Flex>
          <Flex row position={'right'}>
            {/* www.scorekeepersmusic.com, phone (323) 544-9200, email: click here */}
          </Flex>
        </Flex>
        {this.DialogWindows}
        <div className='dialog-top-bg hidden'></div>
        <div className='suggestion-icon hidden'></div>
        <div id='body-render'></div>
      </Flex>
    )
  }
}

export default connect((state) => {
  const ui = state.UserInterface ? state.UserInterface.toJS() : {};
  return {
    Dialogs: state.Dialogs,
    PreviousSearches: state.PreviousSearches,
    Search: state.Search,
    Playlist: state.Playlist,
    UserInterface: ui,
    SearchTextFlag: (ui.AdvancedSearch || {}).SearchTextFlag,
    SearchText: ((ui.AdvancedSearch || {}).SearchText) || ''
  }
})(withRouter(HomeContainer));
