import React from 'react';
import { FontIcon, Typewriter } from 'components/ui';

class SurveyPage1 extends React.Component {   
    getDialogResponse = () => {
        return null;
    }

    validatePage = () => {
        return true;
    }

    render() {
        let dateRange = 'January 1, 2021 to January 31, 2021';

        switch (this.props.state.trialPeriod) {
            case 2: 
                dateRange = 'April 22, 2019 to June 22, 2019';
                break;
            case 3:
                dateRange = 'April 22, 2019 to July 22, 2019';
                break
            case 4:
                dateRange = 'January 1, 2021 to January 31, 2021';
                break;
        }

        return (
            <div className='survey-page-1'>                                
                <div className='survey-page-header'>
                    <FontIcon name='inspector' />
                    <Typewriter message={ `Congratulations on winning a 1 month blanket music license from ScoreKeepers!` } />
                </div>
                <div className='survey-page-content'>
                    <div><b>Terms and conditions:</b></div>
                    <div className='textarea'>                        
                        This license agreement grants you the right to download music via The Supervisor from The ScoreKeepers Music library (“Music”) and synchronize that Music with your 
                        audio/visual productions (“Productions”), excluding national brand advertising, from {dateRange}. The Music is authorized for broadcast in Productions 
                        completed during the term in perpetuity and throughout the world. Any further or different uses of the Music are prohibited without an additional license agreement 
                        from ScoreKeepers Music, Inc. Upon expiration of this agreement, unless otherwise authorized by ScoreKeepers, all Music files in your possession including temporary
                        copies made by you during the course of normal production must be deleted. By proceeding, you agree to these terms and conditions. This offer is only extended to the 
                        recipient, sharing of the access link, or login is expressly forbidden.
                    </div>
                </div>
            </div>
        )
    }
}

export default SurveyPage1;