const PLAYLIST_DROPDOWN_MAX_WIDTH = 295;

import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Button, Flex, FontIcon, IgnoreErrors, RenderIf, ScrollWindow, Tabs, Tab } from 'components/ui';
import { PlaylistTracks } from './playlist-tracks/playlist-tracks';
import PlaylistDropDown from './playlist-dropdown';
import Switch from 'react-toggle-switch';
import 'react-toggle-switch/dist/css/switch.min.css';
import './playlist.scss';

import { basicFieldSorter, getTranslation } from 'utility/common';
import { setAdvancedSearchFieldValue } from '../../../../store/userInterface/actions';
import ReactTooltip from 'react-tooltip';
import { Badge } from 'material-ui';

const waveformWidth = 250;

class PlaylistPanel extends React.Component {
  constructor() {
    super(...arguments);
    const toolTipMessage = {
      'importPlaylist':"IMPORT A SCOREKEEPERS .XML PLAYLIST FILE",
      'exportPlaylist':"EXPORT A SCOREKEEPERS .XML PLAYLIST FILE",
      'renamePlaylist':"SELECT AND RENAME ANY PLAYLIST",
      'mergePlaylist':"SELECT AND MERGE ANY TWO PLAYLISTS",
      'duplicatePlaylist':"SELECT AND DUPLICATE ANY PLAYLIST",
      'deletePlaylist':"SELECT AND DELETE ANY PLAYLIST",
      'deletePlaylist':"SELECT AND DELETE ANY PLAYLIST",
      'exportALEPlaylist':"EXPORT AN AVID COMPATIBLE .ALE FILE TO CREATE SIFTABLE AVID BINS",
      'exportCSVPlaylist':"EXPORT AN EXCEL COMPATIBLE .CSV FILE OF PLAYLIST TRACK DATA",
      'trackHider':"EXCLUDE TRACKS IN A PLAYLIST FROM YOUR SEARCH RESULTS",
      'multiplePlaylist':"ADD TRACKS TO AND WORK WITH MULTIPLE PLAYLISTS",
      'sharePlaylist':'GET A URL TO SHARE YOUR ACTIVE PLAYLIST TRACKS ONLINE'
    }

    this.state = {
      showSavedPlaylist: false,
      showSharedPlaylist: false,
      hideTracksInSearch: null,
      toolTip : toolTipMessage || ''

    }
  }

  get IsAdmin() {
    const userDetails = this.UserInterface.UserDetails
    if (userDetails != null) {
      return userDetails.IsAdmin
    }

    return false;
  }

  get Playlist() {
    const { Playlist } = this.props;
    return Playlist ? Playlist.toJS() : {};
  }

  get PlaylistTracks() {
    return (this.Playlist.Tracks || []).sort(basicFieldSorter("Title"));
  }

  get SelectedTrack() {
    return this.UserInterface.SelectedPlaylistTrack;
  }

  get UserInterface() {
    const { UserInterface } = this.props;
    return UserInterface ? UserInterface.toJS() : {};
  }

  get UserDetails() {
    return Common.GetState().UserInterface.toJS().UserDetails || {};
  }

  get SearchTrack() {
    const { Search } = this.props;
    return Search ? Search.toJS() : {};
  }

  handleCloseDropDown() {
    this.setState({ showSavedPlaylist: false });
  }

  handleCreatePlaylist() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ShowCreatePlaylistWindow();
  }

  handleDeletePlaylist() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ShowDeletePlaylistWindow();
  }

  handleDownloadPlaylistTracks(event) {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    const rect = event.target.getBoundingClientRect();
    Common.UserInterface.ShowDownloadTrack(null, rect, this.Playlist.Id);
  }

  handleDuplicatePlaylist() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ShowDuplicatePlaylistWindow();
  }

  handleSetPlaylistTrackToAdminPage(Tracks, PlayListViewStatus) {
    let trackIds = [];
    Tracks.forEach(subItem => {
      trackIds.push(subItem.Id)
    });
    localStorage.setItem('SearchedTrackIds', trackIds)
    localStorage.setItem('SelectedPlaylistView', PlayListViewStatus)
  }

  handleExpandPlaylist() {

    if (this.Playlist.Id !== null) {
      if (!this.UserInterface.ShowPlaylistExtension) {
        this.handleSetPlaylistTrackToAdminPage(this.Playlist.Tracks,true)
      }
      else {
        this.handleSetPlaylistTrackToAdminPage(this.SearchTrack.SearchResults.Tracks,false)
      }
    }


    Common.UserInterface.TogglePlaylistExtension(!this.UserInterface.ShowPlaylistExtension);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  }

  handleExportPlaylistAsAvid() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Dialogs.ShowDialog('ExportPlaylist', true);
  }

  handleExportPlaylistAsFinalCut() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ExportPlaylistAsFinalCut();
  }

  handleExportPlaylistAsText() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ExportPlaylistAsText();
  }

  handleExportPlaylist() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ExportPlaylistAsXML();
  }

  handleImportPlaylist() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ShowImportPlaylistWindow();
  }

  handleLoadPlaylist(playlistId) {
    Common.Playlist.LoadPlaylist(playlistId, false);
  }

  handleMergePlaylist() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ShowMergePlaylistWindow();
  }

  handleOnPlayTrack(track) {
    this.webPlayer.handlePlay(track);
  }

  handleOpenPlaylist() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ShowOpenPlaylistWindow();
  }

  handleRenamePlaylist() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    Common.Playlist.ShowRenamePlaylistWindow();
  }

  handleScrollWindowClick(e) {
    e.stopPropagation();
    if (e.target.closest('.track') == null)
      Common.UserInterface.SetSelectedPlaylistTrack(null);
  }

  handleShowSavedPlaylist() {
    Common.Playlist.GetPlaylists();    
    this.setState({ showSavedPlaylist: !this.state.showSavedPlaylist });
  }

  handleTabSelect = (selectedTab) => {
    if (selectedTab == 0 && this.UserInterface.SelectedPlaylistTab == 0) {
      this.handleShowSavedPlaylist();
      return;
    }

    Common.UserInterface.SetSelectedPlaylistTab(selectedTab);
  }

  showSharePlaylist() {
    return this.UserInterface.UserDetails
      ? this.UserInterface.UserDetails.IsAdmin || this.UserInterface.UserDetails.SharedFlag
      : false;
  }

  playlistSearchExclusionsLink() {
    if (this.showSharePlaylist()) {
      const onValue = this.UserInterface.AdvancedSearch.ExcludePlaylistTracks || false;

      return (
        <div className='link'>
          <div data-tip={this.state.toolTip.trackHider} data-for="tooltip" className='track-hider-link'>
            <div style={{ display: 'inline-block' }} onClick={() => { Common.Dialogs.ShowDialog('TrackHider', true) }}>
              <FontIcon
                name='list'
                size={16}
                className='playlist-icon' />{getTranslation("Track Hider")} ...
              </div>
            <div className='track-hider-container'>
              <Switch on={onValue} onClick={this.toggleSearchTrackHider} />
            </div>
          </div>
        </div>
      )
    } else {
      return '';
    }
  }

  playWithMultiplePlaylist() {
    if (this.showSharePlaylist()) {
    // if (this.showSharePlaylist() || window.Environment === 'home-electron') {
      const onValue = this.UserInterface.ShowMultiplePlaylist || false;
      return (
        <div data-tip={this.state.toolTip.multiplePlaylist} data-for="tooltip" className='link'>
          <div className='track-hider-link'>
            <div style={{ display: 'inline-block' }} onClick={() => { Common.Dialogs.ShowDialog('MultiplePlaylist', true) }}>
              <FontIcon
                name='list'
                size={16}
                className='playlist-icon' />{getTranslation("Multiple Playlists", "it")} ...
            </div>
            <div className='track-hider-container'>
              <Switch on={onValue} onClick={this.toggleMultiplePlaylist} />
            </div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  }

  toggleMultiplePlaylist = () => {
    var value = !this.UserInterface.ShowMultiplePlaylist;
    Common.UserInterface.ToggleMultiplePlaylist(value);
  }

  toggleSearchTrackHider = () => {
    var value = !this.UserInterface.AdvancedSearch.ExcludePlaylistTracks;
    this.props.dispatch(setAdvancedSearchFieldValue("ExcludePlaylistTracks", value));

    if (Common.UserInterface.HasAdvancedSearchFiltersSelected()) {
      setTimeout(() => {
        Common.Search.AdvancedSearch(this.UserInterface.AdvancedSearch, 0, Common.GetSearchSize(), true);
      }, 1);
    }
  }

  sharePlaylist() {
    Common.Playlist.SetDefaultSharePlaylist();
    Common.Dialogs.ShowDialog('SharePlaylist', true);
  }

  sharedPlaylistLink() {
    if (this.showSharePlaylist()) {
      if (this.Playlist.Tracks.length > 0) {
        return (
          <div  className='link' onClick={() => this.sharePlaylist()}>
            <FontIcon
              name='import'
              size={16}
              className='playlist-icon' />{getTranslation("Share Playlist")} ...
          </div>
        )
      } else {
        return (
          <div className='link disabled'>
            <FontIcon
              name='import'
              size={16}
              className='playlist-icon' />{getTranslation("Share Playlist")} ...
          </div>
        )
      }
    } else {
      return '';
    }
  }

  render() {
    const chevronType = this.state.showSavedPlaylist
      ? (<FontIcon name='chevron-up' />)
      : (<FontIcon name='chevron-down' />);

      const sharedPlaylistLink = this.showSharePlaylist()
      ? (
        <div className='link' onClick={() => this.sharePlaylist()}>
          <FontIcon
            name='download-all'
            size={16}
            className='playlist-icon' /> Share Playlist ...
        </div>
      )
      : '';

    const playlistTitle = (
      <div>
        <div className='playlist-title-header' style={{position: 'relative', top: '5px'}}>
          <RenderIf condition={this.UserInterface.UserDetails != null && this.UserInterface.UserSharedPlaylists.filter(x => !x.HasBeenViewed).length > 0 && !this.state.showSavedPlaylist}>
            <div style={{position: 'absolute', top: -15, left: -55, zIndex: 100}}>
              <Badge badgeContent={this.UserInterface.UserSharedPlaylists.length} badgeStyle={{backgroundColor: 'red', color: 'white', fontSize: '110%'}} />
            </div>
          </RenderIf>
          {getTranslation("Active Playlist")}
          {this.UserInterface.UserDetails || (process.env.IsDesktopVersion) ?
            <div className='show-playlists' style={{position: 'absolute', right: -35, top: 0}}>
              {chevronType}
            </div>
            : null
          }
        </div>
        <div className='playlist-title ellipsis' style={{position: 'relative', top: 5}}>{this.Playlist.Title}</div>
      </div>
    )

    const playlistOptions = (<div style={{whiteSpace: 'break-spaces'}}>{getTranslation("Playlist options")}</div>)

    const expandButton = this.UserInterface.ShowPlaylistExtension
      ? (
        <Button className='expand-btn' type='default' size='md' onClick={this.handleExpandPlaylist.bind(this)}><FontIcon
          name='eye' />{getTranslation("Back to Search View")}</Button>)
      : (
        <IgnoreErrors><Button tooltip="PLAYLIST-VIEW" className='expand-btn' type='default' size='md' onClick={this.handleExpandPlaylist.bind(this)}><FontIcon
          name='eye' />{getTranslation("Playlist view")}</Button></IgnoreErrors>);

    const playlistHeaderClass = classNames({
      'playlist-header': true,
      'hidden': (this.SelectedTrack == null)
    });

    let playlists = this.UserInterface.UserPlaylists;
    let sharedPlaylists = this.UserInterface.UserSharedPlaylists
    let userPlaylistItems = [];
    if (this.UserInterface.ShowMultiplePlaylist && typeof playlists !== 'undefined') {
      let playlistIds = localStorage.getItem('playlistIds');

      let selectedPlaylists = JSON.parse(playlistIds);
      if (selectedPlaylists) {
        playlists.map((p, i) => {
          selectedPlaylists.map((x) => {
            if (x === p.Id) {
              userPlaylistItems.push(p);
            }
          })
        })
      }
    } else {
      userPlaylistItems = playlists
    }

    const savedPlaylistDropDown = this.state.showSavedPlaylist
      ? this.IsAdmin
        ? <PlaylistDropDown savedPlaylists={userPlaylistItems} />
        : (<SavedPlaylistDropDown userPlaylists={userPlaylistItems}
             onCloseDropDown={this.handleCloseDropDown.bind(this)}
             onSelectPlaylist={this.handleLoadPlaylist.bind(this)} />)
      : '';

    const playlistFooter = true
      ? (
        <Flex row fixed className='playlist-footer'>
          <Flex className='buttons'>
            {expandButton}
          </Flex>
          <Flex className='track-count'>
            {this.Playlist.Tracks.length} Tracks
          </Flex>
        </Flex>
      )
      : (
        <Flex row fixed className='playlist-footer'>
          <Flex className='track-count'>
            {this.Playlist.Tracks.length} Tracks
          </Flex>
          <Flex className='buttons'>
            {expandButton}
          </Flex>
        </Flex>
      );

    const savedPlaylistTabStyle = this.UserInterface.ShowPlaylistExtension ? { display: 'none' } : {};

    return (
      <Flex className='playlist' fixed onMouseLeave={this.handleCloseDropDown.bind(this)}>
        <Tabs>
          <Tab className='playlist-tab' label={playlistTitle} style={savedPlaylistTabStyle} width='2/3'
            selected={this.UserInterface.SelectedPlaylistTab == 0} textAlign='left'
            onTabSelect={this.handleTabSelect.bind(this, 0)}>
            <Flex hide={!this.UserInterface.SelectedPlaylistTab == 0 || this.UserInterface.ShowPlaylistExtension}>
              {savedPlaylistDropDown}
              <Flex fixed className={playlistHeaderClass}>
                <PlaylistPlayer ref={(i) => this.webPlayer = i} track={this.SelectedTrack} />
              </Flex>
              <Flex className='playlist-content' onClick={this.handleScrollWindowClick.bind(this)}>
                <div className='playlist-container'>
                  <PlaylistTracks
                    owner={this}
                    tracks={this.PlaylistTracks}
                    selectedTrack={this.SelectedTrack}
                    onPlayTrack={this.handleOnPlayTrack.bind(this)}
                    playListId={this.Playlist.Id} />
                </div>
              </Flex>
              {playlistFooter}
            </Flex>
          </Tab>
          <Tab label={playlistOptions}
            selected={this.UserInterface.ShowPlaylistExtension || this.UserInterface.SelectedPlaylistTab == 1}
            onTabSelect={this.handleTabSelect.bind(this, 1)}>
            <Flex hide={!this.UserInterface.SelectedPlaylistTab == 1 && !this.UserInterface.ShowPlaylistExtension}>
              <Flex className='playlist-content'>
                <ScrollWindow className='playlist-options-list'>
                  <div className='section'>
                    <ReactTooltip id="tooltip" type="light" place="left" multiline={true} effect="solid" delayHide={1000}></ReactTooltip>

                    <div className='link' onClick={this.handleCreatePlaylist.bind(this)}><FontIcon name='new-list'
                      size={16}
                      className='playlist-icon' />{getTranslation("New Playlist")} ...
                    </div>
                    <div className='link' onClick={this.handleOpenPlaylist.bind(this)}><FontIcon name='circle-plus'
                      size={16}
                      className='playlist-icon' />{getTranslation("Open Saved Playlist")} ...
                    </div>
                    <div data-tip={this.state.toolTip.importPlaylist} data-for="tooltip" className='link' onClick={this.handleImportPlaylist.bind(this)}><FontIcon name='export'
                      size={16}
                      className='playlist-icon' />{getTranslation("Import Playlist XML")} ...
                    </div>
                    <div data-tip={this.state.toolTip.exportPlaylist} data-for="tooltip" className='link' onClick={this.handleExportPlaylist.bind(this)}><FontIcon name='import'
                      size={16}
                      className='playlist-icon' />{getTranslation("Export Playlist XML")} ...
                  </div>
                  </div>
                  <div className='section'>
                    <div  data-tip={this.state.toolTip.renamePlaylist} data-for="tooltip" className='link' onClick={this.handleRenamePlaylist.bind(this)}><FontIcon name='rename'
                      size={16}
                      className='playlist-icon' />{getTranslation("Rename")} ...
                    </div>
                    <div  data-tip={this.state.toolTip.mergePlaylist} data-for="tooltip" className='link' onClick={this.handleMergePlaylist.bind(this)}><FontIcon name='merge' size={16}
                      className='playlist-icon' />{getTranslation("Merge")} ...
                    </div>
                    <div  data-tip={this.state.toolTip.duplicatePlaylist} data-for="tooltip" className='link' onClick={this.handleDuplicatePlaylist.bind(this)}><FontIcon name='duplicate'
                      size={16}
                      className='playlist-icon' />{getTranslation("Duplicate")} ...
                    </div>
                  </div>
                  <div className='section'>
                    <div data-tip={this.state.toolTip.deletePlaylist} data-for="tooltip" className='link' onClick={this.handleDeletePlaylist.bind(this)}><FontIcon name='close'
                      size={16}
                      className='playlist-icon' />{getTranslation("Delete")} ...
                    </div>
                  </div>
                  <div className='section'>
                    <div data-tip={this.state.toolTip.exportALEPlaylist} data-for="tooltip" className='link' onClick={this.handleExportPlaylistAsAvid.bind(this)}>
                      <div className='playlist-icon-export-avid' />{getTranslation("Export as Avid ALE")} ...</div>
                    {/* <div className='link' onClick={this.handleExportPlaylistAsFinalCut.bind(this)}><div className='playlist-icon-export-final-cut' /> Export as Final Cut ...</div> */}
                    <div data-tip={this.state.toolTip.exportCSVPlaylist} data-for="tooltip" className='link' onClick={this.handleExportPlaylistAsText.bind(this)}><FontIcon name='csv-file'
                      size={16}
                      className='playlist-icon' /> Export as CSV ...
                    </div>
                  </div>
                  <div className='section'>
                    {this.playlistSearchExclusionsLink()}
                    {this.playWithMultiplePlaylist()}
                    {this.sharedPlaylistLink()}
                    <div className='link' onClick={this.handleDownloadPlaylistTracks.bind(this)}><FontIcon
                      name='download-all' size={16} className='playlist-icon' /> { Common.GetDownloadAllTracksText() }
                    </div>
                  </div>
                </ScrollWindow>
              </Flex>
              <Flex row fixed className='playlist-footer'>
                <Flex className='buttons'>
                  {expandButton}
                </Flex>
                <Flex className='track-count'>
                  {this.Playlist.Tracks.length} {getTranslation("Tracks")}
                </Flex>
              </Flex>
            </Flex>
          </Tab>
        </Tabs>
      </Flex>
    )
  }
}

class PlaylistPlayer extends React.Component {
  constructor() {
    super(...arguments);

    this.handleTrackEvent = (event) => {
      if (Common.TrackPlayer.AudioSource != "playlist") return;

      switch (event.eventType) {
        case "error": {
          //alert("Unable to play audio track.")
          break;
        }
        case "playing": {
          this.state.isPlaying = true;
          this.forceUpdate();
          break;
        }
        case "loadstart": {
          this.state.isPlaying = true;
          this.forceUpdate();
          break;
        }
        case "canplay": {
          if (this.tracker) {
            this.tracker.style.display = 'block';
          }
          break;
        }
        case "progress": {
          if (this.loaded) {
            this.loaded.style.clip = `rect(0px,${event.percentage * waveformWidth}px,30px,0px)`
          }
          break;
        }
        case "timeupdate": {
          if (this.playback && this.tracker) {
            this.setState({ trackDuration: event.current });
            this.playback.style.clip = `rect(0px,${event.percentage * waveformWidth}px,30px,0px)`
            this.loaded.style.clip = `rect(0px,${event.loaded * waveformWidth}px,30px,0px)`
            this.tracker.style.left = (event.percentage * 100) + '%';
            this.tracker.style.display = 'block';
          }
          break;
        }
        case "paused": {
          this.state.isPlaying = false;
          this.forceUpdate();
          break;
        }
        case "reset": {
          if (this.loaded) {
            this.loaded.style.clip = `rect(0px,0px,30px,0px)`
          }
          break;
        }
        case "ended": {
          this.state.isPlaying = false;
          if (this.playback && this.tracker) {
            this.playback.style.clip = `rect(0px,0px,30px,0px)`
            this.tracker.style.display = 'none';
            this.tracker.style.left = 0;
            this.setState({ trackDuration: 0 });
          }
          this.forceUpdate();
          break;
        }
      }
    }

    this.state = {
      isPlaying: false,
      trackDuration: 0
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.track != nextProps.track) {
      if (this.props.track != null)
        Common.TrackPlayer.UnregisterTrackNotifications(this.props.track.Id, this.handleTrackEvent);

      if (nextProps.track != null)
        Common.TrackPlayer.RegisterTrackNotifications(nextProps.track.Id, this.handleTrackEvent);
    }
  }

  handlePause(track) {
    Common.TrackPlayer.Pause();
  }

  handlePlay(track) {
    Common.TrackPlayer.AudioSource = 'playlist';
    Common.TrackPlayer.Play(track);
  }

  handleWaveformClick(track, event) {
    var rect = event.target.getBoundingClientRect();
    var x = event.pageX - rect.left;
    var posAsPercent = x / rect.width;

    Common.TrackPlayer.AudioSource = 'playlist';
    Common.TrackPlayer.Play(track, posAsPercent);
  }

  get isPlaying() {
    if (Common.TrackPlayer.track == null)
      return false;

    return this.state.isPlaying && this.props.track.Id == Common.TrackPlayer.track.Id;
  }

  render() {
    const {
      track = null
    } = this.props;

    const trackTitle = track ? track.Title : 'No Track Selected';
    const waveformUrl = track ? Common.GetWaveformUrl(track) : '';
    const trackDuration = track ? Common.Helpers.FormatDuration(track.Duration) : '';
    const trackCurrentDuration = track ? Common.Helpers.FormatDuration(this.state.trackDuration) : 0;
    const playerButtonStyle = {
      width: `${100}%`
    };
    const playerButton = this.isPlaying
      ? (<FontIcon name='circle-pause' color={'#87CC41'} size={25} style={playerButtonStyle} onClick={(e) => this.handlePause(track, e)} />)
      : (<FontIcon name='circle-play' size={25} style={playerButtonStyle} onClick={(e) => this.handlePlay(track, e)} />);

    const playlistPlayerClass = classNames({
      'playlist-player': true
    })

    return (
      <Flex className={playlistPlayerClass}>
        <Flex hide={track != null}>{trackTitle}</Flex>
        <Flex hide={track == null}>
          <Flex row>
            <Flex position='left' width='80%'>{trackTitle}</Flex>
            <Flex position='right'>{trackCurrentDuration}/{trackDuration}</Flex>
          </Flex>
          <Flex>
            <Flex row>
              <Flex className='playlist-player-button'>{playerButton}</Flex>
              <Flex className='waveforms' width={waveformWidth} height={30}>
                <img ref={(i) => {
                  this.waveform = i
                }} src={waveformUrl} width={waveformWidth} height={30} />
                <img ref={(i) => {
                  this.loaded = i
                }} src={waveformUrl} width={waveformWidth} height={30} />
                <img ref={(i) => {
                  this.playback = i
                }} src={waveformUrl} width={waveformWidth} height={30} />
                <div className='indicator-path' onClick={(e) => {
                  this.handleWaveformClick(track, e)
                }}>
                  <div ref={(i) => {
                    this.tracker = i
                  }} className='indicator' />
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

class SavedPlaylistDropDown extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      showTopIndicator: false,
      showBottomIndicator: false
    }
  }

  componentDidMount() {
    const values = this.scrollWindow.scrollBars.getValues();

    // set the bottom scroll indicator if the scroll window is taller than the element
    if (values.scrollHeight > values.clientHeight) {
      this.setState({ showBottomIndicator: true });
    }
  }

  handleMouseLeave(event) {
    const {
      onCloseDropDown = () => {
      }
    } = this.props;

    onCloseDropDown();
  }

  handleScrollFrame(values) {
    this.state.showTopIndicator = values.scrollTop > 0;
    this.state.showBottomIndicator = values.scrollTop < (values.scrollHeight - values.clientHeight - 2);
    this.forceUpdate();
  }

  handleSelectPlaylist(playlistId) {
    const {
      onSelectPlaylist = () => {
      }
    } = this.props;

    this.handleMouseLeave();
    localStorage.setItem("selectedPlaylist",playlistId)
    onSelectPlaylist(playlistId);
  }

  calculateComponentWidth = (playlists, maxWidth = PLAYLIST_DROPDOWN_MAX_WIDTH) => {
    const span = document.createElement("span");
    span.id = "playlist-title-calculation-span";

    let textHtml = '';

    playlists.forEach(playlist => {
      textHtml += playlist.Title + '<br />';
    })

    span.innerHTML = textHtml;
    document.body.appendChild(span);

    let width = span.getBoundingClientRect().width;

    document.body.removeChild(span);

    return Math.min(
      Math.max(width + 80, 150),
      maxWidth
    );
  }

  render() {
    const {
      userPlaylists = [],
      onSelectPlaylist = () => {
      }
    } = this.props;

    const topIndicator = this.state.showTopIndicator ? (<div className='scroll-top-indicator' />) : '';
    const bottomIndicator = this.state.showBottomIndicator ? (<div className='scroll-bottom-indicator' />) : '';

    const componentWidth = this.calculateComponentWidth(userPlaylists);
    const componentHeight = userPlaylists.length <= 20 ? (userPlaylists.length * 28) + 44 : 604;

    const savedPlaylistItems = userPlaylists.map((playlist, index) => (
      <div
        key={index}
        style={{ maxWidth: componentWidth }}
        className='saved-playlist-item'
        onClick={this.handleSelectPlaylist.bind(this, playlist.Id)}>{playlist.Title}</div>
    ));

    const savedPlaylistsStyle = {
      height: componentHeight,
      width: componentWidth
    };

    return (
      <div className='saved-playlists' style={savedPlaylistsStyle} onMouseLeave={this.handleMouseLeave.bind(this)}>
        {topIndicator}
        {bottomIndicator}
        <ScrollWindow
          ref={(e) => { this.scrollWindow = e }}
          onScrollFrame={this.handleScrollFrame.bind(this)}>
          <div className='inner-scroll'>
            <div className='saved-playlist-header'>Your Playlists:</div>
            {savedPlaylistItems}
          </div>
        </ScrollWindow>
      </div>
    )
  }
}

export default connect(state => {
  return {
    Playlist: state.Playlist,
    UserInterface: state.UserInterface,
    Search:state.Search
  }
})(PlaylistPanel);