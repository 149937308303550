import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import style from './open-playlist.scss';

import { Button, Dialog, DropDown, Flex, FontIcon, ScrollWindow, TextField, Typewriter } from 'components/ui';

const dialogName = 'OpenPlaylist';

export default class OpenPlaylist extends React.Component {
    constructor() {
        super(...arguments);

        this.onKeyUp = (event) => {
            if (event.which == 27) {
                Common.Dialogs.ShowDialog(dialogName, false);
            }

            if (event.which == 13) {
                this.handleOK();
            }
        }

        this.state = {
            errorMessage: '',
            message: null,
            filter: '',
            playlists: [],
            selectedPlaylist: null
        }
    }

    get filteredPlaylists() {
        return this.state.playlists.filter(p => p.text.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0);
    }

    get onClose() {
        const {
            onClose = () => {}
        } = this.props;

        return onClose;
    }

    componentWillMount() {
        this.state.message = Common.UserInterface.GetRandomMessage('OpenPlaylist');

        Common.Playlist.GetPlaylistsAsync()
            .then(playlists => {
                this.state.playlists = playlists.map(p => { return { id: p.Id, text: p.Title }});
                this.forceUpdate();
            })
            .catch(err => {
                // TODO: throw error message
            });

            document.addEventListener('keyup', this.onKeyUp);

        setTimeout(() => {
            this.searchField.focus();
        }, 200);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.onKeyUp);
    }

    handleFilterTextChanged(value) {
        this.setState({ filter: value });
    }

    handleCancel = () => {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleOK() {
        if (this.state.selectedPlaylist != null && this.state.selectedPlaylist.id !== null) {
            this.setState({ errorMessage: ''});
            Common.UserInterface.SetSelectedPlaylistTab(0);
            Common.Playlist.LoadPlaylist(this.state.selectedPlaylist.id,false)
                .then(() => setTimeout(this.handleCancel, 10))
                .catch(err => {
                    console.error('ERROR: ', err);
                });
        } else {
            this.setState({ errorMessage: 'You must select a playlist to open'});
        }

        this.onClose();
    }

    handleSelectPlaylist(playlist) {
        this.setState({ selectedPlaylist: playlist });
    }

    handleSelectDoubleClick(playlist) {
        this.setState({ selectedPlaylist: playlist });
        this.handleOK();
    }

    handleTextChanged(value) {
        this.setState({ title: value });
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        const playlistItems = this.filteredPlaylists.map((p, i) => (<PlaylistItem key={i} playlist={p} selected={this.state.selectedPlaylist == p} onSelect={this.handleSelectPlaylist.bind(this, p)} onDoubleClick={this.handleSelectDoubleClick.bind(this, p)} />));

        return (
            <Dialog title='Open Playlist' name='OpenPlaylist' width={800} height={620} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleCancel.bind(this)}>
                <Flex block className='open-playlist' position='top'>
                    <Flex row className='header'>
                        <FontIcon name='inspector' />
                        <Flex position='left'><Typewriter message={ this.state.message } /></Flex>
                    </Flex>
                    <Flex row className='header-search' position='top-left'>
                        <Flex className='search-filter-title' width={100}>Search: </Flex>
                        <Flex><TextField ref={(i) => { this.searchField = i; }} placeholder='Search name' value={this.state.filter} height={35} onChange={this.handleFilterTextChanged.bind(this)} /></Flex>
                    </Flex>
                    <Flex className='content'>
                        <ScrollWindow showscrollIndicator={true}>
                            <Flex position='left'>
                                {playlistItems}
                            </Flex>
                        </ScrollWindow>
                    </Flex>
                    <Flex row fixed className='footer' position='right'>
                        <div className='error-message'>{this.state.errorMessage}</div>
                        <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                        <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}

class PlaylistItem extends React.Component {
    constructor() {
        super(...arguments);
        this.delay = 400;
        this.hasClicked = false;
    }

    handleSelectPlaylist(item) {
        const {
            onSelect = () => {},
            onDoubleClick = () => {}
        } = this.props;

        if (!this.hasClicked) {
            this.hasClicked = true;

            setTimeout(() => {
                this.hasClicked = false;
            }, this.delay);

            onSelect(item);
        } else {
            this.hasClick = false;
            onDoubleClick(item);
        }
    }

    render() {
        const {
            playlist = null,
            selected = false,
            onSelect = () => {}
        } = this.props;

        const playlistItemClass = classNames('playlist-item', { selected: selected });

        return (
            <div className={playlistItemClass} onClick={this.handleSelectPlaylist.bind(this, playlist)}>{playlist.text}</div>
        )
    }
}