import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Styles from './typewriter.scss';

const TYPEWRITER_SPEED = 20;

class typewriter {
    constructor(message, speed, variable, textChangedCallback, finishCallback) {
        this.index = 0;
        this.breakIndexes = [];
        this.finishCallback = finishCallback;
        this.message = message;
        this.speed = speed;
        this.textChangedCallback = textChangedCallback;
        this.text = '';
        this.variable = variable;

        if (message.indexOf("<br/>") >= 0) {
            let lastIndex = 0;

            while (lastIndex != -1) {                
                lastIndex = message.indexOf("<br/>", lastIndex+1);

                if (lastIndex != -1) {
                    this.breakIndexes.push(lastIndex);
                    lastIndex++;
                }
            }
        }
    }

    process() {
        const _this = this;

        if (this.speed == 0) {
            this.textChangedCallback(this.message);
            return;
        }

        if (this.index < this.message.length) {
            if (this.breakIndexes.findIndex(v => v == this.index) == -1) {
                this.text = this.text + this.message[this.index++];
            } else {
                this.text = this.text + "<br/>";
                this.index += 5;
            }            

            if (this.isActive) {
                if (this.textChangedCallback)
                    this.textChangedCallback(this.text);

                if (this.index < this.message.length) {
                    setTimeout(() => 
                        { _this.process(); }, 
                        this.speed + parseInt(Math.random() * this.variable))
                } else {
                    if (this.finishCallback)
                        this.finishCallback();
                }
            }
        }
    }

    start() {
        this.isActive = true;
        this.process();
    }

    stop() {
        this.isActive = false;
    }
}

export class Typewriter extends React.Component {
    constructor() {
        super(...arguments);

        this.typewriter = null;
        this.state = {
            message: '',
            showCaret: false
        }
    }

    componentDidMount() {
        this.setState({ isActive: true });
        this.typewriter = new typewriter(this.props.message, this.props.speed || TYPEWRITER_SPEED, 0, this.handleTextChanged.bind(this), this.handleFinished.bind(this));
        this.typewriter.start();
    }

    componentWillUnmount() {
        this.state.isActive = false;
        this.stopTypewriter();
    }

    handleTextChanged(message) {
        if (this.state.isActive)
            this.setState({ message: message, showCaret: true });
    }

    handleFinished() {
        this.setState({ isActive: false });
        this.stopTypewriter();
        this.forceUpdate();
    }

    stopTypewriter() {
        if (this.typewriter) {
            this.typewriter.stop();
            this.typewriter = null;
        } 
    }

    render() {
        const {
            componentClass = null
        } = this.props;        

        const componentClassName = classNames('typewriter', { 'show-caret': this.state.isActive}, componentClass);                

        return (
            <div className={componentClassName}>
                <span className='typewriter-text' dangerouslySetInnerHTML={{__html: this.state.message}} />
            </div>
        )
    }
}
