import React from 'react';
import Style from './waveform.scss';

import { Image } from 'components/ui';

const handleEventCallback = (event, waveform) => {

}

class Waveform extends React.Component {
  constructor() {
    super(...arguments);   
    
    this.handleEventCallback = (event) => {
      const tracker = this.Tracker;
      if (tracker) {
        switch (event.type) {
          case 'TIME_UPDATE': {          
            tracker.style.left = ((event.currentTime / event.totalTime) * 100) + '%';
            tracker.style.display = 'block';
            break;
          }
          case 'PLAY': {
            tracker.style.left = ((event.currentTime / event.totalTime) * 100) + '%';
            tracker.style.display = 'block';
            break;
          }
          case 'PAUSED':
          case 'ENDED':           
          case 'STOPPED': {
            tracker.style.display = 'none';
            tracker.style.left = 0;
            break;
          }
        }
      }
    }
  }

  componentDidMount() {    
    Common.AudioPlayer.registerForEvents(this.props.track.Id, this);
  }

  componentWillUnmount() {
    Common.AudioPlayer.unregisterForEvents(this.props.track.Id, this);
  }

  shouldComponentUpdate(nextProps) {
    return (this.props.track != nextProps.track);
  }

  render() {
    const { 
      track = {}
    } = this.props;

    const imageUrl = Common.GetWaveformUrl(track);

    return (
      <div className='waveform'>
          <Image ref={(i) => { this.Waveform = i}} url={imageUrl} width={400} missingImageUrl={Common.GetMissingWaveformImageUrl()} height={30} /> 
          <div className='indicator-path'><div ref={(i) => {this.Tracker = i}} className='indicator' style={{display:'none'}} /></div>
      </div>
    )
  }
}

export { Waveform }

