import { fetchGet, fetchPost } from 'utility/fetchHelpers';
import { showDialog } from 'store/dialogs/actions';
import { importCueSheet } from 'utility/api/common/cueSheetImporter';
import uuid from 'uuid';
import EDL from '../../edl';
import Timecode from 'smpte-timecode';

import {
    clearCueSheet,
    createNewCueSheet,
    insertCueSheetRow,
    setCueSheet,
    setCueSheetTitle,
    updateCueSheetRow,
    updateFieldValue
} from 'store/cueSheet/actions';

let store = null;
let cueSheetImportIndex = 0;

class CueSheet {
    constructor(_store) {
        store = _store;
    }

    AddLine(cueSheetId, position) {
        store.dispatch(insertCueSheetRow(cueSheetId, position));
    }

    Clear() {
        store.dispatch(clearCueSheet());
    }

    Create() {
        const cueSheet = store.getState().CueSheet.toJS();

        // TODO: Check to see if the current cuesheet has been updated
        if (cueSheet.Id != null && cueSheet.UpdatedFlag) {
            Common.Dialogs.ShowDialog('NewCueSheet', true);
        } else {
            store.dispatch(createNewCueSheet());
        }
    }

    CreateCueSheetLine(title, timeCodeIn, timeCodeOut, timeCodeLength) {
        let cueSheetLine = null;

        // Handle partial second frame counts
        if (timeCodeLength.frames >= 15) {
            timeCodeLength = Timecode(timeCodeLength + Timecode("00:00:01:00") - Timecode(`00:00:00:${timeCodeLength.frames}`));
        } 

        // Ignore FADE, SAMPLE ACCURATE EDIT titles along with any entry that is less than 1 seconds
        // TODO -- the time limit for the track cutoff should be a configurable value
        if (title !== "FADE" && title !== "SAMPLE ACCURATE EDIT" && timeCodeLength >= Timecode("00:00:01:00")) {
            const durationHours = (timeCodeLength.hours > 0) ? timeCodeLength.hours.toString().padLeft(2, '0') + ':' : '';
            const durationMinutes = timeCodeLength.minutes.toString().padLeft(2, '0') + ':';
            const durationSeconds = timeCodeLength.seconds.toString().padLeft(2, '0');

            cueSheetLine = {
                CueNumber: (cueSheetImportIndex++) + 1,
                Title: title,
                TimeCodeIn: timeCodeIn.toString(),
                TimeCodeOut: timeCodeOut.toString(),
                TimeCodeLength: durationHours + durationMinutes + durationSeconds
            }
        }

        return cueSheetLine;
    }

    Duplicate(newTitle) {
        const cueSheet = store.getState().CueSheet.toJS();

        cueSheet.Id = null;
        cueSheet.Title = newTitle;
        cueSheet.HasChanges = true;
        cueSheet.Lines.forEach(l => { l.Id = null });

        this.Save(cueSheet);
    }

    Export() {
        Common.Dialogs.ShowDialog('ExportCueSheet', true);
    }

    GenerateCueSheetCSV(cueSheet, filename) {
        let lines = [];
        
        lines.push('"Cue#","Cue Name","Start Time","End Time","Duration","Usage","Composer","Publisher","Society","Music Source"\r\n');

        cueSheet.Lines.forEach(cueLine => {
            lines.push(`"${cueLine.CueNumber}","${cueLine.Title}","${cueLine.TimeCodeIn}","${cueLine.TimeCodeOut}","${cueLine.TimeCodeLength}","${cueLine.Usage}","${cueLine.Composers}","${cueLine.Publishers}","${cueLine.Society}","${cueLine.MusicSource}"\r\n`);
        });

        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob(lines, {type: 'text/csv'}));

        if (filename.indexOf('.') === -1) {
            filename += ".csv";
        }

        a.download = filename;
    
        // Append anchor to body.
        document.body.appendChild(a);
        a.click();
    
        // Remove anchor from body
        document.body.removeChild(a);
    }

    GetCueSheet() {
        return store.getState().CueSheet.toJS();
    }

    GetTitle() {
        return this.GetCueSheet().Title;
    }

    Import() {
        // Reset the cueSheetImportIndex
        cueSheetImportIndex = 0;

        //Common.Dialogs.ShowDialog('ImportCueSheet', true);
        var fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');

        const onChange = (e) => {
            fileInput.removeEventListener('change', onChange);
            fileInput = null;

            var data = new FormData();
            var binary = e.target.files[0];

            Common.UserInterface.SetIsLoading(true);

            var reader = new FileReader();
            reader.onload = (e) => {
                const fileData = e.currentTarget.result.replace(/\r/g, "\n");

                const edl = new EDL();
                const data = edl.fromString(fileData);
                const filtered = data.events.filter(f => f.sourceFile != null || f.sourceClip != null);

                let cueSheet = {
                    Id: uuid.v4(),
                    Title: '',
                    Episode: '',
                    Lines: []
                };
                
                let cue = 0;
                let title = null;
                let start = null;
                let end = null;

                filtered.forEach((e, i) => {
                    const cTitle = (e.sourceFile || e.sourceClip);

                    if (cTitle !== '(NULL)' && cTitle !== 'SAMPLE ACCURATE EDIT' && cTitle !== 'FADE') {
                        if (title !== cTitle) {
                            if (title !== null) {
                                const timeCodeIn = Timecode(start.toString().replace(/\;/g, ':'));
                                const timeCodeOut = Timecode(end.toString().replace(/\;/g, ':'));
                                const timeCodeLength = Timecode(timeCodeOut - timeCodeIn);
                                const cueLineEntry = this.CreateCueSheetLine(title, timeCodeIn, timeCodeOut, timeCodeLength)
                                
                                if (cueLineEntry) 
                                    cueSheet.Lines.push(cueLineEntry);   
                            }
    
                            title = cTitle;
                            start = e.recordStart;
                            end   = e.recordEnd;
                        } else {
                            if (e.recordStart.frameCount >= start.frameCount && e.recordStart.frameCount <= end.frameCount) {
                                end = e.recordEnd;
                            } else {
                                if (e.recordStart.frameCount >= (end.frameCount + Math.ceil(data.frameRate) * 5)) {
                                    const timeCodeIn = Timecode(start.toString().replace(/\;/g, ':'));
                                    const timeCodeOut = Timecode(end.toString().replace(/\;/g, ':'));
                                    const timeCodeLength = Timecode(timeCodeOut - timeCodeIn);
                                    const cueLineEntry = this.CreateCueSheetLine(title, timeCodeIn, timeCodeOut, timeCodeLength)
                                    
                                    if (cueLineEntry) 
                                        cueSheet.Lines.push(cueLineEntry);

                                    title = cTitle;
                                    start = e.recordStart;
                                }

                                // set the end no matter what
                                end   = e.recordEnd;
                            }
                        }
                    }
                });

                
                // Set the Episode Title from the EDL file
                cueSheet.Title = data.title;
                cueSheet.Episode = data.title;

                // Add the last Cue Sheet Line from the parsing                
                const timeCodeIn = Timecode(start.toString().replace(/\;/g, ':'));
                const timeCodeOut = Timecode(end.toString().replace(/\;/g, ':'));
                const timeCodeLength = Timecode(timeCodeOut - timeCodeIn);
                const cueLineEntry = this.CreateCueSheetLine(title, timeCodeIn, timeCodeOut, timeCodeLength)
                
                if (cueLineEntry) 
                    cueSheet.Lines.push(cueLineEntry);

                fetchPost(`/api/cuesheet/import`, {
                    method: 'POST',
                    body: JSON.stringify(cueSheet)
                })
                .then(cueSheet => {
                    Common.UserInterface.SetIsLoading(false);
                    this.SetCueSheet(cueSheet);
                })
                .catch(err => {
                    console.error('ERROR: ', err);
                    Common.UserInterface.SetIsLoading(false);
                });              
            }
            
            reader.readAsText(e.target.files[0]);
        }

        fileInput.addEventListener('change', onChange, false);
        fileInput.click();
    }

    LoadCueSheet(cueSheetId) {
        fetchGet(`/api/cuesheet/load/${cueSheetId}`)
            .then(cueSheet => {
                this.SetCueSheet(cueSheet);
            })
            .catch(err => {
                console.error('ERROR: ', err);
            })       
    }

    LoadCueSheetList() {
        return new Promise((resolve, reject) => {
            fetchGet(`/api/cuesheet/list`)
                .then(cueSheets => {
                    resolve(cueSheets);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    Open() {
        Common.Dialogs.ShowDialog('OpenCueSheet', true);
    }

    Print() {
        Common.Dialogs.ShowDialog('PrintCueSheet', true);
    }

    RemoveLine(cueNumber) {
        var cueSheet = this.GetCueSheet();
        cueSheet.Lines = cueSheet.Lines.filter(line => line.CueNumber !== cueNumber);

        this.SetCueSheet(cueSheet);
    }
    
    Save(saveCueSheet = null) {
        return new Promise((resolve, reject) => {
            const cueSheet = saveCueSheet || store.getState().CueSheet.toJS();

            if (cueSheet.Title.trim() == '' && cueSheet.Id == null) {
                resolve();
                Common.ShowAlertMessage("Save Cue Sheet", "You must specify a Cue Sheet title.", 400);
            } else {         
                fetchPost(`/api/cuesheet/save`, {
                        method: 'POST',
                        body: JSON.stringify(cueSheet)
                    })
                    .then(json => {
                        this.SetCueSheet(json);
                        Common.ShowAlertMessage("Save Cue Sheet", "This cue sheet has been saved to your account.", 400);
                        resolve();
                    })
                    .catch(err => {
                        console.error('ERROR: ', err);
                        reject(err);
                    });
            }
        })
    }

    SaveAs() {
        Common.Dialogs.ShowDialog('SaveCueSheetAs', true);
    }

    SetCueSheet(cueSheet) {
        store.dispatch(setCueSheet(cueSheet));
    }
    
    SetTitle(title) {        
        store.dispatch(setCueSheetTitle(title));
    }

    UpdateCueSheet(cueSheet) {
        store.dispatch(setCueSheet(Object.assign({}, store.getState().CueSheet.toJS(), cueSheet)));
    }

    UpdateFieldValue(fieldName, value) {    
        store.dispatch(updateFieldValue(fieldName, value)); 
    }

    ValidateCueSheetField(cueSheetId, cueSheetLine, fieldName, value) {
        if (fieldName == 'Title') {
            fetchPost(`/api/search/track`, { Title: value })
                .then(json => {
                    if (json.Title) {
                        var society = '';

                        if (json.Publisher.Name != '') {
                            const societyParts = [];
                            const pName = json.Publisher.Name;

                            const parts = pName.split('/');
                            parts.forEach(part => {
                                const s = part.split(', ');
                                if (societyParts.findIndex(sp => sp === s[1]) === -1)
                                    societyParts.push(s[1]);
                            })

                            society = societyParts.join('/');
                        }

                        cueSheetLine.Title = json.Title,
                        cueSheetLine.Composers = json.Composer.Name,
                        cueSheetLine.Publishers = json.Publisher.Name
                        cueSheetLine.Society = society,
                        cueSheetLine.MusicSource = 'Music Library'
                    } else { 
                        if (cueSheetLine.Composers.trim() == '') 
                            cueSheetLine.Composers = 'CONTACT MUSIC PROVIDER FOR INFO'
                    }

                    store.dispatch(updateCueSheetRow(cueSheetId, cueSheetLine));
                })
                .catch(err => {
                    console.error('ERROR: ', err);
                })
        }

        if (fieldName == 'TimeCode') {

        }
        
        cueSheetLine[fieldName] = value;
        store.dispatch(updateCueSheetRow(cueSheetId, cueSheetLine));
    }
}

export default CueSheet;