import React from 'react';
import classNames from 'classnames';
import style from './create-playlist.scss';

import { Button, Dialog, Flex, FontIcon, TextField, Typewriter } from 'components/ui';

const dialogName = 'CreatePlaylist';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

export default class CreatePlaylist extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            title: 'Untitled',
            message: ''
        }
    }

    get onClose() {
        const {
            onClose = () => {}
        } = this.props;

        return onClose;
    }

    componentWillMount() {
        this.state.message = Common.UserInterface.GetRandomMessage('NewPlaylist');

        setTimeout(() => {
            this.title.focus();
            this.title.select();
        }, 20);

        document.addEventListener('keyup', onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    }

    handleKeyUp(event) {
        if (event.which == 13) {
            this.handleOK();
        }
    }

    handleCancel() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleOK() {
        Common.Playlist.CreatePlaylist(this.title.value)
            .then(() => {
                this.handleCancel();
            })
            .catch(err => {
                Common.Dialogs.ShowAlertMessage({title:'Error', message: err.message});
            })

    }

    handleTextChanged(value) {
        this.setState({ title: value });
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        return (
            <Dialog title='New Playlist' name='CreatePlaylist' width={600} height={290} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleCancel.bind(this)}>
                <Flex className='create-playlist' position='top'>
                    <Flex row fixed className='header' position='top'>
                        <FontIcon name='inspector' />
                        <Flex position='left'><Typewriter message={this.state.message} /></Flex>
                    </Flex>
                    <Flex row fixed className='content'>
                        <Flex width={100}>Name:</Flex>
                        <Flex><TextField ref={(i) => { this.title = i }} fontSize={16} placeholder={this.state.title} value='' onKeyUp={this.handleKeyUp.bind(this)} /></Flex>
                    </Flex>
                    <Flex row fixed className='footer' position='bottom-right'>
                        <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                        <Button type='default' size='md' width='100px' onClick={onClose.bind(this, 'CreatePlaylist')}>Cancel</Button>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}