import React from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NavigationMenu from './nav-menu/nav-menu';
import { Button, Flex, FontIcon, LoadingPanel, RenderIf } from 'components/ui';
import { 
    ContactUsDialog,
    LoginDialog,
    HowToDialog,
    SignUpDialog,
    ForgotPasswordDialog,
    ResetPasswordDialog,
} from 'components/common/dialogs'
import { TrackResults } from 'components/common/track-results/track-results';
import { fetchPost } from 'utility/fetchHelpers';
import { setUserDetails } from 'store/userInterface/actions';
import './sharedPlaylist.scss';
import { showAlert, closeAlert } from '../../ui/alert/alert';

const getQueryParams = (queryString) => {
    const params = [];
    const locationParts = location.search.toLowerCase().replace("?","").split("&");
    locationParts.forEach(param => {
        const paramParts = param.split("=");
        params[paramParts[0]] = paramParts[1];
    })

    return params;
}

class SharedPlaylist extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
            playlist: null,
            name: '',
            email: '',
            message: '',
            phone: '',
            error: false
        }

        Common.GetStore().dispatch(setUserDetails({ SharedPlaylist: true }));
    }

    get DialogWindows() {
        const dialogs = [];
        if (this.Dialogs.LoginDialog) dialogs.push(<LoginDialog key='LoginDialog' onClose={this.handleCloseDialog.bind(this)} />);
        if (this.Dialogs.ContactUsDialog) dialogs.push(<ContactUsDialog key='ContactUsDialog' onClose={this.handleCloseDialog.bind(this)} />);
        if (this.Dialogs.HowToDialog) dialogs.push(<HowToDialog key='HowToDialog' onClose={this.handleCloseDialog.bind(this)} />);
        if (this.Dialogs.SignUpDialog) dialogs.push(<SignUpDialog key='SignUpDialog' onClose={this.handleCloseDialog.bind(this)} />);
        if (this.Dialogs.ForgotPasswordDialog) dialogs.push(<ForgotPasswordDialog key='ForgotPasswordDialog' onClose={this.handleCloseDialog.bind(this)} />);
        if (this.Dialogs.ResetPasswordDialog) dialogs.push(<ResetPasswordDialog key='ResetPasswordDialog' onClose={this.handleCloseDialog.bind(this)} />);
        return dialogs;
    }

    get Dialogs() {
        const { Dialogs } = this.props
        return Dialogs ? Dialogs.toJS() : {}
    }

    get UserInterface() {
        const {UserInterface} = this.props
        return UserInterface ? UserInterface.toJS() : {}
    }

    get UserDetails() {
        return this.UserInterface.UserDetails
    }

    componentDidMount() {
        const params = getQueryParams();
        const id = params["id"];

        if (id) {
            Common.UserInterface.GetNavigationMenu();
            Common.UserInterface.LoadAllMessages();

            const url = `/api/playlist/shared?id=${id}`;
            fetch(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        window.location.href = '/';
                    }
                })
                .then(json => {
                    if (JSON.stringify(json) !== "{}") {                        
                        const trackGroups = [...new Set(json.Tracks.map(t => t.TrackGroup))]
                        const tracksToDisplay = trackGroups.map(x => json.Tracks.filter(t => t.TrackGroup === x).sort((a, b) => {
                            if (a.TrackSortTitle < b.TrackSortTitle) return -1
                            if (a.TrackSortTitle > b.TrackSortTitle) return 1
                            return 0
                        })[0])

                        tracksToDisplay.forEach(t => {
                            t.AltTracks = json.Tracks.filter(x => x.TrackGroup === t.TrackGroup && x.Id != t.Id).sort((a, b) => {
                                if (a.TrackSortTitle < b.TrackSortTitle) return -1
                                if (a.TrackSortTitle > b.TrackSortTitle) return 1
                                return 0
                            })                            
                            t.AltTrackCount = t.AltTracks.length
                        })

                        json.Tracks = tracksToDisplay

                        this.setState({
                            playlist: json
                        });
                    } else {
                        window.location.href = "/";
                    }
                })
                .catch(err => {
                    this.setState({ error: true});
                    console.error('ERROR: ', err);
                    window.alert("This shared playlist has expired.");
                    window.open("https://thesupervisor.com/", "_self")
                })
        } else {
            window.location.href = "/";
        }
    }

    handleCloseDialog(name) {
        Common.Dialogs.ShowDialog(name, false);
    }

    handleDownloadPlaylistTracks(format = 'wav', event) {
        const {
            playlist
        } = this.state;

        if (playlist.IsLocked && this.UserDetails == null) {
            Common.Dialogs.ShowDialog("SignUpDialog", true);
            return
        }

        if (format === 'xml') {
            const params = getQueryParams();
            const id = params["id"];
            Common.Playlist.ExportPlaylistAsXML(id, 'shared');
        }
        else {
            fetchPost('/api/playlist/download', {
                PlaylistId: playlist.Id,
                PlaylistTitle: playlist.Title,
                AudioFormat: format,
                SharedPlaylist: true
            })
            .then(json => {
                window.location = json.Url;
            })
            .catch(err => {
                console.error('ERROR: ', err);
            });
        }
    }

    handleDownloadTrack(track, event, state) {
        let rect = event.target.getBoundingClientRect();
        rect = { top: rect.top, left: event.target.offsetLeft, bottom: rect.bottom, right: rect.right, width: rect.width, height: rect.height };

        if (state) {
            if (state.isDrawer) rect.left = rect.left + 55;
        }

        Common.UserInterface.ShowDownloadTrack(track, rect, null, (format) => {
            const params = getQueryParams();
            const id = params["id"];
            const isDiscovery = this.state.playlist.IsDiscovery        

            const {collection, title, audioFormat} = track;
            const link = document.createElement('a');
            const filename = (isDiscovery ? `DLSSK_${track.Title}` : track.Title) + '.' + format; 
            link.href = `/api/playlist/shared/download/${id}/${track.Title}/${track.Collection.Name}/${format}?filename=${filename}`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            const postData = 
            {
              "TrackId": track.Id,
              "PlayedCount": 0,
              "DownloadCount": 1
            }
            Common.UserInterface.UpdateTrackStatistics(postData);
        });
    }

    async handleSendMessage() {
        const { playlist, name, email, message } = this.state
        if (name.trim() === '' || email.trim() === '' || message.trim() === '') { 
            const content = (
                <Flex row height={80} position='left'>
                    <FontIcon name='inspector' />
                    <div className='message'>
                        <div>Please fill in all REQUIRED fields.</div>
                        <div>&nbsp;</div>
                        <div>Name</div>
                        <div>Email Address</div>
                        <div>Tell us about your project</div>
                    </div>                    
                </Flex>
            )    

            const handleOK = () => {                
                closeAlert();
            }

            const buttons = [
                <Button key={0} width={80} onClick={handleOK.bind(this)}>OK</Button>,
            ]

            showAlert({
                title: 'Send Error',
                width: 600,
                height: 210,
                content: content,
                buttons: buttons
            });
            return;
        }

        try {
            var response = await Common.UserInterface.SendPlaylistMessage({
                playlistId: playlist.Id,
                title: playlist.Title,
                name: this.state.name,
                email: this.state.email,
                message: this.state.message,
                phone: this.state.phone
            })            

            if (response.Success) {
                showAlert({
                    title: 'Submitted',
                    width: 600,
                    height: 250,
                    content: (<div style={{fontSize: '14pt'}}>Thank you for your interest. We will reach out to you soon.</div>),
                    buttons: (<Button key={0} width={80} onClick={() => closeAlert()}>OK</Button>)
                })
            } else {
                showAlert({
                    title: 'Error Saving Information',
                    width: 600,
                    height: 250,
                    content: (<div style={{fontSize: '14pt'}}>Uh oh. There was an issue saving the information.<br/><br/>Please reach out to us directly if you continue to see this message.</div>),
                    buttons: (<Button key={0} width={80} onClick={() => closeAlert()}>OK</Button>)
                })
            }
        } catch (err) {
            showAlert({
                title: 'Error Saving Information',
                width: 600,
                height: 250,
                content: (<div style={{fontSize: '14pt'}}>Uh oh. There was an unknown issue saving the information.<br/><br/>Please reach out to use directly if you continue to see this message.</div>),
                buttons: (<Button key={0} width={80} onClick={() => closeAlert()}>OK</Button>)
            })
        }
    }

    openLink(url) {
        window.open(url, '_blank');
    }

    render() {
        const {
            playlist
        } = this.state;

        const currentYear = new Date().getFullYear();          

        if (playlist) {
            const playlistType = playlist.PlaylistType == 'Marketing' ? 'marketing-playlist' : 'user-playlist';      
            return (
                <Flex column className={`shared-playlist ${playlistType}`} style={{width: '100%'}}>
                    <RenderIf condition={playlist.PlaylistType == 'User'}>
                        <NavigationMenu />
                        <Flex row className='dialog-header dialog-top-bg' fixed>
                            <Flex row>
                                <Flex className='dialog-title'>
                                    <div>
                                        <span>Shared Playlist: </span>
                                        <span>{playlist.Title}</span>
                                        <span>({playlist.Tracks.length} tracks)</span>
                                    </div>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex position='left' className='shared-user-playlist-message'>
                            <Flex row className='message-container'>
                                <FontIcon name='inspector' />
                                <Flex className='shared-playlist-message' position='left'>
                                    <div>{playlist.Message}</div>
                                    <div className='supervisor-link'>To explore more music, visit <a href='/'>The Supervisor!</a></div>
                                </Flex>
                            </Flex> 
                        </Flex>
                    </RenderIf>
                    <RenderIf condition={playlist.PlaylistType == 'Marketing'}>
                        <Flex row className='dialog-header dialog-top-bg' fixed>
                            <Flex>
                                <Flex row>
                                    <Flex className='dialog-title shared-playlist-title'>
                                        <div>
                                            <span>Shared Playlist: </span>
                                            <span>{playlist.Title}</span>
                                            <span>({playlist.Tracks.length} tracks)</span>
                                        </div>
                                    </Flex>
                                    <Flex className='shared-playlist-info'>
                                        <div>
                                            <span>To explore more music, visit <a href="https://thesupervisor.com">TheSupervisor.com</a></span>
                                        </div>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </RenderIf>
                    <Flex className='shared-playlist-results'>
                        <TrackResults
                            ref={e => this.TrackResults = e}
                            owner={this}
                            source={playlist.IsLocked ? 'MarketingPlaylist' : 'SharedPlaylist'}
                            onDownloadTrack={this.handleDownloadTrack.bind(this)}
                            disableContextMenu={playlist.PlaylistType == 'Marketing'}
                            discovery={playlist.IsDiscovery}
                            tracks={playlist.Tracks}
                            hideAuditionIcon={true}
                            hideAlbumLink={false}
                            hideInfoIcon={true}                            
                            hideInfoPanel={true}
                            hideDescriptionPanel={false}
                            hidePlaylistIcon={playlist.PlaylistType == 'Marketing'}
                            hideDownloadIcon={playlist.IsLocked}
                            hideMoreLikeThisIcon={true}
                            hideFilterLinks={false}
                            hideToggle={false}
                            hideAltIcon={false}
                            hideTrackInfoLinks={false}
                            disableHoverActions={true}>
                        </TrackResults>
                    </Flex>
                    <RenderIf condition={playlist.PlaylistType == 'Marketing'}>                        
                        <Flex row className='shared-marketing-playlist-message'>
                            <div>Like what you hear? Shoot us your info and we'll prepare a customized playlist for <i>your</i> project!</div>
                            <div className='message-form'>
                                <section>
                                    <div>
                                        <span className='field-name'><RequiredField /> Name:</span>
                                        <span className='field-value'><input type="text" value={this.state.name} onChange={(e) => this.setState({...this.state, name: e.target.value})} /></span>
                                    </div>
                                    <div>
                                        <span className='field-name'><RequiredField /> Email:</span>
                                        <span className='field-value'><input type="text" value={this.state.email} onChange={(e) => this.setState({...this.state, email: e.target.value})}  /></span>
                                    </div>
                                    <div>
                                        <span className='field-name'>Phone/Cell:</span>
                                        <span className='field-value'><input type="text" value={this.state.phone} onChange={(e) => this.setState({...this.state, phone: e.target.value})}  /></span>
                                    </div>
                                </section>
                                <section>
                                    <div>
                                        <span className='field-name'><RequiredField /> Tell us about your project:</span>
                                        <span className='field-value'><textarea style={{width: 400}} value={this.state.message} onChange={(e) => this.setState({...this.state, message: e.target.value})}  /></span>
                                    </div>
                                    <div style={{display:'flex', justifyContent: 'flex-end', marginLeft: 250}}>
                                        <div style={{color: '#ff0000', fontSize: '80%', marginTop: 15, flex: 1}}><span>*</span> Required Fields</div>
                                        <button className='send-button' onClick={() => this.handleSendMessage(this)}>Send</button>
                                    </div>
                                </section>
                            </div>
                        </Flex> 
                    </RenderIf>
                    <RenderIf condition={playlist.PlaylistType != 'Marketing' && !playlist.IsLocked}>
                        <Flex row position='left' height={40} className='main-footer'>
                            <Flex row position='left'>
                                <Button
                                    className='download-all'
                                    onClick={this.handleDownloadPlaylistTracks.bind(this, 'wav')}>
                                        <FontIcon name='download-circle' size={16}></FontIcon> Download All (WAV)
                                </Button>
                                <Button
                                    className='download-all'
                                    onClick={this.handleDownloadPlaylistTracks.bind(this, 'mp3')}>
                                        <FontIcon name='download-circle' size={16}></FontIcon> Download All (MP3)
                                </Button>
                                <Button
                                    className='download-all-xml'
                                    onClick={this.handleDownloadPlaylistTracks.bind(this, 'xml')}>
                                        <FontIcon name='download-circle' size={16}></FontIcon> Download ScoreKeepers Playlist .XML File
                                </Button>

                            </Flex>
                            <Flex row position={'right'}>
                            <div>The Supervisor, &#xA9;{currentYear}</div>
                                <div><Flex row inline><FontIcon name='app-logo' size={22}/> <span className={'padding-left-5'}>Scorekeepers Music, Inc.</span></Flex>
                                </div>
                                <div>
                                <Flex row inline>
                                    Follow Us:
                                    <span className={'padding-left-5'}><FontIcon name='facebook' className={'link'}
                                                                                size={20}
                                                                                onClick={this.openLink.bind(this, 'https://www.facebook.com/ScoreKeepersMusic/')}/></span>
                                    <span className={'padding-left-5'}><FontIcon name='twitter' className={'link'} size={20}
                                                                                onClick={this.openLink.bind(this, 'https://twitter.com/scorekeepers')}/></span>
                                    <span className={'padding-left-5'}><FontIcon name='instagram' className={'link'}
                                                                                size={20}
                                                                                onClick={this.openLink.bind(this, 'https://www.instagram.com/scorekeepers_music/')}/></span>
                                </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </RenderIf>
                    <RenderIf condition={playlist.PlaylistType == 'Marketing' || playlist.IsLocked}>
                    <Flex row position='center' height={40} className='main-footer'>
                            <Flex row position={'center'}>
                            <div>The Supervisor, &#xA9;{currentYear}</div>
                                <div><Flex row inline><FontIcon name='app-logo' size={22}/> <span className={'padding-left-5'}>Scorekeepers Music, Inc.</span></Flex>
                                </div>
                                <div>
                                <Flex row inline>
                                    Follow Us:
                                    <span className={'padding-left-5'}><FontIcon name='facebook' className={'link'}
                                                                                size={20}
                                                                                onClick={this.openLink.bind(this, 'https://www.facebook.com/ScoreKeepersMusic/')}/></span>
                                    <span className={'padding-left-5'}><FontIcon name='twitter' className={'link'} size={20}
                                                                                onClick={this.openLink.bind(this, 'https://twitter.com/scorekeepers')}/></span>
                                    <span className={'padding-left-5'}><FontIcon name='instagram' className={'link'}
                                                                                size={20}
                                                                                onClick={this.openLink.bind(this, 'https://www.instagram.com/scorekeepers_music/')}/></span>
                                </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </RenderIf>
                    {this.DialogWindows}
                    <div id='body-render'></div>

                </Flex>
            )
        } else {
            return (
                <Flex className="shared-playlist-loading">
                    {!this.state.error ? <LoadingPanel /> : null}
                </Flex>
            )
        }
    }
}

const RequiredField = () => {
    return (
        <span className='required-field'>*</span>
    )
}

export default connect((state) => {
    return {
      Dialogs: state.Dialogs,
      UserInterface: state.UserInterface,
    }
  })(withRouter(SharedPlaylist));
