import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Styles from './download-format.scss';

import { Flex, RenderInBody } from 'components/ui';

const onClick = (event) => {
    if (event.target.className != 'clickbait' && event.target.className != 'format') {
        closeDownloadDialog();
    }
}

const onKeyUp = (event) => {
    if (event.which == 27) {
        closeDownloadDialog();
    }
}

let downloadDialogNode = null;

export const closeDownloadDialog = () => {
    setTimeout(() => {
        if (downloadDialogNode) {
            ReactDOM.unmountComponentAtNode(downloadDialogNode);
            downloadDialogNode = null;
        }
    }, 20)
}

export const showDownloadDialog = (title, left, top, onSelected, onClose) => {
    if (downloadDialogNode != null) {
        ReactDOM.unmountComponentAtNode(downloadDialogNode);
        downloadDialogNode = null;
    }

    downloadDialogNode = document.createElement('div');

    ReactDOM.render(
        (<DownloadDialog title={title} left={left} top={top} onSelected={onSelected} onClose={onClose} />),
        document.getElementById('body-render').appendChild(downloadDialogNode)
    );

    return downloadDialogNode;
}


class DownloadDialog extends React.Component {
    constructor() {
        super(...arguments);
    }

    componentDidMount() {
        document.addEventListener('keyup', onKeyUp);
        document.addEventListener('click', onClick);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
        document.removeEventListener('click', onClick);
    }

    handleFormatSelected(format) {
        const { onSelected = () => {}, onClose = () => {} } = this.props;
        onSelected(format);
    }

    get UserDetails() {
        return Common.GetState().UserInterface.toJS().UserDetails || {};
    }

    get downloadOptions() {
        const downloadOptions = [];
        downloadOptions.push((<div key='mp3' onClick={this.handleFormatSelected.bind(this, 'mp3')} className='format'>MP3</div>));

        if (this.UserDetails.CanDownloadHq || document.querySelector('.shared-playlist')) {
            // downloadOptions.push((<div key='aif' onClick={this.handleFormatSelected.bind(this, 'aif')} className='format'>AIF</div>))
            downloadOptions.push((<div key='wav' onClick={this.handleFormatSelected.bind(this, 'wav')} className='format'>WAV</div>))
        }

        return downloadOptions;
    }

    render() {
        const {
            title = 'Download Track',
            left = 0,
            top = 0
        } = this.props;

        const componentStyle = {
            left: left,
            top: top
        };

        return (
            <div className='download-format' style={componentStyle}>
                <div className='background' />
                <div className='clickbait' />
                <div className='content-title'>{title}</div>
                <div className='content'>
                    <Flex row>
                        { this.downloadOptions }
                    </Flex>
                </div>
            </div>
        )
    }
}

export default connect(state => {
    return {
        UserInterface: state.UserInterface
    }
})(DownloadDialog);