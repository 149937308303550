import React from 'react';
import { Button, Dialog, DropDown, Flex, TextField } from 'components/ui';
import './export-playlist.scss';

const dialogName = 'ExportPlaylist';

class ExportPlaylistDialog extends React.Component {
    constructor() {
        super(...arguments);

        this.onKeyUp = (event) => {
            if (event.which == 27) {
                Common.Dialogs.ShowDialog(dialogName, false);
            }

            if (event.which == 13) {
                event.preventDefault();
                this.handleOK();
            }
        }

        this.state = {
            customFrameRate: '',
            videoFormat: null,
            frameRate: null
        }
    }

    componentDidMount() {
        document.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.onKeyUp);
    }

    get onClose() {
        const { onClose = () => {} } = this.props;
        return onClose;
    }

    handleClose = () => {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleOK = () => {
        // TODO: Check to see if the values are set
        const videoFormat = parseInt(this.state.videoFormat.id);
        const frameRate = this.state.frameRate.id !== 'Custom'
            ? parseFloat(this.state.frameRate.id)
            : parseFloat(this.state.customFrameRate);

        if (isNaN(videoFormat) ) {
            Common.Dialogs.ShowAlertMessage({ title: 'Video Format', message: 'You must specify a valid video format.' });
            return;
        }

        if (isNaN(frameRate)) {
            Common.Dialogs.ShowAlertMessage({ title: 'Frame Rate', message: 'You must specify a valid frame rate.' });
            return;
        }

        Common.Playlist.ExportPlaylistAsAvid(videoFormat, frameRate)
            .then(() => {
                this.handleClose();
            })
            .catch((ex) => {
                if (ex) {
                    Common.Dialogs.ShowAlertMessage({ title: 'Error', message: ex.message });
                }
            });
    }

    handleSelectFrameRate = (value) => {
        this.setState({ frameRate: value });
    }

    handleSelectVideoFormat = (value) => {
        this.setState({ videoFormat: value });
    }

    handleTextChanged = (value) => {
        this.setState({ customFrameRate: value });
    }

    render() {
        const videoFormats = [
            { id: '720', text: '720' },
            { id: '1080', text: '1080' },
            { id: '2160', text: '2160 (4K)' },
        ];

        const frameRates = [
            { id: '23.976', text: '23.976' },
            { id: '23.98', text: '23.98' },
            { id: '24', text: '24' },
            { id: '25', text: '25' },
            { id: '29.97', text: '29.97' },
            { id: '30', text: '30' },
            { id: 'Custom', text: 'Custom...' },
        ];

        const customFrameRate = (this.state.frameRate || {}).id !== 'Custom'
            ? ''
            : (
                <Flex row fixed height={40}>
                    <Flex className='field-name' width={150} position='right'>Custom Frame Rate:</Flex>
                    <Flex className='field-value' width={'100%'}>
                        <TextField fontSize={16} autoFocus={true} showClearIcon={false} placeholder={''} value={this.state.customFrameRate} onTextChanged={this.handleTextChanged} />
                    </Flex>
                </Flex>
            );


        return (
            <Dialog title="Export Playlist" name={dialogName} width={600} height={300} model={true} hideTitleIcon={true} closeText='' onClose={this.handleClose} onEscape={this.handleClose}>
                <Flex className='export-playlist-dialog'>
                    <Flex position='top'>
                        <Flex fixed height={180} className='content'>
                            <div>
                                <Flex row fixed height={40}>
                                    <Flex className='field-name' width={150} position='right'>Video Format:</Flex>
                                    <Flex className='field-value' width={'100%'}>
                                        <DropDown placeholder={''}
                                                    items={videoFormats}
                                                    value={this.state.videoFormat}
                                                    onSelect={this.handleSelectVideoFormat} />
                                    </Flex>
                                </Flex>
                                <Flex row fixed height={40}>
                                    <Flex className='field-name' width={150} position='right'>Frame Rate:</Flex>
                                    <Flex className='field-value' width={'100%'}>
                                        <DropDown placeholder={''}
                                                    items={frameRates}
                                                    value={this.state.frameRate}
                                                    onSelect={this.handleSelectFrameRate} />
                                    </Flex>
                                </Flex>
                                {customFrameRate}
                            </div>
                        </Flex>
                        <Flex row fixed={true} className='footer' position='right'>
                            <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                            <Button type='default' size='md' width='100px' onClick={this.handleClose.bind(this)}>Cancel</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Dialog>
        );
    }
}

export default ExportPlaylistDialog;