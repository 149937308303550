export const PLAYLIST_ADD_TRACK_TO_PLAYLIST = 'PLAYLIST_ADD_TRACK_TO_PLAYLIST';
export const PLAYLIST_ADD_TRACKLIST_TO_PLAYLIST = 'PLAYLIST_ADD_TRACKLIST_TO_PLAYLIST';
export const PLAYLIST_CLEAR_PLAYLIST = 'PLAYLIST_CLEAR_PLAYLIST';
export const PLAYLIST_DELETE_PLAYLIST = 'PLAYLIST_DELETE_PLAYLIST';
export const PLAYLIST_REMOVE_TRACK_FROM_PLAYLIST = 'PLAYLIST_REMOVE_TRACK_FROM_PLAYLIST';
export const PLAYLIST_SET_PLAYLIST = 'PLAYLIST_SET_PLAYLIST';
export const PLAYLIST_EXPORT_PLAYLIST_AS_AVID = 'PLAYLIST_EXPORT_PLAYLIST_AS_AVID';
export const PLAYLIST_EXPORT_PLAYLIST_AS_FINAL_CUT = 'PLAYLIST_EXPORT_PLAYLIST_AS_FINAL_CUT';
export const PLAYLIST_EXPORT_PLAYLIST_AS_TEXT = 'PLAYLIST_EXPORT_PLAYLIST_AS_TEXT';

export const addTrackToPlaylist = (track, playlistId) => {
    return {
        type: PLAYLIST_ADD_TRACK_TO_PLAYLIST,
        track: track,
        playlistId: playlistId
    }
}

export const addTrackListToPlaylist = (trackList) => {
    return {
        type: PLAYLIST_ADD_TRACKLIST_TO_PLAYLIST,
        trackList: trackList
    }
}

export const clearPlaylist = () => {
    return {
        type: PLAYLIST_CLEAR_PLAYLIST        
    }
}

export const deletePlaylist = () => {
    return {
        type: PLAYLIST_DELETE_PLAYLIST        
    }
}

export const exportPlaylistAsAvid = (playlistId) => {
    alert('Export Playlist as Avid: ' + playlistId);
    return {
        type: PLAYLIST_EXPORT_PLAYLIST_AS_AVID,
        playlistId: playlistId
    }
}

export const exportPlaylistAsFinalCut = (playlistId) => {
    return {
        type: PLAYLIST_EXPORT_PLAYLIST_AS_FINAL_CUT,
        playlistId: playlistId
    }
    
}

export const exportPlaylistAsText = (playlistId) => {
    alert('Export Playlist as Text: ' + playlistId);
    return {
        type: PLAYLIST_EXPORT_PLAYLIST_AS_TEXT,
        playlistId: playlistId
    }
}

export const removeTrackFromPlaylist = (track) => {
    return {
        type: PLAYLIST_REMOVE_TRACK_FROM_PLAYLIST,
        track: track
    }
}

export const setPlaylist = (playlist) => {
    return {
        type: PLAYLIST_SET_PLAYLIST,
        playlist: playlist
    }
}
