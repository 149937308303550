import { List, Map, fromJS } from 'immutable'

import * as Actions from './actions'

/**
 * Initial reducer state
 */
export const initialPreviousSearchesState = List();


/**
 * Home view reducer
 */
export const previousSearchesReducer = {
  [Actions.UPDATE_PREVIOUS_SEARCHES]: (state, action) => {
    return List(action.searches);
  }
}