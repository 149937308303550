import React from 'react';
import ReactDOM from 'react-dom';
import { Flex } from '../flex/flex';
import { FontIcon } from '../font-icon/font-icon';
import { ShowContextMenu, CloseContextMenu } from 'components/ui/virtual-context-menu/virtual-context-menu';

class VirtualContextMenuList extends React.Component {
    constructor() {
        super(...arguments);

        this.menuButton = React.createRef();
    }

    handleMouseEnter = () => {
        try {
            const virtualContextMenu = document.getElementById("virtual-context-menu");

            if (virtualContextMenu) {
                // If the currently context menu was opened with the same control, just leave don't reshow it.
                if (ReactDOM.findDOMNode(this.menuButton.current).contains(virtualContextMenu.owner))
                    return;
            }

            var rect = ReactDOM.findDOMNode(this.menuButton.current).getBoundingClientRect();
            var rectTop = rect.top;

            if (rectTop + 300 > window.innerHeight) {
                rectTop = rect.top - 300;
            }

            ShowContextMenu(rect.right - 2, rectTop, this.props.contextMenu, this.props.selectedItems, { 
                owner: this.menuButton.current,
                onSelect: (a, b, c) => this.props.onSelect(a, b, c),
                width: this.props.width,
                height: this.props.height
            });
        } catch (ex) {
            console.error("ERROR:", ex);
        }
    }

    handleMouseLeave = (e) => {
        const target = event.relatedTarget;
        const virtualContextMenu = document.getElementById("virtual-context-menu");

        // if we are NOT going to the context menu, close the context menu
        if (virtualContextMenu && !virtualContextMenu.contains(target) && !ReactDOM.findDOMNode(this.menuButton.current).contains(target)) {
            CloseContextMenu();
        }  
    }

    render() {
        const {
            width = 100,
            placeholder = 'Test'
        } = this.props;

        return (
            <Flex ref={this.menuButton} className={'context-menu-list'} width={width} onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)}>
                <Flex row className='current-value' width='100%'>
                    <Flex className='value'>{placeholder}</Flex>
                    <Flex width='20px' align='left' fixed><FontIcon name='chevron-right' size='9px' /></Flex>              
                </Flex>     
            </Flex>  
        )
    }
}

export {
    VirtualContextMenuList
};