import React from 'react';
import './virtual-scrollbars.scss';

class VirtualScrollBars extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        return (
            <div className='virtual-scroll-bars'>
                {this.props.children}
            </div>
        )
    }
}

export { VirtualScrollBars };