import React from 'react';
import { AutoSizer, Grid, List } from 'react-virtualized';
import { Scrollbars } from 'react-custom-scrollbars';
import './scrollbar-controls.scss';

class ScrollableGrid extends React.Component {
    handleScroll = ({ target }) => {
        const { scrollTop, scrollLeft } = target;

        this.Grid.handleScrollEvent({ scrollTop, scrollLeft });
    };

    scrollToTop() {
        this.List.Grid._scrollingContainer.parentElement.scrollTop = 0
    }

    render() {
        const {
            width,
            height,
            autoHide = false,
            onScrollFrame = () => {}
        } = this.props;

        return (
            <Scrollbars className='scrollable-control' autoHide={autoHide} onScrollFrame={onScrollFrame} onScroll={this.handleScroll}>
                <Grid
                    {...this.props}
                    ref={instance => (this.Grid = instance)}
                    style={{
                        overflowX: false,
                        overflowY: false
                    }}
                />
            </Scrollbars>
        );
    }
}

class ScrollableList extends React.Component {
    constructor() {
        super(...arguments);
    }

    handleScroll = ({ target }) => {
        const { scrollTop, scrollLeft, scrollHeight, clientHeight } = target;
        this.List.Grid.handleScrollEvent({ scrollTop, scrollLeft });
    };

    recomputeRowHeights(index = 0) {
        this.List.recomputeRowHeights(index);
    }

    scrollTo(position = 0) {
        this.List.Grid._scrollingContainer.parentElement.scrollTop = position;
    }

    scrollToTop() {
        this.List.Grid._scrollingContainer.parentElement.scrollTop = 0;
    }

    render() {
        const {
            width,
            height,
            autoHide = false,
            autoSize = false,
            onScrollFrame = () => {},
        } = this.props;

        const content = (
            <Scrollbars className='scrollable-control' autoHide={autoHide} onScrollFrame={onScrollFrame} onScroll={this.handleScroll}>                
                <List
                    {...this.props}
                    ref={instance => (this.List = instance)}
                    style={{
                        overflowX: false,
                        overflowY: false
                    }}
                />
            </Scrollbars>
        )

        if (autoSize) {
            return (
                <AutoSizer>
                    {({ width, height }) => (
                        <div style={{ width, height }}>
                            {content}
                        </div>
                    )}
                </AutoSizer>
            )
        } else {
            return (content)
        }    
    }
}

export {
  ScrollableGrid,
  ScrollableList
}
