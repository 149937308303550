const patterns = {
  CMX_FRAME_RATE_REGEX: /^FCM:/,
  CMX_TIMECODE_FRAME_RATE_REGEX: /^TIMECODE FORMAT:\s+([\d+.]+)\s+(?:.*)/,
  CMX_MOTION_EFFECT_REGEX: /^M2\s+(\w+)\s+(\S+)\s+(\d{2}:\d{2}:\d{2}:\d{2})(?:\s+)?$/,
  // -- OLD METHOD --
  // CMX_EVENT_REGEX: /^(\d+)\s+(\w+)\s+(\S+)\s+(\w+)\s+(?:\w+\s+)?(?:\w+\s+)?(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2})/,   
  // -- NEW METHOD - 09/23/2021 --
  CMX_EVENT_REGEX: /^(\d+)\s+(.*?)\s+(\w+)\s+(\w+)\s+(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2}).*/,     
  CMX_EVENT_WITH_TRACKNUMBER_REGEX: /^(\d+)\s+(\w+)\s+(\S+)\s+(\w+)\s+(?:\w+\s+)?(?:\w+\s+)?(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2}).*/,
  CMX_EVENT_WITH_TRACKNUMBER_AND_TITLE_REGEX: /^(\d+)\s+(\d+)\s+\t+(.[^\t]+)\s+(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2})\s+(\d{2}:\d{2}:\d{2}:\d{2})(?:.+)/,
  CMX_SOURCE_FILE_REGEX: /^\*(?:\s+)?SOURCE FILE:\s+(.*)$/,
  CMX_SOURCE_CLIP_REGEX: /^\*(?:\s+)?FROM CLIP NAME:\s+(.*)$/,
  CMX_TITLE_REGEX: /^(?:\s+)?TITLE:\s+(.*)$/,
  CMX_SESSION_NAME_REGEX: /^(?:\s+)?SESSION NAME:\s+(.*)$/,
  CMX_COMMENT_REGEX: /^\*(?:\s+)?(.*)$/,
  GVG_EVENT_REGEX: /\d+\s+\w+\s+\w\s+\w+\s+\d{2}[.:;]\d{2}[.:;]\d{2}[.:;]\d{2}\s+\d{2}[.:;]\d{2}[.:;]\d{2}[.:;]\d{2}\s+\d{2}[.:;]\d{2}[.:;]\d{2}[.:;]\d{2}\s+\d{2}[.:;]\d{2}[.:;]\d{2}[.:;]\d{2}/,
};

Object.freeze(patterns);
module.exports = patterns;
