import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Button, Checkbox, Flex, FontIcon, LoadingPanel, ScrollWindow, TagFilter, Toggle, Switch, Typewriter, VolumeKnob } from 'components/ui';
import Styles from './searchResults.scss';

import { TrackResults } from 'components/common/track-results/track-results';
import { showAlert, closeAlert } from 'components/ui/alert/alert'
import { showMoreLikeThis } from 'store/search/actions'
import { getTranslation } from 'utility/common';

class SearchResults extends React.Component {
    constructor() {
        super(...arguments);

        this.calculateVisibleRowCount = () => {
            if (this.TrackResults) {
                this.state.visibleRowCount = Math.ceil(ReactDOM.findDOMNode(this.TrackResults).clientHeight / 70);
                this.state.currentRow = this.PageRow;
                this.forceUpdate();
            }
        }

        this.state = {
            currentRow: 0,
            isLoadingMore: false,
            includeResults: false,
            includeAlternates: false,
            includeEdits: false,
            visibleRowCount: 0,
            isSorting: false
        }
    }

    componentDidMount() {
        this.setState({ currentRow: this.PageRow });
        window.addEventListener('resize', this.calculateVisibleRowCount);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateVisibleRowCount);
    }

    componentWillReceiveProps(nextProps) {
        this.calculateVisibleRowCount();

        if ((this.props.Search.toJS().SearchResults.Page || 0) !== (nextProps.Search.toJS().SearchResults.Page)) {
            this.state.isLoadingMore = false;
        }

        if (nextProps.isVisible && (nextProps.isVisible != this.props.isVisible)) {
            setTimeout(() => { try { this.onResize(); } catch (ex) {} } , 10);
        }
    }

    get Playlist() {
        const { Playlist } = this.props;
        return Playlist ? Playlist.toJS() : {};
    }

    get Search() {
        const { Search } = this.props;
        return Search ? Search.toJS() : {};
    }

    get UserInterface() {
        const { UserInterface } = this.props;
        return UserInterface ? UserInterface.toJS() : {};
    }

    get PageRow() {
        return this.TrackResults
            ? !isNaN(this.TrackResults.ScrollableList.List.Grid._renderedRowStopIndex) ? this.TrackResults.ScrollableList.List.Grid._renderedRowStopIndex + 1 : 0
            : 0;
    }

    get UserDetails() {
        return Common.GetState().UserInterface.toJS().UserDetails || {};
      }

    handleAddAllToPlaylist() {
        if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
            Common.Dialogs.ShowDialog("SignUpDialog", true);
        return;
        }
        let options = {
            searchType: 'AdvancedSearch'
        };
        Common.Dialogs.ShowDialog('AddAllToPlaylist', true, options);
    }

    handleAlbumInfo(track) {
        Common.Search.ShowAlbumInfo(track.Genre.Id);
    }

    handleAlternates(track) {

    }

    handleIncludeCheck(type, value) {
        this.setState({ [type]: value });
    }

    handleMoreLikeThis(track) {
        Common.Search.ShowMoreLikeThis(track, track.Collection.Id, track.Genre.Id, null, null, null, null);
    }

    handleClearFilters() {
        Common.UserInterface.ClearSearchFilters();
        this.setState({ noResultsFoundMessage: this.state.zeroStateMessage });
    }

    handleClearAuditionHistory() {
        const _this = this;

        const content = (
            <Flex row height={100} position='left'>
                <FontIcon name='inspector' />
                <div className='message'><Typewriter message={ Common.UserInterface.GetRandomMessage('ClearAuditionHistory') } /></div>
            </Flex>
        )

        const handleYes = () => {
            Common.UserInterface.ClearAuditionHistory();
            Common.UserInterface.ClearPreviousTrackDuration();
            setTimeout(() => { closeAlert(); }, 100);
        }

        const handleNo = () => {
            setTimeout(() => { closeAlert(); }, 100);
        }

        const buttons = [
            <Button key={0} width={90} onClick={handleYes.bind(this)}>Yes</Button>,
            <Button key={1} width={90} onClick={handleNo.bind(this)}>No</Button>
        ]

        showAlert({
            contentClass: 'clear-audition-alert',
            title: 'Clear Audition History?',
            width: 600,
            height: 250,
            content: content,
            buttons: buttons,
            onClose: handleNo.bind(this)
        });
    }

    handleRemoveFilter(filter) {
        Common.UserInterface.RemoveQuickSearchFilter(filter);
    }

    handleScrollFrame(values) {
        const searchResults = this.props.Search.toJS().SearchResults;

        this.setState({ currentRow: this.PageRow });

        if (values.top > 0.75 && !this.state.isLoadingMore && searchResults.CanLoadMore) {
            this.state.isLoadingMore = true;
            Common.Search.GetNextSearchResultsPage()
        }
    }

    handleSortBy(type) {
        const forceResort = type.toLowerCase() === 'random';

        this.state.isSorting = true;
        Common.TrackPlayer.Stop();
        this.TrackResults.ScrollableList.scrollToTop();
        Common.UserInterface.SetSearchSortType(type);
        Common.Search.Sort(type, forceResort)
          .then(() => {
            this.state.isSorting = false;
          });
    }

    handleToggleTrack(track) {
        Common.UserInterface.ToggleSearchResultsTrack(track);
    }

    render() {
        return (this.UserInterface.IsSearching || this.Search.SearchResults.Total > 0)
            ? this.renderSearchResultsList()
            : this.renderNoResultsFound();
    }

    renderNoResultsFound() {
        const noResultsClass = classNames('no-results-found', { initial: true });

        const noResultsFoundElement = this.UserInterface.NoSearchResultsFoundMessage != ''
            ? (
                <Flex row height={100}>
                    <FontIcon name='inspector' size={90} />
                    <div className='text-message text-wrap'>
                        <Typewriter message={this.UserInterface.NoSearchResultsFoundMessage} />
                    </div>
                </Flex>
            )
            : '';

        return (
            <Flex className='search-results'>
                <Flex className={noResultsClass}>
                    {noResultsFoundElement}
                </Flex>
            </Flex>
        )
    }

    renderSearchResultsList() {
        const tagFilters = this.UserInterface.SelectedSearchFilters.map((x, i) => {
            return (<TagFilter label={x.Name} item={x} key={i} onClick={this.handleRemoveFilter.bind(this, x)} />);
        })

        if (this.UserInterface.SelectedSearchFilters.length > 0) {
            tagFilters.push(<TagFilter key={tagFilters.length} type='text' label='Clear All' onClick={this.handleClearFilters.bind(this)} />)
        }

        const trackCount = this.state.visibleRowCount;
        const trackTotal = (this.Search.SearchResults.Total);
        const trackCountElement = trackTotal > 0
            ? (<div>{getTranslation("TRACKS", true)}: {this.state.currentRow} / {trackTotal}</div>)
            : ''

        const opacityStyle = (this.UserInterface.IsSearching || this.state.isSorting) ? 1 : 0;
        const zIndexStyle = (this.state.isSorting) ? 1 : 0;
        const backgroundColorStyle = (this.state.isSorting) ? 'rgba(44,43,43, .7)' : 'transparent';
        const searchResultsClass = (this.UserInterface.IsSearching) ? 'hidden' : '';
        return (
            <Flex className='search-results'>
                <Flex style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: zIndexStyle, opacity: opacityStyle, backgroundColor: backgroundColorStyle}}>
                    <LoadingPanel />
                </Flex>
                <Flex className={searchResultsClass} position='left'>
                    <TrackResults
                            ref={(i) => { this.TrackResults = i }}
                            owner={this}
                            source={'search'}
                            autoSize={true}
                            tracks={this.Search.SearchResults.Tracks}
                            onScroll={this.handleScrollFrame.bind(this)}
                    />
                    <Flex row fixed className='footer' position='left'>
                        <Flex position='left' className='add-to-playlist-wrapper'>
                            <Button tooltip = "ADD-ALL-PLAYLIST" className='add-to-playlist-button' type='default' onClick={this.handleAddAllToPlaylist.bind(this)}><Flex row><FontIcon name='circle-plus' />&nbsp; {getTranslation("Add All to Playlist")}</Flex></Button>
                        </Flex>
                        <Flex position='left' className='clear-audition-history-wrapper'>
                            <Button tooltip = "CLEAR-AUDITION-HISTORY" className='clear-audition-history-button' type='default' onClick={this.handleClearAuditionHistory}><Flex row><FontIcon className='padding-5' name='headphones' /> {getTranslation("Clear Audition History")}</Flex></Button>
                        </Flex>
                        <Flex className='clear-sort-order-wrapper'>
                            <Toggle className='sort-by-toggle' label={getTranslation("Sort by") + ":"} labelWidth={80} switchWidth={180} switchHeight={20}>
                                <Switch label={getTranslation("Relevant")} value='Relevant' onClick={this.handleSortBy.bind(this)} selected={this.UserInterface.SearchSortType == 'Relevant'} />
                                <Switch label={getTranslation("Newest")} value='Newest' onClick={this.handleSortBy.bind(this)} selected={this.UserInterface.SearchSortType == 'Newest'} />
                                <Switch label={getTranslation("Random")} value='Random' onClick={this.handleSortBy.bind(this)} selected={this.UserInterface.SearchSortType == 'Random'} />
                                { this.UserDetails.IsAdmin ? <Switch label={getTranslation("Rating")} value='Rating' onClick={this.handleSortBy.bind(this)} selected={this.UserInterface.SearchSortType == 'Rating'} /> : '' }
                            </Toggle>
                        </Flex>
                        <Flex className='flex-r track-count-wrapper'>
                            {trackCountElement}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        )
    }
}

export default connect(state => {
    return {
        Playlist: state.Playlist,
        UserInterface: state.UserInterface,
        Search: state.Search
    }
})(SearchResults);
