import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './login.scss';

import { setUserDetails } from 'store/userInterface/actions';
import { fetchPost } from 'utility/fetchHelpers';
import { Button, Checkbox, Dialog, Flex, FontIcon, TextField, Typewriter } from 'components/ui';

const dialogName = 'LoginDialog';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

class LoginDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            message: '',
            username: '',
            password: '',
            rememberMe: false,
            errorMessage: '',
            showErrorMessage: false
        }
    }

    componentWillMount() {
        this.state.message = Common.UserInterface.GetRandomMessage('Login');

        document.addEventListener('keyup', onKeyUp);
        setTimeout(() => {
            this.Username.focus();
        }, 100);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    }

    get onClose() {
        const {
            onClose = () => {}
        } = this.props;

        return onClose;
    }

    handleCancel() {
        Common.Dialogs.ShowDialog('LoginDialog', false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog('LoginDialog', false);
        this.onClose()
    }

    handleOK() {
        const { dispatch } = this.props;
        const _this = this;

        fetchPost(`/api/login`, {
            username: this.state.username,
            password: this.state.password,
            rememberMe: this.state.rememberMe
        })
        .then(json =>  {
            if (json.success) {
                Common.Dialogs.ShowDialog('LoginDialog', false);
                dispatch(setUserDetails(json.userDetails));
                _this.onClose();
            } else {
                _this.setState({ errorMessage: json.errorMessage, showErrorMessage: true });
            }
        })
        .catch(err => {
            console.error('ERROR: ', err);
        })
    }

    handleRememberMe(value) {
        this.setState({ rememberMe: value });
    }

    handleUsernameChange(value) {
        this.setState({ username: value });
    }

    handlePasswordChange(value) {
        this.setState({ password: value });
    }

    handleKeyPress(value) {
        if (value.which == 13) {
            this.handleOK();
        }
    }

    handleShowSignup = () => {
        Common.Dialogs.ShowDialog('LoginDialog', false);
        Common.Dialogs.ShowDialog('SignUpDialog', true);
    }

    handleForgotPassword = () => {
        Common.Dialogs.ShowDialog('LoginDialog', false);
        Common.Dialogs.ShowDialog('ForgotPasswordDialog', true);
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        const errorMessageClass = classNames({
            'error-message': true,
            'show': this.state.showErrorMessage
        });

        const links = Common.IsAudiofficina()
            ? (<React.Fragment>                                    
                <p>
                    <br/>
                    <div className='link' style={{color:'#FFFFFF'}} onClick={this.handleForgotPassword}>Forgot My Password</div>
                    <br/>
                    <div className='link' style={{color:'#FFFFFF'}} onClick={this.handleShowSignup}>Have an Access Code?</div>
                </p>
                </React.Fragment>)
            : (<p>
                 <div className='link' style={{color:'#FFFFFF'}} onClick={this.handleForgotPassword}>Forgot My Password</div>
               </p>)

        return (
            <form onSubmit={() => false}>
            <Dialog title='Login' name='LoginDialog' width={600} height={350} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleEscape.bind(this)}>
                <Flex className='login-dialog'>
                    <Flex row fixed className='header' position='top'>
                        <FontIcon name='inspector' />
                        <Flex position='left'><Typewriter message={this.state.message} /></Flex>
                    </Flex>
                    <Flex>
                        <Flex width='80%'>
                            <Flex row height='25%'>
                                <Flex width='20%' position='right' style={{paddingRight: '20px'}}>Username:</Flex>
                                <Flex width='80%'><TextField ref={(i) => { this.Username = i }} onKeyPress={this.handleKeyPress.bind(this)} onChange={this.handleUsernameChange.bind(this)} value={this.state.username} height='30px' showClearIcon={false} /></Flex>
                            </Flex>
                            <Flex row height='25%'>
                                <Flex width='20%' position='right' style={{paddingRight: '20px'}}>Password:</Flex>
                                <Flex width='80%'><TextField onKeyPress={this.handleKeyPress.bind(this)} onChange={this.handlePasswordChange.bind(this)} type='password' value={this.state.password} height='30px' showClearIcon={false} /></Flex>
                            </Flex>
                            <Flex row height='25%'>
                                <Flex width='20%'></Flex>
                                <Flex position='left'>{links}</Flex>
                                <Flex position='right'><Checkbox label='Remember Me' textSize='14px' value={this.state.rememberMe} onCheck={this.handleRememberMe.bind(this)} /></Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex row height='40px'>
                        <Flex row position='center'>
                            <span className={errorMessageClass}>{this.state.errorMessage}</span>
                        </Flex>
                        <Flex row width='200px' position='right'>
                            <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>Login</Button>
                            <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Dialog>
            </form>
        )
    }
}

export default connect(state => {
    return {
        UserInterface: state.UserInterface
    }
})(LoginDialog);