import React from 'react';
import classNames from 'classnames';
import Style from './text-field.scss';

import { Flex, FontIconButton } from 'components/ui';

import { toolTipsNotes } from "../../../utility/common";
import ReactTooltip from 'react-tooltip';

export class TextField extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
            showPlaceholder: true,
            value: '',
            toolNotes:""
        };
    }

    componentDidMount() {
        const { autoFocus = false } = this.props;
        
        // set the initial value for the text-field
        this.setState({ 
            value: this.props.value || '' 
        });

        // if autofocus has been selected, focus on the field
        if (autoFocus) {
            this.input.select();
            this.input.focus();
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            value: props.value || ''
        });
    }

    // Blur
    blur() {
        this.input.blur();
    }

    // Focus 
    focus() {
        this.input.focus();
        this.setState({ showPlaceholder: true });
    }  

    select() {
        this.input.select();
    } 
    
    setSelectionRange(selectionStart, selectionEnd, selectionDirection = 'none' ) {
        this.input.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
    }

    // Value Property
    get value () {
        return this.state.value;
    }
    
    set value(value) {
        this.state.value = value;
    }

    // handles the blur event of the text-field
    // fires the onTextChanged event if the original value and the new value are different
    handleBlur() {
        const { 
            value,
            onBlur = () => {},
            onTextChanged = () => {}
        } = this.props;

        if (value != this.state.value)
            onTextChanged(this.state.value, value);

        this.setState({ showPlaceholder: true });

        onBlur();
    }

    handleClearField(e) {
        const { 
            onChange = () => {},
            onTextChanged = () => {}
        } = this.props;
        var val = '';
        
        this.setState({ value: val });
        
        setTimeout(() => {
            onTextChanged(this.state.value, val);
            onChange(val);
    
            this.input.focus();
        }, 20);
    }

    // handles the focus event for the text-field
    handleFocus() {
        const { onFocus = () => {} } = this.props;
        this.setState({ showPlaceholder: false });
        onFocus();
    }

    handleKeyPress(e) {
        const { 
            onEnter = () => {},
            onKeyPress = () => {}
        } = this.props;

        if (e.which == 13) {
            onEnter(e.target.value);
        }         

        onKeyPress(e);
    }

    handleKeyDown(e) {
        const { 
            onEnter = () => {},
            onKeyDown = () => {}
        } = this.props;

        onKeyDown(e);
    }

    // handles the value changed event
    handleTextChanged(e) {
        const { onChange = () => {} } = this.props;
        const val = e.target.value;
        this.setState({ value: val });
        onChange(val);
    }

     hadleMouseEnterTooltips = () => {
        let label =  toolTipsNotes(this.props.tooltip)
        this.setState({toolNotes:label})
    }

    render() {
        const {           
            fontSize = null, 
            placeholder = '',
            textColor = null,
            showClearIcon = true,
            tooltip,
            readonly = false,
            type = 'text',
            width = null,
            height = null,
            style = {},
            className = null,
            inputClass = null,
            required = false,
            onClick = () => {},
            onKeyDown = () => {},
            onKeyPress = () => {},
            onKeyUp = () => {}
        } = this.props;

        const componentStyle = Object.assign({}, style, {
            fontSize: fontSize,         
            width: width,
            height: height,
            color: textColor
        });

        const componentClass = classNames({
            'text-field': true,
            'readonly': readonly,
            'required': required
        }, className);

        const componentInputClass = classNames({
            'input': true            
        }, inputClass)

        const placeholderElement = this.state.showPlaceholder && this.state.value.trim() == ''
            ? (<div className='placeholder'>{placeholder}</div>)
            : '';

        const clearTextIcon = showClearIcon
            ? <FontIconButton className='clear-text' name='close' size={fontSize} onClick={this.handleClearField.bind(this)} />
            : '';

        const place = tooltip === 'SEARCH-TEXT-FIELD' ? 'right' : 'bottom';
        const reactTooltip = (tooltip != null)
            ? (<ReactTooltip id={tooltip} className="tooltip-background" place={place} type="light" multiline={true} delayHide={1000} effect="solid"></ReactTooltip>)
            : '';

        return (
            <Flex className={componentClass} style={componentStyle}>
                <Flex className='container' >
                    {placeholderElement}
                    <input data-tip={this.state.toolNotes} data-for={tooltip} className={componentInputClass}                           
                           autoComplete='off'                           
                           readOnly={readonly}
                           type={type} 
                           ref={(input) => { this.input = input }} 
                           value={this.state.value}
                           onBlur={this.handleBlur.bind(this)} 
                           onFocus={this.handleFocus.bind(this)}
                           onChange={this.handleTextChanged.bind(this)}
                           onClick={onClick}
                           onKeyDown={this.handleKeyDown.bind(this)} 
                           onKeyPress={this.handleKeyPress.bind(this)} 
                           onKeyUp={onKeyUp.bind(this)} 
                           onMouseEnter={this.hadleMouseEnterTooltips.bind(this)}
                           />
                    {clearTextIcon}
                    {reactTooltip}                    
                </Flex>
            </Flex>
        )
    }
}