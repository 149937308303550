import React from 'react';
import classNames from 'classnames';
import { Flex } from 'components/ui';
import Styles from './text-area.scss';

export class TextArea extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            value: ''
        };        
    }

    componentDidMount() {
        const { autoFocus = false } = this.props;
        
        // set the initial value for the text-field
        this.setState({ 
            value: this.props.value || '' 
        });

        // if autofocus has been selected, focus on the field
        if (autoFocus) {
            this.input.select();
            this.input.focus();
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            value: props.value || ''
        });
    }

    // Blur
    blur() {
        this.input.blur();
    }

    // Focus 
    focus() {
        this.input.focus();
    }  

    select() {
        this.input.select();
    }  

    // Value Property
    get value () {
        return this.state.value;
    }

    // handles the blur event of the text-field
    // fires the onTextChanged event if the original value and the new value are different
    handleBlur() {
        const { 
            value,
            onBlur = () => {},
            onTextChanged = () => {}
        } = this.props;

        if (value != this.state.value)
            onTextChanged(this.state.value, value);

        onBlur();
    }

    handleClearField() {
        const { onChange = () => {} } = this.props;
        var val = '';

        this.setState({ value: val });
        onChange(val);
        
        setTimeout(() => {
            this.input.focus();
        }, 20);
    }

    // handles the focus event for the text-field
    handleFocus() {
        const { onFocus = () => {} } = this.props;
        onFocus();
    }

    handleKeyPress(e) {
        const { 
            onEnter = () => {},
            onKeyPress = () => {}
        } = this.props;

        if (e.which == 13) {
            onEnter();
        } 

        onKeyPress(e);
    }

    // handles the value changed event
    handleTextChanged(e) {
        const { onChange = () => {} } = this.props;
        const val = e.target.value;
        this.setState({ value: val });

        onChange(val);
    }

    render() {
        const {
            fieldName = '',
            required = false,
            resize = 'none',
            value,
            height = null,
            width = null,
            className = null,
            onKeyDown = () => {},
            onKeyPress = () => {},
            onKeyUp = () => {}
        } = this.props;

        const componentClass = classNames({
            'text-area': true,
            'required': required
        }, className);

        const componentStyle = {
            width: width,
            height: height
        }

        const textAreaStyle = {
            width: '100%',
            height: '100%',
            resize: resize
        }

        return (
            <Flex className={componentClass} style={componentStyle}>
                <textarea style={textAreaStyle} 
                          ref={(input) => { this.input = input }} 
                          value={this.state.value}
                          onBlur={this.handleBlur.bind(this)} 
                          onFocus={this.handleFocus.bind(this)}
                          onChange={this.handleTextChanged.bind(this)}
                          onKeyDown={onKeyDown.bind(this)} 
                          onKeyPress={this.handleKeyPress.bind(this)} 
                          onKeyUp={onKeyUp.bind(this)}                
                
                />
            </Flex>
        )
    }
}
