import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { TextField, ScrollWindow } from 'components/ui';
import './context-menu.scss';

const contextMenus = new Array(10);
let checkedItems = [];
let menuOptions = {};

const callbacks = {
    onItemSelected: () => {},
};

const itemIsChecked = (item) => {
    return checkedItems.findIndex(i => i === item) >= 0;
}

const itemHasAllChildrenChecked = (item) => {
    if (item.SubMenu && item.SubMenu.length > 0) {
        let checked = true;

        item.SubMenu.forEach(subItem => {
            if (!itemIsChecked(subItem))
                checked = false;
        });

        return checked;
    }

    return false;
}

const itemHasSomeChildrenChecked = (item) => {
    const childrenCount = (item.SubMenu && item.SubMenu.length > 0) ? item.SubMenu.length : 0;
    let childrenChecked = 0;

    if (itemIsChecked(item)) return true;

    if (childrenCount > 0) {
        item.SubMenu.forEach(subItem => {
            if (itemHasSomeChildrenChecked(subItem)) {
                childrenChecked++;
            }
        })
    }

    return ((childrenCount > 0) && (childrenChecked > 0));
}

const defaultSettings = {
    height: null,
    width: null,
    lineHeight: 30,
    maxHeight: window.innerHeight - 32,
    maxWidth: window.innerWidth / 2,
    minHeight: 32,
    minWidth: null
}

const eventPath = (evt) => {
    var path = null; //evt.path || (evt.composedPath && evt.composedPath());
    var target = evt.target;

    if (path != null) {
        // Safari doesn't include Window, but it should.
        return (path.indexOf(window) < 0) ? path.concat(window) : path;
    }

    if (target === window) {
        return [window];
    }

    function getParents(node, memo) {
        memo = memo || [];
        var parentNode = node.parentNode;

        if (!parentNode) {
            return memo;
        }
        else {
            return getParents(parentNode, memo.concat(parentNode));
        }
    }

    return [target].concat(getParents(target), window);
}

const onContextClick = (event) => {
    const path = eventPath(event);
    const contextWindow = path.findIndex(ep => ep.className && ep.className.indexOf('cm-window') > -1);

    if (contextWindow === -1) closeContextMenu();
}

let menuTimer = 0;
export const closeContextMenu = () => {    
    const cm = document.getElementById('context-menu');
    if (menuTimer) {
      clearTimeout(menuTimer);
    }

    if (cm != null) {
        document.body.removeChild(cm);
    }

    for (let i = 0; i < 10; i++) {
        contextMenus[i] = null;
    }

    if (callbacks.onClose)
        callbacks.onClose();

    document.removeEventListener('click', onContextClick);
}

export const showContextMenu = (x, y,menu, selectedItems = [], options, onItemSelected = () => {}, onItemChecked = () => {}, onClose = () => {}, fromTrackPlayer, placeholder) => {
    if (document.getElementById('context-menu') != null) {
        return;
    }

    clearTimeout(menuTimer);
    checkedItems = selectedItems
        ? (selectedItems.constructor === Array)
            ? selectedItems.slice(0)
            : []
        : [];
    callbacks.onItemChecked = onItemChecked;
    callbacks.onItemSelected = onItemSelected;
    callbacks.onClose = onClose;

    const cm = document.createElement("div");
    cm.id = 'context-menu';
    document.body.appendChild(cm);

    menuOptions = Object.assign({}, defaultSettings, options);

    ReactDOM.render (
        <ContextMenuContainer>
            <ContextMenu x={x} y={y} level={0} menu={menu ? menu.slice(0) : []} options={menuOptions} parent={menuOptions.owner} fromTrackPlayer={fromTrackPlayer} placeholder={placeholder}/>
        </ContextMenuContainer>
    , cm);

    document.addEventListener('click', onContextClick);
}

class ContextMenuContainer extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            selectedItems: [],
            childMenus: new Array(10)
        }
    }

    componentDidMount() {
        const { level } = this.props;
        contextMenus[level] = this;
    }

    handleContainerClick(event) {
        event.stopPropagation();
        event.preventDefault();
        closeContextMenu();
    }

    render() {
        const {
            children
        } = this.props;

        return (
            <div className='cm-container' onClick={this.handleContainerClick.bind(this)} onContextMenu={this.handleContainerClick.bind(this)}>
                {children}
            </div>
        );
    }
}

class ContextMenu extends React.Component {
    constructor() {
        super(...arguments);
        this.itemElements = [];
        this.currentSubMenu = null;
        this.state = {
            txtSearchBox: '',
            menu:[]
        };
    }

    componentDidMount() {
        contextMenus[this.props.level] = this;
        clearTimeout(menuTimer);
        this.setState({menu:this.props.menu})
        if(this.props.fromTrackPlayer) menuTimer = setTimeout(() => {closeContextMenu();}, 3000);
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize)
    }

    componentDidUpdate() {
        const parentObj = ReactDOM.findDOMNode(this.props.parent);
        if (parentObj === null) {
            return;
        }
        
        const parentRect = ReactDOM.findDOMNode(this.props.parent).getBoundingClientRect();
        const elm = ReactDOM.findDOMNode(this.element);

        if (elm == null) return;

        const shadowElm = ReactDOM.findDOMNode(this.shadowElement);
        const rect = elm.getBoundingClientRect();

        var x = parentRect.right;
        var y = this.props.y;

        if ((y + rect.height) > window.innerHeight) {
            y = window.innerHeight - Math.min(rect.height, this.props.options.maxHeight);
                        
            if (y < 30) y = 30
        }

        if ((x + rect.width) > window.innerWidth) {
            x = this.props.parent
                ? ReactDOM.findDOMNode(this.props.parent).getBoundingClientRect().left - rect.width
                : window.innerWidth - rect.width;
        }

        elm.style.left = x + 'px';
        elm.style.top = y + 'px';     

        const shadowRect = elm.getBoundingClientRect();
        shadowElm.style.left = shadowRect.left + 'px';
        shadowElm.style.top = (shadowRect.top - 20) + 'px';
        shadowElm.style.width = (shadowRect.width + 20) + 'px';
        shadowElm.style.height = (shadowRect.height + 40) + 'px';
    }

    handleWindowClick(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    handleWindowResize(event) {
        options.maxHeight = window.innerHeight * 0.95
        this.forceUpdate();
    }

    handleItemClick(item, elementIndex, event) {
        const element = this.itemElements[elementIndex];
        if (menuOptions.multiSelect) {
            const isChecked = !(element.classList.contains('checked'));

            if (item.SubMenu && item.SubMenu.length > 0) {
                item.SubMenu.forEach((subItem, index) => {
                    const subIndex = checkedItems.findIndex(ci => ci.Value.Id === subItem.Value.Id);
                    if (isChecked) {
                        checkedItems.push(subItem);
                    } else {
                        if (subIndex >= 0)
                            checkedItems.splice(subIndex, 1);
                    }
                });
            } else {
                const subIndex = checkedItems.findIndex(ci => ci.Value.Id === item.Value.Id);

                if (this.parentMenu) {
                    let allChecked = true;
                    this.parentMenu.currentSubMenu.props.menu.forEach(sm => {
                        allChecked = allChecked && (checkedItems.findIndex(v => v.Value.Id === sm.Value.Id) >= 0);
                    });

                    ReactDOM.findDOMNode(this.parentMenu).classList.toggle('checked', isChecked);
                }

                if (isChecked) {
                    if (subIndex === -1)
                        checkedItems.push(item);
                } else {
                    if (subIndex >= 0)
                    checkedItems.splice(subIndex, 1);
                }
            }

            element.classList.toggle('checked', isChecked);
            callbacks.onItemSelected(checkedItems, isChecked);
        } else {
            callbacks.onItemSelected(item);
            closeContextMenu();
        }

        this.forceUpdate();
        if (this.parentMenu) this.parentMenu.forceUpdate();
        if (this.currentSubMenu) this.currentSubMenu.forceUpdate();
    }

    handleMouseLeave(elementIndex, event) {
        const target = event ? event.relatedTarget : null;
        const owner = this.props.options.owner;
        const context = document.getElementById("context-menu");
        const parentElement = this.itemElements[elementIndex];

        clearTimeout(menuTimer);

        if (event.target !== parentElement && parentElement.classList)
            parentElement.classList.toggle('parent-item', false);

        try {
            if (owner && owner instanceof Node) {
                if ((!owner.contains(target)) && (context && !context.contains(target))) {
                    menuTimer = setTimeout(() => closeContextMenu(), 1000);
                }
            }
        } catch (ex) {}
    }

    handleMouseEnter(item, elementIndex, event) {
        const { options, level } = this.props;
        const newLevel = level + 1;

        clearTimeout(menuTimer);

        try {
            if (item.SubMenu && item.SubMenu.length > 0) {
                if (contextMenus[newLevel] && contextMenus[newLevel].parent === item) return;

                if (contextMenus[newLevel] != null) {
                    contextMenus[0].currentSubMenu = null;
                    ReactDOM.unmountComponentAtNode(contextMenus[newLevel].subMenu);
                }

                const cm = document.getElementById('context-menu');
                const rect = event.target.closest('.cm-item').getBoundingClientRect();
                const temp = cm.appendChild(document.createElement("div"));
                const parentElement = this.itemElements[elementIndex];

                this.itemElements.forEach(e => {
                    if (e !== parentElement)
                        e.classList.toggle('parent-item', false);
                    else
                        e.classList.toggle('parent-item', true);
                })

                var currentSubMenu = ReactDOM.render(<ContextMenu x={rect.right-9} y={rect.top-8} level={newLevel} menu={item.SubMenu} options={options} parent={this} />, temp);
                this.currentSubMenu = currentSubMenu;
                this.currentSubMenu.parentMenu = this;
                contextMenus[level+1] = { parent: item, subMenu: temp };
            } else {
                if (contextMenus[newLevel] != null) {
                    this.currentSubMenu = null;
                    ReactDOM.unmountComponentAtNode(contextMenus[newLevel].subMenu);
                }

                contextMenus[newLevel] = null;
            }
        } catch(ex) {}
    }

    handleSearchBoxChanged(value) {
        this.setState({ txtSearchBox: value });
        let myMenu = [...this.props.menu];
        if (value !== "") {
            var composerSearch = [];
            myMenu.forEach(val => {
              if (val.Label.toLowerCase().includes(value.toLowerCase())) {
                composerSearch.push(val);
              }
            });
                this.setState({ menu: composerSearch });
        }
        else{
             this.setState({ menu: this.props.menu });
        }
    }

    handleSearchBoxClick = (e) =>{
       clearTimeout(menuTimer);
    }

    render() {
        const {
            x = 0,
            y = 0,
            menu = [],
            parent = null,
            options = defaultSettings(),
            fromTrackPlayer
        } = this.props;

        if (options.maxHeight == null) {
            options.maxHeight = window.innerHeight * 0.95;
        }

        let maxChar = 35;

        if (menu.length === 0 ) return (<div />)
        
        const menuItems = this.state.menu.map((item, index) => {
          let checked = itemIsChecked(item);
          let someChecked = false;

          if (!item) return;

          if (item.SubMenu && item.SubMenu.length > 0) {
            checked = itemHasAllChildrenChecked(item);
            someChecked = itemHasSomeChildrenChecked(item);
          }

          if (item.Selected) {
            checked = item.Selected;
          }

          const cmItemClass = classNames({
            "cm-item": true,
            checked: checked,
            "some-checked": someChecked,
            children: item.SubMenu && item.SubMenu.length > 0
          });

          maxChar = item.Label.length > maxChar ? item.Label.length : maxChar;

          return (
            <div
              key={index}
              ref={i => {
                this.itemElements[index] = i;
              }}
              className={cmItemClass}
              onClick={this.handleItemClick.bind(this, item, index)}
              onMouseLeave={this.handleMouseLeave.bind(this, index)}
              onMouseEnter={this.handleMouseEnter.bind(this, item, index)}
            >
              {item.Selected !== undefined && item.Selected !== null ? (
                item.Selected === true ? (
                  <div className="checkbox_new" width={20} />
                ) : (
                  <div className="checkBoxSpace" />
                )
              ) : item.Selected === null ? (
                <div className="checkBoxSpace" />
              ) : (
                <div className="checkbox" width={20} />
              )}
              <div className="label">{item.Label}</div>
              <div className="submenu" width={20} />
            </div>
          );
        });

        const componentStyle = Object.assign({}, {
            left: x,
            top: y,
            width: options.width,
            height: options.height,
            maxHeight: options.maxHeight,
            maxWidth: options.maxWidth + 30,
            minHeight: options.minHeight,
            overflowY: 'auto'               // TODO: temporary until ScrollWindow can get fixed
        });

        if (options.height == null) {         
            if (window.innerHeight < options.maxHeight) {
                options.maxHeight = window.innerHeight - 32;
            }   
            
            componentStyle.height = Math.min(menuItems.length * 30, options.maxHeight) + 16;
        }
        
        if (componentStyle.top + componentStyle.height > Common.windowHeight - 10) {
            if (Common.isSmallScale) {
                componentStyle.top = Common.windowHeight - componentStyle.height - 10;                
            } else {
                componentStyle.top = Common.windowHeight - componentStyle.height - 10;
            }
        }

        const cmWindowClass = classNames({
            'cm-window': true,
            'cm-window-child': parent instanceof ContextMenu
        });

        const searchBoxStyle = {
            marginTop: '5px',
            marginBottom: '10px',
            marginLeft: '10px'
        };

        const textBoxStyle = {
          backgroundColor: "#565656"
        };

        const searchBoxField = fromTrackPlayer === 'Composer' ?
                    <div style={searchBoxStyle}>
                        <TextField placeholder='Type here to search...' style={textBoxStyle}
                           value={this.state.txtSearchBox}
                           onChange={this.handleSearchBoxChanged.bind(this)}
                           onClick={this.handleSearchBoxClick(event)}
                        />
                    </div> : null;

        return (
            <React.Fragment>
                <div ref={(i) => { this.element = i}} className={cmWindowClass} style={componentStyle} onClick={this.handleWindowClick.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this, 0)}>
                        {searchBoxField}

                    <ScrollWindow showscrollIndicator={true}>
                        {menuItems}
                    </ScrollWindow>
                </div>
                <div ref={(i) => { this.shadowElement = i}} className='cm-window-shadow' onMouseLeave={this.handleMouseLeave.bind(this, 0)}></div>
           </React.Fragment>
        )
    }
}
