import React, { Component } from 'react';
import { Button, Dialog, Flex, FontIcon, TextField, Typewriter } from 'components/ui';
import './forgot-password.scss';

const dialogName = 'ForgotPasswordDialog';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

class ForgotPasswordDialog extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      message: 'Forgot your password?  No problem, we can fix it.  Just type your username below and we will send you password reset instructions to your email address.',
      username: null
    }
  }

  componentDidMount() {
    this.Username.focus();
  }

  handleCancel = () => {
    const {
      onClose = () => {}
    } = this.props;

    Common.Dialogs.ShowDialog(dialogName, false);
    onClose();
  }

  handleEscape = () => {
    const {
      onClose = () => {}
    } = this.props;

    Common.Dialogs.ShowDialog(dialogName, false);
    onClose();
  }
  
  handleKeyPress = (event) => {
    if (event.which == 13) {
        this.handleOK();
    }        
  }

  handleOK = () => {
    Common.UserInterface.ForgotPassword(this.state.username)
      .then(() => {
        Common.Dialogs.ShowAlertMessage({
          contentClass: 'forgot-password-success',
          title: 'Forgot Password',
          message: 'Email successfully sent.  Please check your inbox (or spam) folder for an email with instructions on how to reset your password.',
          width: 500,
          height: 200
        });
        
        Common.Dialogs.ShowDialog('ForgotPasswordDialog', false);  
      })
      .catch(error => {
        Common.Dialogs.ShowAlertMessage({
          contentClass: 'forgot-password-error',
          title: 'Forgot Password',
          message: error,
          width: 500,
          height: 200
        });

        Common.Dialogs.ShowDialog('ForgotPasswordDialog', false);
      })
  }

  handleUsernameChanged = (value) => {
    this.setState({ username: value });
  }

  render() {
    return (
      <Dialog title='Forgot Password' name={dialogName} width={600} height={290} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel} onEscape={this.handleEscape}>
        <Flex className='forgot-password-dialog'>
          <Flex row fixed className='header' position='top'>
            <FontIcon name='inspector' />
            <Flex position='left'><Typewriter message={this.state.message} /></Flex>                              
          </Flex>

          <Flex row className='form' position='left'>
            <Flex width={'20%'} position='right' style={{paddingRight: '20px'}}>Username:</Flex>
            <Flex width={'80%'}><TextField ref={(i) => { this.Username = i }} onKeyPress={this.handleKeyPress} onChange={this.handleUsernameChanged} value={this.state.username} height='30px' showClearIcon={false} /></Flex>
          </Flex>
          <div className='error-message'>{this.state.errorMessage}</div>

          <Flex row className='footer' position='right'>
            <Button onClick={this.handleOK}>Reset</Button>
            <Button onClick={this.handleCancel}>Cancel</Button>
          </Flex>
        </Flex>
      </Dialog>
    )
  }
}

export default ForgotPasswordDialog;
