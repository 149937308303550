import { generateGuid } from 'utility/utility';
import { List, Map } from 'immutable'
import * as Actions from './actions'

const createEmptyPlaylist = () => {
  return {
    Id: null,
    Title: '',
    Tracks: []
  }
}


/// <summary>
/// Returns the main track ids for the entire playlist
/// </summary>
const getMainTrackList = (tracks) => {
  const mainTracks = tracks.filter(t => t.IsMainTrack);
  const nonEntries = tracks.filter(t => mainTracks.findIndex(x => x.TrackGroup == t.TrackGroup) === -1);

  // Get the unique groups from the entries we don't have main tracks for
  const uniqueGroups = [];
  nonEntries.forEach(ne => {
    if (uniqueGroups.findIndex(g => g === ne.TrackGroup) === -1)
      uniqueGroups.push(ne.TrackGroup);
  });

  // Sort the tracks according to their sort title and return the top one for unique groups
  uniqueGroups.forEach(g => {
    const t = nonEntries.filter(ne => ne.TrackGroup == g);
    t.sort((a, b) => (a.TrackSortTitle > b.TrackSortTitle) ? 1 : -1);

    mainTracks.push(t[0]);
  });

  return mainTracks.map(t => t.Id).join(',');
}

/**
 * Initial reducer state
 */
export const initialPlaylistState = Map(createEmptyPlaylist());

/**
 * Home view reducer
 */
export const playlistReducer = {
  [Actions.PLAYLIST_ADD_TRACK_TO_PLAYLIST]: (state, action) => {
    var playlist = state.toJS();
    if(playlist.Id === action.playlistId){
      var trackIndex = playlist.Tracks.findIndex(t => t.Id === action.track.Id);

      if (trackIndex === -1) {
        playlist.Tracks.push(action.track);
      } else {
        playlist.Tracks[trackIndex] = action.track;
      }
    }

    playlist.MainTracks = getMainTrackList(playlist.Tracks);
  
    return Map(playlist);
  },

  [Actions.PLAYLIST_ADD_TRACKLIST_TO_PLAYLIST]: (state, action) => {
    var playlist = state.toJS();

    action.trackList.forEach((track, i) => {
      var trackIndex = playlist.Tracks.findIndex(t => t.Id === track.Id);

      if (trackIndex === -1) {
        playlist.Tracks.push(track);
      }
    })
    
    return Map(playlist);
  },

  [Actions.PLAYLIST_CLEAR_PLAYLIST]: (state, action) => {
    const playlist = createEmptyPlaylist();
    return state.set(playlist);
  },

  [Actions.PLAYLIST_DELETE_PLAYLIST]: (state, action) => {
    const playlist = createEmptyPlaylist();
    return Map(playlist);
  },

  [Actions.PLAYLIST_REMOVE_TRACK_FROM_PLAYLIST]: (state, action) => {
    var playlist = state.toJS();
    var trackIndex = playlist.Tracks.findIndex(t => t.Id === action.track.Id);

    if (trackIndex >= 0) {
      playlist.Tracks.splice(trackIndex, 1);
      playlist.MainTracks = getMainTrackList(playlist.Tracks);
      return Map(playlist);
    }

    return state;
  },

  [Actions.PLAYLIST_SET_PLAYLIST]: (state, action) => {
    var  playlist = state.toJS();
    playlist.MainTracks =  getMainTrackList(playlist.Tracks);
    var newState = Object.assign({}, createEmptyPlaylist(), action.playlist);
    return Map(newState);
  },
}
