import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import './working.scss';

import { Dialog, Flex, RenderInBody } from 'components/ui';

const dialogName = 'WorkingDialog';

const onKeyUp = (event) => {
    if (event.which == 27) {
        closeWorkingAlert();
    }
}

let alertNode = null;

let defaultOptions = {
    onClose: () => {},
    buttons: null,
    content: (<div>Working</div>),
    contentClass: null,
    title: 'Working',
    height: 175,
    width: 380
}

export const closeWorkingAlert = () => {  
    if (alertNode != null) {
        ReactDOM.unmountComponentAtNode(alertNode);    
        alertNode = null;
    }
}

export const showWorkingAlert = (passedOptions = defaultOptions) => {    
    if (alertNode == null) {
        alertNode = document.createElement('div');

        const options = Object.assign({}, defaultOptions, passedOptions);

        ReactDOM.render(
            (<WorkingDialog className={options.contentClass} title={options.title} width={options.width} height={options.height} content={options.content} buttons={options.buttons} onClose={options.onClose} />),
            document.getElementById('body-render').appendChild(alertNode)
        );

        return alertNode;
    }
}

class WorkingDialog extends React.Component {
    constructor() {
        super(...arguments);        
    }

    componentDidMount() {
        document.addEventListener('keyup', onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    }

    handleCancel() {
        const {
            onClose = () => {}
        } = this.props;

        onClose();        
        closeWorkingAlert();
    }

    render() {
        const {    
            width = 600,
            height = 300,
            title = 'Working',
            content = null,        
            buttons = null,
            hideTitleIcon = true,
            className = null,
            onClose = () => {}
        } = this.props;

        const workingFooter = buttons
            ? (<Flex row className='footer' position='right' height={70}>{buttons}</Flex>)
            : '';

        const contentClass = classNames('alert-content', className);

        return (
            <Dialog hideTitleIcon={hideTitleIcon} title={title} name={dialogName} width={width} height={height} modal={true} onClose={this.handleCancel.bind(this)} closeText=''>
                <Flex className='working-dialog'>
                    <Flex row className={contentClass} position='center'>
                        <div className='working-alert-head' /> { content }
                    </Flex>                
                    { workingFooter }
                </Flex>
            </Dialog>
        )
    }
}

export default WorkingDialog;