import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Button, Checkbox, ContextMenuList, DateDropDown, DropDown, Flex, FontIcon, FontIconButton, ScrollWindow, TextField, VirtualContextMenuList } from "components/ui";
import "./advanced-search.scss";
import { getTranslation } from 'utility/common';

const getFormattedDuration = (value) => {
  if (!value) return "";
  return Common.ConvertToHHMMSS(value);
};

class AdvancedSearch extends React.Component {
  constructor() {
    super(...arguments);
  }

  get AdvancedSearch() {
    return this.UserInterface.AdvancedSearch;
  }

  get UserInterface() {
    const { UserInterface } = this.props;
    return UserInterface ? UserInterface.toJS() : {};
  }

  get SelectedContextMenuItems() {
    return this.AdvancedSearch.SelectedContextMenuItems;
  }

  componentDidUpdate() {
    const currentTime = new Date().getTime();
    localStorage.setItem("expirationTime", currentTime);
  }

  componentDidMount() {
    const expirationDuration = 1000 * 60 * 5; // 5 minutes
    const expirationTime = localStorage.getItem("expirationTime");
    const currentTime = new Date().getTime();
    const notAccepted = expirationTime == undefined;
    const expirationTimeExpired = expirationTime != undefined && currentTime - expirationTime > expirationDuration;
    if (notAccepted || expirationTimeExpired) {
      localStorage.setItem("expirationTime", currentTime);
    } else {
      if (localStorage.getItem("advancedSearch") !== null) {
        const advancedSearch = JSON.parse(localStorage.getItem("advancedSearch"));
        
        this.AdvancedSearch.IncludeType = advancedSearch.AdvancedSearchIncludeType;
        this.AdvancedSearch.Include = advancedSearch.AdvancedSearchInclude;
        this.AdvancedSearch.Exclude = advancedSearch.AdvancedSearchExclude;
        this.AdvancedSearch.ExcludeGenreFlag = advancedSearch.AdvancedSearchExcludeGenreFlag;
        this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Genre = advancedSearch.ContextMenuItemsGenre;
        this.AdvancedSearch.ExcludeMoodFlag = advancedSearch.AdvancedSearchExcludeGenreFlag;
        this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Mood = advancedSearch.ContextMenuItemsMood;
        this.AdvancedSearch.ExcludeTempoFlag = advancedSearch.AdvancedSearchExcludeTempoFlag;
        this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Tempo = advancedSearch.ContextMenuItemsTempo;
        this.AdvancedSearch.ExcludeEnergyFlag = advancedSearch.AdvancedSearchExcludeEnergyFlag;
        this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Energy = advancedSearch.ContextMenuItemsEnergy;
        this.AdvancedSearch.ExcludeLooksLikeFlag = advancedSearch.AdvancedSearchExcludeLooksLikeFlag;
        this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Scenes = advancedSearch.ContextMenuItemsScenes;
        this.AdvancedSearch.ExcludeSoundsLikeFlag = advancedSearch.AdvancedSearchExcludeSoundsLikeFlag;
        this.UserInterface.AdvancedSearch.SelectedContextMenuItems.SoundsLike = advancedSearch.ContextMenuItemsSoundsLike;
        this.AdvancedSearch.ExcludeCollectionFlag = advancedSearch.AdvancedSearchExcludeCollectionFlag;
        this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Collection = advancedSearch.ContextMenuItemsCollection;
        this.AdvancedSearch.TrackPrefix = advancedSearch.TrackPrefix;
        this.AdvancedSearch.ExcludeComposerFlag = advancedSearch.AdvancedSearchExcludeComposerFlag;
        this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Composer = advancedSearch.ContextMenuItemsComposer;
        this.AdvancedSearch.ExcludeAdTimingFlag = advancedSearch.AdvancedSearchExcludeAdTimingFlag;
        this.UserInterface.AdvancedSearch.SelectedContextMenuItems.AdTiming = advancedSearch.ContextMenuItemsAdTiming;
        this.AdvancedSearch.DurationMin = advancedSearch.DurationMin;
        this.AdvancedSearch.DurationMax = advancedSearch.DurationMax;
        // //DateDropDown
        this.AdvancedSearch.Title = advancedSearch.Title;
        this.AdvancedSearch.Exclusive = advancedSearch.Exclusive;
        this.AdvancedSearch.NonExclusive = advancedSearch.NonExclusive;

        this.AdvancedSearch.Scorekeepers = advancedSearch.Scorekeepers;
        this.AdvancedSearch.IndieOrange = advancedSearch.IndieOrange;
        this.AdvancedSearch.PleasantPictures = advancedSearch.PleasantPictures;
        this.AdvancedSearch.Classical = advancedSearch.Classical;
        this.AdvancedSearch.BigThree = advancedSearch.BigThree;
      }
    }
  }

  get UserDetails() {
    return Common.GetState().UserInterface.toJS().UserDetails || {};
  }

  handleClearAllItems(type) {
    Common.UserInterface.ClearAllSelectedItems(type);
    this.handleSearch();
  }

  handleClearSearch() {
    if (Common.TrackPlayer.IsPlaying) Common.TrackPlayer.Stop();
    this.NewAsOfDate.clear();
    Common.UserInterface.ClearAdvancedSearch();
    Common.Search.ClearSearchResults();
    let selectedMultiplePlaylistIds = localStorage.getItem("playlistIds");
    let selectedPlaylist = localStorage.getItem("selectedPlaylist");
    let showMultiplePlaylist = localStorage.getItem("ShowMultiplePlaylist");

    // 08/31/2020 - DO NOT CLEAR OUT AUDITIONED TRACKS
    const auditionedTracks = localStorage.getItem('audition-history')

    localStorage.clear();
    localStorage.setItem("audition-history", auditionedTracks)
    localStorage.setItem("selectedPlaylist", selectedPlaylist);
    localStorage.setItem("playlistIds", selectedMultiplePlaylistIds);
    localStorage.setItem("ShowMultiplePlaylist", showMultiplePlaylist);
  }

  handleContextMenuSelect(type, item, checked = true) {
    Common.UserInterface.SetAdvancedSearchSelectedContextMenuItem(type, item, checked);

    if (!checked) {
      let quickSearchText = Common.UserInterface.GetQuickSearchText();
      quickSearchText = quickSearchText.replace((item.Label || "").trim(), "").trim();
      Common.UserInterface.SetQuickSearchText(quickSearchText);
    }

    this.handleSearch();
  }

  handleFieldValueChecked(fieldType, value) {
    const getFieldNameByType = (fieldType) => {
      switch (fieldType) {
        case "ExcludeGenreFlag":
          return "Genre";
        case "ExcludeMoodFlag":
          return "Mood";
        case "ExcludeTempoFlag":
          return "Tempo";
        case "ExcludeEnergyFlag":
          return "Energy";
        case "ExcludeLooksLikeFlag":
          return "LooksLike";
        case "ExcludeSoundsLikeFlag":
          return "SoundsLike";
        case "ExcludeComposerFlag":
          return "Composer";
        case "ExcludeCollectionFlag":
          return "Collection";
        case "ExcludeAdTimingFlag":
          return "AdTiming";
        case "Exclusive":
          return "Exclusive";
        case "NonExclusive":
          return "NonExclusive";
        case "Scorekeepers":
          return "Scorekeepers";
        case "IndieOrange":
          return "IndieOrange";
        case "PleasantPictures":
          return "PleasantPictures";
        case "Classical":
          return "Classical";
        case "BigThree":
          return "BigThree";
      }
    };
            
    const fieldName = getFieldNameByType(fieldType);
    const hasEntries = (Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems(fieldName) || []).length > 0;
    Common.UserInterface.UpdateAdvancedSearchField(fieldType, value);

    if (!this.UserInterface.NewSearch && hasEntries) {
      this.handleSearch();
      return;
    } 
    
    if (["Exclusive", "NonExclusive", "Scorekeepers", "IndieOrange", "PleasantPictures", "Classical", "BigThree"].includes(fieldType)) {
      this.handleSearch(true);
      return
    }
  }

  handleFieldValueChanged(fieldType, fieldValue) {
    const value = fieldValue ? fieldValue : this[fieldType].value;
    Common.UserInterface.UpdateAdvancedSearchField(fieldType, value);
    this.handleSearch();
  }

  handleDurationFieldValueChanged(fieldType, fieldValue) {
    let value = 0;

    if (fieldValue.indexOf(":") >= 0) {
      value = Common.ConvertToSeconds(fieldValue).toString();
    } else {
      value = fieldValue ? fieldValue : this[fieldType].value;
    }

    Common.UserInterface.UpdateAdvancedSearchField(fieldType, value);
    this.handleSearch();
  }

  handleFieldEnter(fieldType, fieldValue) {
    let value = 0;

    if (fieldValue.indexOf(":") >= 0) {
      value = Common.ConvertToSeconds(fieldValue).toString();
    } else {
      value = fieldValue ? fieldValue : this[fieldType].value;
    }

    Common.UserInterface.UpdateAdvancedSearchField(fieldType, value);
    this.handleSearch();
  }

  handleCollectionFieldEnter(fieldValue) {
    const collectionList = this.AdvancedSearch.SelectedContextMenuItems["Collection"];
    const collection = Common.UserInterface.GetCollectionContextMenuItemByName(fieldValue);

    if (collection != null) {
      if (collectionList.findIndex((cl) => cl.Value.Id === collection.Value.Id) === -1) collectionList.push(collection);

      this.handleContextMenuSelect("Collection", collectionList, true);
      this.handleSearch();
    }
  }

  handleCollectionFieldChanged(value) {
    const collectionList = this.AdvancedSearch.SelectedContextMenuItems["Collection"];
    const collection = Common.UserInterface.GetCollectionContextMenuItemByName(value);

    Common.UserInterface.UpdateAdvancedSearchField("TrackPrefix", "");

    if (collection != null) {
      if (!collectionList.find((cl) => cl.Value.Id === collection.Value.Id)) {
        collectionList.push(collection);

        this.handleContextMenuSelect("Collection", collectionList, true);
        this.handleSearch();
      }
    }
  }

  handleIncludeType(item) {
    Common.UserInterface.UpdateAdvancedSearchField("IncludeType", item);
    this.handleSearch();
  }

  handleNewAsOfChecked(value) {
    Common.UserInterface.UpdateAdvancedSearchField("NewAsOfEnabled", value);
  }

  handleNewAsOfDateChanged(value) {
    console.trace('Test: ', value)
    Common.UserInterface.UpdateAdvancedSearchField("NewAsOfDate", value);

    if (value != null) this.handleSearch();
  }

  handleRemoveSelectedItem(type, index) {
    const _this = this;
    const item = this.AdvancedSearch.SelectedContextMenuItems[type][index];
    this.handleContextMenuSelect(type, item, false);
    this.handleSearch();
  }

  handleSearch(forceSearch = false) {
    if (Common.UserInterface.HasAdvancedSearchFiltersSelected() || forceSearch) {
      Common.Search.AdvancedSearch(this.UserInterface.AdvancedSearch, 0, Common.GetSearchSize(), true);
      localStorage.setItem("searchData", JSON.stringify(this.UserInterface.AdvancedSearch));
    } else {
      this.handleClearSearch();
    }
  }
 
  get isLoggedIn() {
    const userDetails = Common.GetState().UserInterface.toJS().UserDetails;
    return (userDetails != null && userDetails.UserId != null);
  }

  saveAdvancedSearch() {
    const advancedSearch = {
      AdvancedSearchIncludeType: this.AdvancedSearch.IncludeType,
      AdvancedSearchInclude: this.AdvancedSearch.Include,
      AdvancedSearchExclude: this.AdvancedSearch.Exclude,
      AdvancedSearchExcludeGenreFlag: this.AdvancedSearch.ExcludeGenreFlag,
      ContextMenuItemsGenre: this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Genre,
      AdvancedSearchExcludeGenreFlag: this.AdvancedSearch.ExcludeMoodFlag,
      ContextMenuItemsMood: this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Mood,
      AdvancedSearchExcludeTempoFlag: this.AdvancedSearch.ExcludeTempoFlag,
      ContextMenuItemsTempo: this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Tempo,
      AdvancedSearchExcludeEnergyFlag: this.AdvancedSearch.ExcludeEnergyFlag,
      ContextMenuItemsEnergy: this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Energy,
      AdvancedSearchExcludeLooksLikeFlag: this.AdvancedSearch.ExcludeLooksLikeFlag,
      ContextMenuItemsScenes: this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Scenes,
      AdvancedSearchExcludeSoundsLikeFlag: this.AdvancedSearch.ExcludeSoundsLikeFlag,
      ContextMenuItemsSoundsLike: this.UserInterface.AdvancedSearch.SelectedContextMenuItems.SoundsLike,
      AdvancedSearchExcludeCollectionFlag: this.AdvancedSearch.ExcludeCollectionFlag,
      ContextMenuItemsCollection: this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Collection,
      TrackPrefix: this.AdvancedSearch.TrackPrefix,
      AdvancedSearchExcludeComposerFlag: this.AdvancedSearch.ExcludeComposerFlag,
      ContextMenuItemsComposer: this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Composer,
      AdvancedSearchExcludeAdTimingFlag: this.AdvancedSearch.ExcludeAdTimingFlag,
      ContextMenuItemsAdTiming: this.UserInterface.AdvancedSearch.SelectedContextMenuItems.AdTiming,
      DurationMin: this.AdvancedSearch.DurationMin,
      DurationMax: this.AdvancedSearch.DurationMax,
      // //DateDropDown
      Title: this.AdvancedSearch.Title,
      Exclusive: this.AdvancedSearch.Exclusive,
      NonExclusive: this.AdvancedSearch.NonExclusive,

      Scorekeepers: this.AdvancedSearch.Scorekeepers,
      IndieOrange: this.AdvancedSearch.IndieOrange,
      PleasantPictures: this.AdvancedSearch.PleasantPictures,
      Classical: this.AdvancedSearch.Classical,
      BigThree: this.AdvancedSearch.BigThree,
    };

    localStorage.setItem("advancedSearch", JSON.stringify(advancedSearch));
  }

  render() {
    this.saveAdvancedSearch();
    const includeTypeOptions = [
      { Id: "All", Text: "All" },
      { Id: "Any", Text: "Any" },
    ];

    const selectedItems = [];
    selectedItems["AdTiming"] = getSelectedItemsElement(
      this.UserInterface.AdvancedSearch.SelectedContextMenuItems.AdTiming,
      this.handleRemoveSelectedItem.bind(this, "AdTiming"),
      this.handleClearAllItems.bind(this, "AdTiming")
    );
    selectedItems["Genre"] = getSelectedItemsElement(
      this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Genre,
      this.handleRemoveSelectedItem.bind(this, "Genre"),
      this.handleClearAllItems.bind(this, "Genre")
    );
    selectedItems["Mood"] = getSelectedItemsElement(
      this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Mood,
      this.handleRemoveSelectedItem.bind(this, "Mood"),
      this.handleClearAllItems.bind(this, "Mood")
    );
    selectedItems["Tempo"] = getSelectedItemsElement(
      this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Tempo,
      this.handleRemoveSelectedItem.bind(this, "Tempo"),
      this.handleClearAllItems.bind(this, "Tempo")
    );
    selectedItems["Energy"] = getSelectedItemsElement(
      this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Energy,
      this.handleRemoveSelectedItem.bind(this, "Energy"),
      this.handleClearAllItems.bind(this, "Energy")
    );
    selectedItems["SoundsLike"] = getSelectedItemsElement(
      this.UserInterface.AdvancedSearch.SelectedContextMenuItems.SoundsLike,
      this.handleRemoveSelectedItem.bind(this, "SoundsLike"),
      this.handleClearAllItems.bind(this, "SoundsLike")
    );
    selectedItems["LooksLike"] = getSelectedItemsElement(
      this.UserInterface.AdvancedSearch.SelectedContextMenuItems.LooksLike,
      this.handleRemoveSelectedItem.bind(this, "LooksLike"),
      this.handleClearAllItems.bind(this, "LooksLike")
    );
    selectedItems["Collection"] = getSelectedItemsElement(
      this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Collection,
      this.handleRemoveSelectedItem.bind(this, "Collection"),
      this.handleClearAllItems.bind(this, "Collection")
    );
    selectedItems["Composer"] = getSelectedItemsElement(
      this.UserInterface.AdvancedSearch.SelectedContextMenuItems.Composer,
      this.handleRemoveSelectedItem.bind(this, "Composer"),
      this.handleClearAllItems.bind(this, "Composer")
    );

    const isExclusiveTracks =
      window.Environment === "home" && this.UserDetails.IsAdmin ? (
        <div className="section-row track-type">
          <Flex className="section-title">Exclusive / Non-Exclusive</Flex>
          <Flex className="exclude-check">
            <Checkbox label="EXCLUSIVE TRACKS" value={this.AdvancedSearch.Exclusive} onCheck={this.handleFieldValueChecked.bind(this, "Exclusive")} />
          </Flex>
          <Flex className="exclude-check">
            <Checkbox label="NON-EXCLUSIVE TRACKS" value={this.AdvancedSearch.NonExclusive} onCheck={this.handleFieldValueChecked.bind(this, "NonExclusive")} />
          </Flex>
        </div>
      ) : null;

    const getSoundsLikeContextMenu = () => {
      let contextMenuData = this.UserInterface.ContextData.SoundsLike;

      if (Common.IsAudiofficina() && !this.isLoggedIn) {
        contextMenuData = contextMenuData.filter(x => x.Label !== "Bands & Artists" && x.Label !== "Composers");
      }

      return contextMenuData
    }

    const catalogSection = () => {
      if (window.Environment === "home" && this.UserDetails.IsAdmin) {
        return (
          <React.Fragment>
            <Flex className="section-title">Music Catalogs</Flex>
            <div className="section-row track-type">
              <Flex className="exclude-check">
                <Checkbox label="SCOREKEEPERS MUSIC TRACKS" value={this.AdvancedSearch.Scorekeepers} onCheck={this.handleFieldValueChecked.bind(this, "Scorekeepers")} />
              </Flex>
              <Flex className="exclude-check">
                <Checkbox label="INDIE ORANGE TRACKS" value={this.AdvancedSearch.IndieOrange} onCheck={this.handleFieldValueChecked.bind(this, "IndieOrange")} />
              </Flex>              
              <Flex className="exclude-check">
                <Checkbox label="PLEASANT PICTURES TRACKS" value={this.AdvancedSearch.PleasantPictures} onCheck={this.handleFieldValueChecked.bind(this, "PleasantPictures")} />
              </Flex>
              <Flex className="exclude-check">
                <Checkbox label="CLASSICAL TRACKS" value={this.AdvancedSearch.Classical} onCheck={this.handleFieldValueChecked.bind(this, "Classical")} />
              </Flex>
              <Flex className="exclude-check">
                <Checkbox label="BIG 3" value={this.AdvancedSearch.BigThree} onCheck={this.handleFieldValueChecked.bind(this, "BigThree")} />
              </Flex>
            </div>
          </React.Fragment>
        )
      }
    }    

    return (
      <Flex className="advanced-search show-y-scrollbar">
        <ScrollWindow>
          <div className="section">
            <Flex className="section-title">Instrumentation / Description</Flex>
            <Flex row className="section-row">
              <Flex width="70px" position="left">
                <DropDown width="60px" items={includeTypeOptions} value={this.AdvancedSearch.IncludeType} onSelect={this.handleIncludeType.bind(this)} textPropName={"Text"} />
              </Flex>
              <Flex>
                <TextField
                  ref={(i) => {
                    this.Include = i;
                  }}
                  value={this.AdvancedSearch.Include}
                  onTextChanged={this.handleFieldValueChanged.bind(this, "Include")}
                  onEnter={this.handleFieldEnter.bind(this, "Include")}
                />
              </Flex>
            </Flex>
            <Flex row className="section-row">
              <Flex width="70px" position="left" style={{textTransform: "uppercase"}}>
                {getTranslation("Exclude")}
              </Flex>
              <Flex>
                <TextField
                  ref={(i) => {
                    this.Exclude = i;
                  }}
                  value={this.AdvancedSearch.Exclude}
                  onTextChanged={this.handleFieldValueChanged.bind(this, "Exclude")}
                  onEnter={this.handleFieldEnter.bind(this, "Exclude")}
                />
              </Flex>
            </Flex>
          </div>
          <div className="section">
            <Flex className="section-title" />
            <Flex row className="section-row" position="left">
              <Flex className="exclude-check">
                <Checkbox tooltip="GENRE(S)" label={getTranslation("Exclude", true)} value={this.AdvancedSearch.ExcludeGenreFlag} onCheck={this.handleFieldValueChecked.bind(this, "ExcludeGenreFlag")} />
              </Flex>
              <Flex className="drop-down-button" width="190px">
                <ContextMenuList
                  className="test"
                  placeholder={getTranslation("Genre")}
                  contextMenu={this.UserInterface.ContextData.Genre}
                  selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems("Genre")}
                  multiSelect={true}
                  onSelect={this.handleContextMenuSelect.bind(this, "Genre")}
                />
              </Flex>
            </Flex>
            {selectedItems["Genre"]}

            <Flex row className="section-row" position="left">
              <Flex className="exclude-check">
                <Checkbox tooltip="MOOD(S)" label={getTranslation("Exclude", true)} value={this.AdvancedSearch.ExcludeMoodFlag} onCheck={this.handleFieldValueChecked.bind(this, "ExcludeMoodFlag")} />
              </Flex>
              <Flex className="drop-down-button" width="190px">
                <ContextMenuList
                  placeholder={getTranslation("Mood")}
                  contextMenu={this.UserInterface.ContextData.Mood}
                  selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems("Mood")}
                  multiSelect={true}
                  onSelect={this.handleContextMenuSelect.bind(this, "Mood")}
                />
              </Flex>
            </Flex>
            {selectedItems["Mood"]}
            <Flex row className="section-row" position="left">
              <Flex className="exclude-check">
                <Checkbox tooltip="TEMPO(S)" label={getTranslation("Exclude", true)} value={this.AdvancedSearch.ExcludeTempoFlag} onCheck={this.handleFieldValueChecked.bind(this, "ExcludeTempoFlag")} />
              </Flex>
              <Flex className="drop-down-button" width="190px">
                <ContextMenuList
                  placeholder={getTranslation("Tempo")}
                  contextMenu={this.UserInterface.ContextData.Tempo}
                  selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems("Tempo")}
                  multiSelect={true}
                  onSelect={this.handleContextMenuSelect.bind(this, "Tempo")}
                />
              </Flex>
            </Flex>
            {selectedItems["Tempo"]}
            <Flex row className="section-row" position="left">
              <Flex className="exclude-check">
                <Checkbox tooltip="ENERGY(S)" label={getTranslation("Exclude", true)} value={this.AdvancedSearch.ExcludeEnergyFlag} onCheck={this.handleFieldValueChecked.bind(this, "ExcludeEnergyFlag")} />
              </Flex>
              <Flex className="drop-down-button" width="190px">
                <ContextMenuList
                  placeholder={getTranslation("Energy")}
                  contextMenu={this.UserInterface.ContextData.Energy}
                  selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems("Energy")}
                  multiSelect={true}
                  onSelect={this.handleContextMenuSelect.bind(this, "Energy")}
                />
              </Flex>
            </Flex>
            {selectedItems["Energy"]}
            <Flex row className="section-row" position="left">
              <Flex className="exclude-check">
                <Checkbox tooltip={getTranslation('scenes', true)} label={getTranslation("Exclude", true)} value={this.AdvancedSearch.ExcludeLooksLikeFlag} onCheck={this.handleFieldValueChecked.bind(this, "ExcludeLooksLikeFlag")} />
              </Flex>
              <Flex className="drop-down-button" width="190px">
                <ContextMenuList
                  placeholder={getTranslation("scenes")}
                  contextMenu={this.UserInterface.ContextData.LooksLike}
                  selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems("LooksLike")}
                  multiSelect={true}
                  onSelect={this.handleContextMenuSelect.bind(this, "LooksLike")}
                />
              </Flex>
            </Flex>
            {selectedItems["LooksLike"]}
            <Flex row className="section-row" position="left">
              <Flex className="exclude-check">
                <Checkbox tooltip={getTranslation('sounds_like', true)} label={getTranslation("Exclude", true)} value={this.AdvancedSearch.ExcludeSoundsLikeFlag} onCheck={this.handleFieldValueChecked.bind(this, "ExcludeSoundsLikeFlag")} />
              </Flex>
              <Flex className="drop-down-button" width="190px">
                <ContextMenuList
                  placeholder={getTranslation("Sounds Like")}
                  contextMenu={getSoundsLikeContextMenu()}
                  selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems("SoundsLike")}
                  multiSelect={true}
                  onSelect={this.handleContextMenuSelect.bind(this, "SoundsLike")}
                />
              </Flex>
            </Flex>
            {selectedItems["SoundsLike"]}
            <Flex row className="section-row" position="left">
              <Flex className="exclude-check">
                <Checkbox tooltip="COLLECTION(S)" label={getTranslation("Exclude", true)} value={this.AdvancedSearch.ExcludeCollectionFlag} onCheck={this.handleFieldValueChecked.bind(this, "ExcludeCollectionFlag")} />
              </Flex>
              <Flex className="drop-down-button" width="190px">
                <ContextMenuList
                  placeholder={getTranslation("Collection")}
                  contextMenu={this.UserInterface.ContextData.Collection}
                  selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems("Collection")}
                  multiSelect={true}
                  onSelect={this.handleContextMenuSelect.bind(this, "Collection")}
                />
              </Flex>
            </Flex>
            {selectedItems["Collection"]}
            <Flex row className="section-row" position="left">
              <Flex className="exclude-check"></Flex>
              <Flex className="drop-down-button" width="190px">
                <TextField
                  placeholder={getTranslation("Enter Track Prefix")}
                  ref={(i) => {
                    this.TrackPrefix = i;
                  }}
                  value={this.AdvancedSearch.TrackPrefix}
                  onTextChanged={this.handleCollectionFieldChanged.bind(this)}
                  onEnter={this.handleCollectionFieldEnter.bind(this)}
                />
              </Flex>
            </Flex>
            <Flex row className="section-row" position="left">
              <Flex className="exclude-check">
                <Checkbox tooltip="COMPOSER(S)" label={getTranslation("Exclude", true)} value={this.AdvancedSearch.ExcludeComposerFlag} onCheck={this.handleFieldValueChecked.bind(this, "ExcludeComposerFlag")} />
              </Flex>
              {/* <Flex className='drop-down-button' width='190px'><ContextMenuList placeholder='Composer' width={750} height={500} contextMenu={this.UserInterface.ContextData.Composer} selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems('Composer')} multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'Composer')} /></Flex> */}
              <Flex className="drop-down-button" width="190px">
                <VirtualContextMenuList
                  placeholder={getTranslation("Composer")}
                  width={750}
                  height={500}
                  contextMenu={this.UserInterface.ContextData.Composer}
                  selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems("Composer")}
                  multiSelect={true}
                  onSelect={this.handleContextMenuSelect.bind(this, "Composer")}
                />
              </Flex>
            </Flex>
            {selectedItems["Composer"]}
            <Flex row className="section-row" position="left">
              <Flex className="exclude-check">
                <Checkbox tooltip="AD TIMING" label={getTranslation("Exclude", true)} value={this.AdvancedSearch.ExcludeAdTimingFlag} onCheck={this.handleFieldValueChecked.bind(this, "ExcludeAdTimingFlag")} />
              </Flex>
              <Flex className="drop-down-button" width="190px">
                <ContextMenuList
                  placeholder={getTranslation("Ad Timing")}
                  contextMenu={this.UserInterface.ContextData.AdTiming}
                  selectedItems={Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems("AdTiming")}
                  multiSelect={true}
                  onSelect={this.handleContextMenuSelect.bind(this, "AdTiming")}
                />
              </Flex>
            </Flex>
            {selectedItems["AdTiming"]}
            <div className="section-row">
              <Flex className="section-title">{getTranslation("Duration Range")}</Flex>
              <Flex row className="section-row" position="left">
                <Flex width="70px">
                  <TextField
                    placeholder="Min"
                    height={30}
                    fontSize={12}
                    ref={(i) => {
                      this.DurationMin = i;
                    }}
                    value={getFormattedDuration(this.AdvancedSearch.DurationMin)}
                    showClearIcon={false}
                    onTextChanged={this.handleDurationFieldValueChanged.bind(this, "DurationMin")}
                    onEnter={this.handleFieldEnter.bind(this, "DurationMin")}
                  />
                </Flex>
                <Flex width="10px">
                  <FontIcon name="caret-right" color="#888888" />
                </Flex>
                <Flex width="70px">
                  <TextField
                    placeholder="Max"
                    height={30}
                    fontSize={12}
                    ref={(i) => {
                      this.DurationMax = i;
                    }}
                    value={getFormattedDuration(this.AdvancedSearch.DurationMax)}
                    showClearIcon={false}
                    onTextChanged={this.handleDurationFieldValueChanged.bind(this, "DurationMax")}
                    onEnter={this.handleFieldEnter.bind(this, "DurationMax")}
                  />
                </Flex>
              </Flex>
            </div>
            <div className="section-row">
              <Flex className="section-title">New As Of</Flex>
              <Flex row className="section-row" position="left">
                <DateDropDown
                  ref={(i) => {
                    this.NewAsOfDate = i;
                  }}
                  minDate="01/01/2000"
                  onChange={this.handleNewAsOfDateChanged.bind(this)}
                />
              </Flex>
            </div>
            <div className="section-row">
              <Flex className="section-title">Title / Id</Flex>
              <Flex row className="section-row">
                <Flex>
                  <TextField
                    ref={(i) => {
                      this.Title = i;
                    }}
                    value={this.AdvancedSearch.Title}
                    onTextChanged={this.handleFieldValueChanged.bind(this, "Title")}
                    onEnter={this.handleFieldEnter.bind(this, "Title")}
                  />
                </Flex>
              </Flex>
            </div>
            {isExclusiveTracks}
            {catalogSection()}
          </div>
        </ScrollWindow>
        <Flex height="60px">
          <Flex row className="footer">
            <Flex position="left">
              <Button tooltip="SEARCH-FILTER-BUTTON" type="success" width="130px" onClick={this.handleSearch.bind(this)}>
                Search
              </Button>
            </Flex>
            <Flex position="right">
              <Button tooltip="RESET-FILTER-BUTTON" type="secondary" width="130px" onClick={this.handleClearSearch.bind(this)}>
                Reset
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

class SelectedItem extends React.Component {
  constructor() {
    super(...arguments);
  }

  render() {
    const { index = null, item = null, label = null, onRemoveSelectedItem = () => {} } = this.props;

    return (
      <Flex row className="item" onClick={onRemoveSelectedItem.bind(this, index)}>
        <Flex width="90%" position="left" className="ellipsis">
          {label}
        </Flex>
        <Flex width="10%">
          <FontIconButton name="close-thin" size={12} />
        </Flex>
      </Flex>
    );
  }
}

class SelectedItemsClearAll extends React.Component {
  constructor() {
    super(...arguments);
  }

  render() {
    const { onClearAllItems = () => {} } = this.props;

    return (
      <Flex row className="item-clear-all" onClick={onClearAllItems}>
        <Flex width="90%" position="right" className="ellipsis">
          Clear All
        </Flex>
        <Flex width="10%">
          <FontIconButton name="close-thin" size={12} />
        </Flex>
      </Flex>
    );
  }
}

const getSelectedItemsElement = (selectedItems, onRemoveSelectedItem, onClearAllItems) => {
  if (selectedItems.length == 0) return "";

  const items = selectedItems.map((m, i) => <SelectedItem key={i} index={i} label={m.Label} item={m} onRemoveSelectedItem={onRemoveSelectedItem} />);
  const clearAllButton = selectedItems.length > 1 ? <SelectedItemsClearAll onClearAllItems={onClearAllItems} /> : "";

  return (
    <Flex className="selected-items" width="100%">
      <div style={{ width: "100%" }}>
        {clearAllButton}
        {items}
      </div>
    </Flex>
  );
};

export default connect((state) => {
  return {
    UserInterface: state.UserInterface,
  };
})(AdvancedSearch);
