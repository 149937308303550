import React from 'react';
import { DropDown, FontIcon, TextField, Typewriter } from 'components/ui';
import { CommunicationNoSim } from 'material-ui/svg-icons';

const productionTypes = [
    { id: 'TV', text: 'TV' },
    { id: 'Film', text: 'Film' },
    { id: 'Radio', text: 'Radio' },
    { id: 'Corporate', text: 'Corporate' },
    { id: 'Advertising', text: 'Advertising' },
    { id: 'Educational', text: 'Educational' },
    { id: 'Other', text: 'Other (specify)' },
];

const jobTypes = [
    { id: 'Producer', text: 'Producer' },
    { id: 'Editor', text: 'Editor' },
    { id: 'Post Production Supervisor', text: 'Post Production Supervisor' },
    { id: 'Other', text: 'Other (specify)' },
]

class SurveyPage2 extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            productionType: null,
            productionTypeValue: "",
            jobType: null,
            jobTypeValue: "",
            struggleValue: "",            
            currentProvider: "",
        }
    }

    componentDidMount = () => {
        const props = this.props.state;
        this.setState({ 
            ...this.state, 
            ...this.props.state,
        })
    }

    getDialogResponse = () => {
        return this.state;
    }

    handleCurrentProvider = (value) => {
        this.setState({ currentProvider: value });
    }

    handleProductionType = (value) => {
        this.setState({ productionType: value });

        if (value.id === 'Other') 
            setTimeout(() => this.productionTypeValue.select(), 10);
    }

    handleProductionTypeValue = (value) => {
        this.setState({ productionTypeValue: value });
    }

    handleJobType = (value) => {
        this.setState({ jobType: value });

        if (value.id === 'Other') 
            setTimeout(() => this.jobTypeValue.select(), 10);
    }

    handleJobTypeValue = (value) => {
        this.setState({ jobTypeValue: value });
    }
    
    handleStruggleValue = (value) => {
        this.setState({ struggleValue: value });
    }

    validatePage = () => {
        if (this.state.productionType === undefined | null) {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please provide an answer for which type of production best describes your work.'});
            return false;
        } 
        else if (this.state.productionType && this.state.productionType.id === 'Other' && this.state.productionTypeValue.trim() === '') {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please specify which OTHER type of production best describes your work.'});            
            return false;
        }

        if (this.state.jobType === undefined | null) {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please provide an answer for which best describes your job.'})
            return false;
        }
        else if (this.state.jobType && this.state.jobType.id === 'Other' && this.state.jobTypeValue.trim() === '') {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please specify which OTHER value best describes your job.'})
            return false;
        }

        if (this.state.struggleValue.trim() === '') {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please specify what your biggest struggle is when it comes to music.'})
            return false;
        }

        if (this.state.currentProvider.trim() === '') {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please specify who your current music provider is.'})
            return false;
        }

        return true;
    }

    render() {
        
        const productionTypeField = (this.state.productionType || {}).id == 'Other'
            ? (<div className='field-value'><TextField ref={(i) => this.productionTypeValue = i} value={this.state.productionTypeValue} onTextChanged={this.handleProductionTypeValue} width={750} height={35} /></div>)
            : ''

        const jobTypeField = (this.state.jobType || {}).id == 'Other'
            ? (<div className='field-value'><TextField ref={(i) => this.jobTypeValue = i} value={this.state.jobTypeValue } onTextChanged={this.handleJobTypeValue} width={750} height={35} /></div>)
            : ''

        return (
            <div className='survey-page-2'>                                
                <div className='survey-page-header'>
                    <FontIcon name='inspector' />
                    <Typewriter message={ 'To better understand your production music needs, please answer the following questions:' } />
                </div>
                <div className='survey-page-content' width={'100%'}>
                    <div className='field-section'>
                        <div className='field-label'>
                            Which type of production best describes your work?
                        </div>
                        <div className='field-value'>
                            <DropDown items={productionTypes} value={this.state.productionType} onSelect={this.handleProductionType} width={750} style={{fontSize: 18}} />
                        </div>
                        { productionTypeField }
                    </div>

                    <div className='field-section'>
                        <div className='field-label'>
                            Which best describes your job? 
                        </div>
                        <div className='field-value'>
                            <DropDown items={jobTypes} value={this.state.jobType} onSelect={this.handleJobType} width={750} style={{fontSize: 18}} />
                        </div>
                        { jobTypeField }
                    </div>    

                    <div className='field-section'>
                        <div className='field-label'>
                            When it comes to music, what is your biggest struggle?
                        </div>
                        <div className='field-value'>
                            <TextField ref={(i) => this.struggleValue = i} value={this.state.struggleValue } onTextChanged={this.handleStruggleValue} width={750} height={35} />
                        </div>                        
                    </div> 

                    <div className='field-section'>
                        <div className='field-label'>
                            Who is your current music provider?
                        </div>
                        <div className='field-value'>
                            <TextField ref={(i) => this.currentProvider = i} value={this.state.currentProvider } onTextChanged={this.handleCurrentProvider} width={400} height={35} />
                        </div>                        
                    </div>                                    
                </div>
            </div>
        )
    }
}

export default SurveyPage2;