import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './scroll-window.scss';

import {Flex, FontIcon} from 'components/ui';
import {Scrollbars} from 'react-custom-scrollbars';

export class ScrollWindow extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
    className: PropTypes.string,
    autoHide: PropTypes.bool
  }

  constructor() {
    super(...arguments);
    this.state = {
      showTopIndicator: false,
      showBottomIndicator: false
    }
  }

  onComponentLoaded = () => {
    if (!this.scrollBars) return;

    const scrollbarValues = this.scrollBars.getValues();
    if (this.props.showscrollIndicator) {   
      if (scrollbarValues.scrollHeight > scrollbarValues.clientHeight) {
        this.state.showBottomIndicator = true;
        this.forceUpdate();
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.children !== nextProps.children) {
      setTimeout(() => {
        this.onComponentLoaded();
      }, 1);
    }

    return (
      this.props.width !== nextProps.width ||
      this.props.height !== nextProps.height ||
      this.props.children !== nextProps.children ||
      this.props.style !== nextProps.style ||
      this.props.className !== nextProps.className ||
      this.props.autoHide !== nextProps.autoHide
    )
  }

  scrollTop() {
    this.scrollBars.scrollTop();
  }

  handleScrollFrame(values) {
    const {
      onScrollFrame = () => {}
    } = this.props;

    this.state.showTopIndicator = values.scrollTop > 0;
    this.state.showBottomIndicator = values.scrollTop < (values.scrollHeight - values.clientHeight - 2);
    this.forceUpdate();

    onScrollFrame(values);
  }

  render() {
    const {
      width = null,
      height = null,
      children = null,
      style = {},
      className = null,
      autoHide = false,
      showscrollIndicator = false,
      onScroll = () => {
      },
      onScrollFrame = () => {
      },
      onScrollStart = () => {
      },
      onScrollStop = () => {
      },
      onUpdate = () => {
      },
    } = this.props;

    const scrollWindowClass = classNames({
      'scroll-window': true
    }, className);

    const scrollWindowStyle = Object.assign({}, style);

    const scrollIndicatorTop = showscrollIndicator && this.state.showTopIndicator
      ? (<Flex className='scroll-indicator-top' height={15}><FontIcon name='chevron-up'/></Flex>)
      : ''

    const scrollIndicatorBottom = showscrollIndicator && this.state.showBottomIndicator
      ? (<Flex className='scroll-indicator-bottom' height={15}><FontIcon name='chevron-down'/></Flex>)
      : ''

    return (
      <Flex col>
        {scrollIndicatorTop}
        <Scrollbars className={scrollWindowClass} width={width} height={height}
                    autoHide={autoHide}
                    onScroll={onScroll.bind(this)}
                    onScrollFrame={this.handleScrollFrame.bind(this)}
                    onScrollStart={onScrollStart.bind(this)}
                    onScrollStop={onScrollStop.bind(this)}
                    onUpdate={onUpdate.bind(this)}
                    ref={(scrollBars) => {
                      this.scrollBars = scrollBars
                    }}>
          <div>{children}</div>
        </Scrollbars>
        {scrollIndicatorBottom}
      </Flex>
    )
  }
} 