import { getCookie } from 'utility/utility'
import { fetchGet, fetchPost } from "utility/fetchHelpers";
import {
  clearAdvancedSearch,
  clearAllSelectedItems,
  clearAllPlaylistSelectedItems,
  clearAuditionHistory,
  clearBasicSearch,
  clearPlaylistFilters,
  clearQuickSearch,
  clearToggledSearchTracks,
  setAdvancedSearch,
  setAdvancedSearchFieldValue,
  setBasicSearchFieldValue,
  setPlaylistFiltersFieldValue,
  setContextData,
  setQuickSearchText,
  setAdvancedSelectedContextMenuItem,
  setApplicationMessages,
  setAuditionedStatus,
  setBasicSelectedContextMenuItem,
  setPlaylistFiltersSelectedContextMenuItem,
  setIsLoading,
  setSearchFilter,
  setSearchSortType,
  setSelectedPlaylistTab,
  setSelectedPlaylistTrack,
  setSelectedSearchFilters,
  setSelectedSearchTab,
  setSharedPlaylistCount,
  setShowSuggestionsDropDown,
  toggleBasicSearchPanel,
  togglePlaylistExtension,
  togglePlaylistFilterPanel,
  togglePlaylistTrack,
  toggleSearchResultsTrack,
  toggleMultiplePlaylist,
  updateQuickSearchPlaceholder,
} from "store/userInterface/actions";

import { showDownloadDialog, closeDownloadDialog } from "components/ui/download-format/download-format";
import { GetCartAction } from '../../../store/cart/actions';
import { setUserSharedPlaylists } from '../../../store/userInterface/actions';

let store = null;

class UserInterface {
  constructor(_store) {
    store = _store;
  }

  AddQuickSearchFilter(filter, newSearch = true) {
    const state = store.getState();
    let filters = state.UserInterface.toJS().SelectedSearchFilters;

    if (newSearch) {
      filters = [filter];
    } else {
      const filterIndex = filters.findIndex((f) => f.FilterType === filter.FilterType && f.Id === filter.Id);
      if (filterIndex === -1) {
        filters.push(filter);
      }
    }

    // TODO: SetQuickSearchText
    store.dispatch(setSelectedSearchFilters(filters));
    Common.Search.QuickSearch(null, filters, 0, Common.GetSearchSize(), true);
  }

  AddSearchFilter(filter, newSearch = true, resetFilters = false) {
    const state = store.getState();
    const advancedSearch = state.UserInterface.toJS().AdvancedSearch;

    store.dispatch(setSearchFilter(filter, resetFilters));
    Common.Search.AdvancedSearch(advancedSearch, 0, Common.GetSearchSize(), true);
  }

  CheckUsernameIsAvailable(username) {
    return new Promise((resolve, reject) => {
      fetchGet(`/api/account/verify/username/${username}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  }

  ClearAdvancedSearch(clearQuickSearchText) {
    if (Common.TrackPlayer.AudioSource === "search") Common.TrackPlayer.Stop();

    Common.Search.ClearSearchResults();
    store.dispatch(clearAdvancedSearch(clearQuickSearchText));
    store.dispatch(updateQuickSearchPlaceholder());
  }

  ClearAllSelectedItems(type) {
    store.dispatch(clearAllSelectedItems(type));
  }

  ClearAllPlaylistSelectedItems(type) {
    store.dispatch(clearAllPlaylistSelectedItems(type));
  }

  ClearAuditionHistory() {
    store.dispatch(clearAuditionHistory());
  }

  ClearBasicSearch() {
    store.dispatch(clearBasicSearch());
  }

  ClearPlaylistFilters() {
    store.dispatch(clearPlaylistFilters());
    this.ClearPreviousTrackDuration();
  }

  ClearQuickSearch() {
    store.dispatch(clearQuickSearch());
  }

  ClearSearchFilters() {
    Common.Search.ClearSearchResults();
    store.dispatch(setSelectedSearchFilters([]));
  }

  ClearToggledSearchTracks() {
    store.dispatch(clearToggledSearchTracks());
  }

  async DownloadTrack(track, audioFormat = "aif") {
    const data = {
      Collection: track.Collection.Name,
      Title: track.Title,
      AudioFormat: audioFormat,
    };

    try {
      // make a request to server to see if user is authenticated and track exists on S3
      const json = await fetchPost("/api/track/download", data);
      if (json.success) {
        // all good, call another method from server with our track that will stream it
        // Do not use json.url, as it will make browser play the song, it must be streamed from server as an attachment
        const { collection, title, audioFormat } = json;
        const link = document.createElement("a");
        link.href = `/api/track/get/${title}/${collection}/${audioFormat}`;
        link.download = title + "." + audioFormat;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const postData = {
          TrackId: track.Id,
          PlayedCount: 0,
          DownloadCount: 1,
        };
        Common.UserInterface.UpdateTrackStatistics(postData);
      } else {
        // not authenticated
        if (json.errorMessage === "Unauthorized") Common.Dialogs.ShowDialog("LoginDialog", true);
        else throw json.errorMessage;
      }
    } catch (e) {
      console.error("ERROR: ", err);
    }
  }

  CreateTrialUser(surveyResults) {
    return new Promise((resolve, reject) => {
      fetchPost(`/api/account/trial/submit`, surveyResults)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  }

  GetContextMenuData(contextMenuName) {
    return store.getState().UserInterface.toJS().ContextData[contextMenuName];
  }

  GetNavigationMenu() {
    // TODO: GetNavigationMenu
  }

  GetQuickSearchText() {
    return store.getState().UserInterface.toJS().AdvancedSearch.SearchText;
  }

  GetSearchSortType() {
    return store.getState().UserInterface.toJS().SearchSortType;
  }

  GetAdvancedSearchSelectedContextMenuItems(contextMenuType) {
    const advancedSearch = store.getState().UserInterface.toJS().AdvancedSearch.SelectedContextMenuItems;
    return advancedSearch[contextMenuType];
  }

  GetBasicSearchFieldValue(fieldName) {
    return store.getState().UserInterface.toJS().BasicSearch[fieldName];
  }

  GetBasicSearchSelectedContextMenuItems(contextMenuType) {
    const basicSearch = store.getState().UserInterface.toJS().BasicSearch;
    return basicSearch[contextMenuType];
  }

  GetCollectionContextMenuItemByName(collectionName) {
    const data = this.GetContextMenuData("Collection");

    try {
      data.forEach((menu) => {
        const collection = menu.SubMenu.find((sb) => sb.Label.toLowerCase() === collectionName.toLowerCase());

        if (collection != null) throw collection;
      });
    } catch (e) {
      if (e.Label != null) return e;

      throw e;
    }
  }

  GetCurrentSelectedContextMenuItems(fieldType) {
    return store.getState().UserInterface.toJS().AdvancedSearch.SelectedContextMenuItems[fieldType];
  }

  GetSource() {
    return "Web";
  }

  GetDownloadIconName() {
    return "download-circle";
  }

  GetPlaylistFiltersFieldValue(fieldName) {
    return store.getState().UserInterface.toJS().PlaylistFilters[fieldName];
  }

  GetPlaylistFiltersSelectedContextMenuItems(contextMenuType) {
    const playlistFilters = store.getState().UserInterface.toJS().PlaylistFilters;
    return playlistFilters[contextMenuType];
  }

  GetPreferences() {
    return {
      Source: "Web",
    };
  }

  GetRandomNumber(maxNumber) {
    return Math.floor(Math.random() * Math.floor(maxNumber));
  }

  GetRandomMessage(messageType, lang = "en") {
    const countryCode = getCookie("countryCode") || 'US';
    const messages = store
      .getState()
      .UserInterface.toJS()
      .Messages.filter((v) => v.MessageType.toLowerCase() === messageType.toLowerCase());

    if (messages.length > 0) {
      const index = this.GetRandomNumber(messages.length);
      const msg = messages[index];
      let randomMessage = '';

      // // --- temporary block --- Temporary until we can fix the search issue for non-english 
      // return msg.English; 
      // // --- end temporary block ---      

      switch (countryCode.toLowerCase()) {
        case "it": 
            randomMessage = msg.Italian;
            break;
        case "es": 
            randomMessage = msg.Spanish;
            break;
        default:
            randomMessage = msg.English;
            break;
      }

      return randomMessage || msg.MessageText;
    }

    return '';
  }

  GetSelectedPlaylistTrack() {
    return store.getState().UserInterface.toJS().SelectedPlaylistTrack;
  }

  GetTrialPeriod(code) {
    return new Promise((resolve, reject) => {
      fetchGet(`/api/account/trial/period/${code}`)
        .then((json) => resolve(json))
        .catch((err) => reject(err));
    });
  }

  GetUserSetting(name) {
    return new Promise((resolve, reject) => {
      fetchGet(`/api/account/setting/${name}`)
        .then((json) => {
          if (json.Success) {
            resolve(json.Value);
          } else reject();
        })
        .catch((err) => reject(err));
    });
  }

  GetShowMultiplePlaylist() {
    return store.getState().UserInterface.toJS().ShowMultiplePlaylist;
  }

  HasAdvancedSearchFiltersSelected(includeSelectedItems = true) {
    const advancedSearch = store.getState().UserInterface.toJS().AdvancedSearch;

    let hasSelections = advancedSearch.Include.trim() !== "";
    hasSelections |= advancedSearch.Exclude.trim() !== "";
    hasSelections |= advancedSearch.ExcludeCollectionFlag === true;
    hasSelections |= advancedSearch.ExcludeComposerFlag === true;
    hasSelections |= advancedSearch.ExcludeEnergyFlag === true;
    hasSelections |= advancedSearch.ExcludeGenreFlag === true;
    hasSelections |= advancedSearch.ExcludeLooksLikeFlag === true;
    hasSelections |= advancedSearch.ExcludeMoodFlag === true;
    hasSelections |= advancedSearch.ExcludeSoundsLikeFlag === true;
    hasSelections |= advancedSearch.ExcludeTempoFlag === true;
    hasSelections |= (advancedSearch.TrackPrefix || "").trim() !== "";
    hasSelections |= (advancedSearch.Title || "").trim() !== "";
    hasSelections |= advancedSearch.SelectedContextMenuItems.Collection.length > 0;
    hasSelections |= advancedSearch.SelectedContextMenuItems.Composer.length > 0;
    hasSelections |= (advancedSearch.NewAsOfDate || "").trim() !== "";
    hasSelections |= advancedSearch.Exclusive === false;

    hasSelections |= advancedSearch.Scorekeepers === false;
    hasSelections |= advancedSearch.IndieOrange === false;
    hasSelections |= advancedSearch.PleasantPictures === false;
    hasSelections |= advancedSearch.Classical === false;
    hasSelections |= advancedSearch.BigThree === false;

    if (includeSelectedItems) {
      hasSelections |= (advancedSearch.SearchText || "").trim() !== "";
      hasSelections |= (advancedSearch.DurationMin || "").trim() !== "";
      hasSelections |= (advancedSearch.DurationMax || "").trim() !== "";
      hasSelections |= advancedSearch.SelectedContextMenuItems.AdTiming.length > 0;
      hasSelections |= advancedSearch.SelectedContextMenuItems.Genre.length > 0;
      hasSelections |= advancedSearch.SelectedContextMenuItems.Mood.length > 0;
      hasSelections |= advancedSearch.SelectedContextMenuItems.Tempo.length > 0;
      hasSelections |= advancedSearch.SelectedContextMenuItems.Energy.length > 0;
      hasSelections |= advancedSearch.SelectedContextMenuItems.LooksLike.length > 0;
      hasSelections |= advancedSearch.SelectedContextMenuItems.SoundsLike.length > 0;
    }

    return hasSelections;
  }

  HasAuditionedTrack(track) {
    try {
      let auditioned_history = localStorage.getItem("audition-history");
      if (auditioned_history) {
        return JSON.parse(auditioned_history).findIndex((t) => t === track.Id) >= 0;
      }
    } catch (e) {}

    return false;
  }

  HaspreviousTrack(track) {
    try {
      let track_play_history = localStorage.getItem("track_play_history");
      if (track_play_history) {
        return JSON.parse(track_play_history).find((t) => t.id == track);
      }
    } catch (e) {}
    
    return false;
  }

  ClearPreviousTrackDuration() {
    localStorage.removeItem("track_play_history");
  }

  LoadAllMessages() {
    fetchGet("/api/Messages")
      .then((messages) => store.dispatch(setApplicationMessages(messages)))
      .catch((err) => console.error("ERROR: ", err));
  }

  LoadCart() {
    store.dispatch(GetCartAction())
  }

  LoadContextMenu(contextMenuType) {
    fetchGet(`/api/context/${contextMenuType}`)
      .then((menu) => store.dispatch(setContextData(contextMenuType, menu)))
      .catch((err) => console.error("ERROR: ", err));
  }

  LoadAltTracks(track) {
    return new Promise((resolve, reject) => {
      fetchGet(`/api/track/alts/${track.Id}`)
        .then((tracks) => resolve(tracks))
        .catch((err) => reject(err));
    });
  }

  LoadEditTracks(track) {
    return new Promise((resolve, reject) => {
      fetchGet(`/api/track/edits/${track.Id}`)
        .then((tracks) => resolve(tracks))
        .catch((err) => reject(err));
    });
  }

  async LoadSharedPlaylistsForUser() {    
    try {
      const results = await fetchGet('/api/playlist/shared/user')   
      store.dispatch(setUserSharedPlaylists(results))
    } catch (error) {
      console.error(error)
      console.error('Error occurred trying to check for any shared playlists.')
    }
  }

  async LoadSharedPlaylistsCountForUser() {    
    try {
      const sharedPlaylists = await fetchGet('/api/playlist/shared/user/count')      
      const sharedPlaylistCount = JSON.parse(sharedPlaylists).Count

      store.dispatch(setSharedPlaylistCount(sharedPlaylistCount))
    } catch (error) {
      console.error('Error occurred trying to check for any shared playlists.')
    }
  }

  Logout() {
    localStorage.clear()
    fetchPost(`/api/logout`).then((json) => {      
      Common.UserInterface.GetNavigationMenu();      
    });
  }

  RemoveQuickSearchFilter(filter) {
    const state = store.getState();
    var filters = state.UserInterface.toJS().SelectedSearchFilters;
    var filterIndex = filters.findIndex((f) => f.FilterType === filter.FilterType && f.Id === filter.Id);

    if (filterIndex >= 0) filters.splice(filterIndex, 1);
    // TODO: SetQuickSearchText
    store.dispatch(setSelectedSearchFilters(filters));

    Common.Search.QuickSearch(null, filters, 0, Common.GetSearchSize(), true);
  }

  ForgotPassword(username) {
    return new Promise((resolve, reject) => {
      fetchPost(`/api/account/password/forgot`, { username })
        .catch((error) => reject(error))
        .then((response) => resolve());
    });
  }

  ResetPassword(newPassword) {
    const state = store.getState();
    const token = state.UserInterface.toJS().ResetPasswordToken;
    return new Promise((resolve, reject) => {
      fetchPost(`/api/account/password/reset`, { token, newPassword })
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  }

  SetQuickSearchText(value) {
    store.dispatch(setQuickSearchText(value));
  }

  SetAdvancedSearch(searchQuery) {
    store.dispatch(setAdvancedSearch(searchQuery));
  }

  SetAdvancedSearchSelectedContextMenuItem(type, item, checked) {
    store.dispatch(setAdvancedSelectedContextMenuItem(type, item, checked));
  }

  SetAuditionedStatus(track) {
    store.dispatch(setAuditionedStatus(track));
  }

  SetBasicSearchSelectedContextMenuItem(type, item, checked) {
    store.dispatch(setBasicSelectedContextMenuItem(type, item, checked));
  }

  SetIsLoading(isLoading) {
    store.dispatch(setIsLoading(isLoading));
  }

  SetPlaylistFiltersSelectedContextMenuItem(type, item, checked) {
    store.dispatch(setPlaylistFiltersSelectedContextMenuItem(type, item, checked));
  }

  SetSearchSortType(sortType) {
    store.dispatch(setSearchSortType(sortType));
  }

  SetSelectedPlaylistTab(tab) {
    store.dispatch(setSelectedPlaylistTab(tab));
  }

  SetSelectedPlaylistTrack(track) {
    if (track == null) Common.WebPlayer.play(null, null);
    store.dispatch(setSelectedPlaylistTrack(track));
  }

  SetSelectedSearchTab(tab) {
    if (this.HasAdvancedSearchFiltersSelected(true) === 1 && tab === 0) {
      Common.Dialogs.ShowSearchFilterWarning();
      return;
    }

    store.dispatch(setSelectedSearchTab(tab));
  }

  SendPlaylistMessage(messageData) {
    return new Promise(async (resolve, reject) => {
      fetchPost(`/api/playlist/shared/message`, messageData)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    })    
  }

  ShowDownloadTrack(track = null, rect = {}, playlistId = null, callback = undefined) {
    showDownloadDialog(
      "Download Track",
      rect.left - 100,
      rect.bottom,
      (value) => {
        closeDownloadDialog();

        if (track != null) {
          if (callback) {
            callback(value);
          } else {
            Common.UserInterface.DownloadTrack(track, value);
          }
        } else {
          if (callback) {
            callback(value);
          } else {
            Common.Playlist.DownloadPlaylistTracks(value);
          }
        }
      },
      () => {
        closeDownloadDialog();
      }
    );
  }

  SignUp(form) {
    return new Promise((resolve, reject) => {
      fetchPost(`/api/account/signup`, form)
        .then((user) => resolve(user))
        .catch((err) => reject(err));
    });
    // store.dispatch(submitSignUpForm(form));
  }

  ShowSuggestionsDropDown(show) {
    store.dispatch(setShowSuggestionsDropDown(show));
  }

  ToggleBasicSearchPanel(panelName, toggled) {
    store.dispatch(toggleBasicSearchPanel(panelName, toggled));
  }

  TogglePlaylistExtension(show) {
    store.dispatch(togglePlaylistExtension(show));
  }

  TogglePlaylistFilterPanel(panelName, toggled) {
    store.dispatch(togglePlaylistFilterPanel(panelName, toggled));
  }

  TogglePlaylistTrack(track) {
    store.dispatch(togglePlaylistTrack(track));
  }

  ToggleSearchResultsTrack(track) {
    const toggledTrack = store
      .getState()
      .UserInterface.toJS()
      .ToggledSearchTracks.find((t) => t.Id == track.Id);
    store.dispatch(toggleSearchResultsTrack(track));
  }

  ToggleMultiplePlaylist(show) {
    store.dispatch(toggleMultiplePlaylist(show));
  }

  UpdateAdvancedSearchField(fieldType, fieldValue) {    
    store.dispatch(setAdvancedSearchFieldValue(fieldType, fieldValue));
  }

  UpdateBasicSearchField(fieldType, fieldValue) {
    store.dispatch(setBasicSearchFieldValue(fieldType, fieldValue));
  }

  UpdatePlaylistFiltersField(fieldType, fieldValue) {
    store.dispatch(setPlaylistFiltersFieldValue(fieldType, fieldValue));
  }

  UpdateUserSetting(name, value) {
    return new Promise((resolve, reject) => {
      fetchPost(`/api/account/setting`, { name, value })
        .then((user) => resolve(user))
        .catch((err) => reject(err));
    });
  }

  UpdateTrackStatistics(data) {
    return new Promise((resolve, reject) => {
      fetchPost(`/api/track/saveTrackStatistics`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  EditUser(form) {
    return new Promise((resolve, reject) => {
      fetchPost(`/api/account/edit-user`, form)
        .then((user) => resolve(user))
        .catch((err) => reject(err));
    });
  }

  GetUserDetails(username) {
    return new Promise((resolve, reject) => {
      fetchGet(`/api/account/get-user/${username}`)
        .then((json) => resolve(json))
        .catch((err) => reject(err));
    });
  }
}

export default UserInterface;
