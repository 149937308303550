import React from 'react';
import { connect } from 'react-redux';
import { Flex, Tabs, Tab } from 'components/ui';
import AdvancedSearch from './advanced-search/advanced-search';
import BasicSearch from './basic-search/basic-search';
import {getTranslation} from 'utility/common';
import './searchFilters.scss';

class SearchFilters extends React.Component {
    constructor() {
        super(...arguments);
    }

    get UserInterface() {
        const { UserInterface } = this.props;
        return UserInterface ? UserInterface.toJS() : {};
    }

    componentDidMount() {
        if (localStorage.getItem('SelectedSearchTab') !== null)
        {
            const selectedTab = localStorage.getItem('SelectedSearchTab');
            Common.UserInterface.SetSelectedSearchTab(selectedTab);
        }
    }

    handleTabSelect(selectedTab) {
        localStorage.setItem('SelectedSearchTab', selectedTab);
        Common.UserInterface.SetSelectedSearchTab(selectedTab);
    }

    render() {
        const { UserInterface } = this.props;
        const basicSearchTitle = (<span>{getTranslation("Basic Filter")}</span>);
        const advancedSearchTitle = (<span>{getTranslation("Advanced Filter")}</span>);

        var showBasicSearch = this.UserInterface.SelectedSearchTab == 0;

        return (
            <Flex className='search-filters' fixed>
                <Tabs>
                    <Tab label={basicSearchTitle} selected={this.UserInterface.SelectedSearchTab == 0} onTabSelect={this.handleTabSelect.bind(this, 0)}>
                        <Flex hide={!showBasicSearch}>
                            <BasicSearch />
                        </Flex>
                    </Tab>
                    <Tab label={advancedSearchTitle} selected={this.UserInterface.SelectedSearchTab == 1} onTabSelect={this.handleTabSelect.bind(this, 1)}>
                        <Flex hide={showBasicSearch}>
                            <AdvancedSearch state={UserInterface.toJS()} />
                        </Flex>
                    </Tab>
                </Tabs>
            </Flex>
        )
    }
}



export default connect(state => {
    return {
        UserInterface: state.UserInterface
    }
})(SearchFilters);