export const SEARCH_ADD_ALT_TRACKS = 'SEARCH_ADD_ALT_TRACKS';
export const SEARCH_ADD_EDIT_TRACKS = 'SEARCH_ADD_EDIT_TRACKS';
export const SEARCH_CLEAR_SEARCH_RESULTS = 'SEARCH_CLEAR_SEARCH_RESULTS';
export const SEARCH_CLEAR_SHOW_MORE_LIKE_THIS = 'SEARCH_CLEAR_SHOW_MORE_LIKE_THIS'
export const SEARCH_SET_SEARCH_RESULTS = 'SEARCH_SET_SEARCH_RESULTS';
export const SEARCH_SET_SUGGESTIONS = 'SEARCH_SET_SUGGESTIONS';
export const SEARCH_LOAD_ALBUM_INFO = 'SEARCH_LOAD_ALBUM_INFO';
export const SEARCH_SHOW_MORE_LIKE_THIS = 'SEARCH_SHOW_MORE_LIKE_THIS';
export const SEARCH_SET_ALT_TRACKS = 'SEARCH_SET_ALT_TRACKS';
export const SEARCH_SET_EDIT_TRACKS = 'SEARCH_SET_EDIT_TRACKS';
export const SEARCH_SET_MORE_LIKE_THIS_RESULTS = 'SEARCH_SET_MORE_LIKE_THIS_RESULTS';

import { fetchPost } from 'utility/fetchHelpers'

export const addAltTracks = (trackId, tracks) => {
    return {
        type: SEARCH_ADD_ALT_TRACKS,
        trackId: trackId,
        tracks: tracks        
    }
}

export const clearSearchResults = () => {
    return {
        type: SEARCH_CLEAR_SEARCH_RESULTS
    }
}

export const setAltTracks = (track, tracks) => {    
    return {
        type: SEARCH_SET_ALT_TRACKS,
        track: track,
        tracks: tracks
    }
}

export const setEditTracks = (track, tracks) => {    
    return {
        type: SEARCH_SET_EDIT_TRACKS,
        track: track,
        tracks: tracks
    }
}

export const setMoreLikeThisResults = (track, moreLikeThisResults) => dispatch => {
    dispatch({
        type: SEARCH_SHOW_MORE_LIKE_THIS,
        track: track,
        results: moreLikeThisResults,
        isLoading: false,
    });
}

export const setSearchResults = (searchResults) => {
    return {
        type: SEARCH_SET_SEARCH_RESULTS,
        searchResults: searchResults
    };
}

export const setSuggestions = (suggestions) => dispatch => {
    dispatch({
        type: SEARCH_SET_SUGGESTIONS,
        suggestions: suggestions
    });
}

export const loadAlbumInfo = (genreId) => dispatch => {
    Common.Search.LoadAlbumInfo(genreId)
        .then(json => {            
            dispatch({
                type: SEARCH_LOAD_ALBUM_INFO,
                genreId: genreId,
                results: json,
                isLoading: false
            })
        })
        .catch(err => {
            console.error('ERROR: An error occurred trying to load the Album Information for this track.', err);
        });    

    dispatch({
        type: SEARCH_LOAD_ALBUM_INFO,
        genreId: genreId,
        results: {
            AlbumName: '',
            AlbumCoverArt: '',
            AlbumDescription: ''
        },
        isLoading: true
    })
}

export const showMoreLikeThis = (track, collectionId, genreId, moodId, tempoId, energyId, composerId, refine = false) => dispatch => {    
    const showMoreLikeThisQuery = {
        Page: 0,
        Size: 30,
        SortType: "",
        RandomizeSeed: null,
        SearchQuery: {
            CollectionId: collectionId,
            GenreId: genreId,
            ComposerId: composerId,
            MoodId: moodId,
            TempoId: tempoId,
            EnergyId: energyId
        }
    }

    if (refine) dispatch({ type: SEARCH_CLEAR_SHOW_MORE_LIKE_THIS });

    Common.Search.GetMoreLikeThis(track, showMoreLikeThisQuery)
        .then(json => {
            dispatch(setMoreLikeThisResults(track, json));
        })
        .catch(err => {
            console.error('ERROR: An error occurred trying to load the More Like This tracks.\n', err);
        });

    return {
        type: SEARCH_SHOW_MORE_LIKE_THIS,
        track: track,
        results: [],
        isLoading: true
    }
}

