import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import style from './context-menu-list.scss';

import { Flex, FontIcon } from 'components/ui';
import { showContextMenu, closeContextMenu } from '../context-menu/context-menu';
import { ShowContextMenu as showVirtualContextMenu, CloseContextMenu as closeVirtualContextMenu } from '../virtual-context-menu/virtual-context-menu';

let currentContextMenu = null;

export class ContextMenuList extends React.Component {
    constructor(){
        super(...arguments);
        this.state = {
            openMenu: false
        }
    }

    componentWillReceiveProps(props) {
    }

    handleCloseMenu() {
        this.setState({ openMenu: false });
    }

    handleShowContextMenu() {
        const { contextMenu, contextMenuName = null, selectedItems = [], multiSelect = false, onSelect = () => {}, width = null, height = null, placeholder } = this.props;
        const contextMenuData = contextMenuName ? Common.UserInterface.GetContextMenuData(contextMenuName) : contextMenu;
        const rect = ReactDOM.findDOMNode(this.menuButton).getBoundingClientRect();

        this.setState({ openMenu: true });
        showContextMenu(rect.right, rect.top, contextMenuData, selectedItems, {             
                multiSelect: multiSelect,
                width: width,
                height: height,
                owner: ReactDOM.findDOMNode(this.menuButton)
            },  onSelect, null, this.handleCloseMenu.bind(this), placeholder);        
    }

    handleClick(event) {
        closeContextMenu();     
        this.handleShowContextMenu();
    }

    handleMouseEnter(event) {
        closeContextMenu();
        closeVirtualContextMenu();
        this.handleShowContextMenu();
    }

    handleMouseLeave(event) {
        const target = event.relatedTarget;
        const context = document.getElementById("context-menu");

        // if we are NOT going to the context menu, close the context menu
        if (context && !context.contains(target)) {
            closeContextMenu();
        }        
    }

    render() {
        const {
            contextMenu = [],
            placeholder = '', 
            value = null,       
            width = null,
            className = null
        } = this.props;   

        const contextMenuListClass = classNames('context-menu-list', { 'open-menu': this.state.openMenu });
        const placeholderText = value ? value.Label : placeholder; 

        return (
            <Flex ref={(i) => { this.menuButton = i}} className={contextMenuListClass} width={width} onClick={this.handleClick.bind(this)} onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)}>
                <Flex row className='current-value' width='100%'>
                    <Flex className='value'>{placeholderText}</Flex>
                    <Flex width='20px' align='left' fixed><FontIcon name='chevron-right' size='9px' /></Flex>              
                </Flex>     
            </Flex>            
        )
    }
}

class ContextMenuButton extends React.Component {
    constructor() {
        super(...arguments);
    }

    handleContextMenuClick(e) {
        const options = {
            position: 'top-right',
        }

        let menu = [];
        api.GetGenreContextTree()
            .then(menu => { 
                showContextMenu(ReactDOM.findDOMNode(this.menuButton), menu, options);
             })
            .catch(err => {
                console.error('ERROR: Error getting context tree: ', err);
            });
    }

    render() {
        const {
            placeholder = '',
            value,
            width = null
        } = this.props;

        return (
            <Flex ref={(i) => { this.menuButton = i }} className='context-menu-button' width={width} onClick={this.handleContextMenuClick.bind(this)}>
                <Flex row className='current-value' width='100%'>
                    <Flex className='value'>{placeholder}</Flex>
                    <Flex width='20px' align='left' fixed><FontIcon name='chevron-thin-down' size='9px' /></Flex>              
                </Flex>     
            </Flex>
        );
    }
}

