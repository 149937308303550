import React from 'react';
import style from './volume-knob.scss';

export class VolumeKnob extends React.Component {
    constructor() {
        super();
    }

    render() {
        const {
            volume = 100
        } = this.props;

        return (
            <div className='volume-knob'>
                <div className='bottomwheel' />
                <div className='topwheel' />
            </div>
        );
    }
}

