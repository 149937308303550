import React from 'react';
import { FontIcon } from 'components/ui';
import style from './checkbox.scss';
import ReactTooltip from 'react-tooltip';
import { toolTipsNotes } from "../../../utility/common";

export class Checkbox extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            checked: false,
            toolNotes:""
        }
    }

    componentDidMount() {
        const { value } = this.props;
        this.setState({ checked: value });
    }

    componentWillReceiveProps(props) {
        this.setState({
            checked: props.value || false
        });
    }

    handleToggleCheck () {
        const { 
            onCheck = () => {}
        } = this.props;

        onCheck(!this.state.checked);
        this.setState({ checked: !this.state.checked });
    }

    hadleMouseEnterTooltips = () => {
        let label =  toolTipsNotes(this.props.tooltip)
        this.setState({toolNotes:label})
    }
    render() {
        const {
            label,
            tooltip,
            readOnly = false,
            textSize = '12px',
        } = this.props;

        const labelStyle = {
            fontSize: textSize
        }

        const checkboxClass = this.state.checked ? 'checkbox checked' : 'checkbox';
        const checkboxLabel = (<div  data-tip data-multiline data-for='soundsLikeinfo' className='checkbox-label' style={labelStyle}>{label}   </div>);

        const checkboxMark = this.state.checked
            ? (<FontIcon name='checkmark' />)
            : ''

        const reactTooltip = (tooltip != null) 
            ? (<ReactTooltip id={tooltip} className="tooltip-background" place="right" type="light" multiline={true} effect="solid" delayHide={1000}></ReactTooltip>)
            : '';

        return (
            <div data-tip = {this.state.toolNotes} data-for={tooltip} className={checkboxClass} onClick={!readOnly ? this.handleToggleCheck.bind(this) : () => {}} onMouseEnter={this.hadleMouseEnterTooltips.bind(this)} >
                <div className='checkbox-icon'>{checkboxMark}</div>
                {checkboxLabel}
                {reactTooltip}
            </div>
        )
    }
}