import React from 'react';
import classNames from 'classnames';
import Style from './button.scss';
import ReactTooltip from 'react-tooltip';
import { toolTipsNotes } from "../../../utility/common";

export class Button extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            toolNotes:""
        }
    }

    hadleMouseEnterTooltips = () => {
        let label =  toolTipsNotes(this.props.tooltip)
        this.setState({toolNotes:label})
    }
    render() {
        const {
            type = 'default',
            size = 'md',            
            disabled = false,
            tooltip,
            positionToolTip="top",
            width = null,
            height = null,
            children = null,
            style = {},
            className = null,
            onMouseDown = () => {},
            onMouseUp = () => {},
            onClick = () => {},
            onHover = () => {}
        } = this.props;

        const componentStyle = Object.assign({}, style, {
            width: width,
            height: height
        });

        const componentClass = classNames({
            'button': true,
            'default': type.equalsIgnoreCase('default'),
            'success': type.equalsIgnoreCase('success'),
            'danger': type.equalsIgnoreCase('danger'),
            'secondary': type.equalsIgnoreCase('secondary'),
            'disabled': disabled,
            'text': type.equalsIgnoreCase('text'),
            'size-sm': size.in(['small', 'sm'], false),            
            'size-md': size.in(['medium', 'md'], false),            
            'size-lg': size.in(['large', 'lg'], false),            
        }, className)

        const reactTooltip = (tooltip != null)
            ? (<ReactTooltip id={tooltip} className={`${ positionToolTip === "bottom" ? "tooltip-background-bottom" : "tooltip-background"} ${tooltip === 'SEARCH-FILTER-BUTTON' ? "search-filter-button" : ""}`  } place={positionToolTip} multiline={true} type="light" effect="solid" delayHide={1000}></ReactTooltip>)
            : '';

        return (
            <div data-tip={this.state.toolNotes} data-for={tooltip}  className={componentClass} style={componentStyle} 
                onMouseDown={onMouseDown.bind(this)} 
                onMouseUp={onMouseUp.bind(this)} 
                onClick={onClick.bind(this)} 
                onMouseOver={onHover.bind(this)}
                onMouseEnter={this.hadleMouseEnterTooltips.bind(this)}
                >
                {children}
                {reactTooltip}
            </div>
        )
    }
}