import React from 'react';
import classNames from 'classnames';
import style from './quick-tips.scss';

import { Button, Dialog, Flex, ScrollWindow } from 'components/ui';

import WebView from 'react-electron-web-view'; 

const dialogName = 'QuickTipsDialog';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}



class QuickTipsDialog extends React.Component {
    componentDidMount() {
        document.addEventListener('keyup', onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    } 

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog('QuickTipsDialog', false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog('QuickTipsDialog', false);
        this.onClose()        
    }

    


    render(){

        return (
            <Dialog title='Quick Tips' name='QuickTipsDialog' width={800} height={600} modal={true} closeText={''} onClose={this.handleCancel.bind(this)}>
                <Flex className='quick-tips-dialog'>
                    <Flex position='center'>
                        <Flex height='100%' width='100%'>
                            <Flex id="player">
                                <VideoList />
                            </Flex>
                        </Flex>
                    </Flex>                
                </Flex>
            </Dialog>
        );
    }
}


class VideoList extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            toggleIndex: null,
            showVideo: false
        }
    }    

    showHideVideoHandler(index) {
        this.setState({
            toggleIndex: index,
            showVideo: !this.state.showVideo
        })
    }

    render(){
        const videosArray = [
            {
                title: "Getting Started",
                id: 'pc5SFz8jgzQ',
                link: 'https://www.youtube.com/embed/pc5SFz8jgzQ'
            },
            {
                title: "Quick Search",
                id: 'WQ-X2V9-hQQ',
                link: 'https://www.youtube.com/embed/WQ-X2V9-hQQ'
            },
            {
                title: "Basic Filter",
                id: 'iusEMt4oP1g',
                link: 'https://www.youtube.com/embed/iusEMt4oP1g'
            },
            {
                title: "Audition Tips",
                id: 'ifwH88T7SSQ',
                link: 'https://www.youtube.com/embed/ifwH88T7SSQ'
            },
            {
                title: "Scenes",
                id: '72TmJ65Ki_w',
                link: 'https://www.youtube.com/embed/72TmJ65Ki_w'
            },
            {
                title: "Inspired By",
                id: 'Q-7VHGQ-EF0',
                link: 'https://www.youtube.com/embed/Q-7VHGQ-EF0'
            },
            {
                title: "Duration & Ad Timing",
                id: 'Xf6L5pu5gv4',
                link: 'https://www.youtube.com/embed/Xf6L5pu5gv4'
            },
            {
                title: "Track Drawer",
                id: '3w-XBln38A8',
                link: 'https://www.youtube.com/embed/3w-XBln38A8'
            },
            {
                title: "Alt Mixes",
                id: 'DGzhyWfwaak',
                link: 'https://www.youtube.com/embed/DGzhyWfwaak'
            },
            {
                title: "More Like This",
                id: 'rUIINk-h0yw',
                link: 'https://www.youtube.com/embed/rUIINk-h0yw'
            },
            {
                title: "Genre Window",
                id: 'q2bZbI6bY-c',
                link: 'https://www.youtube.com/embed/q2bZbI6bY-c'
            },
            {
                title: "Creating Playlists",
                id: 'yjZILhSG2YU',
                link: 'https://www.youtube.com/embed/yjZILhSG2YU'
            },
            {
                title: "Playlist View",
                id: 'GlY8CDT3gdU',
                link: 'https://www.youtube.com/embed/GlY8CDT3gdU'
            },
            {
                title: "Playlist Functions",
                id: 'h8b57tm-zDM',
                link: 'https://www.youtube.com/embed/h8b57tm-zDM'
            },
            {
                title: "Downloading Tracks",
                id: 'UJuZf-Tr4Gw',
                link: 'https://www.youtube.com/embed/UJuZf-Tr4Gw'
            },
            {
                title: "Hyperlinks",
                id: '290Oq1WKCgw',
                link: 'https://www.youtube.com/embed/290Oq1WKCgw'
            },
            {
                title: "Audition History",
                id: 'sz9O0SDFFns',
                link: 'https://www.youtube.com/embed/sz9O0SDFFns'
            },
            {
                title: "Back/Undo",
                id: 'DI0H4M3yXO8',
                link: 'https://www.youtube.com/embed/DI0H4M3yXO8'
            },
            {
                title: "Advanced Filter",
                id: 'qP_DUBWZiJc',
                link: 'https://www.youtube.com/embed/qP_DUBWZiJc'
            },
            {
                title: "Collections",
                id: 'a_vDHmdtr74',
                link: 'https://www.youtube.com/embed/a_vDHmdtr74'
            },
            {
                title: "Title Search",
                id: 'y4enHvhBOow',
                link: 'https://www.youtube.com/embed/y4enHvhBOow'
            },
            {
                title: "Cue Sheet Maker",
                id: '3Tb9sg2V8x4',
                link: 'https://www.youtube.com/embed/3Tb9sg2V8x4'
            }
        ];

        const inlineStyle = {
            height: `${50}px`
        }
        
        return (
            <Flex>
                <ScrollWindow className='scrollable-control show-y-scrollbar'>
                    {videosArray.map((value, index) => {
                        return <div data-rowindex={index}  key={value.id} id={value.id}>
                            <Flex className="section">
                                <Flex className="section-header" onClick={this.showHideVideoHandler.bind(this, index)}>
                                    <Flex className="title" ><a href={"#"+value.id}>{value.title}</a></Flex>
                                </Flex>
                                { this.state.toggleIndex === index ?
                                <Video id={value.id} link={value.link} />
                            : ""}
                            </Flex>
                        </div>
                    })}
                    <Flex style={inlineStyle}>&nbsp;</Flex>
                </ScrollWindow>
            </Flex>
        );
    }
}

class Video extends React.Component{

    render() {
        const {
            id = '',
            link = '',
        } = this.props;

        return (
            <Flex >
                {
                    process.env.HOME_PAGE=='home-electron' ? 
                    <Flex className="webView"><WebView src={link} autosize /></Flex> : 
                    <iframe className="iframeView"
                        src={link}
                        allowFullScreen="allowFullScreen"
                        allow="autoplay"
                    ></iframe>
                }
                
            </Flex>
        );
    }
}

export default QuickTipsDialog;