import uuid from 'uuid';
import Timecode from 'smpte-timecode';

const importCueSheet = (lines) => {
  if (lines[0].startsWith('SESSION NAME:')) return loadAvidFormat(lines);
  if (lines[0].startsWith('TITLE:')) return loadFinalCutFormat(lines);
}

const loadAvidFormat = (lines) => {
  let cueSheet = {
    Id: uuid.v4(),
    Title: '',
    Episode: '',
    Lines: []
  }

  let cueIndex = 0;
  let cueLine = { Id: uuid.v4() };

  lines.forEach((line, index) => {
    if (line.startsWith('SESSION NAME: ')) {
      cueSheet.Episode = line.substr(14, line.length-14);
    } 
    else if (line.trim() == "") {
      // do nothing
    }   
    else if (line.trim().startsWith("*")) {
      // do nothing
    }         
    else if (line.match(/\d{3}[^\n]*\s[0-9:]{11}\s[0-9:]{11}\s[0-9:]{11}\s[0-9:]{11}/) != null) {
      let parts = lines[index].match(/(\d{3})[^\n]*\s([0-9:]{11})\s([0-9:]{11})\s([0-9:]{11})\s([0-9:]{11})/);
      let parts2 = lines[index+1].match(/(?:\* FROM CLIP NAME: )([a-zA-Z .0-9:]*)(.[a-zA-Z0-9:]{3})$/);
        
      if (parts2[1] == cueLine.Title) {
        let timeCodeIn = Timecode(parts[4]);
        let timeCodeOut = Timecode(parts[5]);    
            
        if (timeCodeIn.frameCount < cueLine.TimeCodeIn.frameCount) 
            cueLine.TimeCodeIn = timeCodeIn;
        
        if (timeCodeOut.frameCount > cueLine.TimeCodeOut.frameCount) 
            cueLine.TimeCodeOut = timeCodeOut;
       //cueLine.TimeCodeLength = cueLine.TimeCodeLength.add(Timecode(timeCodeOut - timeCodeIn).frameCount);
      } else {
        if (cueLine.Title != null) {                                
          // cueLine.TimeCodeLength = cueLine.TimeCodeLength.toString();
          cueLine.TimeCodeLength = Timecode(cueLine.TimeCodeOut - cueLine.TimeCodeIn);
          cueLine.TimeCodeLength.add(cueLine.TimeCodeLength.frames > 0 ? 30 : 0);
          cueLine.TimeCodeLength = ((cueLine.TimeCodeLength.hours > 0) ? cueLine.TimeCodeLength.hours + ':' : '') +
                                  ((cueLine.TimeCodeLength.minutes > 0) ? cueLine.TimeCodeLength.minutes.toString().padLeft(2, '0') + ':' : '00:') +
                                  (cueLine.TimeCodeLength.seconds.toString().padLeft(2, '0'));
          cueLine.TimeCodeIn = cueLine.TimeCodeIn.toString();
          cueLine.TimeCodeOut = cueLine.TimeCodeOut.toString();
          cueSheet.Lines.push(cueLine);
        } 

        cueLine = { Id: uuid.v4() };
        cueLine.CueNumber = ++cueIndex;
        cueLine.Title = parts2[1];
        cueLine.TimeCodeIn = Timecode(parts[4]);
        cueLine.TimeCodeOut = Timecode(parts[5]);                            
        cueLine.TimeCodeLength = Timecode(cueLine.TimeCodeOut - cueLine.TimeCodeIn);
      }
    }        
  })

  // cueLine.TimeCodeLength = Timecode(cueLine.TimeCodeOut - cueLine.TimeCodeIn).toString().replace(';', ':');
  cueLine.TimeCodeLength = Timecode(cueLine.TimeCodeOut - cueLine.TimeCodeIn);
  cueLine.TimeCodeLength.add(cueLine.TimeCodeLength.frames > 0 ? 30 : 0);
  cueLine.TimeCodeLength = ((cueLine.TimeCodeLength.hours > 0) ? cueLine.TimeCodeLength.hours + ':' : '') +
                          ((cueLine.TimeCodeLength.minutes > 0) ? cueLine.TimeCodeLength.minutes.toString().padLeft(2, '0') + ':' : '00:') +
                          (cueLine.TimeCodeLength.seconds.toString().padLeft(2, '0'));
  cueLine.TimeCodeIn = cueLine.TimeCodeIn.toString();
  cueLine.TimeCodeOut = cueLine.TimeCodeOut.toString();
  cueSheet.Lines.push(cueLine);

  return cueSheet;
}

const loadFinalCutFormat = (lines) => {
  let cueSheet = {
    Id: uuid.v4(),
    Title: '',
    Episode: '',
    Lines: []
  }

  let cueIndex = 0;
  let cueLine = { Id: uuid.v4() };

  lines.forEach((line, index) => {
    if (line.startsWith('TITLE: ')) {
      cueSheet.Episode = line.substr(7, line.length-7);
    } 
    else if (line.trim() == "") {
      // do nothing
    }   
    else if (line.trim().startsWith("*")) {
      // do nothing
    }         
    else if (line.match(/\d{3}[^\n]*\s[0-9:]{11}\s[0-9:]{11}\s[0-9:]{11}\s[0-9:]{11}/) != null) {
      let parts = lines[index].match(/(\d{3})[^\n]*\s([0-9:]{11})\s([0-9:]{11})\s([0-9:]{11})\s([0-9:]{11})/);
      let parts2 = lines[index+1].match(/(?:\* FROM CLIP NAME: )([a-zA-Z 0-9:]*)(.*)$/);

      if (parts2 === null) {
        const line = lines[index+1];

        if (line.startsWith("* FROM CLIP NAME: ")) {
          parts2 = ["", ""];
          parts2[0] = "* FROM CLIP NAME: ";
          parts2[1] = line.substr(parts2[0].length + 1, line.length - (parts2[0].length));
        } else {
          //TODO handle the second line if there are other possibilities
          parts2[0] = "";
          parts2[1] = "Unknown track";
        }
      }

      if (parts2 && parts2[1] == cueLine.Title) {
        let timeCodeIn = Timecode(parts[4]);
        let timeCodeOut = Timecode(parts[5]);    
            
        if (timeCodeIn.frameCount < cueLine.TimeCodeIn.frameCount) 
            cueLine.TimeCodeIn = timeCodeIn;
        
        if (timeCodeOut.frameCount > cueLine.TimeCodeOut.frameCount) 
            cueLine.TimeCodeOut = timeCodeOut;
       //cueLine.TimeCodeLength = cueLine.TimeCodeLength.add(Timecode(timeCodeOut - timeCodeIn).frameCount);
      } else {
        if (cueLine.Title != null) {                                
          // cueLine.TimeCodeLength = cueLine.TimeCodeLength.toString();
          cueLine.TimeCodeLength = Timecode(cueLine.TimeCodeOut - cueLine.TimeCodeIn);
          cueLine.TimeCodeLength.add(cueLine.TimeCodeLength.frames > 0 ? 30 : 0);
          cueLine.TimeCodeLength = ((cueLine.TimeCodeLength.hours > 0) ? cueLine.TimeCodeLength.hours + ':' : '') +
                                  ((cueLine.TimeCodeLength.minutes > 0) ? cueLine.TimeCodeLength.minutes.toString().padLeft(2, '0') + ':' : '00:') +
                                  (cueLine.TimeCodeLength.seconds.toString().padLeft(2, '0'));
          cueLine.TimeCodeIn = cueLine.TimeCodeIn.toString();
          cueLine.TimeCodeOut = cueLine.TimeCodeOut.toString();
          cueSheet.Lines.push(cueLine);
        } 

        cueLine = { Id: uuid.v4() };
        cueLine.CueNumber = ++cueIndex;
        cueLine.Title = parts2[1];
        cueLine.TimeCodeIn = Timecode(parts[4]);
        cueLine.TimeCodeOut = Timecode(parts[5]);                            
        cueLine.TimeCodeLength = Timecode(cueLine.TimeCodeOut - cueLine.TimeCodeIn);
      }
    }        
  })

  // cueLine.TimeCodeLength = Timecode(cueLine.TimeCodeOut - cueLine.TimeCodeIn).toString().replace(';', ':');
  cueLine.TimeCodeLength = Timecode(cueLine.TimeCodeOut - cueLine.TimeCodeIn);
  cueLine.TimeCodeLength.add(cueLine.TimeCodeLength.frames > 0 ? 30 : 0);
  cueLine.TimeCodeLength = ((cueLine.TimeCodeLength.hours > 0) ? cueLine.TimeCodeLength.hours + ':' : '') +
                          ((cueLine.TimeCodeLength.minutes > 0) ? cueLine.TimeCodeLength.minutes.toString().padLeft(2, '0') + ':' : '00:') +
                          (cueLine.TimeCodeLength.seconds.toString().padLeft(2, '0'));
  cueLine.TimeCodeIn = cueLine.TimeCodeIn.toString();
  cueLine.TimeCodeOut = cueLine.TimeCodeOut.toString();
  cueSheet.Lines.push(cueLine);

  return cueSheet;
}

module.exports = {
  importCueSheet
};