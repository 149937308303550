import React from 'react';
import classNames from 'classnames';
import style from './signup.scss';

import { showAlert, closeAlert } from 'components/ui/alert/alert'
import { setUserDetails } from 'store/userInterface/actions';
import { Button, Checkbox, Dialog, Flex, FontIcon, TextArea, TextField, Typewriter } from 'components/ui';

const dialogName = 'SignUpDialog';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

class SignUpDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            formData: {
                firstName: '',
                middleName: '',
                lastName: '',
                companyName: '',            
                emailAddress: '',         
                phoneNumber: '',
                username: '',
                password: '',
                confirmPassword: '',
                purpose: '',
                comments: '',
                groupPassword: ''
            },
            validation: {
                firstName: { required: true, isValid: false, missingMessage: '* First Name is a required field'  },
                middleName: { required: false, isValid: false },
                lastName: { required: true, isValid: false, missingMessage: '* Last Name is a required field' },
                emailAddress: { required: true, isValid: false, missingMessage: '* Email Address is a required field' },
                phoneNumber: { required: true, isValid: false, missingMessage: '* Phone Number is a required field' },
                company: { required: false, isValid: false },            
                username: { required: true, isValid: false, missingMessage: '* Username is a required field' },
                password: { required: true, isValid: false },
                confirmPassword: { required: true, isValid: false },
                purpose: { required: true, isValid: false, missingMessage: '* Project Description is a required field' },
                comments: { required: false, isValid: false },
                groupPassword: { required: false, isValid: false }
            },
            invalidUsername: false,
        }
    }

    componentDidMount() {
        document.addEventListener('keyup', onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    }    

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog('SignUpDialog', false);
        this.onClose();
    }

    handleShowLogin = () => {
        Common.Dialogs.ShowDialog('SignUpDialog', false);
        this.onClose();
        Common.Dialogs.ShowDialog('LoginDialog', true);
    }

    handleEscape() {
        Common.Dialogs.ShowDialog('SignUpDialog', false);
        this.onClose()        
    }

    handleFieldChange(fieldName, value) {
        const state = this.state;
        state.formData[fieldName] = value;
        this.setState(state);
    }

    handleOK() {
        Common.UserInterface.ShowDialog('SignUpDialog', false);
    }

    handleSubmitForm() {
        if (this.validateForm()) {
            Common.UserInterface.SignUp(this.state.formData)
                .then((val) => {
                    if (val.Success) {
                        Common.Dialogs.ShowDialog('SignUpDialog', false);

                        const content = (
                            <Flex row height={80} position='left'>
                                <FontIcon name='inspector' />
                                <div className='message'><Typewriter message={val.Message} /></div>
                            </Flex>
                        )       
                        
                        const handleOK = () => {                
                            closeAlert();
                        }
            
                        const buttons = [
                            <Button key={0} width={80} onClick={handleOK.bind(this)}>OK</Button>,
                        ]
                
                        showAlert({
                            contentClass: 'sign-up-submitted-alert',
                            title: 'SIGN UP SUBMITTED',
                            width: 600,
                            height: 200,
                            content: content,
                            buttons: buttons
                        });
                    } else {
                        if (val.AlertType == 2) {
                            // this.setState({ invalidUsername: true, errorMessage: val.Errors[0]  });
                            const content = (
                                <Flex row height={80} position='left'>
                                    <FontIcon name='inspector' />
                                    <div className='message'><Typewriter message={val.Errors[0]} /></div>
                                </Flex>
                            )       
                            
                            const handleOK = () => {                
                                closeAlert();
                            }
                
                            const buttons = [
                                <Button key={0} width={80} onClick={handleOK.bind(this)}>OK</Button>,
                            ]
                    
                            showAlert({
                                contentClass: 'sign-up-submitted-alert',
                                title: 'WARNING',
                                width: 600,
                                height: 200,
                                content: content,
                                buttons: buttons
                            });
                        }
                    }
                })
                .catch(err => {});
        } else {
            console.error('Validation Error - need to display error here');
        }
    }

    handleWhatsThis() { 
        const content = (
            <Flex row height={80} position='left'>
                <FontIcon name='inspector' />
                <div className='message'><Typewriter message='Only enter a "group code" if your organization has provided one to you..' /></div>
            </Flex>
        )

        const handleOK = () => {
            closeAlert();
        }

        const buttons = [
            <Button key={0} width={80} onClick={handleOK.bind(this)}>OK</Button>,
        ]

        showAlert({
            contentClass: 'signup-group-access-whats-this',
            title: 'What\'s this: Group Access Code',
            width: 600,
            height: 200,
            content: content,
            buttons: buttons
        });               
    }

    validateForm() {
        var isValid = (this.state.formData.password == this.state.formData.confirmPassword);

        if (!isValid) {
            this.setState({ alertMessage: '* Passwords do not match' });
        } else {
            for (var key in this.state.validation) {
                if (this.state.validation[key].required) {
                    var fieldIsValid = this.state.formData[key].trim() != ''
                    isValid = isValid && fieldIsValid;

                    if (!fieldIsValid) {
                        this.setState({ alertMessage: `${this.state.validation[key].missingMessage}` });                        
                        break;                    
                    }
                }
            }

            if (isValid) 
                this.setState({ alertMessage: '' });
        }

        return isValid;
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;
    
        const usernameNotification = this.state.invalidUsername ? this.errorMessage : '';

        return (
            <Dialog title='ACCOUNT SIGN UP' name='SignUpDialog' width={900} height={760} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)}>
                <Flex className='sign-up-dialog' row>
                    <Flex block position='top'>
                        <Flex block fixed>
                        <a href='#' onClick={this.handleShowLogin.bind(this)} className='title' >Already have an account?</a>
                        </Flex>
                        <Flex block fixed wrap className='details'>With a user account you can download music, and create and manage playlists. Fill out the form below and click the sign-up button to get started.</Flex>
                                         
                        
                        <Flex className='form' position='top'>
                            <table>
                                <tbody>
                                    {/* <tr>
                                        <td colSpan='6' className='username-alert'>{usernameNotification}</td>
                                    </tr> */}

                                    <tr>
                                        <td className='username-field'>Username</td>
                                        <td className='username-value'>
                                            <TextField value={this.state.formData.username} onChange={this.handleFieldChange.bind(this, 'username')} showClearIcon={false} required={true} />
                                        </td>
                                        {/* <td colSpan='5' className='username-alert'>{usernameNotification}</td> */}
                                    </tr>                                         
                                    <tr>
                                        <td className='password-field'>PASSWORD</td>
                                        <td className='password-value' colSpan='3'><TextField value={this.state.formData.password} required={true} placeholder={this.state.validation.firstName.placeholder} onChange={this.handleFieldChange.bind(this, 'password')} type='password' showClearIcon={false} /></td>
                                        <td className='password-field'>CONFIRM</td>
                                        <td className='password-value' colSpan='2'><TextField value={this.state.formData.confirmPassword} required={true} onChange={this.handleFieldChange.bind(this, 'confirmPassword')} type='password' password showClearIcon={false} /></td>
                                    </tr> 
                                    <tr>
                                        <td className='first-name-field'>First Name</td>
                                        <td className='first-name-value'><TextField required={true} value={this.state.formData.firstName} onChange={this.handleFieldChange.bind(this, 'firstName')} showClearIcon={false} /></td>
                                        <td className='middle-name-field'>MI</td>
                                        <td className='middle-name-value'><TextField value={this.state.formData.middleName} onChange={this.handleFieldChange.bind(this, 'middleName')} showClearIcon={false} /></td>
                                        <td className='last-name-field'>Last Name</td>
                                        <td className='last-name-value'><TextField required={true} value={this.state.formData.lastName} onChange={this.handleFieldChange.bind(this, 'lastName')} showClearIcon={false} /></td>
                                    </tr>
                                    <tr>
                                        <td className='company-field'>COMPANY</td>
                                        <td className='company-value' colSpan='5'><TextField value={this.state.formData.companyName} onChange={this.handleFieldChange.bind(this, 'companyName')} showClearIcon={false} /></td>
                                    </tr>                                                            
                                    <tr>
                                        <td className='email-field'>EMAIL ADDRESS</td>
                                        <td className='email-value' colSpan='5'><TextField required={true} value={this.state.formData.emailAddress} onChange={this.handleFieldChange.bind(this, 'emailAddress')} showClearIcon={false} /></td>
                                    </tr>    
                                    <tr>
                                        <td className='phone-field'>PHONE NUMBER</td>
                                        <td className='phone-value' colSpan='1'><TextField required={true} value={this.state.formData.phoneNumber} onChange={this.handleFieldChange.bind(this, 'phoneNumber')} showClearIcon={false} /></td>
                                    </tr>    
                                    <tr>
                                        <td className='password-field'>PROJECT DESCRIPTION</td>
                                        <td colSpan='5' style={{paddingBottom: 20}}>  
                                            <TextArea required={true} height={50} value={this.state.formData.purpose} onChange={this.handleFieldChange.bind(this, 'purpose')} />  
                                        </td>
                                    </tr>                                                                                                                           
                                    <tr>
                                        <td className='password-field'>COMMENTS</td>
                                        <td colSpan='5'>  
                                            <TextArea height={50} value={this.state.formData.comments} onChange={this.handleFieldChange.bind(this, 'comments')} /> 
                                        </td>
                                    </tr>   
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>                                                                                                                        
                                    <tr>     
                                        <td className='group-access-code-field'>GROUP ACCESS CODE</td>
                                        <td colSpan='5' style={{paddingBottom: 20}}>  
                                            <Flex row>
                                                <Flex row position='left'>
                                                    <TextField height={40} width={150} type='password' showClearIcon={false} value={this.state.formData.groupPassword} onChange={this.handleFieldChange.bind(this, 'groupPassword')} />                                             
                                                    <a className='whats-this-link' href='#' onClick={this.handleWhatsThis.bind(this)}>What's this?</a>
                                                </Flex>
                                                <Flex row position='right'>
                                                    <span className='text-alert'>{this.state.alertMessage}</span>
                                                </Flex>
                                            </Flex>
                                        </td>
                                    </tr>    
                                    <tr>
                                        <td colSpan='6'>
                                            <Flex row>    
                                                <Flex row position='right'>
                                                    <Button onClick={this.handleSubmitForm.bind(this)} type='success'>Sign Up</Button>
                                                    <Button onClick={this.handleCancel.bind(this)}>Cancel</Button>                                        
                                                </Flex>
                                            </Flex>
                                        </td>
                                    </tr>
                                </tbody>                            
                            </table>
                        </Flex>
                    </Flex>                        
                </Flex>
            </Dialog>
        )
    }
}

export default SignUpDialog;