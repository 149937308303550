import React from "react";
import classNames from "classnames";
import style from "./edit-track.scss";

import { Button, Dialog, Flex, ScrollWindow } from "components/ui";

const dialogName = "EditTrackDialog";

const onKeyUp = event => {
  if (event.which == 27) {
    Common.Dialogs.ShowDialog(dialogName, false);
  }
};

class EditTrackDialog extends React.Component {

  componentDidMount() {
    document.addEventListener("keyup", onKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", onKeyUp);
  }

  get onClose() {
    const { onClose = () => { } } = this.props;
    return onClose;
  }

  handleCancel() {
    Common.Dialogs.ShowDialog("EditTrackDialog", false);
    this.onClose();
  }

  handleEscape() {
    Common.Dialogs.ShowDialog("EditTrackDialog", false);
    this.onClose();
  }

  showTrackEditInfo(id) {
    return (
      <iframe
        className="iframeView"
        src={`${window.location.origin}/admin#/admin/tracks/list/${id}/true`}
      ></iframe>
    );
  }

  render() {
    const { trackId } = this.props;

    return (
      <Dialog title="Edit Track" name="EditTrackDialog" width={1000} height={1200} modal={true} closeText={""} disableClickOutsideToClose={true} onClose={this.handleCancel.bind(this)} >
        <Flex className="edit-track-dialog" >
          {this.showTrackEditInfo(trackId)}
        </Flex>
      </Dialog>
    );
  }
}

export default EditTrackDialog;
