import React from 'react';
import classNames from 'classnames';
import style from './save-cue-sheet.scss';

import { Button, Dialog, Flex, TextField } from 'components/ui';

const dialogName = 'SaveCueSheet';

export default class SaveCueSheetDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            title: ''
        }
    }

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    componentDidMount() {
        const title = Common.CueSheet.GetTitle();
        this.setState({ title: title });

        setTimeout(() => {
            this.input.focus();
            this.input.select();
        }, 20);
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose()        
    }

    handleOK() {
        Common.CueSheet.SetTitle(this.input.value);
        Common.CueSheet.Save();
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleTextChanged(value) {
        this.setState({ title: value});
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        return (
            <Dialog title='Save Cue Sheet' name={dialogName} width={500} height={200} closeText='' modal={true} position='top' style={{top: '15%'}} onClose={this.handleCancel.bind(this)} onEscape={this.handleEscape.bind(this)}>
                <Flex className='save-cue-sheet-dialog'>
                    <Flex row position='left'>                        
                        <Flex row>
                            <Flex width={120} style={{color:'#999999'}}>Cue Sheet Title:</Flex>
                            <Flex><TextField ref={(i) => {this.input = i}} height={35} onTextChanged={this.handleTextChanged.bind(this)} value={this.state.title} /></Flex>
                        </Flex>
                    </Flex>
                    <Flex></Flex>
                    <Flex row height='30px' position='right'>
                        <Flex row width='200px'>
                            <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                            <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}