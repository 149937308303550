if (!window.iif) {
    window.iif = (trueStatement, trueResult, falseResult) => {
        return trueStatement ? trueResult : falseResult;
    }
}

if (!String.prototype.equals) {
    String.prototype.equals = function(value, isCaseSensitive = true) {
        return isCaseSensitive ? value === this : value.toLowerCase() === this.toLowerCase();
    }
}

if (!String.prototype.equalsIgnoreCase) {
    String.prototype.equalsIgnoreCase = function(value) {
        return this.equals(value, false);
    }
}

if (!String.prototype.in) {
    String.prototype.in = function(values = [], isCaseSensitive = true) {
        var result = false;

        if (isCaseSensitive) {
            values.forEach((k) => {        
                if (k == this) result = true;
            });
        } else {
            values.forEach((k) => {        
                if (k.toLowerCase() == this.toLowerCase()) result = true;
            });
        }

        return result;
    }
}

if (!String.prototype.notIn) {
    String.prototype.notIn = function(values = [], isCaseSensitive = true) {
        var result = true;

        if (isCaseSensitive) {
            values.forEach((k) => {  
                if (k === this) result = false;
            });
        } else {
            values.forEach((k) => {        
                if (k.toLowerCase() === this.toLowerCase()) result = false;
            });
        }

        return result;
    }
}

if (!String.prototype.padLeft) {
    String.prototype.padLeft = function (n,str){
      return Array(n-String(this).length+1).join(str||'0')+this;
    }    
}

if (!Number.prototype.padLeft) {
    Number.prototype.padLeft = function (n,str){
      return Array(n-String(this).length+1).join(str||'0')+this;
    }    
}