import React from 'react';
import classNames from 'classnames';
import style from './import-cue-sheet.scss';

import { Button, Dialog, Flex, TextField } from 'components/ui';

const dialogName = 'ImportCueSheet';

export default class ImportCueSheetDialog extends React.Component {
    constructor() {
        super(...arguments);
    }

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose()        
    }

    handleOK() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();  
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;
        
        return (
            <Dialog title='Import Cue Sheet' name={dialogName} width={800} height={500} modal={true} onClose={this.handleCancel.bind(this)} onEscape={this.handleEscape.bind(this)}>
                <Flex className='import-cue-sheet-dialog'>
                    <Flex row position='center'>                        
                        Not implemented yet
                    </Flex>
                    <Flex></Flex>
                    <Flex row height='30px' position='right'>
                        <Flex row width='200px'>
                            <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                            <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}