import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Flex, FontIcon, MenuDropDown } from 'components/ui';
import style from './dialog.scss';

export class Dialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            menuPosition: {
                x: 0,
                y: 0
            },
            showMenu: false
        }
    }

    componentDidMount() {
        try {
            const pos = document.querySelector('.dialog-title').getBoundingClientRect();
            this.setState({
                menuPosition: {
                    x: pos.left,
                    y: pos.bottom - 15
                }
            });
        } catch (ex) {

        }        
    }

    handleClickOutsideToClose = () => {
        const {
            onClose = () => {},
            disableClickOutsideToClose = false
        } = this.props;

        if (!disableClickOutsideToClose) {
            onClose();
        }
    }

    handleCloseMenuDropDown() {
        this.setState({ showMenu: false });
    }

    handleShowMenuDropDown() {
        const {
            menu = null
        } = this.props;

        if (menu != null) {
            this.setState({ showMenu: true });
        }
    }

    render() {
      const {
            title = '',
            closeText = 'Close',
            level = 1,
            name = '',
            menu = null,
            modal = false,
            width = null,
            height = null,
            position = 'center',
            children = null,
            style = {},
            className = null,
            hideTitleIcon = false,
            onClose = () => {}
        } = this.props;

        const dialogContentClass = classNames({
            'dialog-content': true,
        }, className);

        const dialogStyle = Object.assign({}, {
            width: width,
            height: height,
            zIndex: level * 1000
        }, style);

        const dialogTitle = (menu != null)
            ? (<Flex ref={(i) => this.titleElement = i} className='dialog-title'>
                    <Flex row width={170} className='dialog-title-hover' onClick={this.handleShowMenuDropDown.bind(this)} position='left'>{title} <span className='menu-icon'><FontIcon name='chevron-down' /></span></Flex>
                </Flex>)
            : (<Flex className='dialog-title'>
                    <span>{title}</span>
                </Flex>)

        const menuStructure = this.state.showMenu
            ? (<MenuDropDown x={this.state.menuPosition.x} y={this.state.menuPosition.y} menu={menu} onCloseMenuDropDown={this.handleCloseMenuDropDown.bind(this)} />)
            : '';

        const modalBackground = modal
            ? <Flex fill className='modal-background' onClick={this.handleClickOutsideToClose} />
            : ''

        const titleIcon = !hideTitleIcon
            ? (<FontIcon className='dialog-icon' name='inspector' />)
            : '';

        const titleBar = this.props.hideTitlebar
            ? ''
            : (
                <Flex row className='dialog-header dialog-top-bg' fixed>
                    <Flex row>
                        { titleIcon }
                        <Flex className='dialog-title'>{dialogTitle}</Flex>
                    </Flex>
                    <Flex row className='dialog-close' position='right'>
                        <Flex className='close-text' position='right'>{closeText}</Flex>
                        <Flex className='close-button' onClick={onClose}><FontIcon name='close' size='14pt' /></Flex>
                    </Flex>
                </Flex>
            );

        return (
            <Flex fill ref='dialog' className='dialog' position={position}>
                { modalBackground }
                <Flex className='dialog-window' width={width} height={height} style={dialogStyle}>
                    {titleBar}
                    <Flex className={dialogContentClass}>
                        { children }
                    </Flex>
                </Flex>
                {menuStructure}
            </Flex>
        )
    }
}
