import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Styles from './alert.scss';

import { Dialog, Flex, RenderInBody } from 'components/ui';

const dialogName = 'AlertDialog';

const onKeyUp = (event) => {
    if (event.which == 27) {
        closeAlert();
    }
}

let alertNode = null;

export const closeAlert = () => {
    if (alertNode != null) {   
        ReactDOM.unmountComponentAtNode(alertNode);
        alertNode = null;
    }
}

export const showAlert = (options) => {    
    if (alertNode == null) {
        alertNode = document.createElement('div');

        if (!options.onClose) options.onClose = () => {};

        ReactDOM.render(
            (<AlertDialog className={options.contentClass} title={options.title} width={options.width} height={options.height} content={options.content} buttons={options.buttons} onClose={options.onClose} />),
            document.getElementById('body-render').appendChild(alertNode)
        );

        return alertNode;
    }
}

class AlertDialog extends React.Component {
    constructor() {
        super(...arguments);        
    }

    componentDidMount() {
        document.addEventListener('keyup', onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    }

    handleCancel() {
        this.props.onClose();        
        closeAlert();
    }

    render() {
        const {    
            width = 600,
            height = null,
            title = 'Alert',
            content = null,        
            buttons = null,
            hideTitleIcon = true,
            className = null,
            onClose = () => {}
        } = this.props;
                    
        const alertFooter = buttons
            ? (<Flex row className='footer' position='right' height={70}>{buttons}</Flex>)
            : '';

        const contentClass = classNames('alert-content', className);

        return (
            <Dialog hideTitleIcon={hideTitleIcon} title={title} name='AlertDialog' width={width} height={height} modal={true} onClose={this.handleCancel.bind(this)} closeText=''>
                <Flex className='alert-dialog'>
                    <Flex className={contentClass} position='center'>
                        { content }
                    </Flex>                
                    { alertFooter }
                </Flex>
            </Dialog>
        )
    }
}

export default AlertDialog;