import {store} from 'store'
import uuid from 'uuid';
import {fetchGet, fetchPost} from 'utility/fetchHelpers.js';
import {
  clearSearchResults,
  setSearchResults,
  setSuggestions,
  loadAlbumInfo,
  setAltTracks,
  setEditTracks,
  showMoreLikeThis
} from 'store/search/actions';

import {
  setIsSearching,
  setNoSearchResultsFoundMessage
} from 'store/userInterface/actions';

import {
  createEmptyAdvancedSearch
} from 'store/userInterface/reducer';

//Back/Undo functionality with playlist and search tracks
import {
  updatePreviousSearches
} from 'store/previousSearches/actions';

class Search {
  constructor(_store) {
    this.LastSearchGuid = null;
  }

  GetLastSearchGuid = () => {
    return this.LastSearchGuid;
  }

  AdvancedSearch(searchQuery, page = 0, size = 50, newSearch = false, prevSearch = false) {
    const searchGuid = uuid.v4();
    this.LastSearchGuid = searchGuid;

    if (searchQuery == null) {
      searchQuery = store.getState().UserInterface.toJS().AdvancedSearch;
    } else {
      // Make sure that the ExcludePlaylistTracks are still enforced
      searchQuery.ExcludePlaylistTracks = store.getState().UserInterface.toJS().AdvancedSearch.ExcludePlaylistTracks;
    }

    if (newSearch) {
      if (Common.TrackPlayer.AudioSource === 'search')
        Common.TrackPlayer.Stop();

      Common.UserInterface.ClearBasicSearch();
      Common.Search.ClearSearchResults();

      store.dispatch(setIsSearching(true));
    }

    Common.UserInterface.ClearToggledSearchTracks();

    // setTimeout(() => {
    //   fetchPost(`/api/search/advanced`, {
    //     Guid: this.LastSearchGuid,
    //     Page: page,
    //     Size: size,
    //     SortType: Common.UserInterface.GetSearchSortType(),
    //     SearchQuery: searchQuery,
    //     ForceSearch: newSearch,
    //   })
    //     .then(json => {
    //       if (!prevSearch) {
    //         const previousSearches = Common.GetState().PreviousSearches.push(json.LastSearch);
    //         store.dispatch(updatePreviousSearches(previousSearches.toJS()));
    //       }

    //       if (!(json.Tracks || []).length) {
    //         if (!searchQuery.NewAsOfDate) {
    //           store.dispatch(setNoSearchResultsFoundMessage(Common.UserInterface.GetRandomMessage('No results found')));
    //         } else {
    //             store.dispatch(setNoSearchResultsFoundMessage("No new tracks have been added since the selected date."));
    //         }
    //       }

    //       json.CanLoadMore = (json.Page * json.Size) <= json.Total;

    //       if (this.GetLastSearchGuid() == searchGuid) {
    //         this.LastSearchGuid = null;
    //         store.dispatch(setSearchResults(json));
    //         store.dispatch(setIsSearching(false));
    //       }
    //     })
    //     .catch(err => {
    //       console.error('ERROR: ', err);
    //     });
    // }, 1);

    // fetchPost(`/api/search/getadvancedSearch`, {
    //   Guid: this.LastSearchGuid,
    //   Page: page,
    //   Size: size,
    //   SortType: Common.UserInterface.GetSearchSortType(),
    //   SearchQuery: searchQuery,
    //   ForceSearch: newSearch,
    // }).then(json => {
    //       var trackIds = []
    //       json.Tracks.forEach(subItem => {
    //         trackIds.push(subItem)
    //       });
    //       localStorage.setItem('SearchedTrackIds',trackIds)
    //    })

    // Set the exclusive flags for specific users
    var userDetails = Common.GetState().UserInterface.toJS().UserDetails;
    
    if (userDetails && !userDetails.IsAdmin) {
      if (userDetails.ExclusiveOnly) {
        searchQuery.Exclusive = true;
        searchQuery.NonExclusive = false;
      }
    }
    
    searchQuery = {
      ...searchQuery,
      ExcludeAdTimingFlag: searchQuery.ExcludeAdTimingFlag || false,
      IncludeDeleted: searchQuery.IncludeDeleted || false,
      IncludeHidden: searchQuery.IncludeHidden || false,
      IncludeHistory: searchQuery.IncludeHistory || false,
    }

    fetchPost(`/api/search/advanced`, {
      Guid: this.LastSearchGuid,
      Page: page,
      Size: size,
      SortType: Common.UserInterface.GetSearchSortType(),
      RandomizeSeed: '',
      SearchQuery: searchQuery,
      ForceSearch: newSearch,
      PriorityTrackId: ''
    })
      .then(json => {
        if (!prevSearch) {
          const previousSearches = Common.GetState().PreviousSearches.push(json.LastSearch);
          store.dispatch(updatePreviousSearches(previousSearches.toJS()));
        }

        if (!(json.Tracks || []).length) {
          if (!searchQuery.NewAsOfDate) {
            store.dispatch(setNoSearchResultsFoundMessage(Common.UserInterface.GetRandomMessage('No results found')));
          } else {
              store.dispatch(setNoSearchResultsFoundMessage("No new tracks have been added since the selected date."));
          }
        }

        const trackIds = [];
        if (json.Tracks) {
          json.Tracks.forEach(subItem => {
            trackIds.push(subItem.Id);
          })
  
          localStorage.setItem('SearchedTrackIds', trackIds);
  
          json.CanLoadMore = (json.Page * json.Size) <= json.Total;
  
          if (this.GetLastSearchGuid() == searchGuid) {
            this.LastSearchGuid = null;
            store.dispatch(setSearchResults(json));
            store.dispatch(setIsSearching(false));
          }
  
        }
      })
      .catch(err => {
        console.error('ERROR: ', err);
      });    
  }

  BasicSearch(searchQuery, page = 0, size = 20, newSearch = false) {
    if (newSearch) {
      Common.WebPlayer.stop();
      //TODO: remove - Common.UserInterface.ClearQuickSearch();
      Common.UserInterface.ClearAdvancedSearch();
      Common.Search.ClearSearchResults();
    }

    setTimeout(() => {
      fetchPost(`/api/search/basic`, {
        Page: page,
        Size: size,
        SortType: Common.UserInterface.GetSearchSortType(),
        SearchQuery: searchQuery
      })
        .then(json => {
          store.dispatch(setSearchResults(json));
        })
        .catch(err => {
          console.error('ERROR: ', err);
        });
    }, 1);
  }

  ClearSearchResults() {
    if (Common.TrackPlayer.AudioSource === 'search')
      Common.TrackPlayer.Stop();

    store.dispatch(clearSearchResults());
  }

  GetAlbumInfoTracks = (genreId, page, size) => {
    return new Promise((resolve, reject) => {
      const albumInfoTracksQuery = {
        Page: page,
        Size: size,
        SortType: '',
        RandomizeSeed: null,
        SearchQuery: {
          GenreId: genreId
        },
        PriorityTrackId: Common.TrackPlayer.IsPlaying ? Common.TrackPlayer.TrackId : null
      };

      fetchPost('/api/search/albumInfoTracks', albumInfoTracksQuery)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  GetMoreLikeThisOptions(trackId) {
    return new Promise((resolve, reject) => {
      fetchPost(`/api/search/moreLikeThis/options`, { trackId: parseInt(trackId) })
        .then(json => resolve(json))
        .catch(err => reject(err));
    })
  }

  GetMoreLikeThis(track, showMoreLikeThisQuery) {
    showMoreLikeThisQuery.PriorityTrackId = Common.TrackPlayer.IsPlaying ? Common.TrackPlayer.TrackId : null;

    return new Promise((resolve, reject) => {
      fetchPost(`/api/search/moreLikeThis`, showMoreLikeThisQuery)
        .then(json => resolve(json))
        .catch(err => reject(err));
    })
  }

  GetNextSearchResultsPage() {
    const searchResults = store.getState().Search.toJS().SearchResults;
    const lastSearch = searchResults.LastSearch;

    if (searchResults.CanLoadMore) {
      switch (searchResults.LastSearchType) {
        case "AdvancedSearch":
          this.AdvancedSearch(lastSearch.SearchQuery, searchResults.Page, searchResults.Size, false);
          break;
        case "BasicSearch":
          this.BasicSearch(lastSearch.SearchQuery, searchResults.Page, searchResults.Size, false);
          break;
        case "QuickSearch":
          this.QuickSearch(lastSearch.SearchQuery.SearchText, lastSearch.SearchQuery.Filters, searchResults.Page, searchResults.Size, false);
          break;
      }
    }
  }

  GetPreviousSearches() {
    return store.getState().Search.get('PreviousSearches');
  }

  GetQuickSearchSuggestions(searchText) {
    const content = encodeURIComponent(searchText);

    fetchGet(`/api/search/suggestions/${content}`)
      .then(json => {
        store.dispatch(setSuggestions(json));
      })
      .catch(err => {
        console.error('ERROR: ', err);
      });
  }

  SaveQuickSearchSuggestions(searchText, searchType) {
    const content = encodeURIComponent(searchText);

    fetchPost(`/api/search/saveSearchSuggestion`, {
      SearchText: searchText,
      UserId: '',
      SearchType: searchType
    }).then(json => {})
  }

  GetSearchResultsTrackList(options) {
    return new Promise((resolve, reject) => {

    })
  }

  LoadAlbumInfo(genreId) {
    return new Promise((resolve, reject) => {
      const loadAlbumInfoQuery = {
        Page: 0,
        Size: 30,
        SortType: '',
        RandomizeSeed: null,
        SearchQuery: {
          GenreId: genreId,
        }
      }

      fetchPost(`/api/search/albumInfo`, loadAlbumInfoQuery)
        .then(json => resolve(json))
        .catch(err => reject(err));
    })
  }

  /**
   * According to SUPERVISOR-144 We pasted old code ie before SUPRVISOR-14
   */
  LoadPreviousSearch() {
    let previousSearches = Common.GetState().PreviousSearches.pop();
    let lastSearch = previousSearches.last();

    if (lastSearch != null) {
      Common.UserInterface.SetAdvancedSearch(lastSearch.SearchQuery);
      Common.Search.AdvancedSearch(lastSearch.SearchQuery, lastSearch.Page, lastSearch.Size, true, true);
    }

    Common.Search.UpdatePreviousSearches(previousSearches.toJS());
  }

  QuickSearch(searchText) {
    // Common.UserInterface.ClearAdvancedSearch();
    const searchQuery = createEmptyAdvancedSearch();
    searchQuery.SearchText = searchText;

    Common.UserInterface.SetAdvancedSearch(searchQuery);
    Common.Search.AdvancedSearch(searchQuery, 0, Common.GetSearchSize(), true);
  }

  SetAltTracks(track, tracks) {
    store.dispatch(setAltTracks(track, tracks));
  }

  SetEditTracks(track, tracks) {
    store.dispatch(setEditTracks(track, tracks));
  }

  ShowAlbumInfo(genreId) {
    Common.Dialogs.ShowDialog('AlbumInfo', true);
    store.dispatch(loadAlbumInfo(genreId));
  }

  ShowMoreLikeThis(track, collectionId = null, genreId = null, moodId = null, tempoId = null, energyId = null, composerId = null, refine = false) {
    store.dispatch(showMoreLikeThis(track, collectionId, genreId, moodId, tempoId, energyId, composerId));
    Common.WebPlayer.stop();
    if (!refine) {
      Common.Dialogs.ShowDialog('MoreLikeThisDialog', true, {track});
    }
  }

  Sort(type, forceResort = false) {
    const searchResults = store.getState().Search.toJS().SearchResults;
    const lastSearch = searchResults.LastSearch;

    if (lastSearch == null) return new Promise((resolve, reject) => {
      resolve();
    })

    lastSearch.SearchQuery.SortType = type;
    if (lastSearch.SearchQuery.SortType == "random") {
      lastSearch.SearchQuery.RandomizeSeed = null;
    }
    ;

    switch (searchResults.LastSearchType) {
      case "AdvancedSearch":
        this.AdvancedSearch(lastSearch.SearchQuery, 0, 20, forceResort);
        return new Promise((resolve, reject) => {
          resolve();
        })
        break;
      case "BasicSearch":
        this.BasicSearch(lastSearch.SearchQuery, 0, 20, forceResort);
        return new Promise((resolve, reject) => {
          resolve();
        })
        break;
      case "QuickSearch":
        this.QuickSearch(lastSearch.SearchQuery.SearchText, lastSearch.SearchQuery.Filters, 0, 20, forceResort);
        return new Promise((resolve, reject) => {
          resolve();
        })
        break;
    }
  }

  UpdatePreviousSearches(searches) {
    store.dispatch(updatePreviousSearches(searches));
  }
}

export default Search;
