export const UI_CLEAR_ADVANCED_SEARCH = 'UI_CLEAR_ADVANCED_SEARCH';
export const UI_CLEAR_ALL_SELECTED_ITEMS = 'UI_CLEAR_ALL_SELECTED_ITEMS';
export const UI_CLEAR_ALL_PLAYLIST_SELECTED_ITEMS = 'UI_CLEAR_ALL_PLAYLIST_SELECTED_ITEMS';
export const UI_CLEAR_AUDITION_HISTORY = 'UI_CLEAR_AUDITION_HISTORY';
export const UI_CLEAR_BASIC_SEARCH = 'UI_CLEAR_BASIC_SEARCH';
export const UI_CLEAR_QUICK_SEARCH = 'UI_CLEAR_QUICK_SEARCH';
export const UI_CLEAR_PLAYLIST_FILTERS = 'UI_CLEAR_PLAYLIST_FILTERS';
export const UI_CLEAR_TOGGLED_SEARCH_TRACKS = 'UI_CLEAR_TOGGLED_SEARCH_TRACKS';
export const UI_SHOW_RESET_PASSWORD = 'UI_SHOW_RESET_PASSWORD';
export const UI_SET_ADVANCED_SEARCH = 'UI_SET_ADVANCED_SEARCH';
export const UI_SET_ADVANCED_SEARCH_FIELD_VALUE = 'UI_SET_ADVANCED_SEARCH_FIELD_VALUE';
export const UI_SET_AUDITIONED_STATUS = 'UI_SET_AUDITIONED_STATUS';
export const UI_SET_BASIC_SEARCH_FIELD_VALUE = 'UI_SET_BASIC_SEARCH_FIELD_VALUE';
export const UI_SET_PLAYLIST_FILTER_FIELD_VALUE = 'UI_SET_PLAYLIST_FILTER_FIELD_VALUE';
export const UI_SET_NAVIGATION_MENU = 'UI_SET_NAVIGATION_MENU';
export const UI_SET_QUICK_SEARCH_TEXT = 'UI_SET_QUICK_SEARCH_TEXT';
export const UI_SET_ADVANCED_SELECTED_CONTEXT_MENU_ITEM = 'UI_SET_ADVANCED_SELECTED_CONTEXT_MENU_ITEM';
export const UI_SET_APPLICATION_MESSAGES = 'UI_SET_APPLICATION_MESSAGES';
export const UI_SET_BASIC_SELECTED_CONTEXT_MENU_ITEM = 'UI_SET_BASIC_SELECTED_CONTEXT_MENU_ITEM';
export const UI_SET_PLAYLIST_FILTERS_SELECTED_CONTEXT_MENU_ITEM = 'UI_SET_PLAYLIST_FILTERS_SELECTED_CONTEXT_MENU_ITEM';
export const UI_SET_IS_LOADING = 'UI_SET_IS_LOADING';
export const UI_SET_IS_SEARCHING = 'UI_SET_IS_SEARCHING';
export const UI_SET_NO_RESULTS_FOUND_MESSAGE = 'UI_SET_NO_RESULTS_FOUND_MESSAGE';
export const UI_SET_SEARCH_FILTER = 'UI_SET_SEARCH_FILTER';
export const UI_SET_SELECTED_PLAYLIST_TAB = 'UI_SET_SELECTED_PLAYLIST_TAB';
export const UI_SET_SELECTED_PLAYLIST_TRACK = 'UI_SET_SELECTED_PLAYLIST_TRACK';
export const UI_SET_SELECTED_SEARCH_FILTERS = 'UI_SET_SELECTED_SEARCH_FILTERS';
export const UI_SET_SELECTED_SEARCH_TAB = 'UI_SET_SELECTED_SEARCH_TAB';
export const UI_SET_CONTEXT_MENU_DATA = 'UI_SET_CONTEXT_MENU_DATA';
export const UI_SET_PREFERENCES = 'UI_SET_PREFERENCES';
export const UI_SET_SHOW_SUGGESTIONS_DROP_DOWN = 'UI_SET_SHOW_SUGGESTIONS_DROP_DOWN';
export const UI_SET_SEARCH_SORT_TYPE = 'UI_SET_SEARCH_SORT_TYPE';
export const UI_SET_SHARED_FLAG = 'UI_SET_SHARED_FLAG';
export const UI_SET_USER_DETAILS = 'UI_SET_USER_DETAILS';
export const UI_SET_USER_PLAYLISTS = 'UI_SET_USER_PLAYLISTS';
export const UI_SET_USER_SHARED_PLAYLISTS = 'UI_SET_USER_SHARED_PLAYLISTS';
export const UI_SET_USER_MULTIPLE_PLAYLIST = 'UI_SET_USER_MULTIPLE_PLAYLIST';
export const UI_TOGGLE_BASIC_SEARCH_PANEL = 'UI_TOGGLE_BASIC_SEARCH_PANEL';
export const UI_TOGGLE_PLAYLIST_EXTENSION = 'UI_TOGGLE_PLAYLIST_EXTENSION';
export const UI_TOGGLE_PLAYLIST_FILTER_PANEL = 'UI_TOGGLE_PLAYLIST_FILTER_PANEL';
export const UI_TOGGLE_PLAYLIST_TRACK = 'UI_TOGGLE_PLAYLIST_TRACK';
export const UI_TOGGLE_SEARCH_RESULTS_TRACK = 'UI_TOGGLE_SEARCH_RESULTS_TRACK';
export const UI_UPDATE_QUICK_SEARCH_PLACEHOLDER = 'UI_UPDATE_QUICK_SEARCH_PLACEHOLDER';
export const UI_SET_SHARED_PLAYLIST_COUNT = 'UI_SET_SHARED_PLAYLIST_COUNT'


/**
 *
 * @param clearQuickSearchText {boolean}
 * @returns {{type: string, payload: {clearQuickSearchText: boolean}}}
 */
export const clearAdvancedSearch = (clearQuickSearchText = true) => {
  return {
    type: UI_CLEAR_ADVANCED_SEARCH,
    payload: {clearQuickSearchText}
  }
}

export const clearAllSelectedItems = (type) => {
  return {
    type: UI_CLEAR_ALL_SELECTED_ITEMS,
    filterType: type
  }
}

export const clearAllPlaylistSelectedItems = (type) => {
  return {
    type: UI_CLEAR_ALL_PLAYLIST_SELECTED_ITEMS,
    filterType: type
  }
}

export const clearAuditionHistory = () => {
  return {
    type: UI_CLEAR_AUDITION_HISTORY
  }
}

export const clearBasicSearch = () => {
  return {
    type: UI_CLEAR_BASIC_SEARCH
  }
}

export const clearQuickSearch = () => {
  return {
    type: UI_CLEAR_QUICK_SEARCH
  }
}

export const clearPlaylistFilters = () => {
  return {
    type: UI_CLEAR_PLAYLIST_FILTERS
  }
}

export const clearToggledSearchTracks = () => {
  return {
    type: UI_CLEAR_TOGGLED_SEARCH_TRACKS
  }
}

export const resetPassword = (token) => {
  return {
    type: UI_SHOW_RESET_PASSWORD,
    token: token  
  }
}

export const setAdvancedSearch = (searchQuery) => {
  return {
    type: UI_SET_ADVANCED_SEARCH,
    searchQuery: searchQuery
  }
}

export const setAdvancedSearchFieldValue = (fieldType, fieldValue) => {
  return {
    type: UI_SET_ADVANCED_SEARCH_FIELD_VALUE,
    fieldType: fieldType,
    fieldValue: fieldValue
  }
}

export const setApplicationMessages = (messages) => {
  return {
    type: UI_SET_APPLICATION_MESSAGES,
    messages: messages
  }
}

export const setBasicSearchFieldValue = (fieldType, fieldValue) => {
  return {
    type: UI_SET_BASIC_SEARCH_FIELD_VALUE,
    fieldType: fieldType,
    fieldValue: fieldValue
  }
}

export const setPlaylistFiltersFieldValue = (fieldType, fieldValue) => {
  return {
    type: UI_SET_PLAYLIST_FILTER_FIELD_VALUE,
    fieldType: fieldType,
    fieldValue: fieldValue
  }
}

export const setContextData = (contextMenuType, menu) => {
  return {
    type: UI_SET_CONTEXT_MENU_DATA,
    contextMenuType: contextMenuType,
    menu: menu
  }
}

export const setNavigationMenu = (menu) => {
  return {
    type: UI_SET_NAVIGATION_MENU,
    menu: menu
  }
}

export const setPreferences = (preferences) => {
  return {
    type: UI_SET_PREFERENCES,
    preferences: preferences
  }
}

export const setQuickSearchText = (value) => {
  return {
    type: UI_SET_QUICK_SEARCH_TEXT,
    value: value
  }
}

export const setAdvancedSelectedContextMenuItem = (type, item, checked) => {
  return {
    type: UI_SET_ADVANCED_SELECTED_CONTEXT_MENU_ITEM,
    contextType: type,
    contextItem: item,
    isChecked: checked
  }
}

export const setAuditionedStatus = (track) => {
  return {
    type: UI_SET_AUDITIONED_STATUS,
    track: track
  }
}

export const setBasicSelectedContextMenuItem = (type, item, checked) => {
  return {
    type: UI_SET_BASIC_SELECTED_CONTEXT_MENU_ITEM,
    contextType: type,
    contextItem: item,
    isChecked: checked
  }
}

export const setPlaylistFiltersSelectedContextMenuItem = (type, item, checked) => {
  return {
    type: UI_SET_PLAYLIST_FILTERS_SELECTED_CONTEXT_MENU_ITEM,
    contextType: type,
    contextItem: item,
    isChecked: checked
  }
}

export const setIsLoading = (isLoading) => {
  return {
    type: UI_SET_IS_LOADING,
    isLoading: isLoading
  };
}

export const setIsSearching = (isSearching = false) => {
  return {
    type: UI_SET_IS_SEARCHING,
    isSearching: isSearching
  }
}

export const setSelectedPlaylistTab = (selectedTab) => {
  return {
    type: UI_SET_SELECTED_PLAYLIST_TAB,
    selectedTab: selectedTab
  };
}

export const setSelectedPlaylistTrack = (track) => {
  return {
    type: UI_SET_SELECTED_PLAYLIST_TRACK,
    track: track
  }
}

export const setSelectedSearchFilters = (filters) => {
  return {
    type: UI_SET_SELECTED_SEARCH_FILTERS,
    filters: filters
  };
}

export const setSearchFilter = (filter, resetFilters = false) => {
  return {
    type: UI_SET_SEARCH_FILTER,
    filter: {filter, resetFilters}
  }
}

export const setNoSearchResultsFoundMessage = (message) => {
  return {
    type: UI_SET_NO_RESULTS_FOUND_MESSAGE,
    message: message
  }
}

export const setSelectedSearchTab = (selectedTab) => {
  return {
    type: UI_SET_SELECTED_SEARCH_TAB,
    selectedTab: selectedTab
  };
}

export const setSearchSortType = (sortType) => {
  return {
    type: UI_SET_SEARCH_SORT_TYPE,
    sortType: sortType
  };
}

export const setSharedFlag = (sharedFlag = false) => {
  return {
    type: UI_SET_SHARED_FLAG,
    sharedFlag: sharedFlag
  }
}

export const setSharedPlaylistCount = (count = 0) => {
  return {
    type: UI_SET_SHARED_PLAYLIST_COUNT,
    sharedPlaylistCount: count
  }
}

export const setShowSuggestionsDropDown = (show) => {
  return {
    type: UI_SET_SHOW_SUGGESTIONS_DROP_DOWN,
    show: show
  }
}

export const toggleMultiplePlaylist = (show) => {
  return {
    type: UI_SET_USER_MULTIPLE_PLAYLIST,
    show: show
  }
}

export const setUserDetails = (userDetails) => {
  return {
    type: UI_SET_USER_DETAILS,
    userDetails: userDetails
  }
}

export const setUserPlaylists = (playlists) => {
  return {
    type: UI_SET_USER_PLAYLISTS,
    playlists: playlists
  }
}

export const setUserSharedPlaylists = (playlists) => {
  return {
    type: UI_SET_USER_SHARED_PLAYLISTS,
    playlists: playlists
  }
}

export const toggleBasicSearchPanel = (panelName, toggled) => {
  return {
    type: UI_TOGGLE_BASIC_SEARCH_PANEL,
    panelName: panelName,
    toggled: toggled
  };
}

export const togglePlaylistExtension = (show) => {
  return {
    type: UI_TOGGLE_PLAYLIST_EXTENSION,
    show: show
  }
}

export const togglePlaylistFilterPanel = (panelName, toggled) => {
  return {
    type: UI_TOGGLE_PLAYLIST_FILTER_PANEL,
    panelName: panelName,
    toggled: toggled
  };
}

export const togglePlaylistTrack = (track) => {
  return {
    type: UI_TOGGLE_PLAYLIST_TRACK,
    track: track
  }
}

export const toggleSearchResultsTrack = (track) => {
  return {
    type: UI_TOGGLE_SEARCH_RESULTS_TRACK,
    track: track
  }
}

export const updateQuickSearchPlaceholder = () => {
  return {
    type: UI_UPDATE_QUICK_SEARCH_PLACEHOLDER
  }
}