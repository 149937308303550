import React from 'react'

export class SharedPlaylistUserSelection extends React.Component {
  constructor() {
    super(...arguments)
      
    this.state = {
      filter: '',
      selectedUsers: []
    }
  }

  handleOnCheckChanged = (value, isChecked) => {
    let selectedUsers = [...this.state.selectedUsers]
    let selectedUserIndex = selectedUsers.findIndex(x => x.id == value.id)

    if (isChecked && selectedUserIndex === -1) {
      selectedUsers.push(value)
    }
    
    if (!isChecked && selectedUserIndex >= 0) {
      selectedUsers = selectedUsers.filter(x => x.id !== value.id)
    }

    this.setState({ selectedUsers });
    this.props.onSelectedUsersChanged(selectedUsers)
  }

  render() {
    const {
      userList,
      onSelectedUsersChanged = () => {}
    } = this.props

    const filteredUsers = () => {
      if (this.state.filter.trim() == '') return userList
      
      const searchRegexStr = '(.*)' + this.state.filter.split(' ').map(s => `(${s})`).join('(.*?)') + '(.*)';
      const searchRegex = new RegExp(searchRegexStr, 'i');            
      return userList.filter(x => searchRegex.test(x.text))
    }
  

    return (
      <div className='duh'>
        <div className='user-option-filter'>
          <div className='user-option-filter-label'>Search names: </div>
          <div className='user-option-filter-entry'><input className='user-option-filter-input' type='text' value={this.state.filter} onChange={(e) => this.setState({...this.state, ...{ filter: e.target.value}})} /></div>
        </div>
        <div className='user-option-list'>
          {filteredUsers().map(x => (<UserOption option={x} isChecked={this.state.selectedUsers.find(y => y.id === x.id) !== undefined} onCheckChanged={this.handleOnCheckChanged.bind(this)} />))}
        </div>
      </div>
    )
  }
}

class UserOption extends React.Component {
  constructor() {
    super(...arguments)
  }

  render() {
    const {
      option,
      isChecked = false,
      onCheckChanged = () => {}
    } = this.props

    const handleOnClick = () => {
      onCheckChanged(option, !isChecked)
    }

    const checkmarkIcon = isChecked
      ? <svg class="checkmark-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckBoxOutlinedIcon"><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 16H5V5h14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"></path></svg>
      : <svg class="checkmark-empty-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckBoxOutlineBlankOutlinedIcon"><path d="M19 5v14H5V5zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2"></path></svg>

    return (
      <div key={option.id} className='user-option' data-selected={isChecked} onClick={handleOnClick}>        
        <span className='user-option-icon'>{checkmarkIcon}</span>
        <span className='user-option-label'>{option.text}</span>
      </div>
    )
  }
}
