import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Flex } from 'components/ui';

const dialogName = 'ProgressPlaylist';

class ProgressDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            min: 0,
            max: 100,
            value: 43
        }
    }

    get onClose() {
        const { onClose = () => {} } = this.props;
        return onClose;
    }

    render() {
        return (
            <Dialog className='progress-dialog' title='Duplicate Playlist' name='ProgressPlaylist' width={400} height={200} modal={true} hideTitlebar={true} closeText=''>
                <Flex>
                    <div className='progress-dialog-message'></div>
                    <div>
                        <progress value={this.state.value} max={this.state.max}></progress>
                    </div>
                </Flex>
                <Flex fixed={true} height={40}>
                    Footer
                </Flex>
            </Dialog>
        )
    }
}

export default connect(state => {
    return {
        UserInterface: state.UserInterface
    }
})(ProgressDialog);