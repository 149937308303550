import path from 'path';
import AifAudioParser from './AifAudioParser';

let isPlaying = false;
let currentProgress = 0;
let currentLoaded = 0;

const AudioEvents = {
  eventNotifications: [],

  onProgress: (track, event) => {
    if (!track) return;

    if (event.target.buffered.length > 0) {
      currentLoaded = parseFloat(event.target.buffered.end(0) / event.target.duration);
      AudioEvents.sendEventNotification(track.Id, {
        eventType: 'progress',
        event: event,
        percentage: currentLoaded
      })
    }
  },

  onDurationChange: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'durationchange',
      event: event
    })
  },

  onLoadedMetadata: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'loadedmetadata',
      event: event
    })
  },

  onLoadStart: (track, event) => {
    isPlaying = false;
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'loadstart',
      event: event
    })
  },

  onLoaded: (track, event) => {
    isPlaying = false;
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'loaded',
      event: event
    })
  },

  onCanPlay: (track, event) => {
    isPlaying = false;
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'canplay',
      event: event
    })
  },

  onCanPlayThrough: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'canplaythrough',
      event: event
    })
  },

  onAbort: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'abort',
      event: event
    })
  },

  onEmptied: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'emptied',
      event: event
    })
  },

  onEnded: (track, event) => {
    isPlaying = false;
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'ended',
      event: event
    })
  },

  onError: (track, event) => {
    isPlaying = false;
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'error',
      event: event
    })
  },

  onPause: (track, event) => {
    isPlaying = false;
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'paused',
      event: event
    })
  },

  onPlay: (track, event) => {
    isPlaying = true;
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'play',
      event: event
    })
  },

  onPlaying: (track, event) => {
    isPlaying = true;
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'playing',
      event: event
    })
  },

  onRateChange: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'ratechange',
      event: event
    })
  },

  onReset: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'reset',
      event: event
    })
  },

  onSeeked: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'seeked',
      event: event
    })
  },

  onSeeking: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'seeking',
      event: event
    })
  },

  onStalled: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'stalled',
      event: event
    })
  },

  onSuspend: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'suspend',
      event: event
    })
  },

  onTimeUpdate: (track, event) => {
    isPlaying = true;

    if (!track) return;

    currentProgress = parseFloat(event.target.currentTime / event.target.duration);
    currentLoaded = parseFloat((event.target.buffered.length > 0 ? event.target.buffered.end(0) : 0) / event.target.duration);

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'timeupdate',
      current: event.target.currentTime,
      percentage: currentProgress, //parseFloat(event.target.currentTime / event.target.duration),
      loaded: currentLoaded, //parseFloat((event.target.buffered.length > 0 ? event.target.buffered.end(0) : 0) / event.target.duration),
      total: event.target.duration
    })
  },

  onVolumeChange: (track,event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'volumechange',
      event: event
    })
  },

  onWaiting: (track, event) => {
    if (!track) return;

    AudioEvents.sendEventNotification(track.Id, {
      eventType: 'waiting',
      event: event
    })
  },

  registerForEvent: (trackId, target) => {
    const eventNotification = AudioEvents.eventNotifications.find(en => en.id === trackId)

    if (eventNotification != null) {
      if (eventNotification.targets.find(t => t === target) == null) {
        eventNotification.targets.push(target);
      }
    } else {
      AudioEvents.eventNotifications.push({ id: trackId, targets: [ target ] });
    }
  },

  sendEventNotification: (trackId, event) => {
    if (AudioEvents.eventNotifications.length > 0) {
      const eventNotification = AudioEvents.eventNotifications.find(en => en.id === trackId);

      if (eventNotification) {
        eventNotification.targets.forEach(targetEvent => targetEvent(event))
      }
    }
  },

  unregisterForEvent: (trackId, target) => {
    const eventToNotify = AudioEvents.eventNotifications.find(en => en.id === trackId)

    if (eventToNotify != null) {
      const targetIndex = eventToNotify.targets.findIndex(t => t === target);

      if (targetIndex >= 0) {
        eventToNotify.targets.splice(eventToNotify.targets.findIndex(t => t === target), 1);
      }
    }
  }
}

class TrackAudioPlayer {
  constructor() {
    const _this = this;

    this.blobUrl = null;

    this.track = null;
    this.aifAudio = new Audio();
    this.aifAudio.addEventListener('loadstart',      (e) => AudioEvents.onLoadStart(this.track, e));
    this.aifAudio.addEventListener('durationchange', (e) => AudioEvents.onDurationChange(this.track, e));
    this.aifAudio.addEventListener('loadedmetadata', (e) => AudioEvents.onLoadedMetadata(this.track, e));
    this.aifAudio.addEventListener('loadeddata',     (e) => AudioEvents.onLoaded(this.track, e));
    this.aifAudio.addEventListener('progress',       (e) => AudioEvents.onProgress(this.track, e));
    this.aifAudio.addEventListener('canplaythrough', (e) => AudioEvents.onCanPlayThrough(this.track, e));
    this.aifAudio.addEventListener('canplay',        (e) => AudioEvents.onCanPlay(this.track, e));
    this.aifAudio.addEventListener('abort',          (e) => AudioEvents.onAbort(this.track, e));
    this.aifAudio.addEventListener('emptied',        (e) => AudioEvents.onEmptied(this.track, e));
    this.aifAudio.addEventListener('ended',          (e) => AudioEvents.onEnded(this.track, e));
    this.aifAudio.addEventListener('error',          (e) => AudioEvents.onError(this.track, e));
    this.aifAudio.addEventListener('pause',          (e) => AudioEvents.onPause(this.track, e));
    this.aifAudio.addEventListener('play',           (e) => AudioEvents.onPlay(this.track, e));
    this.aifAudio.addEventListener('playing',        (e) => AudioEvents.onPlaying(this.track, e));
    this.aifAudio.addEventListener('ratechange',     (e) => AudioEvents.onRateChange(this.track, e));
    this.aifAudio.addEventListener('seeked',         (e) => AudioEvents.onSeeked(this.track, e));
    this.aifAudio.addEventListener('seeking',        (e) => AudioEvents.onSeeking(this.track, e));
    this.aifAudio.addEventListener('stalled',        (e) => AudioEvents.onStalled(this.track, e));
    this.aifAudio.addEventListener('suspend',        (e) => AudioEvents.onSuspend(this.track, e));
    this.aifAudio.addEventListener('timeupdate',     (e) => AudioEvents.onTimeUpdate(this.track, e));
    this.aifAudio.addEventListener('volumechange',   (e) => AudioEvents.onVolumeChange(this.track, e));
    this.aifAudio.addEventListener('waiting',        (e) => AudioEvents.onWaiting(this.track, e));

    this.wavAudio = new Audio();
    this.wavAudio.addEventListener('loadstart',      (e) => AudioEvents.onLoadStart(this.track, e));
    this.wavAudio.addEventListener('durationchange', (e) => AudioEvents.onDurationChange(this.track, e));
    this.wavAudio.addEventListener('loadedmetadata', (e) => AudioEvents.onLoadedMetadata(this.track, e));
    this.wavAudio.addEventListener('loadeddata',     (e) => AudioEvents.onLoaded(this.track, e));
    this.wavAudio.addEventListener('progress',       (e) => AudioEvents.onProgress(this.track, e));
    this.wavAudio.addEventListener('canplaythrough', (e) => AudioEvents.onCanPlayThrough(this.track, e));
    this.wavAudio.addEventListener('canplay',        (e) => AudioEvents.onCanPlay(this.track, e));
    this.wavAudio.addEventListener('abort',          (e) => AudioEvents.onAbort(this.track, e));
    this.wavAudio.addEventListener('emptied',        (e) => AudioEvents.onEmptied(this.track, e));
    this.wavAudio.addEventListener('ended',          (e) => AudioEvents.onEnded(this.track, e));
    this.wavAudio.addEventListener('error',          (e) => AudioEvents.onError(this.track, e));
    this.wavAudio.addEventListener('pause',          (e) => AudioEvents.onPause(this.track, e));
    this.wavAudio.addEventListener('play',           (e) => AudioEvents.onPlay(this.track, e));
    this.wavAudio.addEventListener('playing',        (e) => AudioEvents.onPlaying(this.track, e));
    this.wavAudio.addEventListener('ratechange',     (e) => AudioEvents.onRateChange(this.track, e));
    this.wavAudio.addEventListener('seeked',         (e) => AudioEvents.onSeeked(this.track, e));
    this.wavAudio.addEventListener('seeking',        (e) => AudioEvents.onSeeking(this.track, e));
    this.wavAudio.addEventListener('stalled',        (e) => AudioEvents.onStalled(this.track, e));
    this.wavAudio.addEventListener('suspend',        (e) => AudioEvents.onSuspend(this.track, e));
    this.wavAudio.addEventListener('timeupdate',     (e) => AudioEvents.onTimeUpdate(this.track, e));
    this.wavAudio.addEventListener('volumechange',   (e) => AudioEvents.onVolumeChange(this.track, e));
    this.wavAudio.addEventListener('waiting',        (e) => AudioEvents.onWaiting(this.track, e));
  }

  get audio() {
    var url = this.track ? Common.GetPreviewUrl(this.track).toLowerCase() : '';

    if (url.endsWith('.aif') || url.endsWith('.aiff')) {
      return this.aifAudio;
    } else {
      return this.wavAudio;
    }
  }

  get IsPlaying() {
    return isPlaying;
  }

  get Loaded() {
    return currentLoaded;
  }

  get Progress() {
    return currentProgress;
  }

  get TrackId() {
    if (this.track) {
      if (this.track.id) return this.track.id;
      if (this.track.Id) return this.track.Id;
    }
    return null;
  }

  FastForward(seconds = 5) {
    if (this.audio.duration > 0) {
      this.audio.currentTime = this.audio.currentTime + seconds;
    }
  }

  LoadTrack(track, position=0) {
    if (track && track !== this.track) {
      if(this.IsPlaying)
        this.audio.pause();

      AudioEvents.onEnded(this.track || { Id: 0 }, { target: this.audio });
      AudioEvents.onReset(this.track || { Id: 0 }, { target: this.autio });

      this.Load(track)
        .then(() => {
          if (position > 0) {
            this.audio.currentTime = this.audio.duration * position;
          }
          //this.audio.play();
        })
        .catch(err => {
          Common.Dialogs.ShowAlertMessage({
            contentClass: 'forgot-password-success',
            title: 'Playback Error',
            message: err.message,
            width: 500,
            height: 200
          });
        });
    } else {
      if (position > 0) {
        this.audio.currentTime = this.audio.duration * position;
      }
    }
  }

  Load(track) {
    if (track && track !== this.track) {
      this.audio.src = '';
      this.audio.currentTime = -1;
    }

    return new Promise((resolve, reject) => {
      this.track = track;
      this.audio.prefetch = true;
      if (track.Id !== 0) {
        var trackUrl = Common.GetPreviewUrl(track);

        switch (path.extname(trackUrl).toLowerCase()) {
          case ".aif":
          case ".aiff":
            const audioData = new AifAudioParser(trackUrl);
            const blob = new Blob([audioData.audioBuffer], { type: 'audio/wav' });

            if (this.blobUrl != null) {
              window.URL.revokeObjectURL(this.blobUrl);
            }

            this.blobUrl = window.URL.createObjectURL(blob);
            this.audio.src = this.blobUrl;
            break;
          default:
            this.audio.src = trackUrl;
        }
      }
      else {
        this.audio.src = track.FullTitle.replace(/[\+]/g,"%2B").replace(/[#]/g,"%23");
      }

      const notifyOnLoadedMetadata = () => {
        setTimeout(() => { this.audio.removeEventListener('loadedmetadata', notifyOnLoadedMetadata) }, 1);
        resolve();
      }

      this.audio.addEventListener('loadedmetadata', notifyOnLoadedMetadata);
    })
  }

  Play(track, position=0) {    
    if (!track) return;
    
    const data = 
    {
      "TrackId": track.Id,
      "PlayedCount": 1,
      "DownloadCount": 0
    }
    Common.UserInterface.UpdateTrackStatistics(data);

    if (track && track !== this.track) {
      if(this.IsPlaying) {
        this.audio.pause();
      }

      AudioEvents.onEnded(this.track || { Id: 0 }, { target: this.audio });
      AudioEvents.onReset(this.track || { Id: 0 }, { target: this.autio });

      this.Load(track)
        .then(() => {
          if (position > 0) {
            this.audio.currentTime = this.audio.duration * position;
          }
          this.audio.play();
        })
        .catch(err => {
          Common.Dialogs.ShowAlertMessage({
            contentClass: 'forgot-password-success',
            title: 'Playback Error',
            message: err.message,
            width: 500,
            height: 200
          });
        });
    } else {
      if (position > 0) {
        this.audio.currentTime = this.audio.duration * position;
      }

      this.audio.play();
    }
  }

  Pause() {
    if (this.audio.duration > 0 && !this.audio.paused) {
      this.audio.pause();
    }
  }

  RegisterTrackNotifications(trackId, target){
    AudioEvents.registerForEvent(trackId, target);
  }

  Rewind(seconds = 5) {
    if (this.audio.duration > 0) {
      this.audio.currentTime = this.audio.currentTime - seconds;
    }
  }

  Seek(position, autoPlay=true) {
    if (this.audio.duration > 0) {
      this.audio.currentTime = (this._audioPlayer.duration * position);

      if (autoPlay)
        this.audio.play();
    }
  }

  SetVolume(volume) {
    if (this.audio) {
      this.audio.volume = volume;
    }
  }

  Stop(track) {
    if (track && this.track && !(track.Id == this.track.Id)) {
      return;
    }

    if (this.audio.duration > 0) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio.src = '';
      AudioEvents.onEnded(this.track || { Id: 0 }, { target: this.audio });
    }

    this.track = track;
  }

  UnregisterTrackNotifications(trackId, target) {
    AudioEvents.unregisterForEvent(trackId, target);
  }
}

export { TrackAudioPlayer }