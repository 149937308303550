export const DIALOGS_SHOW_DIALOG = 'DIALOGS_SHOW_DIALOG';
export const DIALOGS_LOGIN_USER = 'DIALOGS_LOGIN_USER';

export const showDialog = (name, show, options, message, error) => {
    return {
        type: DIALOGS_SHOW_DIALOG,
        name: name,
        show: show,
        options: options,
        message: message,
        error: error
    }
}