import { fetchPost } from 'utility/fetchHelpers';

class Helpers {
    constructor() {
    }

    LogError(message, logType, error) {        
        var message = error ? JSON.stringify(error) : message; //message || JSON.stringify(error);
        fetchPost('/api/error/log', { source: "The Supervisor - Web", logType: logType, errorMessage: message });
    }

    GetPercentage(value) {
        if (value === null) return null;
        if (typeof(value) == 'number') return value + 'px';

        if (value.indexOf('/') > -1) {
            const f = value.split('/');
            return ((f[0] / f[1]) * 100) + '%'
        }

        return value;
    }

    FormatDuration(timeInSeconds) {
        var h = parseInt(timeInSeconds / 3600);
        var m = parseInt(timeInSeconds / 60);
        var s = parseInt(timeInSeconds % 60);

        return  (h > 0 ? h.padLeft(2) + ':' : '') +
                (m > 0 ? m.padLeft(1) + ':' : '0:') +
                (s.padLeft(2));;
    }
}

export default Helpers;
