import { List, Map } from 'immutable'
import * as Actions from './actions'

const createEmptySharePlaylist = () => {
    return {
        Id: null,
        Title: '',
        Tracks: []
    }
}

/**
 * Initial reducer state
 */
export const initialSharePlaylistState = Map(createEmptySharePlaylist());

/**
 * Home view reducer
 */
export const sharePlaylistReducer = {
    [Actions.PLAYLIST_SET_SHARE_PLAYLIST]: (state, action) => {
        var newState = Object.assign({}, createEmptySharePlaylist(), action.sharePlaylist);
        return Map(newState);
    },
}
