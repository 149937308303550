import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Style from './flex.scss';

// TODO: Need to be able to globably set default parameters for the options
window.flexComponent = {
  options: {
    block: false,
    inline: false,
    fill: false,
    fixed: false,
    row: false,
    position: 'center',
    width: null,
    height: null,
  }
}

// ========== Get Percentage ===========
const getPercentage = (value) => {
  if (value === null) return null;
  if (typeof(value) === 'number') return value + 'px';

  if (value.indexOf('/') > -1) {
    const f = value.split('/');
    return ((f[0] / f[1]) * 100) + '%'
  }

  return value;
}

// =========== Flex Component ===========
export class Flex extends React.Component {
  static propTypes = {
    setRefCallBack: PropTypes.func
  }

  constructor() {
    super(...arguments);
  }

  tryCallSetRefCallback  = e => {
    const {setRefCallBack} = this.props;
    if (setRefCallBack)
      setRefCallBack(e);
  }

  render() {
    const {
      block = window.flexComponent.options.block,
      inline = window.flexComponent.options.inline,
      position = window.flexComponent.options.position,
      fill = window.flexComponent.options.fill,
      fixed = window.flexComponent.options.fixed,
      hide = false,
      wrap = false,
      row = window.flexComponent.options.row,
      width = window.flexComponent.options.width,
      height = window.flexComponent.options.height,
      children = null,
      style = {},
      className = null,
      onClick = () => {
      },
      onMouseEnter = () => {
      },
      onMouseLeave = () => {
      },
      onMouseOver = () => {
      }
    } = this.props;

    const componentStyle = Object.assign({}, style, {
      width: getPercentage(width) || style.width,
      height: getPercentage(height) || style.height
    });

    const componentClass = classNames({
      'flex': true,
      'fill': fill,
      'fixed': (fixed || (height != null || style.height != null) || (width != null || style.width != null)),
      'row': row,
      'block': block,
      'inline': inline,
      'hide': hide,
      'wrap': wrap,
      'flex-tl': (position && position.in(['top-left', 'upper-left', 'tl', 'ul'], false)),
      'flex-t': (position && position.in(['top', 'upper', 't'], false)),
      'flex-tr': (position && position.in(['top-right', 'upper-right', 'tr', 'ur'], false)),
      'flex-c': (position && position.in(['center', 'middle', 'c'], false)),
      'flex-l': (position && position.in(['left', 'l'], false)),
      'flex-r': (position && position.in(['right', 'r'], false)),
      'flex-bl': (position && position.in(['bottom-left', 'lower-left', 'll', 'bl'], false)),
      'flex-b': (position && position.in(['bottom', 'lower', 'l', 'b'], false)),
      'flex-br': (position && position.in(['bottom-right', 'lower-right', 'lr', 'br'], false)),
    }, className);

    return (
      <div className={componentClass} style={componentStyle} onClick={onClick} ref={this.tryCallSetRefCallback}
           onMouseEnter={onMouseEnter.bind(this)} onMouseLeave={onMouseLeave.bind(this)}
           onMouseOver={onMouseOver.bind(this)}>
        {children}
      </div>
    )
  }
}
