import React from 'react';
import classNames from 'classnames';
import Style from './tag-filter.scss';

export class TagFilter extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        const {
            label = '',
            type = '',
            item = {},
            width = null,
            height = null,
            children = null,
            style = {},
            className = null,
            onClick = () => {}
        } = this.props;

        const componentStyle = Object.assign({}, style, {
            width: width,
            height: height
        });

        const componentClass = classNames({
            'tag': true,
            'text': type.equalsIgnoreCase('text')
        }, className)

        return (
            <div className={componentClass} style={componentStyle} onClick={onClick.bind(this, item)}>                
                <span className='name'>{label}</span>
                <span className='icon'></span>
            </div>
        )
    }
}