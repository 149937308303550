import { List, Map } from 'immutable'
import * as Actions from './actions'


/**
 * Initial reducer state
 */
export const initialDialogsState = Map({
    ContactUs: false,
    CueSheet: false,
    HowTo: false,
    Login: false,
    CreatePlaylist: false,
    ImportCueSheet: false,
    NewCueSheet: false,
    OpenCueSheet: false,
    PrintCueSheet: false,
    SaveCueSheetAs: false,
    SignUp: false,
    TrackHider: false,
    MultiplePlaylist: false,
    Privacy: false,
    Options: Map({
        MoreLikeThis: Map()
    })
});


/**
 * Home reducer
 */
export const dialogsReducer = {
    [Actions.DIALOGS_SHOW_DIALOG]: (state, action) => { 
        state = state.setIn([action.name], action.show);

        if (action.options != null && action.options != undefined) {
            state = state.setIn(['Options', action.name], action.options);
        }
        //return state.setIn([action.name], action.show);
        return state;
    }
};
