import React from 'react';
import {connect} from 'react-redux';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import style from './album-info.scss';
import {List} from 'immutable';

import {TrackResults} from 'components/common/track-results/track-results';
import {Button, Dialog, Flex, FontIcon, Image, LoadingPanel, ScrollWindow, TextField, Typewriter} from 'components/ui';

import {getAlbumInfoTracks} from 'store/search/actions';

const dialogName = 'AlbumInfo';

const onKeyUp = (event) => {
  if (event.which === 27) {
    Common.Dialogs.ShowDialog(dialogName, false);
  }
}

// export default class AlbumInfo extends React.Component {
class AlbumInfo extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      isLoading: true,
      isLoadingMore: false,
      page: 0,
      size: 30,
      lastQuery: {},
      tracks: List(),
      currentRow: 0,
      totalCount: 0
    };
  }

  get onClose() {
    const {
      onClose = () => {
      }
    } = this.props;

    return onClose;
  }

  get GenreId() {
    return this.Search.AlbumInfo ? this.Search.AlbumInfo.GenreId : null;
  }

  get Search() {
    return this.props.Search ? this.props.Search.toJS() : {};
  }

  get PageRow() {
    return this.TrackResults
      ? !isNaN(this.TrackResults.ScrollableList.List.Grid._renderedRowStopIndex) ? this.TrackResults.ScrollableList.List.Grid._renderedRowStopIndex + 1 : 0
      : 0;
  }

  componentDidMount() {
    this.setState({currentRow: this.PageRow});
    document.addEventListener('keyup', onKeyUp);
    this.handleLoadTracks(this.GenreId, this.state.page, this.state.size);
  }

  componentWillUnmount() {
    if (Common.TrackPlayer.AudioSource === null) {
      Common.TrackPlayer.Stop();
    }

    document.removeEventListener('keyup', onKeyUp);
  }

  updateComponent() {
    this.TrackResults.ScrollableList.List.forceUpdateGrid();
    this.forceUpdate();
  }

  handleLoadTracks(genreId, page, size) {
    this.setState({isLoading: true});
    Common.Search.GetAlbumInfoTracks(genreId, page, size)
      .then(results => {
        const tracks = List(results.Tracks);

        this.setState({
          tracks: tracks,
          isLoading: false,
          page: results.Page,
          size: results.Size,
          totalCount: results.Total
        })
        setTimeout(() => {
          this.updateComponent();
          this.setState({currentRow: this.PageRow})
        }, 20);
      })
      .catch(error => {
        console.error('There was an error: ', error);
      })
  }

  handleLoadMoreTracks(genreId, page, size) {
    this.setState({isLoadingMore: true});
    Common.Search.GetAlbumInfoTracks(genreId, page, size)
      .then(results => {
        const tracks = this.state.tracks.concat(results.Tracks);
        this.setState({
          tracks: tracks,
          isLoading: false,
          isLoadingMore: false,
          page: results.Page,
          size: results.Size,
          totalCount: results.Total
        })
        setTimeout(() => {
          this.updateComponent();
        }, 20);
      })
      .catch(error => {
        console.error('There was an error: ', error);
      })
  }

  handleDownloadTrack(track, event) {
    const rect = event.target.getBoundingClientRect();
    Common.UserInterface.ShowDownloadTrack(track, rect);
  }

  handleKeyUp(event) {
    if (event.which === 13) {
      this.handleOK();
    }
  }

  handleCancel() {
    Common.Dialogs.ShowDialog(dialogName, false);
    this.onClose();
  }

  handleLoadAlbumInfo(genreId) {
    if (Common.TrackPlayer.IsPlaying)
      Common.TrackPlayer.Stop();

    Common.Search.ShowAlbumInfo(genreId);
    this.setState({isLoading: true, tracks: List(), page: 0, size: this.state.size});
    this.handleLoadTracks(genreId, 0, this.state.size);
  }

  handleScrollFrame(values) {
    this.setState({currentRow: this.PageRow});
    if (values.top > 0.75 && !this.state.isLoadingMore) {
      if ((this.state.page * this.state.size) < this.state.totalCount) {
        this.handleLoadMoreTracks(this.GenreId, this.state.page, this.state.size);
      }
    }
  }

  onAddAllToPlaylistClick = () => {
    Common.Playlist.AddTrackList(this.state.tracks.toJS());
  }

  render() {
    const {
      onClose = () => {
      }
    } = this.props;

    const title = (<span className='album-info-title'>Genre: <span
      style={{textTransform: 'none'}}>{this.Search.AlbumInfo.AlbumName}</span></span>)
    const coverArt = !this.Search.AlbumInfo.IsLoading ? (
      <Image url={this.Search.AlbumInfo.AlbumCoverArt} width={210} height={210}
             missingImageUrl={Common.GetSlugImageUrl(1)}/>) : '';
    const loadingBar = this.isLoadingMore ? (<div className='album-info-loading-bar'/>) : '';

    const resultsList = !this.state.isLoading
      ? (
        <TrackResults
          ref={(i) => (this.TrackResults = i)}
          tracks={this.state.tracks.toJS()}
          hideDrawerLinks={true}
          hideMoreLikeThis={true}
          hideFilterLinks={true}
          hideTrackInfoLinks={true}
          keepToggleIndex={true}
          onScroll={this.handleScrollFrame.bind(this)}/>
      )
      : (<LoadingPanel width={300} height={300}/>)

    const similarGenreCovers = this.Search.AlbumInfo.SimilarAlbums.map((sa, idx) => {
      return (<Image key={idx} url={sa.GenreCoverArt} missingImageUrl={Common.GetSlugImageUrl(0)}
                     onClick={this.handleLoadAlbumInfo.bind(this, sa.GenreId)} width={70} height={70}/>)
    });

    const albumTitle = this.Search.AlbumInfo.AlbumName
      ? this.Search.AlbumInfo.AlbumName + ':'
      : '';

    const trackCountElement = this.state.totalCount > 0
      ? (<div>{this.state.currentRow} / {this.state.totalCount} Found Tracks</div>)
      : ''

    return (
      <Dialog title={title} name='AlbumInfo' width={1200} height={800} modal={true} hideTitleIcon={true} closeText=''
              onClose={this.handleCancel.bind(this)} onEscape={this.handleCancel.bind(this)}>
        <Flex className='album-info' position='top'>
          <Flex row fixed className='header' position='top'>
            <Flex width={210}>
              {coverArt}
            </Flex>
            <Flex className='album-description' position='top-left'>
              <Flex position='left' height={40} className='album-description-title'>{albumTitle}</Flex>
              <Flex row position='left'>
                <FontIcon name='inspector' size={42}/>
                <span className='album-description-text'>{this.Search.AlbumInfo.AlbumDescription}</span>
              </Flex>
              <Flex height={40}/>
            </Flex>
            <Flex className='album-similar-genres' position='left' width={340}>
              <span>SIMILAR GENRES:</span>
              <div className='album-similar-genre-covers'>
                <ScrollWindow className='scrollable-control show-y-scrollbar'>
                  <div>
                    {similarGenreCovers}
                  </div>
                </ScrollWindow>
              </div>
            </Flex>
          </Flex>
          <Flex className='content'>
            {resultsList}
          </Flex>
          <Flex row fixed className='footer' position='bottom-right'>
            <Flex className='album-info-buttons'>
              <Button className='add-to-playlist-button' height={20} type='default' onClick={this.onAddAllToPlaylistClick}>
                <Flex row><FontIcon name='circle-plus'/> &nbsp; Add All to Playlist</Flex>
              </Button>
            </Flex>
            <Flex className='album-info-track-count'> {trackCountElement} </Flex>
          </Flex>
        </Flex>
      </Dialog>
    )
  }
}

export default connect(state => {
  return {
    Search: state.Search
  }
})(AlbumInfo)
