import React from 'react';
import classNames from 'classnames';
import Style from './loading-panel.scss';

export class LoadingPanel extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        const {
            width = null,
            height = null
        } = this.props;

        const componentStyle = {
            width: width,
            height: height
        }

        return (
            <div className='loading-panel2 flex-center'>
                <div className='working-head' style={componentStyle} />
            </div>           
        )
    }
}