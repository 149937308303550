import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactDOM from 'react-dom'
import style from './drop-down.scss';

import {Flex, FontIcon, ScrollWindow} from 'components/ui';

export class DropDown extends React.Component {
  static propTypes = {
    idPropName: PropTypes.string,
    textPropName: PropTypes.string
  }

  static defaultProps = {
    idPropName: 'id',
    textPropName: 'text'
  }

  constructor() {
    super(...arguments);
    this.dropDownComponent = null;
    this.dropDownList = null;
    this.state = {
      value: null
    };
  }

  componentDidMount() {
    this.setState({value: this.props.value});
  }

  componentWillReceiveProps(props) {
    this.setState({value: props.value});
  }

  handleClose() {
    const dropDownList = document.getElementById('dropDownListModal');
    ReactDOM.unmountComponentAtNode(dropDownList);
    document.body.removeChild(dropDownList);
  }

  handleSelect(item) {
    const {
      onSelect = () => {
      }
    } = this.props;

    onSelect(item);
    this.setState({value: item});
  }

  handleDropDownList() {
    const {
      disabled = false,
      height = null,
      size = 10
    } = this.props;

    if (disabled) return;

    const component = ReactDOM.findDOMNode(this.dropDownComponent);
    const newElement = document.createElement("div");
    newElement.id = "dropDownListModal";
    document.body.appendChild(newElement);

    const rect = component.getBoundingClientRect();
    ReactDOM.render(
      <DropDownList
        left={rect.left}
        top={rect.bottom}
        width={rect.width}
        height={height}
        size={size}
        textPropName={this.props.textPropName}
        onClose={this.handleClose.bind(this)}
        onSelect={this.handleSelect.bind(this)}
        items={this.props.items}/>, document.getElementById('dropDownListModal'));
  }

  render() {
    const {
      disabled = false,
      placeholder = '',
      width = null
    } = this.props;

    const dropDownClass = classNames({
      'drop-down': true,
      'disabled': disabled,
      'placeholder': this.state.value == null
    });

    const fieldText = this.state.value != null
      ? this.state.value[this.props.textPropName]
      : placeholder;

    return (
      <Flex className={dropDownClass} width={width}>
        <Flex row ref={(input) => {
          this.dropDownComponent = input
        }}
              className='current-value'
              width='100%' onClick={this.handleDropDownList.bind(this)}>
          <Flex className='value'>{fieldText}</Flex>
          <Flex width='20px' align='left' fixed>
            <FontIcon name='chevron-thin-down' size='9px'/>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

class DropDownList extends React.Component {
  constructor() {
    super(...arguments);
  }

  render() {
    const {
      left = 0,
      top = 0,
      size = 10,
      width = null,
      height = null,
      items = [],
      textPropName = 'text',
      onClose = () => {
      },
      onSelect = () => {
      }
    } = this.props;

    const dropDownListStyle = {
      left: left,
      top: top + 2,
      width: width,
    };

    if (height == null) {
      dropDownListStyle.height = items.length > size ? size * 30 : items.length * 30;
      dropDownListStyle.maxHeight = size * 30;
      dropDownListStyle.top = dropDownListStyle.top + dropDownListStyle.height <= window.innerHeight
        ? dropDownListStyle.top
        : window.innerHeight - dropDownListStyle.height - 5
    } else {
      dropDownListStyle.height = items.length > size ? size * 30 : items.length * 30;
      dropDownListStyle.maxHeight = height;
    }

    const content = items.map((item, index) => {
      return (<DropDownItem key={index} item={item} onSelect={onSelect.bind(this)} textPropName={textPropName}/>)
    });

    return (
      <Flex className='dropDownListContainer' onClick={onClose.bind(this, null)}>
        <div className='dropDownList' style={dropDownListStyle} width={width} ref={(input) => {
          this.dropDownList = input
        }}>
          <ScrollWindow>
            {content}
          </ScrollWindow>
        </div>
      </Flex>
    )
  }
}

const DropDownItem = ({item = {}, onSelect, textPropName='text'}) =>
  (<div className='item' onClick={() => onSelect(item)}>{item[textPropName]}</div>);

