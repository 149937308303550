import React from 'react';
import classNames from 'classnames';
import { Flex } from 'components/ui';
import Styles from './toggle-switch.scss';

export class Toggle extends React.Component {
    constructor() {
        super(...arguments);        
    }

    render() {
        const {
            label = '',
            labelColor = null,
            labelWidth = null,
            switchWidth = null,
            switchHeight = null,
            multiple = false,
            width = null,
            height = null,
            style = {},            
            className = null,
            children = null
        } = this.props;

        const componentClass = classNames({
            'toggle-switch': true,
            'multiple': multiple          
        }, className);

        const componentStyle = Object.assign({}, style, {
            width: width,
            height: height
        });

        const labelStyle = Object.assign({}, {
            color: labelColor,
            width: labelWidth
        });

        const switchWrapperStyle = Object.assign({}, {
            width: switchWidth,
            height: switchHeight
        });

        return (
            <Flex row className={componentClass} style={componentStyle} position='left'>
                <Flex position='left' className='label' style={labelStyle}>{label}</Flex>
                <Flex row className='wrapper' style={switchWrapperStyle}>
                    {children}
                </Flex>
            </Flex>
        )
    }
}

export class Switch extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        const {
            label = 'Switch',
            value = null,
            selected = false,
            style = {},
            className = null,
            onClick = () => {}
        } = this.props;

        const componentClass = classNames({
            'vms-switch': true,
            selected: selected
        }, className);

        const componentStyle = Object.assign({}, style);

        return (
            <Flex className={componentClass} style={componentStyle} onClick={onClick.bind(this, value)}>{label}</Flex>
        )
    }
}