// TODO: Need to move this to its own component
import React from "react";
import classNames from "classnames";
import PropTypes from 'prop-types';
import './suggestionsDropDown.scss';

import FilterLink from "./filterLink";
import {Button, Flex, FontIcon} from "components/ui";
import {getTranslation} from 'utility/common';

export class SuggestionsDropDown extends React.Component {
  static propTypes = {
    searchText: PropTypes.string.isRequired
  }

  constructor() {
    super(...arguments);

    this.state = {
      showBottomIndicator: false,
      height: 'auto'
    }

    this.contentRef = null;
  }

  handleSelectFilter(filterType, filterValue) {
    filterValue.FilterType = filterType;
    // Common.UserInterface.ClearAllSelectedItems(null); // Commenting this out prevents the search filters from being set back to NULL
    Common.UserInterface.SetQuickSearchText(filterValue.Name);
    Common.UserInterface.AddSearchFilter(filterValue);
  }

  hasSuggestions() {
    const suggestions = this.props.suggestions;

    return (
      (suggestions.Collections || []).length > 0 ||
      (suggestions.Genres || []).length > 0 ||
      (suggestions.Moods || []).length > 0 ||
      (suggestions.LooksLike || []).length > 0 ||
      (suggestions.SoundsLike || []).length > 0
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchText !== this.props.searchText) {
      this.calculateHeight(true);
      setTimeout(() => this.calculateHeight(), 500);
    }
  }

  componentDidMount() {
    this.calculateHeight();
  }

  calculateHeight = (forceAuto = false) => {
    const {mainViewRef} = this.props;
    if (!mainViewRef || !this.contentRef)
      return;
    if (!forceAuto && this.contentRef.clientHeight > mainViewRef.clientHeight - 240) {
      this.setState({
        showBottomIndicator: true,
        height: (mainViewRef.clientHeight - 240) + 'px'
      });
    } else {
      this.setState({
        showBottomIndicator: false,
        height: 'auto'
      });
    }
  }

  render() {
    const {
      show = false,
      searchText = '',
      suggestions = [],
      onSelect = () => {},
      onSearch = () => {},
      onSearchMouseDown = () => {},
    } = this.props;

    let collectionSection = '';
    let genreSection = '';
    let moodSection = '';
    let looksLikeSection = '';
    let soundsLikeSection = '';
    let keywords = '';
    let height = 0;
    const sectionHeight = 150;

    if ((suggestions.Collections || []).length > 0 && false) {
      const collections = [];
      height += sectionHeight;

      suggestions.Collections.forEach((collection, index) => {
        collections.push(<FilterLink key={index} filterType='Collection' label={collection.Name}
                                     value={collection}
                                     onSelect={onSelect}/>);
      })

      collectionSection = (
        <Flex className='suggestions-collections' position='left'>
          <Flex className='field-name'>Collections:</Flex>
          <Flex row fixed position='left' className='links'>
            {collections}
          </Flex>
        </Flex>
      )
    }

    if ((suggestions.Genres || []).length > 0) {
      const genres = [];
      height += sectionHeight;

      suggestions.Genres.forEach((genre, index) => {
        genres.push(<FilterLink key={index} filterType='Genre' label={genre.Name} value={genre}
                                onSelect={onSelect}/>);
      })

      genreSection = (
        <Flex className='suggestions-genres' position='left'>
          <Flex className='field-name'>{getTranslation('Genres')}:</Flex>

          <Flex row fixed position='left' className='links'>
            {genres}
          </Flex>
        </Flex>
      )
    }

    if ((suggestions.Moods || []).length > 0) {
      const moods = [];
      height += sectionHeight;

      suggestions.Moods.forEach((mood, index) => {
        moods.push(<FilterLink key={index} filterType='Mood' label={mood.Name} value={mood}
                               onSelect={onSelect}/>);
      })

      moodSection = (
        <Flex className='suggestions-moods' position='left'>
          <Flex className='field-name'>{getTranslation('Moods')}:</Flex>
          <Flex row fixed position='left' className='links'>
            {moods}
          </Flex>
        </Flex>
      )
    }

    if ((suggestions.LooksLike || []).length > 0) {
      const looksLikes = [];
      height += sectionHeight;

      suggestions.LooksLike.forEach((looksLike, index) => {
        looksLikes.push(<FilterLink key={index} filterType='LooksLike' label={looksLike.Name} value={looksLike}
                                    onSelect={onSelect}/>);
      });

      looksLikeSection = (
        <Flex className='suggestions-looks-like' position='left'>
          <Flex className='field-name'>{getTranslation('Scenes')}:</Flex>
          <Flex row fixed position='left' className='links'>
            {looksLikes}
          </Flex>
        </Flex>
      )
    }

    if ((suggestions.SoundsLike || []).length > 0) {
      const soundsLikes = [];
      height += sectionHeight;

      suggestions.SoundsLike.forEach((soundsLike, index) => {
        soundsLikes.push(<FilterLink key={index} filterType='SoundsLike' label={soundsLike.Name}
                                     value={soundsLike}
                                     onSelect={onSelect}/>);
      });

      soundsLikeSection = (
        <Flex className='suggestions-sounds-like' position='left' wrap={true}>
          <Flex className='field-name'>{getTranslation('Sounds Like')}:</Flex>
          <Flex row position='left' className='links'>
            {soundsLikes}
          </Flex>
        </Flex>
      )
    }

    if (searchText !== '') {
      keywords = (
        <Flex className='suggestions-sounds-like' position='left' wrap={true}>
          <Flex className='field-name'>{getTranslation('Keywords')}:</Flex>
          <Flex row position='left' className='links'>
            <FilterLink key={searchText} filterType='keywords' label={searchText}
                value={searchText} onSelect={onSelect}/>
          </Flex>
        </Flex>)
    }

    const componentClass = classNames({
      'suggestions-drop-down': true,
      'show': show
    })

    const suggestionsHeader = this.hasSuggestions() 
      ? (
          <div className='suggestions-header'>
            <FontIcon name='inspector' size={55} color={'#767676'}/>
            <div className='suggestion-callout'></div>
            <div className='suggestion-text-container'>
              <div className='suggestion-text'>{Common.UserInterface.GetRandomMessage('SuggestionsHeaderMessage')}</div>
            </div>
          </div>
        )
      : '';

    const {mainViewRef} = this.props;
    height = Math.min(height, mainViewRef ? mainViewRef.clientHeight - 240 : 1075);

    const sectionsStyle = {};
    if (this.contentRef && mainViewRef && this.contentRef.clientHeight > 1075) {
      sectionsStyle['height'] = mainViewRef.clientHeight - 240;
    } else {
      sectionsStyle['height'] = 'auto';
    }

    return (
      <div className={componentClass}>
        {suggestionsHeader}
        <Flex className='sections' style={{'height':this.state.height}} setRefCallBack={e => this.contentRef = e}>
          {genreSection}
          {moodSection}
          {looksLikeSection}
          {soundsLikeSection}
          {keywords}
        </Flex>
      </div>
    )
  }
}