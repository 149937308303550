import AlbumInfo from './album-info/album-info';

import AccountDialog from './account/account';
import ChangePasswordDialog from './change-password/change-password';
import ContactUsDialog from './contact/contact';
import HowToDialog from './howto/howto';
import LoginDialog from './login/login';
import MoreLikeThisDialog from './more-like-this/more-like-this';
import Preferences from './preferences/preferences';
import SignUpDialog from './signup/signup';
import EditProfileDialog from './edit-profile/edit-profile';

import AddAllToPlaylist from './playlist/add-all-to-playlist/add-all-to-playlist';
import CreatePlaylist from './playlist/create-playlist/create-playlist';
import DeletePlaylist from './playlist/delete-playlist/delete-playlist';
import DuplicatePlaylist from './playlist/duplicate-playlist/duplicate-playlist';
import ExportPlaylist from './playlist/export-playlist/export-playlist';
import ImportPlaylist from './playlist/import-playlist/import-playlist';
import MergePlaylist from './playlist/merge-playlist/merge-playlist';
import OpenPlaylist from './playlist/open-playlist/open-playlist';
import RenamePlaylist from './playlist/rename-playlist/rename-playlist';
import SharePlaylist from './playlist/share-playlist/share-playlist';
import TrackHider from './playlist/track-hider/track-hider';
import MultiplePlaylist from './playlist/multiple-playlist/multiple-playlist';

import CueSheetMaker from './cue-sheet/cue-sheet-maker/cue-sheet-maker';
import ExportCueSheetDialog from './cue-sheet/export-cue-sheet/export-cue-sheet';
import ImportCueSheetDialog from './cue-sheet/import-cue-sheet/import-cue-sheet';
import NewCueSheetDialog from './cue-sheet/new-cue-sheet/new-cue-sheet';
import OpenCueSheetDialog from './cue-sheet/open-cue-sheet/open-cue-sheet';
import PrintCueSheetDialog from './cue-sheet/print-cue-sheet/print-cue-sheet';
import SaveCueSheetDialog from './cue-sheet/save-cue-sheet/save-cue-sheet';
import SaveCueSheetAsDialog from './cue-sheet/save-cue-sheet-as/save-cue-sheet-as';

import ForgotPasswordDialog from './reset-password/forgot-password';
import ResetPasswordDialog from './reset-password/reset-password';

import SurveyDialog from './survey/survey';

import ProgressDialog from './progress/progress';

import QuickTipsDialog from './quick-tips/quick-tips';
 
import EditTrackDialog from  './edit-track/edit-track';
import PrivacyDialog from './privacy/privacy';

export {
    AccountDialog,
    AlbumInfo,
    ChangePasswordDialog,
    CueSheetMaker,
    ContactUsDialog,
    HowToDialog,
    LoginDialog,
    MoreLikeThisDialog,
    Preferences,
    SignUpDialog,

    // // Playlist Dialogs
    AddAllToPlaylist,
    CreatePlaylist,
    DeletePlaylist,
    DuplicatePlaylist,
    ExportPlaylist,
    ImportPlaylist,
    MergePlaylist,
    OpenPlaylist,
    RenamePlaylist,
    SharePlaylist,
    TrackHider,
    MultiplePlaylist,
    ForgotPasswordDialog,
    ResetPasswordDialog,

    ProgressDialog,

    ExportCueSheetDialog,
    ImportCueSheetDialog,
    NewCueSheetDialog,
    OpenCueSheetDialog,
    PrintCueSheetDialog,
    SaveCueSheetDialog,
    SaveCueSheetAsDialog,

    SurveyDialog,
    QuickTipsDialog,
    EditTrackDialog,
    PrivacyDialog,
    EditProfileDialog
}