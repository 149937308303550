import React from 'react';
import classNames from 'classnames';
import style from './rename-playlist.scss';

import { Button, Dialog, DropDown, Flex, FontIcon, TextField, Typewriter } from 'components/ui';

const dialogName = 'RenamePlaylist';

export default class RenamePlaylist extends React.Component {
    constructor() {
        super(...arguments);

        this.onKeyUp = (event) => {
            if (event.which == 27) {
                Common.Dialogs.ShowDialog(dialogName, false);
            }

            if (event.which == 13) {
                event.preventDefault();
                this.handleOK();
            }
        }

        this.state = {
            message: null,
            filter: '',
            errorMessage: null,
            playlists: [],
            title: '',
            selectedPlaylist: null
        }
    }

    get onClose() {
        const {
            onClose = () => {}
        } = this.props;

        return onClose;
    }

    componentWillMount() {
        const titleField = this.title;

        const playlist = Common.Playlist.GetPlaylist()
        if (playlist != null) {
            this.state.selectedPlaylist = { id: playlist.Id, text: playlist.Title };
        }

        this.state.message = Common.UserInterface.GetRandomMessage('RenamePlaylist');

        Common.Playlist.GetPlaylistsAsync()
            .then(playlists => {
                this.state.playlists = playlists.map(p => { return { id: p.Id, text: p.Title }});
                this.forceUpdate();
            })
            .catch(err => {
                // TODO: throw error message
            });

        document.addEventListener('keyup', this.onKeyUp);
        setTimeout(() => titleField.focus(), 100);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.onKeyUp);
    }

    handleNewTitleTextChanged(value) {
        this.setState({ title: value });
    }

    handleCancel() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleOK() {
        const title = this.title.value;

        if (this.state.selectedPlaylist != null && title.trim() != '') {
            this.setState({ errorMessage: null });
            Common.Playlist.RenamePlaylist(this.state.selectedPlaylist.id, title);
        } else {
            if (this.state.selectedPlaylist == null) this.setState({ errorMessage: 'You must select a playlist to rename' });
            else if (title.trim() == '') this.setState({ errorMessage: 'You must specify a title for the new playlist' });
        }

        this.onClose();
    }

    handleSelectPlaylist(playlist) {
        this.setState({ selectedPlaylist: playlist });
    }

    handleTextChanged = (value) => {
        this.setState({ title: value });
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        const playlistItems = [];
        const errorMessageClass = classNames('error-message', { show: this.state.message != null});

        return (
            <Dialog title='Rename Playlist' name='RenamePlaylist' width={600} height={310} modal={true} hideTitleIcon={true} closeText=''  onClose={this.handleCancel.bind(this)} onEscape={this.handleCancel.bind(this)}>
                <Flex block className='rename-playlist' position='top'>
                    <Flex row className='header'>
                        <FontIcon name='inspector' />
                        <Flex position='left'><Typewriter message={ this.state.message } /></Flex>
                    </Flex>
                    <Flex className='content'>
                        <Flex row>
                            <Flex width={100} position='right'>Current Name: </Flex>
                            <Flex><DropDown placeholder='Select playlist to delete' items={this.state.playlists} value={this.state.selectedPlaylist} onSelect={this.handleSelectPlaylist.bind(this)} /></Flex>
                        </Flex>
                        <Flex row>
                            <Flex width={100} position='right'>New Name: </Flex>
                            <Flex><TextField ref={(i) => this.title = i} fontSize={16} placeholder={'Untitled'} value={this.state.title} onTextChanged={this.handleTextChanged.bind(this)} /></Flex>
                        </Flex>
                        <Flex row height={0} className={errorMessageClass}>
                            <Flex width={100} position='right'></Flex>
                            <Flex position='left'>{this.state.errorMessage}</Flex>
                        </Flex>
                    </Flex>
                    <Flex row fixed className='footer' position='right'>
                        <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                        <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}

