import React from 'react';
import { Flex } from 'components/ui';
import Styles from './searchExtension.scss';

import PlaylistPanel from '../playlist/playlist';
import SearchFilters from './searchFilters/searchFilters';
import SearchResults from './searchResults/searchResults';

class SearchExtension extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        return (
            <Flex row className='search-extension'>
                <SearchFilters />
                <SearchResults isVisible={this.props.isVisible} />
                <PlaylistPanel />
            </Flex>
        )
    }
}

export default SearchExtension;