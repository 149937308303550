import React from 'react';
import Style from './image.scss';

export class Image extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      showMissingImage: false
    }
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.url != this.props.url)
  }

  handleMissingImage() {
    const { url, missingImageUrl = null } = this.props;

    if (missingImageUrl && !this.state.showMissingImage) {
      this.setState({ showMissingImage: true });
      this.ImageElement.src = missingImageUrl;
    }
    else
      this.ImageElement.style.display = 'none';
  }

  render() {
    const {
      url,
      width = null,
      height = null,
      onClick= () => {},
      onMissingImage = this.handleMissingImage.bind(this)
    } = this.props;

    const imageStyle = {
      width: width,
      height: height
    }

    return (
      <img ref={i => this.ImageElement = i} className='img' style={imageStyle} src={url} onError={onMissingImage} onClick={onClick} />
    )
  }
}