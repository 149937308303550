import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { basicFieldSorter } from "../../../utility/common";
import { showAlert, closeAlert } from "components/ui/alert/alert";
import "./track-results.scss";

import {
  Button,
  Flex,
  FontIcon,
  Image,
  ScrollableList,
  Typewriter
} from "components/ui";
import { TrackPlayer } from "./track-detail-components/track-player";
import { TrackDetailAltsPanel } from "./track-detail-tabs/track-detail-alts/track-detail-alts";
import { TrackDetailEditsPanel } from "./track-detail-tabs/track-detail-edits/track-detail-edits";
import { TrackDetailDescriptionPanel } from "./track-detail-tabs/track-detail-description/track-detail-description";
import { TrackDetailWritersPanel } from "./track-detail-tabs/track-detail-writers/track-detail-writers";
import { AddToCartAction } from "../../../store/cart/actions";
import featureFlags from "../../../featureFlags";

const DEFAULT_ROW_HEIGHT = 70;
var activeTrackId = '';
var isClickInDrawer = false;

class TrackResults extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      source: null,
      isDrawer: false,
      disableContextMenu: false,
      hideToggle: false,
      hideAltIcon: false,
      hideAlbumLink: false,
      hideAuditionIcon: false,
      hideDescriptionPanel: false,
      hideDownloadIcon: false,
      hideDrawerLinks: false,
      hideMoreLikeThisIcon: false,
      hideInfoIcon: false,
      hideInfoPanel: false,
      hideFilterLinks: true,
      hidePlaylistIcon: false,
      hideTrackInfoLinks: false,
      disableHoverActions: false,
      selectedTab: 0,
      toggledIndex: null,
      toggledTrack: null,
      width: 500,
      height: 10000,
      currentTrackIndex: null,
      currentTrackId: null,
      trackSource: null
    };
  }
  
  componentWillMount() {
    this.setState({
      source: this.props.source || null,
      onDownloadTrack: this.props.onDownloadTrack,
      isDrawer: this.props.isDrawer != null ? this.props.isDrawer : this.state.isDrawer,
      discovery: this.props.discovery != null ? this.props.discovery : this.state.discovery,
      disableContextMenu: this.props.disableContextMenu != null ? this.props.disableContextMenu : this.state.disableContextMenu,
      hideToggle: this.props.hideToggle != null ? this.props.hideToggle : this.state.hideToggle,
      hideAltIcon: this.props.hideAltIcon != null ? this.props.hideAltIcon : this.state.hideAltIcon,
      hideAlbumLink: this.props.hideAlbumLink != null ? this.props.hideAlbumLink : this.state.hideAlbumLink,
      hideAuditionIcon: this.props.hideAuditionIcon != null ? this.props.hideAuditionIcon : this.state.hideAuditionIcon,
      hideDescriptionPanel: this.props.hideDescriptionPanel != null ? this.props.hideDescriptionPanel : this.state.hideDescriptionPanel,
      hideDownloadIcon: this.props.hideDownloadIcon != null ? this.props.hideDownloadIcon : this.state.hideDownloadIcon,
      hideDrawerLinks: this.props.hideDrawerLinks != null ? this.props.hideDrawerLinks : this.state.hideDrawerLinks,
      hideMoreLikeThisIcon: this.props.hideMoreLikeThisIcon != null ? this.props.hideMoreLikeThisIcon : this.state.hideMoreLikeThisIcon,
      hideInfoIcon: this.props.hideInfoIcon != null ? this.props.hideInfoIcon : this.state.hideInfoIcon,
      hideInfoPanel: this.props.hideInfoPanel != null ? this.props.hideInfoPanel : this.state.hideInfoPanel,
      hideFilterLinks: this.props.hideFilterLinks,
      hidePlaylistIcon: this.props.hidePlaylistIcon != null ? this.props.hidePlaylistIcon : this.state.hidePlaylistIcon,
      hideTrackInfoLinks: this.props.hideTrackInfoLinks != null ? this.props.hideTrackInfoLinks : this.state.hideTrackInfoLinks,
      disableHoverActions: this.props.disableHoverActions != null ? this.props.disableHoverActions : this.state.disableHoverActions,
      currentTrackIndex: null
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));

    if (this.props.owner)
      this.props.owner.onResize = this.updateDimensions.bind(this);

    this.updateDimensions();
    this.setState({
      source: this.props.source || null,
      onDownloadTrack: this.props.onDownloadTrack,
      isDrawer: this.props.isDrawer || this.state.isDrawer,
      discovery: this.props.discovery || this.state.discovery,
      hideToggle: this.props.hideToggle || this.state.hideToggle,
      hideAltIcon: this.props.hideAltIcon || this.state.hideAltIcon,
      hideAlbumLink: this.props.hideAlbumLink || this.state.hideAlbumLink,
      hideAuditionIcon: this.props.hideAuditionIcon || this.state.hideAuditionIcon,
      hideDownloadIcon: this.props.hideDownloadIcon || this.state.hideDownloadIcon,
      hideDrawerLinks: this.props.hideDrawerLinks || this.state.hideDrawerLinks,
      hideMoreLikeThisIcon: this.props.hideMoreLikeThisIcon || this.state.hideMoreLikeThisIcon,
      hideInfoIcon: this.props.hideInfoIcon || this.state.hideInfoIcon,
      hideFilterLinks: this.props.hideFilterLinks,
      hidePlaylistIcon: this.props.hidePlaylistIcon || this.state.hidePlaylistIcon,
      hideTrackInfoLinks: this.props.hideTrackInfoLinks || this.state.hideTrackInfoLinks,
      currentTrackIndex: null
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillReceiveProps(nextProps, nextState) {
    let originalIndex = this.state.toggledIndex;
    let toggledIndex = originalIndex;
    let toggledTrack = this.state.toggledTrack;

    if (toggledIndex && toggledTrack) {      
      const t = this.state.toggledTrack;
      const b = nextProps.tracks.sort();
      toggledIndex = b.findIndex(x => x.Id === t.Id);
    }

    this.setState({
      hideToggle: nextProps.hideToggle,
      toggledIndex: toggledIndex,
      trackSource: (Common.UserInterface.GetPreferences() || { Source: "Web" }).Source
    });

    this.ScrollableList.recomputeRowHeights(originalIndex);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tracks !== this.props.tracks) {
      if (this.props.keepToggleIndex === false) {
        this.setState({ toggledIndex: null, toggledTrack: null });
      }
    }
  }

  handlePlayerAction(action, event) {
    let minIndex = 0;
    let track = action.track;
    let toggledIndex = this.state.toggledIndex;

    switch (action.type) {
      case "AddFilter": {
        let filter = action.filterValue;
        filter.FilterType = action.filterType;
        Common.UserInterface.AddSearchFilter(filter, false, true);
        break;
      }
      case "SelectTab": {
        this.setState({ selectedTab: action.tab });
        this.ScrollableList.recomputeRowHeights(this.state.toggledIndex);
        break;
      }
      case "ToggleTrack": {
        let newIndex = action.index;
        let prevIndex = this.state.toggledIndex;

        const origRowRect = event.target
          .closest(".track-row")
          .getBoundingClientRect();
        const scrollableListTop = ReactDOM.findDOMNode(
          this.ScrollableList
        ).getBoundingClientRect().top;

        if (prevIndex !== null && Common.TrackPlayer.AudioSource === "drawer") {
          Common.TrackPlayer.Stop();
        }

        if (prevIndex !== newIndex) {
          const selectedTab =
            track.AltTrackCount === 0 && track.EditTrackCount === 0
              ? 2
              : track.AltTrackCount > 0
                ? 0
                : 1;

          this.setState({ toggledIndex: newIndex, toggledTrack: track, selectedTab: selectedTab });
        } else {
          this.setState({ toggledIndex: null, toggledTrack: null });
        }

        this.ScrollableList.recomputeRowHeights(
          Math.min(prevIndex > 0 ? prevIndex : 0, newIndex)
        );
        this.ScrollableList.scrollTo(
          Math.max(
            0,
            newIndex * DEFAULT_ROW_HEIGHT -
            (origRowRect.top - scrollableListTop)
          )
        );

        break;
      }
      case "ShowAlbumInfo": {
        Common.Search.ShowAlbumInfo(track.Genre.Id);
        break;
      }
      case "ShowMoreLikeThis": {
        Common.Search.ShowMoreLikeThis(
          track,
          track.Collection.Id,
          track.Genre.Id,
          null,
          null,
          null,
          null
        );
        break;
      }
      case "ShowAlts": {
        minIndex = Math.min(toggledIndex || 0, action.index);

        if (toggledIndex === action.index) {
          if (this.state.selectedTab === 0 && track.AltTrackCount > 0)
            this.setState({ toggledIndex: null, toggledTrack: null });
          else if (
            this.state.selectedTab === 1 &&
            track.AltTrackCount === 0 &&
            track.EditTrackCount > 0
          )
            this.setState({ toggledIndex: null, toggledTrack: null });
          else this.setState({ selectedTab: track.AltTrackCount > 0 ? 0 : 1 });
        } else {
          this.setState({
            toggledIndex: action.index,
            selectedTab: track.AltTrackCount > 0 ? 0 : 1
          });
        }

        this.ScrollableList.recomputeRowHeights(minIndex);
        break;
      }
      case "ShowInfo": {
        minIndex = Math.min(toggledIndex || 0, action.index);

        if (toggledIndex === action.index) {
          if (this.state.selectedTab === 3)
            this.setState({ toggledIndex: null, toggledTrack: null });
          else this.setState({ selectedTab: 3 });
        } else {
          this.setState({ toggledIndex: action.index, selectedTab: 3 });
        }

        this.ScrollableList.recomputeRowHeights(minIndex);
        break;
      }
      case "DownloadTrack": {
        const rect = event.target.getBoundingClientRect();
        Common.UserInterface.ShowDownloadTrack(track, rect);
      }
    }
  }

  updateDimensions() {
    if (this.TrackResultsElement == null) return;

    const rect = this.TrackResultsElement.getBoundingClientRect();
    this.setState({
      width: ReactDOM.findDOMNode(this).clientWidth - 5,
      height: rect.height
    });
  }

  handleTrackPlayerClick = (vals) => {
    const {isDrawer} = this.props
    activeTrackId = vals.trackId;
    this.setState(
      {
        currentTrackIndex: vals.index,
        currentTrackId: vals.trackId
      });
    // Code to check if alts open and clicked
    if (isDrawer) {
      isClickInDrawer = true;
    } else {
      isClickInDrawer = false;
    }

    let track = this.props.tracks[vals.index];
    Common.TrackPlayer.LoadTrack(track);
  }

  onKeyPressed(e, track) {
    let index = -1;
    let trackId = -1;

    // If alts clicked, disabled up/down navigation
    if (isClickInDrawer)
      return;

    if (e.keyCode === 40) {
      Common.TrackPlayer.Stop();
      index = this.state.currentTrackIndex;
      index = ++index;

      if (index === this.props.tracks.length)
        return;

      trackId = this.props.tracks[index].Id;
      activeTrackId = trackId;
      this.setState(
        {
          currentTrackIndex: index,
          currentTrackId: trackId
        });
    } else if (e.keyCode === 38) {
      Common.TrackPlayer.Stop();
      index = this.state.currentTrackIndex;
      index = (index == 0 ? 0 : index - 1);
      trackId = this.props.tracks[index].Id;
      activeTrackId = trackId;
      this.setState(
        {
          currentTrackIndex: index,
          currentTrackId: trackId
        });
    }

    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
      e.stopPropagation();
      let track = this.props.tracks[index];
      Common.TrackPlayer.LoadTrack(track);
    }
  }

  render() {
    const { autoSize = false, onScroll = () => { }, isDraggable = false, onDragEnd = () => { } } = this.props;
    const selectedTab = this.state.selectedTab;
    const toggledIndex = this.state.toggledIndex;
    const tracks = this.props.autoSortAtoZ
      ? this.props.tracks.sort(basicFieldSorter("Title"))
      : this.props.tracks;

    const calculateRowHeight = (index, toggledIndex, selectedTab, track) => {
      if (!track) {
        return 0;
      }

      if (index === toggledIndex) {
        if (selectedTab === 0) {
          return track.AltTrackCount * DEFAULT_ROW_HEIGHT + 125;
        }
        if (selectedTab === 1) {
          return track.EditTrackCount * DEFAULT_ROW_HEIGHT + 125;
        }
        if (selectedTab === 2) {
          return 335;
        }
        if (selectedTab === 3) {
          return 265;
        }
      } else {
        return DEFAULT_ROW_HEIGHT;
      }
    };

    const className = `track-results ${this.state.disableHoverActions ? 'no-hover' : ''}`.trim()    

    return (
      <div ref={i => (this.TrackResultsElement = i)} className={className}>
        <ScrollableList
          autoSize={autoSize}
          ref={i => (this.ScrollableList = i)}
          rowRenderer={p => {
            const trackId = tracks[p.index].Id;
            const renderedRow = (
              <div key={trackId} style={p.style} onKeyDown={(e) => this.onKeyPressed(e, { index: p.index, trackId: trackId, pindex: p })} tabIndex={p.index}>
                <TrackRow
                  index={p.index}                  
                  track={tracks[p.index]}
                  state={this.state}
                  isCurrentTrack={this.state.currentTrackIndex === p.index ? true : false}
                  onPlayerAction={this.handlePlayerAction.bind(this)}
                  handleTrackPlayerClick={this.handleTrackPlayerClick.bind(this, { index: p.index, trackId: tracks[p.index].Id })}
                  isDraggable={isDraggable}
                  onDragEnd={onDragEnd}
                />
              </div>
            );

            return renderedRow;
          }}
          rowCount={tracks.length || 0}
          rowHeight={({ index }) =>
            calculateRowHeight(index, toggledIndex, selectedTab, tracks[index])
          }
          height={this.state.height}
          width={this.state.width}
          onScrollFrame={onScroll}
        />
      </div>
    );
  }
}

class TrackRow extends React.Component {

  constructor() {
    super(...arguments);
    this.state = {
      hidePlaylistIcon: false,
      isAllAltTracksToggle: false,
      isTrackAdded: false
    };
    this.handleAllAltTracks = this.handleAllAltTracks.bind(this);
    this.handleTrackAdd = this.handleTrackAdd.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.initializeComponent ||
      (this.props.index === nextProps.state.toggledIndex) ||
      (nextProps.state.trackSource != this.props.state.trackSource) ||
      (nextProps.isCurrentTrack != this.props.isCurrentTrack) ||
      (nextProps.state.toggledIndex != this.props.state.toggledIndex) ||
      (nextProps.state.selectedTab != this.props.state.selectedTab) ||
      (nextProps.state.hideAuditionIcon != this.props.state.hideAuditionIcon) ||
      (nextProps.state.hidePlaylistIcon != this.props.state.hidePlaylistIcon) ||
      (nextProps.track != this.props.track) ||
      ((nextProps.track.altTracks || []).length != (this.props.track.altTracks || []).length) ||
      ((nextProps.track.editTracks || []).length != (this.props.track.editTracks || []).length);
  }

  handleAddFilter(filterType, filter) {
    filter.FilterType = filterType;
    Common.UserInterface.AddSearchFilter(filter, false, true);
  }

  handleAllAltTracks(isAllTrackSelected) {
    this.setState({ isAllAltTracksToggle: isAllTrackSelected });
    this.setState({ toggledIndex: null, toggledTrack: null });
  }

  handleAllEditTracks(isAllTrackSelected) {
    this.setState({ isAllEditTracksToggle: isAllTrackSelected });
    this.setState({ toggledIndex: null, toggledTrack: null });this.setState({ toggledIndex: null, toggledTrack: null });
  }

  handleTrackAdd(trackAdded) {
    this.setState({ isTrackAdded: trackAdded });
  }

  handleTrackPlayerClick = () => {
    this.props.handleTrackPlayerClick(this.props.index);

    if (Common.TrackPlayer.TrackId !== this.props.track.Id) {
      if (Common.TrackPlayer.IsPlaying) Common.TrackPlayer.Stop();
    }
  };

  onDragStart = (e) => {
    if (!this.props.isDraggable) {
      return;
    }
    e.dataTransfer.setData("startId", e.target.id);
  }

  onDrop = (e) => {
    if (!this.props.isDraggable) {
      return;
    }
    e.preventDefault();
    const startId = e.dataTransfer.getData("startId");
    const endId = e.currentTarget.id;
    this.props.onDragEnd({ startId, endId })
  }

  allowDrop = (e) => {
    if (!this.props.isDraggable) {
      return;
    }
    e.preventDefault();
  }

  render() {
    const {
      index = null,
      track = {},
      style = {},
      state = {},
      onPlayerAction = () => { },
      isDraggable = false
    } = this.props;
  
    state.isAllAltTracksToggle = this.state.isAllAltTracksToggle;
    state.isAllEditTracksToggle = this.state.isAllEditTracksToggle;
    state.isTrackAdded = this.state.isTrackAdded;

    const trackRowClass = classNames({
      "track-row": true,
      "hide-toggle": state.hideToggle
    });
    const trackRowDetails = (state.toggledIndex === index)
      ? (<TrackRowDetails track={track} state={state} onPlayerAction={onPlayerAction} onAllAltTrackSelected={this.handleAllAltTracks} onAllEditTrackSelected={this.handleAllEditTracks} />)
      : '';

    let activeTrackClass = '';
    if (activeTrackId == track.Id) {
      activeTrackClass = 'activeTrack';
    } else {
      activeTrackClass = '';
    }

    // Add extra condition for active
    if (Common.TrackPlayer.track) {
      if (Common.TrackPlayer.track.Id === track.Id) {
        activeTrackId = Common.TrackPlayer.track.Id;
        activeTrackClass = 'activeTrack';
      }
    }

    const isToggled = (state.toggledIndex === index) ? true : false;
    return (
      <div
        id={"" + track.Id}
        style={style}
        className={activeTrackClass}
        draggable={isDraggable}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onDrop={this.onDrop}
        onDragOver={this.allowDrop}>
        <div className={trackRowClass} data-rowindex={index} onClick={this.handleTrackPlayerClick}>
          <TrackToggle
            track={track}
            index={index}
            onPlayerAction={onPlayerAction}
            state={state}
            isToggled={isToggled}
          />
          <TrackPlayer
            track={track}
            index={index}  
            onPlayerAction={onPlayerAction}
            state={state}
            onTrackAdded={this.handleTrackAdd}
          />
          <TrackActions
            track={track}
            index={index}
            onPlayerAction={onPlayerAction}
            state={state}
          />
          <TrackInfo
            track={track}
            index={index}
            onPlayerAction={onPlayerAction}
            onAddFilter={this.handleAddFilter}
            state={state}
          />
          <TrackCover
            track={track}
            index={index}
            onPlayerAction={onPlayerAction}
            state={state}
          />
          <TrackMetadata
            track={track}
            index={index}
            onPlayerAction={onPlayerAction}
            state={state}
          />
        </div>
        {trackRowDetails}
      </div>
    );
  }
}

class TrackRowDetails extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      isAddAllAltTracksButtonVisible: true,
      isAddAllEditTracksButtonVisible: true,
      addAllAltTracksToggle: false,
      addAllEditTracksToggle: false
    };
  }

  shouldComponentUpdate(nextProps) {
    return this.props.state !== nextProps.state;
  }

  handleAltTracksLoaded = (altsPanel, altTracks) => {
    this.setState({ isAddAllAltTracksButtonVisible: true, isAddAllEditTracksButtonVisible: true });
    this.forceUpdate();
  };

  handleEditTracksLoaded = (editPanel, editTracks) => {
    this.setState({ isAddAllAltTracksButtonVisible: true, isAddAllEditTracksButtonVisible: true });
    this.forceUpdate();
  };

  get UserDetails() {
    return Common.GetState().UserInterface.toJS().UserDetails || {};
  }

  handleAddAllAltTracksClick = () => {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }

    this.setState({ toggledIndex: null, toggledTrack: null })

    if (!this.props.track.AllAltTracksAddedInPlaylist) {
      Common.Playlist.AddTrackList(this.props.track.AltTracks);
    } else {      
      Common.Playlist.RemoveAltTracks(this.props.track.Id, 'ALT');
    }

    this.state.addAllAltTracksToggle = !this.state.addAllAltTracksToggle;
    this.props.onAllAltTrackSelected(this.state.addAllAltTracksToggle);    
  }

  handleAddAllEditTracksClick = () => {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }

    this.setState({ toggledIndex: null, toggledTrack: null })

    if (!this.props.track.AllEditTracksAddedInPlaylist) {
      Common.Playlist.AddTrackList(this.props.track.EditTracks);
    } else {
      Common.Playlist.RemoveAltTracks(this.props.track.Id, 'EDIT');
    }

    this.state.addAllAltTracksToggle = !this.state.addAllAltTracksToggle;
    this.props.onAllEditTrackSelected(this.state.addAllEditTracksToggle);
  }

  render() {
    const { track, state, onPlayerAction } = this.props;

    let panelHeight = 0;

    const panel0 = (
      <TrackDetailAltsPanel
        track={track}
        state={state}
        onPlayerAction={onPlayerAction}
        show={state.selectedTab === 0}
        hideAuditionIcon={state.hideAuditionIcon}
        hideDownloadIcon={state.hideDownloadIcon}
        hideMoreLikeThisIcon={state.hideMoreLikeThisIcon}
        hideTrackInfoLinks={state.hideTrackInfoLinks}
        tracksLoaded_cb={this.handleAltTracksLoaded}
      />
    );
    const panel1 = (
      <TrackDetailEditsPanel
        track={track}
        state={state}
        onPlayerAction={onPlayerAction}
        show={state.selectedTab === 1}
        hideDownloadIcon={state.hideDownloadIcon}
        hideMoreLikeThisIcon={state.hideMoreLikeThisIcon}
        hideTrackInfoLinks={state.hideTrackInfoLinks}
        tracksLoaded_cb={this.handleEditTracksLoaded}
      />
    );
    const panel2 = (
      <TrackDetailDescriptionPanel
        track={track}
        state={state}
        onPlayerAction={onPlayerAction}
        show={state.selectedTab === 2}
      />
    );
    const panel3 = (
    <TrackDetailWritersPanel
        track={track}
        state={state}
        onPlayerAction={onPlayerAction}
        show={state.selectedTab === 3}
      />
    );

    let detailsPanel = "";

    switch (state.selectedTab) {
      case 0:
        panelHeight = (track.AltTrackCount || 0) * 125;
        detailsPanel = panel0;
        break;
      case 1:
        panelHeight = (track.EditTrackCount || 0) * 125;
        detailsPanel = panel1;
        break;
      case 2:
        panelHeight = 335;
        detailsPanel = panel2;
        break;
      case 3:
        panelHeight = 265;
        detailsPanel = panel3;
        break;
    }

    const infoPanel = state.hideInfoPanel
      ? null
      : <WritersTab
          selected={state.selectedTab === 3}
          onPlayerAction={onPlayerAction}
        />

    const descPanel = state.hideDescriptionPanel
      ? null
      : <DescriptionTab
          selected={state.selectedTab === 2}
          onPlayerAction={onPlayerAction}
        />

    return (
      <div className="track-row-details" style={{ height: panelHeight }}>
        <div className="track-details-tabs">
          <AltTab
            track={track}
            state={state}
            selected={state.selectedTab === 0}
            onPlayerAction={onPlayerAction}
            isAddAllTracksButtonVisible={
              this.state.isAddAllAltTracksButtonVisible
            }
            onAddAllTracksClick={this.handleAddAllAltTracksClick}
          />
          <EditTab
            track={track}
            state={state}
            selected={state.selectedTab === 1}
            onPlayerAction={onPlayerAction}
            isAddAllTracksButtonVisible={this.state.isAddAllEditTracksButtonVisible}
            onAddAllTracksClick={this.handleAddAllEditTracksClick}
          />
          {descPanel}
          {infoPanel}          
        </div>
        <div className="track-details-panel">{detailsPanel}</div>
      </div>
    );
  }
}

const AltTab = ({
  selected = false,
  state,
  track,
  onPlayerAction,
  isAddAllTracksButtonVisible,
  onAddAllTracksClick
}) => {
  if (track.AltTrackCount === 0) return "";

  if (track.AltTracks == null) {      
    Common.UserInterface.LoadAltTracks(track)
      .then(tracks => {        
          Common.Search.SetAltTracks(track, tracks);          
          // setTimeout(self.forceUpdate, 1);
      })
      .catch(err => {
          console.error('ERROR: ', err);
      })
      .finally(() => {
        // var tracksInPlaylist = Common.Playlist.GetPlaylist().Tracks.filter(pt => track.AltTracks.map(a => a.Id).includes(pt.Id))
        // track.AllAltTracksAddedInPlaylist = (tracksInPlaylist.length === track.AltTracks.length) 
        track.AllAltTracksAddedInPlaylist = track.AltTracks.every(at => Common.Playlist.GetPlaylist().Tracks.map(t => t.Id).includes(at.Id))
      })
  } else {
    // var tracksInPlaylist = Common.Playlist.GetPlaylist().Tracks.filter(pt => track.AltTracks.map(a => a.Id).includes(pt.Id))    
    // track.AllAltTracksAddedInPlaylist = (tracksInPlaylist.length === track.AltTracks.length) 
    track.AllAltTracksAddedInPlaylist = track.AltTracks.every(at => Common.Playlist.GetPlaylist().Tracks.map(t => t.Id).includes(at.Id))
  }
  
  const tabClass = "track-details-tab " + (selected ? "selected" : "");
  const playlistButton = !state.hidePlaylistIcon && isAddAllTracksButtonVisible 
      ? track.AllAltTracksAddedInPlaylist 
        ? (<FontIcon name="circle-cross" className="add-all-tracks-cross" size={21} onClick={onAddAllTracksClick} />)
        : (<FontIcon name="circle-plus"  className="add-all-tracks-plus" size={21} onClick={onAddAllTracksClick} />)
      : null
    

  return (
    <div
      className={tabClass}
      onClick={() => onPlayerAction({ type: "SelectTab", tab: 0 })}
    >
      {playlistButton}
      <div className="add-all-tracks">
        <span>Alts</span>
      </div>
    </div>
  );
};

const EditTab = ({
  selected = false,
  state,
  track,
  onPlayerAction,
  isAddAllTracksButtonVisible,
  onAddAllTracksClick
}) => {
  if (track.EditTrackCount === 0) return "";

  if (track.EditTracks == null) {
    const self = this;
    Common.UserInterface.LoadEditTracks(track)
    .then(tracks => {
        Common.Search.SetEditTracks(track, tracks);
        //setTimeout(self.forceUpdate, 1);
    })
    .catch(err => {
        console.error('ERROR: ', err);
    })
    .finally(() => {
      // var tracksInPlaylist = Common.Playlist.GetPlaylist().Tracks.filter(pt => track.EditTracks.map(a => a.Id).includes(pt.Id))
      // track.AllEditTracksAddedInPlaylist = (tracksInPlaylist.length === track.EditTracks.length) 
      track.AllEditTracksAddedInPlaylist = track.EditTracks.every(at => Common.Playlist.GetPlaylist().Tracks.map(t => t.Id).includes(at.Id))
    })
  } else {
    //var tracksInPlaylist = Common.Playlist.GetPlaylist().Tracks.filter(pt => track.AltTracks.map(a => a.Id).includes(pt.Id))    
    // track.AllEditTracksAddedInPlaylist = (tracksInPlaylist.length === track.EditTracks.length) 
    track.AllEditTracksAddedInPlaylist = track.EditTracks.every(at => Common.Playlist.GetPlaylist().Tracks.map(t => t.Id).includes(at.Id))    
  }

  // if (track.EditTracks != null) {
  //   var tracksInPlaylist = Common.Playlist.GetPlaylist().Tracks.filter(pt => track.EditTracks.map(a => a.Id).includes(pt.Id))
  //   track.AllEditTracksAddedInPlaylist = (tracksInPlaylist.length === track.EditTrackCount.length) 
  // }

  const tabClass = "track-details-tab " + (selected ? "selected" : "");
  return (
    <div
      className={tabClass}
      onClick={() => onPlayerAction({ type: "SelectTab", tab: 1 })}
    >
      {isAddAllTracksButtonVisible ? track.AllEditTracksAddedInPlaylist ? (
        <FontIcon
          name="circle-cross"
          className="add-all-tracks-cross"
          size={21}
          onClick={onAddAllTracksClick}
        />
      ) : (
          <FontIcon
            name="circle-plus"
            className="add-all-tracks-plus"
            size={21}
            onClick={onAddAllTracksClick}
          />
        ) : null
      }
      <div className="add-all-tracks">
        <span>Edits</span>
      </div>
    </div>
  );
};

const DescriptionTab = ({ selected = false, onPlayerAction }) => (
  <div
    className={"track-details-tab " + (selected ? "selected" : "")}
    onClick={() => onPlayerAction({ type: "SelectTab", tab: 2 })}
  >
    Description
  </div>
);

const WritersTab = ({ selected = false, onPlayerAction }) => (
  <div
    className={"track-details-tab " + (selected ? "selected" : "")}
    onClick={() => onPlayerAction({ type: "SelectTab", tab: 3 })}
  >
    Writers/Publishers
  </div>
);

const TrackToggle = ({
  index,
  track = {},
  state = null,
  isToggled = false,
  onPlayerAction = () => {
  }
}) => {
  if (state.hideToggle) return '';

  const toggleIcon = (state.toggledIndex !== index)
    ? (<FontIcon name='chevron-down' />)
    : (<FontIcon name='chevron-up' />)

  const altTracksInPlaylist = Common.Playlist.GetPlaylist().Tracks.filter(x => x.Id != track.Id && x.TrackGroup == track.TrackGroup);
  const isMainTrackInPlaylist = Common.Playlist.IsTrackInPlaylist(track);

  const toggleAlt = (altTracksInPlaylist.length > 0)
    ? (<FontIcon className='playlistIndicator' name='circle-cross' size={26} />)
    : null;

  const toggleAdd = (altTracksInPlaylist.length > 0) 
    ? (<FontIcon className='circle-indicator' name='close' size={26} />)
    : null;

  return (
    <div className='track-toggle'
      onClick={onPlayerAction.bind(this, { type: 'ToggleTrack', index: index, track: track })}>
      {toggleIcon}      
      {!isToggled ? (toggleAlt ? toggleAlt : toggleAdd) : ''}
    </div>
  )
}

class TrackActions extends React.Component {
  handleDownloadTrack(event) {
    const { track, state } = this.props;
    const { onDownloadTrack = null } = this.props.state;

    if (this.UserDetails.UserId !== undefined && !this.UserDetails.CanDownload && !this.UserDetails.SharedPlaylist) {
      const content = (
        <Flex row height={80} position="left">
          <FontIcon name="inspector" />
          <div className="message">
            <Typewriter message="Your account does not have permission to download tracks.  Please contact Scorekeepers to request permission." />
          </div>
        </Flex>
      );

      const handleOK = () => {
        closeAlert();
      };

      const buttons = [
        <Button key={0} width={80} onClick={handleOK.bind(this)}>
          OK
        </Button>
      ];

      showAlert({
        contentClass: "download-track-alert",
        title: "Download Track",
        width: 600,
        height: 200,
        content: content,
        buttons: buttons
      });
      return;
    } else if (state.source !== 'SharedPlaylist' && (this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && !this.UserDetails.SharedPlaylist && window.Environment === 'home') {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
    } else {
      if (onDownloadTrack) {
        onDownloadTrack(track, event, state);
      } else {
        const rect = event.target.getBoundingClientRect();
        Common.UserInterface.ShowDownloadTrack(track, rect);
      }
    }
  }

  handlePurchaseTrack(event) {
    Common.GetStore().dispatch(AddToCartAction([this.props.track.Id]));
  }

  get UserDetails() {
    return Common.GetState().UserInterface.toJS().UserDetails || {};
  }

  get EmptyIcon() {
    return (
      <FontIcon name='empty' />
    );
  }

  get AltIcon() {
    if (this.props.state.hideAltIcon ||
      (this.props.track.AltTrackCount === 0 &&
        this.props.track.EditTrackCount === 0)) return this.EmptyIcon;

    const param = {
      type: "ShowAlts",
      track: this.props.track,
      index: this.props.index
    };

    return (
      <FontIcon
        name="alt-circle"
        onClick={this.props.onPlayerAction.bind(this, param)}
      />
    );
  }

  get DownloadIcon() {
    if (this.props.state.hideDownloadIcon) return this.EmptyIcon;

    if (!featureFlags.ShoppingCart || !Common.IsAudiofficina()) {
      return (
        <FontIcon
          name={Common.UserInterface.GetDownloadIconName()}
          onClick={this.handleDownloadTrack.bind(this)}
        />
      );
    } else {
      return (
        <FontIcon
          name='euro-coin'          
          onClick={this.handlePurchaseTrack.bind(this)}
        />
      );
    }
  }

  get InfoIcon() {
    if (this.props.state.hideInfoIcon) return this.EmptyIcon;

    const param = {
      type: "ShowInfo",
      track: this.props.track,
      index: this.props.index
    };

    return (
      <FontIcon
        name="info-circle"
        onClick={this.props.onPlayerAction.bind(this, param)}
      />
    );
  }

  get MoreLikeThisIcon() {
    if (this.props.state.hideMoreLikeThisIcon) return this.EmptyIcon;

    const param = {
      type: "ShowMoreLikeThis",
      track: this.props.track,
      index: this.props.index
    };

    return (
      <FontIcon
        name="target-circle"
        onClick={this.props.onPlayerAction.bind(this, param)}
      />
    );
  }

  render() {    
    const {state} = this.props
    
    if (state.source === "MarketingPlaylist" || state.source === "SharedPlaylist") {
      if (state.isDrawer) {
        return (
          <div className='track-actions flex-column-center'>
            <div className="action-button">{this.DownloadIcon}</div>
          </div>
        )
      } else {
        return (
          <div className='track-actions flex-column-center'>
            <div className="action-button">{this.AltIcon}</div>
            <div className="action-button">{this.DownloadIcon}</div>
          </div>
        )
      }
    } else {
      return (
        <div className='track-actions'>
          <div className="action-button">{this.AltIcon}</div>
          <div className="action-button">{this.MoreLikeThisIcon}</div>
          <div className="action-button">{this.InfoIcon}</div>
          <div className="action-button">{this.DownloadIcon}</div>
        </div>
      )
    }
  }
}

const TrackInfo = ({ track = {}, state = {}, onAddFilter = () => { } }) => {
  if (!state.hideTrackInfoLinks) {
    return (
      <div className="track-info">
        <div
          className="flex-full link"
          onClick={() => {
            onAddFilter("Genre", track.Genre);
          }}
        >
          <span className="text-ellipsis">{track.Genre.Name}</span>
        </div>
        <div
          className="flex-full link"
          onClick={() => {
            onAddFilter("Mood", track.Mood);
          }}
        >
          {track.Mood.Name}
        </div>
        <div
          className="flex-full link"
          onClick={() => {
            onAddFilter("Tempo", track.Tempo);
          }}
        >
          {track.Tempo.Name}
        </div>
        <div
          className="flex-full link"
          onClick={() => {
            onAddFilter("Energy", track.Energy);
          }}
        >
          {track.Energy.Name} Energy
        </div>
      </div>
    );
  } else {
    return (
      <div className="track-info">
        <div className="flex-full">
          <span className="text-ellipsis">{track.Genre.Name}</span>
        </div>
        <div className="flex-full">{track.Mood.Name}</div>
        <div className="flex-full">{track.Tempo.Name}</div>
        <div className="flex-full">{track.Energy.Name} Energy</div>
      </div>
    );
  }
};

const TrackCover = ({
  track = {},
  state = null,
  onPlayerAction = () => { }
}) => {
  const trackCoverClass = classNames({
    "track-cover": true,
    "no-link": state.hideAlbumLink
  });

  return (
    <div className={trackCoverClass}>
      <Image
        url={Common.GetGenreArtUrl(track, 0)}
        missingImageUrl={Common.GetSlugImageUrl(0)}
        onClick={onPlayerAction.bind(this, {
          type: "ShowAlbumInfo",
          track: track
        })}
      />
    </div>
  );
};

const TrackMetadata = ({ track = {}, state = null }) => {
  let key = 1;
  const trackDescription = [];
  const trackKeywords = [];
  const trackKeywordLinks = [];

  if (track.TrackDescription) {
    trackDescription.push(
      <span className="no-wrap uppercase" key="instrumentation">
        {track.TrackDescription}
      </span>
    )
  }

  trackKeywords.push(
    <span className="uppercase" key="instrumentation">
      {track.Instrumentation}
    </span>
  );

  const handleAddFilter = (filterType, filter) => {
    filter.FilterType = filterType;
    Common.UserInterface.AddSearchFilter(filter, false, true);
  };

  if (track.LooksLike) {
    track.LooksLike.forEach((looksLike, index) => {
      if (state.hideFilterLinks) {
        trackKeywordLinks.push(
          <span key={key++}>
            <span className="not-link">{looksLike.Name}</span>
          </span>
        );
      } else {
        trackKeywordLinks.push(
          <span key={key++}>
            <span
              className="link"
              onClick={() => handleAddFilter("LooksLike", looksLike)}
            >
              {looksLike.Name}
            </span>
          </span>
        );
      }
    });
  }

  if (track.SoundsLike) {
    track.SoundsLike.forEach((soundsLike, index) => {
      if (state.hideFilterLinks) {
        trackKeywordLinks.push(
          <span key={key++}>
            <span className="not-link">{soundsLike.Name}</span>
          </span>
        );
      } else {
        trackKeywordLinks.push(
          <span key={key++}>
            <span
              className="link"
              onClick={() => handleAddFilter("SoundsLike", soundsLike)}
            >
              {soundsLike.Name}
            </span>
          </span>
        );
      }
    });
  }

  return (
    <div className="track-meta">
      <div>
        <div className="ellipsis">{trackDescription}</div>
        <div>{intersperse(trackKeywords, ', ')}</div>
        <div>{intersperse(trackKeywordLinks, ', ')}</div>
      </div>
    </div>
  );
};

const intersperse = (arr, sep) => {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(function (xs, x, i) {
    return xs.concat([sep, x]);
  }, [arr[0]]);
}

export { TrackResults };
