import { List, Map } from 'immutable'
import { getCookie } from '../../utility/utility'

import * as Actions from './actions'

// NOTE: This whole store needs to be reworked so QuickSearch, BasicSearch and AdvancedSearch are all one, instead of seperate sections.  In the interest of time, it has been decided to leave it as is.  We can refactor at a later time.

export const createEmptyContextMenuItems = () => {
  return {
    AdTiming: [],
    Collection: [],
    Composer: [],
    Genre: [],
    Mood: [],
    Tempo: [],
    Energy: [],
    LooksLike: [],
    SoundsLike: []
  }
};

export const createEmptyAdvancedSearch = () => {
  return {
    AdTiming: [],
    DurationMin: null,
    DurationMax: null,
    Exclude: '',
    ExcludeCollectionFlag: false,
    ExcludeComposerFlag: false,
    ExcludeGenreFlag: false,
    ExcludeMoodFlag: false,
    ExcludeTempoFlag: false,
    ExcludeEnergyFlag: false,
    ExcludeLooksLikeFlag: false,
    ExcludeSoundsLikeFlag: false,
    ExcludePlaylistTracks: false,
    Exclusive: true,
    NonExclusive: true,
    IncludeType: { Id: 'All', Text: 'All' },
    Include: '',
    NewAsOfEnabled: false,
    NewAsOfDate: null,
    SearchText: '',
    SearchTextFlag: true,
    TrackPrefix: null,
    Title: null,
    SortType: 'Relevant',
    SelectedContextMenuItems: createEmptyContextMenuItems(),

    Scorekeepers: true,
    IndieOrange: true,
    PleasantPictures: true,
    Classical: true,
    BigThree: true,
  }
}

const createEmptyBasicSearch = () => {
  return {
    AdTiming: null,
    DurationMin: null,
    DurationMax: null,
    Energy: null,
    Tempo: null,
    Genre: [],
    Mood: [],
    LooksLike: [],
    SoundsLike: []
  }
}

const createEmptyPlaylistFilters = () => {
  return {
    AdTiming: [],
    DurationMin: null,
    DurationMax: null,
    Energy: [],
    Tempo: [],
    Genre: [],
    Mood: [],
    LooksLike: [],
    SoundsLike: []
  }
}

const resetBasicSearchPanels = () => {
  return {
    Genre: false,
    Mood: false,
    Tempo: false,
    Energy: false,
    Duration: false,
    AdTiming: false,
    Scenes: false,
    SoundsLike: false
  }
}

const resetPlaylistFilterPanels = () => {
  return {
    DurationMin: null,
    DurationMax: null,
    Genre: false,
    Mood: false,
    Tempo: false,
    Energy: false,
    Duration: false,
    AdTiming: false,
    Scenes: false,
    SoundsLike: false
  }
}

const getRandomMessage = (messages, messageType, lang = "en") => {
  const randomMessages = messages.filter(v => v.MessageType === messageType);
  const randomIndex = Math.floor(Math.random() * Math.floor(randomMessages.length));
  const countryCode = getCookie("countryCode") || 'US';  
  let tempMessage = '';

  // // --- temporary block --- Temporary until we can fix the search issue for non-english 
  // return randomMessages[randomIndex].English; 
  // // --- end temporary block ---
  
  switch (countryCode.toLowerCase()) {
    case "es":     
      tempMessage = randomMessages[randomIndex].Spanish; 
      break;
    case "it": 
      tempMessage = randomMessages[randomIndex].Italian; 
      break;
    default: 
      tempMessage = randomMessages[randomIndex].English;
      break;
  }  

  if (tempMessage != null) {
    return tempMessage;  
  }
  
  return randomMessages[randomIndex].MessageText;
}


/**
 * Initial reducer state
 */
export const initialUserInterfaceState = Map({
  AdvancedSearch: createEmptyAdvancedSearch(),
  AuditionedTracks: [],
  BasicSearch: createEmptyBasicSearch(),
  BasicSearchPanels: resetBasicSearchPanels(),
  ContextData: {
    AdTiming: [
      {
        Label: '15 Second',
        Value: {
          Id: 15,
          Name: '15 Second'
        }
      },
      {
        Label: '30 Second',
        Value: {
          Id: 30,
          Name: '30 Second'
        }
      },
      {
        Label: '60 Second',
        Value: {
          Id: 60,
          Name: '60 Second'
        }
      }
    ],
    Collection: [],
    Energy: [],
    Genre: [],
    LooksLike: [],
    Mood: [],
    Tempo: [],
    SoundsLike: [],
  },
  IsLoading: false,
  Messages: [],
  NewSearch: true,
  QuickSearchText: '',
  QuickSearchPlaceholder: '',
  PlaylistFilters: createEmptyPlaylistFilters(),
  PlaylistFilterPanels: resetPlaylistFilterPanels(),
  Preferences: {},
  ResetPasswordToken: null,
  NoSearchResultsFoundMessage: '',
  SearchSortType: 'Relevant',
  SelectedPlaylistTab: 0,
  SelectedPlaylistTrack: null,
  SelectedSearchFilters: [],
  SelectedSearchTab: 0,
  ShowPlaylistExtension: false,
  ShowSuggestionsDropDown: false,
  ToggledSearchTracks: [],
  ToggledPlaylistTracks: [],
  UserPlaylists: List(),
  UserSharedPlaylists: List(),
  ShowMultiplePlaylist: false
})


/**
 * Home view reducer
 */
export const userInterfaceReducer = {
  [Actions.UI_CLEAR_ADVANCED_SEARCH]: (state, action) => {
    const newState = state.toJS();
    const originalExcludePlaylistTracksValue = newState.AdvancedSearch.ExcludePlaylistTracks;
    const { clearQuickSearchText } = action.payload;
    newState.QuickSearchText = '';
    const advancedSearchText = newState.AdvancedSearch.SearchText;
    newState.AdvancedSearch = createEmptyAdvancedSearch();

    // 04/13/2019 - When resetting advanced search, the ExcludePlaylistTracks filter should NOT be reset
    newState.AdvancedSearch.ExcludePlaylistTracks = originalExcludePlaylistTracksValue;

    if (!clearQuickSearchText) {
      newState.AdvancedSearch.SearchText = advancedSearchText;
    }
    newState.NewSearch = true;
    newState.NoSearchResultsFoundMessage = '';
    return Map(newState);
  },

  [Actions.UI_CLEAR_ALL_SELECTED_ITEMS]: (state, action) => {
    const newState = state.toJS();
    if (action.filterType != null) {
      newState.AdvancedSearch.SelectedContextMenuItems[action.filterType] = [];
    } else {
      newState.AdvancedSearch.SelectedContextMenuItems = createEmptyContextMenuItems();
    }

    return Map(newState);
  },

  [Actions.UI_CLEAR_ALL_PLAYLIST_SELECTED_ITEMS]: (state, action) => {
    const newState = state.toJS();
    newState.PlaylistFilters[action.filterType] = [];
    return Map(newState);
  },

  [Actions.UI_CLEAR_AUDITION_HISTORY]: (state, action) => {
    const newState = state.toJS();
    newState.AuditionedTracks = [];
    localStorage.removeItem('audition-history');
    return Map(newState);
  },

  [Actions.UI_CLEAR_BASIC_SEARCH]: (state, action) => {
    const newState = state.toJS();
    newState.BasicSearch = createEmptyBasicSearch();
    newState.BasicSearchPanels = resetBasicSearchPanels();
    return Map(newState);
  },

  [Actions.UI_CLEAR_PLAYLIST_FILTERS]: (state, action) => {
    const newState = state.toJS();
    newState.PlaylistFilters = createEmptyPlaylistFilters();
    return Map(newState);
  },

  [Actions.UI_SHOW_RESET_PASSWORD]: (state, action) => {
    const newState = state.toJS();
    newState.ResetPasswordToken = action.token;
    return Map(newState);
  },

  [Actions.UI_SET_ADVANCED_SEARCH]: (state, action) => {
    return state.setIn(['AdvancedSearch'], action.searchQuery);
  },

  [Actions.UI_SET_ADVANCED_SEARCH_FIELD_VALUE]: (state, action) => {
    const newState = state.toJS();
    const advancedSearch = newState.AdvancedSearch;
    advancedSearch[action.fieldType] = action.fieldValue;
    return state.set(newState);
  },

  [Actions.UI_SET_APPLICATION_MESSAGES]: (state, action) => {
    const newState = state.toJS();
    newState.Messages = action.messages;

    newState.QuickSearchPlaceholder = getRandomMessage(action.messages, 'Search');
    newState.NoSearchResultsFoundMessage = getRandomMessage(newState.Messages, 'FirstLook');

    return Map(newState);
  },

  [Actions.UI_SET_BASIC_SEARCH_FIELD_VALUE]: (state, action) => {
    const newState = state.toJS();
    const basicSearch = newState.BasicSearch;
    basicSearch[action.fieldType] = action.fieldValue;
    return Map(newState);
  },

  [Actions.UI_CLEAR_QUICK_SEARCH]: (state, action) => {
    const newState = state.toJS();
    const advancedSearch = newState.AdvancedSearch;
    advancedSearch.SearchText = '';
    advancedSearch.SearchTextFlag = false;
    return state.set(newState);
  },

  [Actions.UI_CLEAR_TOGGLED_SEARCH_TRACKS]: (state, action) => {
    const newState = state.toJS();
    newState.ToggledSearchTracks = [];
    return Map(newState);
  },

  [Actions.UI_SET_PLAYLIST_FILTER_FIELD_VALUE]: (state, action) => {
    const newState = state.toJS();
    const playlistFilters = newState.PlaylistFilters;
    playlistFilters[action.fieldType] = action.fieldValue;
    return state.set(newState);
  },

  [Actions.UI_SET_SHARED_FLAG]: (state, action) => {
    const newState = state.toJS();
    newState.SharedFlag = action.sharedFlag;
    return state.set(newState);
  },

  [Actions.UI_SET_SHARED_PLAYLIST_COUNT]: (state, action) => {
    const newState = state.toJS();
    newState.SharedPlaylistCount = action.sharedPlaylistCount;
    return state.set(newState);
  },

  [Actions.UI_SET_CONTEXT_MENU_DATA]: (state, action) => {
    const newState = state.toJS();
    const contextData = state.toJS().ContextData;

    // TODO: 11/06/2018 - This should really be in the new Scorekeepers database and should be sorted based
    // on a sort id.  However, this is a quick solution to solve the issue until that is in place.
    switch (action.contextMenuType.toLowerCase()) {
      // case "tempo":
      //   contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "Slow"));
      //   contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "Medium Slow"));
      //   contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "Mid Tempo"));
      //   contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "Fast"));
      //   contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "Medium Fast"));
      //   contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "Very Fast"));
      //   break;
      case "energy":
        contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "Low"));
        contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "Medium"));
        contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "High"));
        contextData[action.contextMenuType].push(action.menu.find(m => m.Label === "Building"));
        break;
      default:
        contextData[action.contextMenuType] = action.menu;
        break;
    }

    return state.set(newState);
  },

  [Actions.UI_SET_IS_LOADING]: (state, action) => {
    return state.set('IsLoading', action.isLoading);
  },

  [Actions.UI_SET_IS_SEARCHING]: (state, action) => {
    state = state.set('NewSearch', false);
    state = state.set('IsSearching', action.isSearching);
    return state;
  },

  [Actions.UI_SET_NAVIGATION_MENU]: (state, action) => {
    return state.set('NavigationMenu', action.menu);
  },

  [Actions.UI_SET_QUICK_SEARCH_TEXT]: (state, action) => {
    const newState = state.toJS();
    const advancedSearch = newState.AdvancedSearch;
    advancedSearch.SearchText = action.value;
    advancedSearch.SearchTextFlag = true;
    return state.set(newState);
  },

  [Actions.UI_SET_ADVANCED_SELECTED_CONTEXT_MENU_ITEM]: (state, action) => {
    const newState = state.toJS();
    const itemList = newState.AdvancedSearch.SelectedContextMenuItems[action.contextType];

    if (action.isChecked) {
      newState.AdvancedSearch.SelectedContextMenuItems[action.contextType] = action.contextItem;
    } else {
      if (action.contextItem.constructor !== Array) {
        const index = itemList.findIndex(v => v.Value.Id === action.contextItem.Value.Id);

        if (index > -1) {
          itemList.splice(index, 1);
        }
      } else {
        newState.AdvancedSearch.SelectedContextMenuItems[action.contextType] = action.contextItem;
      }
    }

    return Map(newState);
  },

  [Actions.UI_SET_AUDITIONED_STATUS]: (state, action) => {
    const newState = state.toJS();
    const index = newState.AuditionedTracks.findIndex(trackId => trackId === action.track.Id);

    if (index === -1)
      newState.AuditionedTracks.push(action.track.Id)

    return Map(Object.assign({}, newState));
  },

  [Actions.UI_SET_BASIC_SELECTED_CONTEXT_MENU_ITEM]: (state, action) => {
    const newState = state.toJS();

    if (action.contextItem.constructor === Array)
      newState.BasicSearch[action.contextType] = action.contextItem;
    else {
      const index = newState.BasicSearch[action.contextType].findIndex(v => v.Value.Id === action.contextItem.Value.Id);

      if (index !== -1)
        newState.BasicSearch[action.contextType].splice(index, 1);
      else
        newState.BasicSearch[action.contextType].push(action.contextItem);
    }

    return Map(newState);
  },

  [Actions.UI_SET_PLAYLIST_FILTERS_SELECTED_CONTEXT_MENU_ITEM]: (state, action) => {
    const newState = state.toJS();

    if (action.isChecked) {
      newState.PlaylistFilters[action.contextType] = action.contextItem;
    } else {
      if (action.contextItem.constructor === Array)
        newState.PlaylistFilters[action.contextType] = action.contextItem;
      else {
        const index = newState.PlaylistFilters[action.contextType].findIndex(v => v.Value.Id === action.contextItem.Value.Id);

        if (index !== -1)
          newState.PlaylistFilters[action.contextType].splice(index, 1);
        else
          newState.PlaylistFilters[action.contextType].push(action.contextItem);
      }
    }

    return Map(newState);
  },

  [Actions.UI_SET_PREFERENCES]: (state, action) => {
    return state.set('Preferences', action.preferences);
  },

  [Actions.UI_SET_NO_RESULTS_FOUND_MESSAGE]: (state, action) => {
    return state.set('NoSearchResultsFoundMessage', action.message);
  },

  [Actions.UI_SET_SEARCH_SORT_TYPE]: (state, action) => {
    return state.set('SearchSortType', action.sortType);
  },

  [Actions.UI_SET_SELECTED_PLAYLIST_TAB]: (state, action) => {
    return state.set('SelectedPlaylistTab', action.selectedTab);
  },

  [Actions.UI_SET_SELECTED_PLAYLIST_TRACK]: (state, action) => {
    return state.set('SelectedPlaylistTrack', action.track);
  },

  [Actions.UI_SET_SELECTED_SEARCH_FILTERS]: (state, action) => {
    return state.set('SelectedSearchFilters', action.filters);
  },

  // TODO: Move this to a common Search Values set
  [Actions.UI_SET_SEARCH_FILTER]: (state, action) => {
    const newState = state.toJS();
    const { filter, resetFilters } = action.filter;
    const basicSearch = newState.BasicSearch;
    const contextMenuItems = resetFilters ? createEmptyContextMenuItems() : newState.AdvancedSearch.SelectedContextMenuItems;
    if (resetFilters) {
      newState.AdvancedSearch.Title = '';
      newState.AdvancedSearch.SearchText = '';
      newState.AdvancedSearch.SearchTextFlag = false;
    }

    const newFilter = {
      Label: filter.Name,
      Value: {
        Id: filter.Id,
        Name: filter.Name
      }
    }

    contextMenuItems[filter.FilterType] = [];
    contextMenuItems[filter.FilterType].push(newFilter);
    newState.AdvancedSearch.SelectedContextMenuItems = contextMenuItems;

    return Map(newState);
  },

  [Actions.UI_SET_SELECTED_SEARCH_TAB]: (state, action) => {
    return state.set('SelectedSearchTab', action.selectedTab);
  },

  [Actions.UI_SET_SHOW_SUGGESTIONS_DROP_DOWN]: (state, action) => {
    return state.set('ShowSuggestionsDropDown', action.show);
  },

  [Actions.UI_SET_USER_DETAILS]: (state, action) => {
    let { UserPlaylists } = state;
    if (!action.userDetails) //logout
      UserPlaylists = List();
    return state.set('UserDetails', action.userDetails).set('UserPlaylists', UserPlaylists);
  },

  [Actions.UI_TOGGLE_PLAYLIST_EXTENSION]: (state, action) => {
    return state.set('ShowPlaylistExtension', action.show);
  },

  [Actions.UI_SET_USER_MULTIPLE_PLAYLIST]: (state, action) => {
    localStorage.setItem("ShowMultiplePlaylist", action.show)
    return state.set('ShowMultiplePlaylist', action.show);
  },

  [Actions.UI_TOGGLE_BASIC_SEARCH_PANEL]: (state, action) => {
    const newState = state.toJS();
    const basicSearchPanels = newState.BasicSearchPanels;

    basicSearchPanels[action.panelName] = action.toggled;
    return Map(newState);
  },

  [Actions.UI_TOGGLE_PLAYLIST_FILTER_PANEL]: (state, action) => {
    const newState = state.toJS();
    const playlistFilterPanels = newState.PlaylistFilterPanels;

    playlistFilterPanels[action.panelName] = action.toggled;
    return Map(newState);
  },

  [Actions.UI_TOGGLE_PLAYLIST_TRACK]: (state, action) => {
    const newState = state.toJS();

    if (newState.ToggledPlaylistTracks[0] === action.track)
      newState.ToggledPlaylistTracks = [];
    else
      newState.ToggledPlaylistTracks = [action.track];

    return Map(newState);
  },

  [Actions.UI_TOGGLE_SEARCH_RESULTS_TRACK]: (state, action) => {
    const newState = state.toJS();

    if (newState.ToggledSearchTracks[0] === action.track)
      newState.ToggledSearchTracks = [];
    else
      newState.ToggledSearchTracks = [action.track];

    return Map(newState);
  },

  [Actions.UI_UPDATE_QUICK_SEARCH_PLACEHOLDER]: (state, actions) => {
    let attemptCount = 0;
    const newState = state.toJS();
    const currentMessage = newState.QuickSearchPlaceholder;

    let newMessage = currentMessage;

    // Since we added translations the same message is used multiple times for Italian 
    // and Spanish, so we will try for 3 attempts to get a new message before giving up
    while (newMessage === currentMessage && attemptCount < 3) {
      attemptCount++;
      newMessage = getRandomMessage(newState.Messages, 'Search');
    }

    newState.QuickSearchPlaceholder = newMessage;
    return Map(newState);
  },

  [Actions.UI_SET_USER_PLAYLISTS]: (state, action) => {
    var newState = state.toJS();
    newState.UserPlaylists = List(action.playlists);
    return Map(newState);
  },

  [Actions.UI_SET_USER_SHARED_PLAYLISTS]: (state, action) => {
    var newState = state.toJS();
    newState.UserSharedPlaylists = List(action.playlists);
    return Map(newState);
  },

}
