import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import equal from 'fast-deep-equal';
import { Flex, FontIcon, FontIconButton, ScrollableList } from 'components/ui';
import {getTranslation} from 'utility/common';
import './playlist-tracks.scss';
import {store} from 'store'
import {getTrackTitle} from 'utility/common'
import {
    updatePreviousSearches
  } from 'store/previousSearches/actions';

  var activeTrackId = '';

export class PlaylistTracks extends React.Component {
    constructor() {
        super(...arguments);

        this.PlaylistTracksElement = null;
        this.ScrollableList = null;

        this.state = {
            width: 290,
            height: 962
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);

        if (this.props.owner)
          this.props.owner.onResize = this.updateDimensions;

        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentWillReceiveProps(nextProps) {
        if (!equal(this.props.tracks, nextProps.tracks)) {
            setTimeout(() => {
                this.updateDimensions();
            }, 10);
        }
    }

    updateDimensions = () => {
        if (this.PlaylistTracksElement == null) return;
        let reactDOM = ReactDOM;

        const rect = this.PlaylistTracksElement.getBoundingClientRect();

        this.setState({
          width: this.state.width,
          height: rect.height === 0 ? this.state.height : rect.height
        });

        this.forceUpdate();
    }

    render = () => {
        const {
            tracks = [],
            selectedTrack = {},
            onPlayTrack = () => {},
            playListId = null
        } = this.props;

        const rowRenderer = (item) => {
            const track = tracks[item.index];
            const isSelected = selectedTrack ? track.Id === selectedTrack.Id : false;

            return (
                <PlaylistTrack
                    key={item.key}
                    index={item.index}
                    style={item.style}
                    trackId={track.Id}
                    track={track}
                    selected={isSelected}
                    onSelect={onPlayTrack.bind(this, track)}
                    playListId={playListId}/>
            )
        }

        if (tracks.length > 0) {
            return (
                <div ref={(i) => this.PlaylistTracksElement = i} className='playlist-tracks'>
                    <ScrollableList
                        ref={(i) => window.ScrollableList = i}
                        rowRenderer={rowRenderer}
                        rowCount={tracks.length || 0}
                        rowHeight={45}
                        height={this.state.height}
                        width={this.state.width} />
                </div>
            )
        } else {
            return (
                <Flex className='empty-playlist'>{getTranslation("Empty Playlist")}</Flex>
            )
        }
    }
}

class PlaylistTrack extends React.Component {
    constructor() {
        super();
        this.preventSelection = false;

        this.handleTrackEvent = (event) => {
            if (Common.TrackPlayer.AudioSource != "playlist") return;

            switch (event.eventType) {
                case "playing": {
                    this.state.isPlaying = true;
                    this.forceUpdate();
                    break;
                }
                case "loadstart": {
                    this.state.isPlaying = true;
                    this.forceUpdate();
                    break;
                }
                case "paused": {
                    this.state.isPlaying = false;
                    this.forceUpdate();
                    break;
                }
                case "ended": {
                    this.state.isPlaying = false;
                    this.forceUpdate();
                    break;
                }
            }
        }

        this.state = {
            isPlaying: false
        }
    }

    componentWillMount() {
        Common.TrackPlayer.RegisterTrackNotifications(this.props.track.Id, this.handleTrackEvent);
    }

    componentWillUnmount() {
        Common.TrackPlayer.UnregisterTrackNotifications(this.props.track.Id, this.handleTrackEvent);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.isPlaying != this.props.isPlaying ||
            nextProps.track != this.props.track ||
            nextProps.onSelect != this.props.onSelect ||
            nextProps.selected != this.props.selected ||
            nextState.isPlaying != this.state.isPlaying)
    }

    get isPlaying() {
        const { track } = this.props;
        const { isPlaying } = this.state;

        if (Common.TrackPlayer.track == null)
            return false;

        return (isPlaying && Common.TrackPlayer.track.Id == track.Id);
    }

    handleDownloadTrack = (event) => {
        const rect = event.target.getBoundingClientRect();
        Common.UserInterface.ShowDownloadTrack(this.props.track, rect, true);
    }

    handleRemoveTrack = (event) => {
        const {
            track
        } = this.props;

        try {
            if (Common.WebPlayer.trackUrl === Common.GetPreviewUrl(track)) {
                Common.WebPlayer.stop();
            }
        } catch (e) {}

        let data={
            'type':'playlist',
            'playlist':this.props.track,
            'playListId':this.props.playListId
        }
        const previousSearches = Common.GetState().PreviousSearches.push(data);
        store.dispatch(updatePreviousSearches(previousSearches.toJS()));

        Common.Playlist.RemoveTrack(this.props.track);
        this.forceUpdate();
    }

    handleSelectTrack(event) {
        const {
            track,
            onSelect = () => {}
        } = this.props;

        onSelect(track);
        Common.UserInterface.SetSelectedPlaylistTrack(track);
        activeTrackId = track.Id;
    }

    handlePlayTrack() {
        // if (!this.isPlaying) {
            this.handleSelectTrack();
            this.setState({ isPlaying: true });
        // }

        activeTrackId = this.props.track.Id;
    }

    handlePauseTrack() {
        if (this.isPlaying) {
            Common.TrackPlayer.Pause();
            this.setState({ isPlaying: false });
        }
    }

    handleStopTrack() {
        this.setState({ isPlaying: false });
    }

    render() {
        const {
            track = {},
            height = null,
            onSelect = () => {},
            selected = false
        } = this.props;

        let isSelected = false;
        isSelected = activeTrackId === track.Id;
        const componentClass = classNames({
            'track': true,
            'selected': isSelected
        });

        const downloadIcon = Common.UserInterface.GetSource() == 'Web'
            ? 'download2'
            : 'folder';

        const playEvent = (this.state.isPlaying && Object.is(Common.TrackPlayer.track, track))
            ? this.handlePauseTrack.bind(this)
            : this.handlePlayTrack.bind(this);

        // const playButton =  this.state.isPlaying
        const playButton = (this.state.isPlaying && Object.is(Common.TrackPlayer.track, track))
            ? (<FontIcon name='circle-pause' color={'#85CC71'} size={25} />)
            : (<FontIcon name='circle-play' size={25} />)


        return (
            <div style={this.props.style || {}}>
            <Flex row fixed className={componentClass}>
                <Flex row onClick={playEvent}>
                    <Flex className='icon-player-button'>{ playButton }</Flex>
                    <Flex block className='title'>{getTrackTitle(track)}</Flex>
                </Flex>
                <Flex className='icons' row fixed>
                    <FontIcon name={downloadIcon} onClick={this.handleDownloadTrack.bind(this)} />
                    <FontIconButton name='close' onClick={this.handleRemoveTrack.bind(this)} />
                </Flex>
            </Flex>
            </div>
        )
    }
}