import uuid from 'uuid';

import CueSheet from './_cuesheet';
import Dialogs from './_dialogs';
import Helpers from './_helpers';
import Playlist from './_playlist';
import Search from './_search';
import UserInterface from './_userinterface';
import WebPlayer from './_audioPlayer';

import { GlobalAudioPlayer } from 'utility/api/common/audioPlayer';
import { TrackAudioPlayer } from 'utility/api/common/trackPlayer';
import { getTranslation } from 'utility/common'
import featureFlags from '../../../featureFlags'

class CommonAPI {
    constructor(store) {
        this.GetStore = () => store;
        this.GetState = () => store.getState();
        this.CueSheet = new CueSheet(store);
        this.Dialogs = new Dialogs(store);
        this.Helpers = new Helpers(store);
        this.Playlist = new Playlist(store);
        this.Search = new Search(store);
        this.UserInterface = new UserInterface(store);
        this.WebPlayer = new WebPlayer();
        this.AudioPlayer = new GlobalAudioPlayer();
        this.TrackPlayer = new TrackAudioPlayer();

        this.NewGuid = () => uuid.v4();

        this.ErrorHandler = (message, source, lineNumber, colNumber, error) => {
            Common.Helpers.LogError(message, 'Error', {
                version: 'web',
                message: message,
                source: source,
                lineNumber: lineNumber,
                colNumber: colNumber,
                error: error
            });

            return true;
        }

        this.GenerateGuid = () => {
            return uuid.v4();
        }

        this.GetDownloadIconName = (type) => {
            switch (type) {
                case 1:
                    return 'download2';
                case 2:
                    return 'download-circle';
            }
        }

        this.IsAudiofficina = () => {
            if (featureFlags.IsAudiofficina) return true            
            return (parent !== window && (document.referrer.indexOf('audiofficina.it') >= 0 || document.referrer.indexOf('thefirm.es') >= 0)) ||
            (parent === window && (document.location.hostname.toLowerCase().indexOf('audiofficina.it') >= 0 || document.location.hostname.toLowerCase().indexOf('thefirm.es') >= 0))
        }

        this.GetSearchSize = () => {
            return 100;
        }

        this.GetSearchState = () => {
            return store.getState().Search.toJS();
        }

        this.GetGenreArtUrl = (track, artType = 0) => {
            switch (artType) {
                case 0:     // small
                    return track.GenreCoverArt;
                case 1:     // large
                    return track.GenreCartArtLarge;
            }
        }

        this.GetMissingWaveformImageUrl = () => {
            return 'appearance/images/missing_waveform.png'
        }

        this.GetSlugImageUrl = (artType = 0) => {
            switch (artType) {
                case 0:     // small
                    return `https://sk_cd_art.s3.amazonaws.com/Slug.png`;
                case 1:     // large
                    return `https://sk_cd_art_large.s3.amazonaws.com/Slug.png`
            }
        }

        this.GetPreviewUrl = (track) => {
            return encodeURI(`https://s3.amazonaws.com/scorekeepers/audio/${track.Collection.Name}/${track.Title}.mp3`)
        }

        this.GetDownloadAllTracksText = () => {
            return getTranslation("Download All Tracks") + " ...";            
        }

        this.GetWaveformUrl = (track) => {
            let waveformUrl = track.WaveformUrl;

            if (waveformUrl == null) {
                waveformUrl = `${(process.env.WAVEFORM_URL)}/${track.Collection.Name}/${encodeURIComponent(track.Title)}.png?${Date.now()}`
            }

            waveformUrl = waveformUrl.replace(/[#]/g,"%23");
            // waveformUrl = waveformUrl.replace(/[+]/g,"%2B");

            return waveformUrl;
        }

        this.LoadGenreArtCache = () => { }

        this.ConvertToSeconds = (hhmmss) => {
            let seconds = 0;
            let parts = hhmmss.split(':').reverse();

            parts.forEach((p, i) => {
                const v = Number(p) != 'NaN' ? Number(p) : 0;
                if (i==0) seconds += v;
                if (i==1) seconds += (v * 60);
                if (i==2) seconds += (v * 3600);
            })

            return seconds;
        }

        this.ConvertToHHMMSS = (seconds) => {
            const ss = Number(seconds) % 60;
            const mm = parseInt(seconds / 60);
            const hh = parseInt(seconds / 3600);

            return ((hh > 0) ? hh + ':' : '') +
                   ((mm > 0) ? mm + ':' : ':') +
                   (ss.padLeft(2, '0'));
        }

        this.ShowAlertMessage = (title, message, width, height) => {
            Common.Dialogs.ShowAlertMessage({ title, message, width, height });
        }
    }

    get isSmallScale() {
        return window.innerWidth < 1500;
    }

    get isNormalScale() {
        return window.innerWidth >= 1500;
    }

    get windowHeight() {
        return parseInt(document.querySelector(".main-view").getBoundingClientRect().height);
    }
}

export default CommonAPI;