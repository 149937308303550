import { List, Map } from 'immutable'
import * as Actions from './actions'

const CreateEmptyCueSheet = (cueSheetId = null) => {
    return {
        Id: cueSheetId,
        UpdatedFlag: (cueSheetId != 0),
        Title: '',
        Network: '',
        Series: '',
        Episode: '',
        AirDate: '',
        ProgramLength: '',
        CompanyName: '',
        ContactName: '',
        PhoneNumber: '',
        EmailAddress: '',
        Lines: []
    };
}

const CreateEmptyCueSheetRow = (lineId = null) => {
    return {
        CueNumber: lineId,
        Title: '',
        TimeCodeIn: '',
        TimeCodeOut: '',
        TimeCodeLength: '',
        Usage: '',
        Composers: '',
        Publishers: '',
        Society: '',
        MusicSource: ''        
    }
}

const ReorderCueLineNumbers = (cueLines) => {
    for (var i = 0; i < cueLines.length; i++) {
        cueLines[i].CueNumber = i+1;
    }
    return cueLines;
}


/**
 * Initial reducer state
 */
export const initialCueSheetState = Map(CreateEmptyCueSheet());


/**
 * Home reducer
 */
export const cueSheetReducer = {
    [Actions.CUESHEET_CLEAR_CUESHEET]: (state, action) => {
        return Map(CreateEmptyCueSheet(state.Id));
    },

    [Actions.CUESHEET_CREATE_CUESHEET]: (state, action) => {
        return Map(CreateEmptyCueSheet());
    },

    [Actions.CUESHEET_INSERT_CUE_SHEET_ROW]: (state, action) => {
        const cueSheet = state.toJS();
        const cueSheetLine = CreateEmptyCueSheetRow(action.lineId)         
        
        cueSheet.Lines.forEach((line, index) => {
            if (index >= action.lineId) {
                line.CueNumber = line.CueNumber + 1;
            }
        });

        cueSheet.Lines.splice(action.lineId, 0, cueSheetLine);
        cueSheet.HasChanges = true;        
        cueSheet.Lines = ReorderCueLineNumbers(cueSheet.Lines);

        return Map(Object.assign({}, state.toJS(), {
            UpdatedFlag: true,
            Lines: cueSheet.Lines
        }));
    },

    [Actions.CUESHEET_SET_CUESHEET]: (state, action) => {
        return Map(Object.assign({}, action.cueSheet, {
            UpdateFlag: false
        }));
    },

    [Actions.CUESHEET_SET_CUESHEET_TITLE]: (state, action) => {
        return Map(Object.assign({}, state.toJS(), {
            Title: action.title
        }));
    },

    [Actions.CUESHEET_UPDATE_CUE_SHEET_ROW]: (state, action) => {    
        const cueSheet = state.toJS();
        const lineIndex = cueSheet.Lines.findIndex(x => x.CueNumber == action.line.CueNumber);
        cueSheet.Lines[lineIndex] = action.line;

        return Map(Object.assign({}, state.toJS(), {
            UpdatedFlag: true,
            Lines: cueSheet.Lines
        }));
    },    

    [Actions.CUESHEET_UPDATE_FIELD_VALUE]: (state, action) => {
        return Map(Object.assign({}, state.toJS(), {
            UpdatedFlag: true,
            [action.fieldName]: action.value
        }));
    }
};
