import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { showContextMenu, closeContextMenu } from 'components/ui/context-menu/context-menu';
import { 
    Button, 
    Flex, 
    FontIcon, 
    FontIconButton,
    ScrollWindow,
    Tab,
    Tabs,
    TextField,
} from 'components/ui';
import { basicFieldSorter, getTranslation } from 'utility/common';
import './playlistFilters.scss';

const getFormattedDuration = (value) => {
    if (!value) return '';
    return Common.ConvertToHHMMSS(value);
}

class PlaylistFilters extends React.Component {
    constructor() {
        super(...arguments);
    }

    get PlaylistFilters() {
        return this.UserInterface.PlaylistFilters;
    }

    get UserInterface() {
        const { UserInterface } = this.props;
        return UserInterface ? UserInterface.toJS() : {};
    }

    handleClearAllItems(type) {
        Common.UserInterface.ClearAllPlaylistSelectedItems(type);
    }    

    handleClearSearch() {
        if (Common.TrackPlayer.IsPlaying) Common.TrackPlayer.Stop();
        Common.UserInterface.ClearPlaylistFilters();
    }
    
    handleContextMenuSelect(type, item, checked) {    
        Common.UserInterface.SetPlaylistFiltersSelectedContextMenuItem(type, item, checked);
    }

    handleFieldValueChanged(fieldType, fieldValue) {
        let value = 0;
        
        if (fieldValue.indexOf(':') >= 0) {
            value = Common.ConvertToSeconds(fieldValue).toString();
        } else {
            value = fieldValue ? fieldValue : this[fieldType].value;
        } 

        Common.UserInterface.UpdatePlaylistFiltersField(fieldType, value);         
    } 

    handleFieldEnter(fieldType, fieldValue, c) {        
        let value = 0;
        
        if (fieldValue.indexOf(':') >= 0) {
            value = Common.ConvertToSeconds(fieldValue).toString();
        } else {
            value = fieldValue ? fieldValue : this[fieldType].value;
        } 

        Common.UserInterface.UpdatePlaylistFiltersField(fieldType, value);  
        this.handleSearch();
    }      
    
    handleAdTimingToggleValueChanged(fieldType, fieldValue) {
        var adTiming = this.UserInterface.PlaylistFilters.AdTiming;
        var adTimingIndex = adTiming.findIndex(v => v == fieldValue);

        if (adTimingIndex >= 0) 
            adTiming.splice(adTimingIndex, 1)
        else
            adTiming.push(fieldValue);

        Common.UserInterface.UpdatePlaylistFiltersField(fieldType, adTiming);
    }    

    handleRemoveSelectedItem(type, index) {
        const item = this.PlaylistFilters[type][index];
        this.handleContextMenuSelect(type, item, false);                  
    }

    handleTogglePanel(panelName, toggled) {
        Common.UserInterface.TogglePlaylistFilterPanel(panelName, toggled);
    } 

    render() {
        const selectedItems = [];
        const playlistFiltersTitle = (<span>Playlist Filter</span>);
        
        selectedItems['AdTiming'] = getSelectedItemsElement(this.UserInterface.PlaylistFilters.AdTiming, this.handleRemoveSelectedItem.bind(this, 'AdTiming'), this.handleClearAllItems.bind(this, 'AdTiming'));
        selectedItems['Genre'] = getSelectedItemsElement(this.UserInterface.PlaylistFilters.Genre, this.handleRemoveSelectedItem.bind(this, 'Genre'), this.handleClearAllItems.bind(this, 'Genre'));
        selectedItems['Mood'] = getSelectedItemsElement(this.UserInterface.PlaylistFilters.Mood, this.handleRemoveSelectedItem.bind(this, 'Mood'), this.handleClearAllItems.bind(this, 'Mood'));
        selectedItems['Energy'] = getSelectedItemsElement(this.UserInterface.PlaylistFilters.Energy, this.handleRemoveSelectedItem.bind(this, 'Energy'), this.handleClearAllItems.bind(this, 'Energy'));
        selectedItems['Tempo'] = getSelectedItemsElement(this.UserInterface.PlaylistFilters.Tempo, this.handleRemoveSelectedItem.bind(this, 'Tempo'), this.handleClearAllItems.bind(this, 'Tempo'));
        selectedItems['SoundsLike'] = getSelectedItemsElement(this.UserInterface.PlaylistFilters.SoundsLike, this.handleRemoveSelectedItem.bind(this, 'SoundsLike'), this.handleClearAllItems.bind(this, 'SoundsLike'));
        selectedItems['LooksLike'] = getSelectedItemsElement(this.UserInterface.PlaylistFilters.LooksLike, this.handleRemoveSelectedItem.bind(this, 'LooksLike'), this.handleClearAllItems.bind(this, 'LooksLike'));

        return (
            <Flex className='playlist-filters' fixed>
                <Tabs>
                    <Tab label={playlistFiltersTitle} selected={true} noSelect={true}>
                        <Flex>
                            <ScrollWindow className='basic-filters'>
                                <BasicSearchSection title='Genre' toggled={this.UserInterface.PlaylistFilters.Genre.length > 0} contextMenuName='Genre' multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'Genre')}>
                                    <Flex className='section-row'>
                                        {selectedItems['Genre']} 
                                    </Flex>
                                </BasicSearchSection>
                                <BasicSearchSection title='Mood' toggled={this.UserInterface.PlaylistFilters.Mood.length > 0} contextMenuName='Mood' multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'Mood')}>
                                    <Flex className='section-row'>
                                        {selectedItems['Mood']} 
                                    </Flex>
                                </BasicSearchSection>
                                <BasicSearchSection title='Tempo' toggled={this.UserInterface.PlaylistFilters.Tempo.length > 0} multiSelect={true} contextMenuName='Tempo' onSelect={this.handleContextMenuSelect.bind(this, 'Tempo')}>
                                    <Flex className='section-row'>
                                        <Flex block>{selectedItems['Tempo']}</Flex>
                                    </Flex>
                                </BasicSearchSection>  
                                <BasicSearchSection title='Energy' toggled={this.UserInterface.PlaylistFilters.Energy.length > 0} multiSelect={true} contextMenuName='Energy' onSelect={this.handleContextMenuSelect.bind(this, 'Energy')}>
                                    <Flex className='section-row'>
                                        <Flex block>{selectedItems['Energy']}</Flex>
                                    </Flex>
                                </BasicSearchSection>                                      
                                <BasicSearchSection title={getTranslation('scenes_short')} icon='looks-like' toggled={this.UserInterface.PlaylistFilters.LooksLike.length > 0} contextMenuName='LooksLike' multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'LooksLike')}>
                                    <Flex className='section-row'>
                                        {selectedItems['LooksLike']} 
                                    </Flex>
                                </BasicSearchSection>
                                <BasicSearchSection title={getTranslation('sounds_like')} icon='sounds-like' toggled={this.UserInterface.PlaylistFilters.SoundsLike.length > 0} contextMenuName='SoundsLike' multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'SoundsLike')}>
                                    <Flex className='section-row'>
                                        {selectedItems['SoundsLike']} 
                                    </Flex>
                                </BasicSearchSection>
                                <BasicSearchSection title='Ad Timing' toggled={this.UserInterface.PlaylistFilters.AdTiming.length > 0} contextMenuName='AdTiming' multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'AdTiming')}>
                                    <Flex className='section-row'>
                                        {selectedItems['AdTiming']} 
                                    </Flex>
                                </BasicSearchSection>
                                <BasicSearchSection hideToggle={true} toggled={true}>
                                    <Flex row height={50}>            
                                        <Flex row className='title'>Duration</Flex>                
                                        <Flex row className='section-row'>
                                            <Flex width='75px'><TextField placeholder='Min' fontSize={12} height={30} width={71} ref={(i) => { this.DurationMin = i }} value={getFormattedDuration(this.UserInterface.PlaylistFilters.DurationMin)} showClearIcon={false} onTextChanged={this.handleFieldValueChanged.bind(this, 'DurationMin')} onEnter={this.handleFieldEnter.bind(this, 'DurationMin')} /></Flex>
                                            <Flex width='5px' style={{margin: '0 5px'}}><FontIcon name='caret-right' color='#888888' size={10} /></Flex>
                                            <Flex width='75px'><TextField placeholder='Max' fontSize={12} height={30} width={71} ref={(i) => { this.DurationMax = i }} value={getFormattedDuration(this.UserInterface.PlaylistFilters.DurationMax)} showClearIcon={false} onTextChanged={this.handleFieldValueChanged.bind(this, 'DurationMax')} onEnter={this.handleFieldEnter.bind(this, 'DurationMax')} /></Flex>
                                        </Flex>
                                    </Flex>
                                </BasicSearchSection>
                            </ScrollWindow>
                            <Flex height='60px'>
                                <Flex row className='footer'>
                                    <Flex>
                                        &nbsp;
                                    </Flex>
                                    <Flex position='right'>
                                        <Button type='secondary' onClick={this.handleClearSearch.bind(this)}>Reset</Button>
                                    </Flex>
                                </Flex>  
                            </Flex>                
                        </Flex>
                    </Tab>
                </Tabs>                
            </Flex>
        )
    }
}

class BasicSearchSection extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            openMenu: false
        }
    }

    handleCloseMenu() {
        this.setState({ openMenu: false });
    }

    handleShowContextMenu(e) {
        const { value = null } = this.props;

        const { contextMenu, contextMenuName = null, multiSelect = false, onSelect = () => {}} = this.props;
        const contextMenuData = contextMenuName ? Common.UserInterface.GetContextMenuData(contextMenuName) : contextMenu;
        const rect = ReactDOM.findDOMNode(this.toggleIcon).getBoundingClientRect();
        const selectedItems = value ? [ value ] : Common.UserInterface.GetPlaylistFiltersSelectedContextMenuItems(this.props.contextMenuName);   

        let playlistContextMenu = []
        const tPlaylist = Common.Playlist.GetPlaylist();
        tPlaylist.Tracks.forEach(t => {
            let attributeData = t[contextMenuName];

            if (contextMenuName === 'AdTiming' && attributeData) {
                const newMenu = playlistContextMenu.find(x => x.Value.Id === attributeData);

                if (!newMenu) {
                    playlistContextMenu.push(contextMenuData.find(x => x.Value.Id === attributeData));
                }
            } else {
                if (attributeData) {
                    if (contextMenuData[0].SubMenu.length === 0) {
                        const newMenu = playlistContextMenu.find(x => x.Value.Id === attributeData.Id);

                        if (!newMenu) {
                            playlistContextMenu.push(contextMenuData.find(x => x.Value.Id === attributeData.Id));
                        }
                    } else {                
                        if (attributeData.length == undefined) {
                            attributeData = [ attributeData ];
                        }

                        attributeData.forEach(a => {
                            // the context menu has a SubMenu                
                            let contextMenu = null;
                            let contextSubMenu = null;

                            contextMenuData.forEach(cm => {
                                const subMenu = cm.SubMenu.find(x => x.Value.Id === a.Id);
                                
                                if (subMenu != null) {
                                    contextMenu = Object.assign({}, cm);
                                    contextSubMenu = subMenu;
                                    return;
                                }
                            });

                            let newMenu = playlistContextMenu.find(x => x.Label === contextMenu.Label);

                            if (newMenu == null) {
                                newMenu = Object.assign({}, contextMenu, { SubMenu: [ contextSubMenu ] });
                                playlistContextMenu.push(newMenu);
                            } else {
                                if (!newMenu.SubMenu.find(x => x.Value.Id == contextSubMenu.Value.Id)) {
                                    newMenu.SubMenu.push(contextSubMenu);
                                }
                            }
                        })
                    }
                }
            }
        });

        this.setState({ openMenu: true });

        // sort all playlist context menu items
        if (playlistContextMenu) {
            playlistContextMenu.sort(basicFieldSorter('Label'));
            playlistContextMenu.forEach(pl => { 
                if (pl.SubMenu) 
                    pl.SubMenu.sort(basicFieldSorter('Label'));
                    pl.SubMenu.sort(basicFieldSorter('Label'));
            });
        }

        showContextMenu(rect.right, rect.top, playlistContextMenu, selectedItems, {             
                multiSelect: multiSelect,
                owner: this.contextOwner
            },  onSelect, null, this.handleCloseMenu.bind(this));        
    }    

    handleContextMenu(e) { 
        if (e.target.className.indexOf('toggle') != -1) return;

        if (this.props.toggleOnClick) {
            this.props.onToggle(!this.props.toggled);
            return;
        }         

        closeContextMenu();  
        this.handleShowContextMenu();
    }    

    handleMouseEnter(e) {
        closeContextMenu();
        this.handleShowContextMenu();
    }

    handleMouseLeave(e) {
        const target = e.relatedTarget;
        const context = document.getElementById("context-menu");
        const scrollbar1 = document.querySelector(".playlist-filters .scroll-window > div:last-child");
        const scrollbar2 = document.querySelector(".playlist-filters .scroll-window > div:last-child > div:first-child");

        try {
            // if we are NOT going to the context menu, close the context menu
            if (context && !context.contains(target) && target != scrollbar1 && target != scrollbar2) { 
                closeContextMenu();
            }
        } catch(ex) {}
    }

    render() {
        const {
            title = '',
            icon = null,
            toggled = false,
            hideToggle = false,
            children = null,
            onToggle = () => {}
        } = this.props;

        const sectionHeaderClass = classNames('section-header', { 'open-menu': this.state.openMenu });

        const toggledIcon = toggled
            ? (<div className='basic-search-untoggled' style={{width:48,height:48}} />)
            : (<div className='basic-search-untoggled' style={{width:48,height:48}} />);
        
        const sectionClass = classNames({
            'section': true,
            'no-toggle': hideToggle
        })

        const contentClass = classNames({
            'section-content': true,
            'toggled': toggled
        });

        const toggleIcon = !hideToggle 
            ? (<Flex className='toggle' fixed ref={(i) => { this.toggleIcon = i }}>{toggledIcon}</Flex>)
            : '';        

        const sectionIcon = icon  
        ? (
             <Flex className='section-icon'>
                <FontIcon name={icon} />
             </Flex>
          )
        : '';

        return (
            <div className={sectionClass} ref={(i) => { this.contextOwner = i }}>
                <Flex row className={sectionHeaderClass} onClick={this.handleContextMenu.bind(this)} onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)}>
                    { sectionIcon }
                    <Flex className='title' ref={(i) => { this.title = i }}>{title}</Flex>
                    { toggleIcon }
                </Flex>
                <Flex className={contentClass}>
                    {children}
                </Flex>
            </div>
        )
    }
}

class SelectedItem extends React.Component {
    constructor() {
        super(...arguments);
    }
     
    render() {
        const {
            index = null,
            item = null,
            label = null,
            onRemoveSelectedItem = () => {}
        } = this.props;

        return(
            <Flex row className='item' onClick={onRemoveSelectedItem.bind(this, index)}>
                <Flex width='90%' position='left' className='ellipsis'>{label}</Flex>
                <Flex width='10%'><FontIconButton name='close-thin' size={12} /></Flex>
            </Flex>            
        )
    }
}

class SelectedItemsClearAll extends React.Component {
    constructor() {
        super(...arguments);        
    }

    render() {
        const {
            onClearAllItems = () => {}
        } = this.props;

        return (
            <Flex row className='item-clear-all' onClick={onClearAllItems}>
                <Flex width='90%' position='right' className='ellipsis'>Clear All</Flex>
                <Flex width='10%'><FontIconButton name='close-thin' size={12} /></Flex>
            </Flex>            
        )
    }
}

const getSelectedItemsElement = (selectedItems = [], onRemoveSelectedItem, onClearAllItems) => {
    if (selectedItems.length == 0) return '';

    const items = selectedItems.map((m, i) => (<SelectedItem key={i} index={i} label={m.Label} item={m} onRemoveSelectedItem={onRemoveSelectedItem} />));
    const clearAllButton = selectedItems.length > 1 ? (<SelectedItemsClearAll onClearAllItems={onClearAllItems} />) : '';

    return (
        <Flex className='selected-items' width='100%'>
            <div style={{width:'100%'}}>
                { clearAllButton }
                { items }
            </div>
        </Flex>
    )
}

export default connect(state => {
    return {
        UserInterface: state.UserInterface
    }
})(PlaylistFilters);