const fetchAuth = () => {
    return new Promise((resolve, reject) => {
        fetchPost('/api/auth/isValid')
            .then(json => {                
                if (json.success) resolve(true);
                else throw Error('Not Authorized');
            })
            .catch(err => {
                reject(err);
            })
    });
}

const fetchUserDetails = () => {
    return new Promise((resolve, reject) => {
        fetchGet('/api/auth/userDetails')
            .then(json => {         
                if (json.success) resolve(json.userDetails);
                else throw Error('Not Authorized');
            })
            .catch(err => {
                reject(err);
            })
    });
}

const fetchGet = (url, data) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Source': 'scorekeepersmusic.com'
            },            
            body: data
        };

        fetch(url, content)
            .then(response => {
                if (!response.ok) throw new Error(response.message);
                response.json() 
                    .then(json => {
                        resolve(json);
                    });
            })
            .catch(err => {
                reject(err);
            })
    });
}

const fetchMultiPart = (url, data) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'POST',
            credentials: 'include',
            body: data            
        };

        fetch(url, content)
            .then(response => {
                if (!response.ok) throw new Error(response.message);
                response.json() 
                    .then(json => {
                        resolve(json);
                    });
            })
            .catch(err => {
                reject(err);
            })
    });
}

const fetchPost = (url, data) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Source': 'scorekeepersmusic.com'
            },
            body: JSON.stringify(data)            
        };

        fetch(url, content)
            .then(response => {
                if (!response.ok) throw new Error(response.message);
                response.json() 
                    .then(json => {
                        resolve(json);
                    });
            })
            .catch(err => {
                reject(err);
            })
    });
}

const fetchPostRaw = (url, data) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Source': 'scorekeepersmusic.com'
            },
            body: JSON.stringify(data)
        };

        fetch(url, content)
            .then(response => {
                if (!response.ok) throw new Error(response.message);
                resolve(response);
            })
            .catch(err => {
                reject(err);
            })
    });
}

export {
    fetchAuth,
    fetchGet,
    fetchMultiPart,
    fetchPost,
    fetchUserDetails,
    fetchPostRaw
};