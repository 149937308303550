import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showAlert, closeAlert } from 'components/ui/alert/alert'
import { Button, Dialog, Flex, FontIcon, TextField, Typewriter } from 'components/ui';
import './reset-password.scss';

const dialogName = 'ResetPasswordDialog';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

class ResetPasswordDialog extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      newPassword: null,
      confirmPassword: null,
      errorMessage: null
    }
  }

  componentDidMount() {
    this.NewPassword.focus();
  }

  handleCancel = () => {
    Common.Dialogs.ShowDialog(dialogName, false);
    this.onClose();
  }

  handleEscape = () => {
    Common.Dialogs.ShowDialog(dialogName, false);
    this.onClose();
  }

  handleOK = () => {
    this.setState({ errorMessage: null });
    
    if ((this.state.newPassword !== "") && (this.state.newPassword == this.state.confirmPassword)) {
      Common.UserInterface.ResetPassword(this.state.newPassword)
        .then(response => { 
          if (response.Success) {
            Common.Dialogs.ShowAlertMessage({
              title: 'Reset Password Successful',
              message: 'Your password was successfully reset. <br/> Please login to The Supervisor with your new password.',
              width: 500,
              height: 200,
              contentClass: 'reset-password-success'
            });

            Common.Dialogs.ShowDialog('ResetPasswordDialog', false);  
          } else {
            Common.Dialogs.ShowAlertMessage({
              title: 'Reset Password Failed',
              message: response.ErrorMessage,
              width: 500,
              height: 200,              
              contentClass: 'reset-password-error'
            });

            Common.Dialogs.ShowDialog('ResetPasswordDialog', false);
          }          
        });
    } else {
      this.setState({ errorMessage: 'The passwords do not match.'})
    }
  }

  render() {
    return (
      <Dialog title='Reset Password' name={dialogName} width={600} height={300} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel} onEscape={this.handleEscape}>
        <Flex className='reset-password-dialog' position='top-left'>
          <Flex row fixed className='header' position='top'>
            <FontIcon name='inspector' />
            <Flex position='left'><Typewriter message={'Please enter your new password and confirm the password below.'} /></Flex>                              
          </Flex>

          <Flex block className='form'>
            <Flex row>
              <Flex className='field-name' width={'20%'} position='right'>New Password:</Flex>
              <Flex width={'80%'} className='field-value'><TextField ref={(i) => { this.NewPassword = i }} type="password" onChange={(value) => this.setState({ newPassword: value })} value={this.state.newPassword} height='30px' showClearIcon={false} /></Flex>
            </Flex>
            <Flex row>
              <Flex className='field-name' width={'20%'} position='right'>Confirm Password:</Flex>
              <Flex width={'80%'} className='field-value'><TextField ref={(i) => { this.ConfirmPassword = i }} type="password" onChange={(value) => this.setState({ confirmPassword: value })} value={this.state.confirmPassword} height='30px' showClearIcon={false} /></Flex>
            </Flex>
            <div className='error-message'>{this.state.errorMessage || ' '}</div>
          </Flex>

          <Flex row className='footer' position="right">
            <Button onClick={this.handleOK}>OK</Button>
            <Button onClick={this.handleCancel}>Cancel</Button>
          </Flex>
        </Flex>
      </Dialog>
    )
  }
}

export default ResetPasswordDialog;
