export const CUESHEET_CLEAR_CUESHEET = 'CUESHEET_CLEAR_CUESHEET';
export const CUESHEET_CREATE_CUESHEET = 'CUESHEET_CREATE_CUESHEET';
export const CUESHEET_INSERT_CUE_SHEET_ROW = 'CUESHEET_INSERT_CUE_SHEET_ROW';
export const CUESHEET_SET_CUESHEET = 'CUESHEET_SET_CUESHEET';
export const CUESHEET_SET_CUESHEET_TITLE = 'CUESHEET_SET_CUESHEET_TITLE';
export const CUESHEET_UPDATE_CUE_SHEET_ROW = 'CUESHEET_UPDATE_CUE_SHEET_ROW';
export const CUESHEET_UPDATE_FIELD_VALUE   = 'CUESHEET_UPDATE_FIELD_VALUE';


export const clearCueSheet = () => {
    return {
        type: CUESHEET_CLEAR_CUESHEET
    }
}

export const createNewCueSheet = () => {
    return {
        type: CUESHEET_CREATE_CUESHEET
    }
}

export const insertCueSheetRow = (cueSheetId, lineId = 0) => {
    return {
        type: CUESHEET_INSERT_CUE_SHEET_ROW,
        cueSheetId: cueSheetId,
        lineId: lineId 
    }
}

export const setCueSheet = (cueSheet) => {
    return {
        type: CUESHEET_SET_CUESHEET,
        cueSheet: cueSheet
    }
}

export const setCueSheetTitle = (title) => {
    return {
        type: CUESHEET_SET_CUESHEET_TITLE,
        title: title
    }
}

export const updateCueSheetRow = (cueSheetId, line) => {
    return {
        type: CUESHEET_UPDATE_CUE_SHEET_ROW,
        line: line
    }
}

export const updateFieldValue = (fieldName, value) => {
    return {
        type: CUESHEET_UPDATE_FIELD_VALUE,
        fieldName: fieldName,
        value: value
    }
}