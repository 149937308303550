// const fs = require('fs');
// const readline = require('readline');
const RegexPatterns = require('../common/RegexPatterns');
const Event = require('../Event/Event');
const { basicFieldSorter } = require('../../common');

class EDL {
  constructor(frameRate) {
    this.title = '';
    this.frameRate = frameRate || 29.97;
    this.events = [];
    this.parseLine = this.parseLine.bind(this);
    this.trackFlag = false;
  }

  fromString(string) {
    this.currentEvent = {};
    this.sourceFrameRate = this.frameRate;

    string.split('\n').forEach(this.parseLine);

    this.events.push(this.currentEvent);
    delete this.currentEvent;
    delete this.sourceFrameRate; 

    //return this;
    return this.filterDuplicateMultitrack();
  }

  toJSON(stringify) {
    const json = this.events.map(event => event.toJSON(false));

    if (stringify === true) return JSON.stringify(json);

    return json;
  }

  filterDuplicateMultitrack() {
    const filtered = new EDL(this.frameRate);
    if (this.events.length > 0 && this.events[0].channel && this.events[0].event) {
      const eventList = [];

      this.events.forEach(e => {
        const track = eventList.find(x => x.sourceFile.substr(0, 10) == e.sourceFile.substr(0, 10) && x.sourceStart.frameCount == e.sourceStart.frameCount && x.sourceEnd.frameCount == e.sourceEnd.frameCount)
        if (!track) {
          e.sort = e.sourceStart.frameCount;
          eventList.push(e);
        }
      });

      filtered.events = eventList.sort(basicFieldSorter('sort'));
    } else {
      filtered.events = this.events.filter((event, index) => {
        if (index === 0) return true;

        if (event.reel === this.events[index - 1].reel
            && event.trackType === this.events[index - 1].trackType
            && event.sourceClip === this.events[index - 1].sourceClip
            && event.sourceFile === this.events[index - 1].sourceFile
            && event.sourceStart.toString() === this.events[index - 1].sourceStart.toString()
            && event.sourceEnd.toString() === this.events[index - 1].sourceEnd.toString()
            && event.recordStart.toString() === this.events[index - 1].recordStart.toString()
            && event.recordEnd.toString() === this.events[index - 1].recordEnd.toString()
        ) return false;

        return true;
      });
    }

    return filtered;
  }

  setEventFrameRate(line) {
    if (line === 'FCM: NON-DROP FRAME') {
      this.sourceFrameRate = 30;
    } else if (line === 'FCM: DROP FRAME') {
      this.sourceFrameRate = 29.97;
    } else if (line.startsWith('TIMECODE FORMAT')) {
      const [,frameRate] = RegexPatterns.CMX_TIMECODE_FRAME_RATE_REGEX.exec(line);
      this.sourceFrameRate = parseFloat(frameRate);
    }
  }

  setEdlTitle(line) {
    if (line.startsWith("TITLE:")) {
      //TITLE:
      const [,title] = RegexPatterns.CMX_TITLE_REGEX.exec(line)

      this.title = title;
    } else {
      //SESSION NAME:
      const [,title] = RegexPatterns.CMX_SESSION_NAME_REGEX.exec(line)
      this.title = title;
    }
  }

  parseLine(line) {
    if (RegexPatterns.CMX_EVENT_REGEX.test(line)) {
      if (Object.prototype.hasOwnProperty.call(this.currentEvent, 'number')) {        
        this.events.push(this.currentEvent);
      }
      this.currentEvent = new Event(line, this.sourceFrameRate, this.frameRate);
      this.trackFlag = true;
    } else if (RegexPatterns.CMX_EVENT_WITH_TRACKNUMBER_AND_TITLE_REGEX.test(line)) {
      if (Object.prototype.hasOwnProperty.call(this.currentEvent, 'number')) {        
        this.events.push(this.currentEvent);
      }
      this.currentEvent = new Event(line, this.sourceFrameRate, this.frameRate);
      this.trackFlag = true;
    } else if (RegexPatterns.CMX_MOTION_EFFECT_REGEX.test(line)) {
      this.currentEvent.setMotionEffect(line, this.sourceFrameRate);
    } else if (RegexPatterns.CMX_COMMENT_REGEX.test(line)) {
      if (this.trackFlag && (line.indexOf("FROM CLIP NAME:") != -1 || line.indexOf("SOURCE FILE:") != -1)) {
        this.currentEvent.addComment(line);
        this.trackFlag = false;
      }
    } else if (RegexPatterns.CMX_FRAME_RATE_REGEX.test(line)) {
      this.setEventFrameRate(line);
    } else if (RegexPatterns.CMX_TIMECODE_FRAME_RATE_REGEX.test(line)) {
      this.setEventFrameRate(line)
    } else if (RegexPatterns.CMX_TITLE_REGEX.test(line)) {
      this.setEdlTitle(line);
    } else if (RegexPatterns.CMX_SESSION_NAME_REGEX.test(line)) {
      this.setEdlTitle(line);
    }
  }
}

module.exports = EDL;
