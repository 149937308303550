export const UPDATE_CART = 'UPDATE_CART'

const createRequest = (method, requestBody) => {
    return {
        method: method,
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(requestBody)
    }
}

export const AddToCartAction = (tracks) => async (dispatch, getState) => {
    try {
        const cartGuid = getState().Cart.CartGuid || '00000000-0000-0000-0000-000000000000'  
        const response = await fetch('/api/cart', createRequest('POST', { cartGuid, tracks }))

        if (!response.ok) {
            console.error('Response was: ', response.status)
            return
        }

        const json = await response.json()
        dispatch({
            type: UPDATE_CART,
            cartGuid: json.CartGuid,
            tracks: json.Tracks
        })
    } catch (ex) {
        console.error('AddToCartAction Error: ', ex)
    }
}

export const GetCartAction = () => async (dispatch, getState) => {
    const cartGuid = getState().Cart.CartGuid || '00000000-0000-0000-0000-000000000000'  
    const response = await fetch(`/api/cart/${cartGuid}`)

    if (response.ok) {        
        const json = await response.json()
        return dispatch({
            type: UPDATE_CART,
            cartGuid: json.CartGuid,
            tracks: json.Tracks
        })
    }
    
    // TODO: TRANSLATION NEEDED!!!
    Common.ShowAlertMessage('Shopping Cart Issue', 'Something happened and we weren\'t able to get your shopping cart. Please try again later.');

    return dispatch({
        type: UPDATE_CART,
        cartGuid: cartGuid,
        tracks: []
    });        
}