import React from 'react';
import classNames from 'classnames';
import style from './open-cue-sheet.scss';

import { Button, Dialog, Flex, ScrollWindow } from 'components/ui';

const dialogName = 'OpenCueSheet';

export default class OpenCueSheetDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            cueSheetList: [],
            selectedItem: null            
        }
    }

    componentDidMount() {
        Common.CueSheet.LoadCueSheetList()
            .then(cueSheets => {
                this.setState({                    
                    cueSheetList: cueSheets
                });
            })
            .catch(err => {
                console.error('ERROR: ', err);
            })
    }

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose()        
    }

    handleOK() {
        if (this.state.selectedItem != null) {
            Common.CueSheet.LoadCueSheet(this.state.selectedItem.Id);
            Common.Dialogs.ShowDialog(dialogName, false);
            this.onClose();  
        } else {
            alert('You must first select a Cue Sheet.');
        }
    }

    handleItemSelected(item) {
        this.setState({
            selectedItem: item
        });
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        const cueSheetList = this.state.cueSheetList.map((cueSheet, index) => {
            return (<CueSheetListItem key={index} selected={this.state.selectedItem == cueSheet} item={cueSheet} onItemSelected={this.handleItemSelected.bind(this)} />)
        })
        
        return (
            <Dialog title='Open Cue Sheet' name={dialogName} width={800} height={500} modal={true} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleEscape.bind(this)}>
                <Flex className='open-cue-sheet-dialog'>
                    <Flex position='left'>
                        <Flex>
                            <Flex row height={50} className='table-header' position='left'>
                                <Flex fixed className='title'>Title</Flex>
                                <Flex fixed className='line-count'>Line Count</Flex>
                                <Flex fixed className='last-saved'>Last Saved</Flex>
                            </Flex>
                            <Flex className='cue-line-items'>
                                <ScrollWindow>
                                    {cueSheetList}
                                </ScrollWindow>
                            </Flex>
                        </Flex>                                                
                    </Flex>
                    <Flex row height='30px' position='right'>
                        <Flex row width='200px'>
                            <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OPEN</Button>
                            <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}

class CueSheetListItem extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        const {
            item,
            selected = false,
            onItemSelected = () => {}
        } = this.props;

        const componentClass = classNames({
            'cue-line-item': true,
            'selected': selected
        })

        return (
            <Flex row fixed className={componentClass} onClick={onItemSelected.bind(this, item)}>
                <Flex className='title'>{item.Title}</Flex>
                <Flex className='line-count'>{item.CueLineCount}</Flex>
                <Flex className='last-saved'>{item.LastSavedDateTime}</Flex>
            </Flex>
        )
    }
}