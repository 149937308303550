import React from 'react';
import TimeCode from 'smpte-timecode';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './cue-sheet-maker.scss';

import { Button, Dialog, Flex, FontIcon, ScrollWindow, TextField } from 'components/ui';
import { updateFieldValue } from 'store/cueSheet/actions';
import { RefreshIndicator } from 'material-ui';
import { basicFieldSorter } from 'utility/common';

import {
    OpenCueSheetDialog
} from 'components/common/dialogs'
import { getTranslation } from 'utility/common';

const dialogName = 'CueSheet';

const getMenuItems = (obj) => {
    return [
            {
                Label: 'New',
                onClick: () => { Common.CueSheet.Create() }
            },
            {
                Label: 'My Cue Sheets',
                onClick: () => { Common.CueSheet.Open() }
            },
            {
                Label: 'Save Cue Sheet',
                onClick: () => { Common.CueSheet.Save() }
            },
            {
                Label: 'Save Cue Sheet As...',
                onClick: () => { Common.CueSheet.SaveAs() }
            },            
            {
                Label: '-'
            },
            {
                Label: 'Clear Cue Sheet',
                onClick: () => { 
                    Common.CueSheet.Clear() 
                    setTimeout(() => {
                        obj.Network.focus();
                    }, 20);
                }                
            },
            {
                Label: '-'
            },
            {
                Label: 'Import EDL...',
                onClick: () => { Common.CueSheet.Import() }
            },
            {
                Label: 'Export As CSV...',
                onClick: () => { Common.CueSheet.Export() }
            }
        ];
}

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

class CueSheetMaker extends React.Component {
    constructor() {
        super(...arguments); 
        this.state = {
            dropDownMenu: null,
            selectedCueNumber: null
        };       
    }

    componentDidMount() {
        const self = this;

        this.setState({
            dropDownMenu: getMenuItems(this)
        })

        setTimeout(() => {
            if (self.Network) {
                self.Network.focus();
            }  
        }, 20);

        document.addEventListener('keyup', onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    }    

    get ActiveChildDialog() {
        if (this.state.showOpenCueSheet) return (<OpenCueSheetDialog />);
        return '';
    }

    get CueSheet () {
        const { CueSheet } = this.props;
        return CueSheet ? CueSheet.toJS() : {};
    }

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }
    
    handleCancel() {        
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();        
    }

    handleDeleteRow() {        
        Common.CueSheet.RemoveLine(this.state.selectedCueNumber || 0);
    }

    handleExport() {
        Common.CueSheet.Export();
    }

    handleFieldChanged (fieldName, value) {
        Common.CueSheet.UpdateFieldValue(fieldName, value);
    }

    handleImport() {
        Common.CueSheet.Import();
        this.setState({ selectedCueNumber: null });
    }    

    handleInsertRow() {
        Common.CueSheet.AddLine(this.CueSheet.Id, this.state.selectedCueNumber);                
        this.setState({ selectedCueNumber: this.state.selectedCueNumber + 1});

        setTimeout(() => {
            document.querySelector(`div[data-key="cue-sheet-line-${this.state.selectedCueNumber}"] .title input`).focus();
        }, 10);
    }

    handleRowSelect(cueNumber) {
        this.setState({ selectedCueNumber: cueNumber });
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        const lines = this.CueSheet.Lines.sort(basicFieldSorter('CueNumber')).map((cueLine, index) => {
            cueLine.CueNumber = index + 1;
            return (<CueSheetLine key={index} root={this} selected={this.state.selectedCueNumber == index+1} line={cueLine} onRowSelect={this.handleRowSelect.bind(this)} />)
        });

        const loadingSpinner = this.props.UserInterface.toJS().IsLoading
            ? (<div className='loading-spinner'>
                   <span></span>
                   <span></span>
                   <span></span>
                   <span></span>
               </div>)
            : '';

        return (
            <Dialog title='Cue Sheet Maker' name='CueSheet' menu={this.state.dropDownMenu} className='cue-sheet-maker' width='1200px' modal={true} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleCancel.bind(this)}>
                <Flex row className='cue-sheet-maker-title'>{this.CueSheet.Title}</Flex>
                <Flex row height='60px' className='header'>
                    <Flex row className='field-buttons'>
                        <Flex row position='left' width={'50%'}>
                            <Button type='default' size='md' width='220px' onClick={this.handleImport.bind(this)}><Flex row position='center'>{getTranslation("Import EDL")}...</Flex></Button>
                            <Button type='default' size='md' width='220px' onClick={this.handleExport.bind(this)}><Flex row position='center'>{getTranslation("Export As CSV")}...</Flex></Button>
                        </Flex>
                        <Flex row position='right' width={'50%'}>
                            <Button type='default' size='md' onClick={this.handleInsertRow.bind(this)}>{getTranslation("Insert Row")}</Button>
                            <Button type='default' size='md' onClick={this.handleDeleteRow.bind(this)}>{getTranslation("Delete Row")}</Button>
                            <input ref={(i) => {this.fileUploader = i}} type='file' value='' style={{display:'none'}} />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex row height='55px' className='table-header'>
                    <Flex fixed className='cue'>{getTranslation("Cue")} #</Flex>
                    <Flex fixed className='title'>{getTranslation("Track Title")}</Flex>
                    <Flex fixed className='timecode'>{getTranslation("Time Code In/Out")}</Flex>
                    <Flex fixed className='timecode-length'>{getTranslation("Length")}</Flex>
                    <Flex fixed className='use'>{getTranslation("Use")}</Flex>
                    <Flex className='composers'>{getTranslation("Composers")}</Flex>
                    <Flex className='publishers'>{getTranslation("Publishers")}</Flex>
                    <Flex fixed className='society'>{getTranslation("Society")}</Flex>
                    <Flex fixed className='source'>{getTranslation("Music Source")}</Flex>
                </Flex>
                <Flex className='cue-sheet-lines'>
                    <ScrollWindow autoHide={false} className='table-content'>
                        <div>
                            { loadingSpinner }
                            { lines }
                        </div>
                    </ScrollWindow>
                </Flex>
                <Flex fixed className='footer'>
                    <Flex row>
                        <Flex position='left'>{this.CueSheet.Lines.length} {getTranslation("Track(s)")}</Flex>                            
                    </Flex>                                       
                </Flex>
            </Dialog> 
        )
    }
}

class CueSheetLine extends React.Component {
    constructor() {
        super(...arguments);
    }

    handleFieldChanged (fieldName, newVal, oldVal) {
        const cueSheetLine = this.props.line;
        Common.CueSheet.ValidateCueSheetField(cueSheetLine.Id, cueSheetLine, fieldName, newVal);        
    }

    handleTimeCodeFieldChanged (fieldName, newVal, oldVal) {
        const cueSheetLine = this.props.line;
        const REGEX_SMTE = /^(?:(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d):)?([0-5]?\d)$/;

        if (REGEX_SMTE.test(newVal)) {
            const parts = newVal.replace(';', ':').split(':');

            switch (parts.length) {
                case 1: 
                    newVal = `00:00:00:${parts[0].padLeft(2, '0')}`; 
                    break;
                case 2: 
                    newVal = `00:00:${parts[0].padLeft(2, '0')}:${parts[1].padLeft(2, '0')}`; 
                    break;
                case 3: 
                    newVal = `00:${parts[0].padLeft(2, '0')}:${parts[1].padLeft(2, '0')}:${parts[2].padLeft(2, '0')}`; 
                    break;
                case 4: 
                    newVal = `${parts[0].padLeft(2, '0')}:${parts[1].padLeft(2, '0')}:${parts[2].padLeft(2, '0')}:${parts[3].padLeft(2, '0')}`; 
                    break;
            }

            Common.CueSheet.ValidateCueSheetField(cueSheetLine.Id, cueSheetLine, fieldName, newVal);   
        } else {
            Common.CueSheet.ValidateCueSheetField(cueSheetLine.Id, cueSheetLine, fieldName, '')
        }     
    }

    handleKeyDown = (e) => {
        if (e.which == 13) {
            var inputs = e.target.parentNode.parentNode.querySelectorAll("input");

            for (var i = 0; i < inputs.length; i++) {
                if (inputs[i] == document.activeElement) {
                    if (i < (inputs.length-1)) {
                        inputs[i+1].focus();
                        break;
                    } else {
                        inputs[0].focus();
                        break;
                    }
                }
            }
        }
    }

    render() {
        const {
            line,
            selected = false,
            onRowSelect = () => {}
        } = this.props;

        let timeCodeLength = '';

        const className = classNames({
            'line': true,
            'selected': selected
        });

        try {
            const tcStart = TimeCode(line.TimeCodeIn);
            const tcEnd   = TimeCode(line.TimeCodeOut);
            let tcDuration = TimeCode(tcEnd - tcStart);
            
            // Add a second to the timecode if the framecount > 15.
            if (tcDuration.frames > 15) 
                tcDuration = TimeCode(tcDuration + TimeCode("00:00:01:00"));
        
            timeCodeLength = `${tcDuration.minutes.toString()}:` +
                             `${tcDuration.seconds.toString().padLeft(2, '0')}`;
        } catch(e) {}

        return (
            <div className={className} data-key={`cue-sheet-line-${line.CueNumber}`} onClick={onRowSelect.bind(this, line.CueNumber)} onKeyDown={this.handleKeyDown}>
                <div className='cue'>{line.CueNumber}</div>
                <div className='title'><CueSheetTextField onTextChanged={this.handleFieldChanged.bind(this, 'Title')} value={line.Title} /></div>
                <div className='timecode-in'><CueSheetTextField onTextChanged={this.handleTimeCodeFieldChanged.bind(this, 'TimeCodeIn')} value={line.TimeCodeIn} /></div>
                <div className='timecode-out'><CueSheetTextField onTextChanged={this.handleTimeCodeFieldChanged.bind(this, 'TimeCodeOut')} value={line.TimeCodeOut} /></div>
                <div className='timecode-length'><label>{timeCodeLength}</label></div>
                <div className='use'><CueSheetTextField onTextChanged={this.handleFieldChanged.bind(this, 'Usage')} value={line.Usage} /></div>
                <div className='composers'><CueSheetTextField onTextChanged={this.handleFieldChanged.bind(this, 'Composers')} value={line.Composers} /></div>
                <div className='publishers'><CueSheetTextField onTextChanged={this.handleFieldChanged.bind(this, 'Publishers')} value={line.Publishers} /></div>
                <div className='society'><CueSheetTextField onTextChanged={this.handleFieldChanged.bind(this, 'Society')} value={line.Society} /></div>
                <div className='source'><CueSheetTextField onTextChanged={this.handleFieldChanged.bind(this, 'MusicSource')} value={line.MusicSource} /></div>
            </div>
        );
    }
}

class CueSheetTextField extends React.Component {
    constructor() {
        super(...arguments);
        this.input = null;
        this.state = {
            value: ''
        }
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    componentWillReceiveProps(props) {
        this.setState({ value: props.value });
    }

    handleBlur(e) {
        const {
            onBlur = () => {},
            onTextChanged = () => {}
        } = this.props;

        var val = e.target.value;

        if (val != this.props.value) {
            onTextChanged(val, this.props.value);
        }

        onBlur(e);
    }

    handleClick(e) {
        if (this.props.onClick) this.props.onClick(e);
        this.input.focus();
    }

    handleChange(e) {
        if (!this.props.readonly) {
            this.setState({ value: e.target.value });
        
            if (this.props.onChange) 
                this.props.onChange(e);
        }
    }

    handleFocus(e) {
        const {
            readonly = false,
            onBlur = () => {},
            onFocus = () => {}
        } = this.props;

        if (!readonly) {
            onFocus(e);
        } else {
            this.handleBlur(e);
        }
    }

    render() {
        const {
            readonly = false,
            onClick = () => {},
            onFocus = () => {}
        } = this.props;

        return (
            <input ref={(i) => { this.input = i}}
                    readOnly={readonly} 
                    type='text' 
                    onClick={this.handleClick.bind(this)} 
                    onBlur={this.handleBlur.bind(this)} 
                    onChange={this.handleChange.bind(this)} 
                    onFocus={this.handleFocus.bind(this)} 
                    value={this.state.value} />
        )
    }
}


export default connect((state) => {
    return {
        CueSheet: state.CueSheet,
        UserInterface: state.UserInterface
    }
})(CueSheetMaker);