import React from 'react';
import { Flex, FontIcon, TextField, Typewriter } from 'components/ui';

class SurveyPage3 extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            name: '',
            emailAddress: '',
            username: '',
            password: '',
            confirm: '',
            usernameIsAvailable: null
        }
    }

    componentDidMount(newProps = { state: {} }) {
        this.emailAddress = null;

        this.setState({
            ...this.state,
            ...this.props.state,
        });
    }

    handleCheckUsername = (username) => {
        Common.UserInterface.CheckUsernameIsAvailable(username)
            .then(response => {        
                this.setState({ username, usernameIsAvailable: response.IsAvailable });
            })
            .catch(err => {
                Common.Dialogs.ShowAlertMessage({ title: 'Username Error', message: err.message });
            })
    }

    getDialogResponse() {
        return this.state;
    }
    
    validatePage = () => {
        if (this.state.name.trim() === '') {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please provide your name.'});
            return false;
        } 
        
        if (this.state.emailAddress.trim() === '') {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please provide your email address.'});
            return false;
        } 

        if (!(new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(this.state.emailAddress))) {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please provide a valid email address.'});
            return false;
        }

        if (this.state.username.trim() === '') {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please provide a username you would like to use for your account.'});
            return false;
        } 

        if (!this.state.usernameIsAvailable) {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'The username you chose is already taken.  Please choose a different username.'});
            return false;
        }

        if (this.state.password.trim() === '' || this.state.confirm.trim() === '') {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'Please provide and confirm the password you would like to use for your account.'});
            return false;
        } 

        if (this.state.password !== this.state.confirm) {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: 'The password and confirmation do not match.  Please re-enter and confirm the password you would like to use for your account.'});
            return false;
        }

        return true;
    }

    render() {
        let usernameAvailabilityMessage = '';

        if (this.state.username.trim() != '') {
            if (this.state.usernameIsAvailable) {
                usernameAvailabilityMessage = <span className='username-available'><FontIcon name='checkmark' /> Username is available</span>;
            } else {
                usernameAvailabilityMessage = <span className='username-not-available'><FontIcon name='circle-cross' />Username is not available</span>;
            }
        }

        return (
            <div className='survey-page-3'>                                
                <div className='survey-page-header'>
                    <FontIcon name='inspector' />
                    <Typewriter message={ 'Create a user account by completing the following:' } />
                </div>
                <div className='survey-page-content' width={'100%'}>
                    <div className='field-section'>
                        <div className='field-label'>
                            Name: 
                        </div>
                        <div className='field-value'>                        
                            <TextField value={this.state.name} onTextChanged={(i) => this.setState({ name: i })} />
                        </div>
                    </div>

                    <div className='field-section'>
                        <div className='field-label'>Email Address:</div>
                        <div className='field-value'><TextField ref={(i) => this.emailAddress = i} value={this.state.emailAddress} onTextChanged={(i) => this.setState({ emailAddress: i })} /></div>
                    </div>    

                    <div className='field-section'>
                        <div className='field-label'>Username: { usernameAvailabilityMessage }</div>
                        <div className='field-value'><TextField value={this.state.username} onTextChanged={(username) => this.handleCheckUsername(username)} /></div>
                    </div> 

                    <div className='field-section'>
                        <Flex row>
                            <Flex fixed col position='left' width={'50%'}>
                                <div className='field-label'>Password:</div>
                                <div className='field-value'><TextField type={'password'} value={this.state.password} width={320} onTextChanged={(i) => this.setState({ password: i })} /></div>
                            </Flex>
                            <Flex fixed col position='left' width={'50%'}>
                                <div className='field-label'>Confirm:</div>
                                <div className='field-value'><TextField type={'password'} value={this.state.confirm} width={320} onTextChanged={(i) => this.setState({ confirm: i })} /></div>
                            </Flex>
                        </Flex>
                    </div> 
                </div>
            </div>
        )
    }
}

export default SurveyPage3;