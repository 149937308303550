import React from 'react';
import classNames from 'classnames';
import style from './contact.scss';

import { setUserDetails } from 'store/userInterface/actions';
import { Button, Checkbox, Dialog, Flex, TextField } from 'components/ui';

const dialogName = 'ContactUsDialog';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

class ContactUsDialog extends React.Component {
    constructor() {
        super(...arguments);
    }

    componentDidMount() {
        document.addEventListener('keyup', onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    } 

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog('ContactUsDialog', false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog('ContactUsDialog', false);
        this.onClose()        
    }

    handleOK() {
        Common.UserInterface.ShowDialog('ContactUsDialog', false);
    }

    handleWebsite() {
        window.location.href = 'https://www.scorekeepersmusic.com';
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;
        
        return (
            <Dialog title='Contact Us' name='ContactUsDialog' width={600} height={300} modal={true} closeText={''} onClose={this.handleCancel.bind(this)}>
                <Flex className='contact-us-dialog'>
                    <Flex position='center'>
                        <Flex height='50%' width='70%'>
                            <table>
                                <tr>
                                    <td width='30%'>PHONE:</td>
                                    <td>800.320.4808 / 323.544.9200</td>
                                </tr>
                                <tr>
                                    <td>INFO:</td>
                                    <td><a href='mailto:inform@scorekeepersmusic.com' style={{color:'white'}}>inform@scorekeepersmusic.com</a></td>
                                </tr>
                                <tr>
                                    <td>SUPPORT:</td>
                                    <td><a href='mailto:support@scorekeepersmusic.com' style={{color:'white'}}>support@scorekeepersmusic.com</a></td>
                                </tr>
                                <tr>
                                    <td>WEBSITE:</td>
                                    <td><a href='#' onClick={this.handleWebsite.bind(this)} style={{color:'white'}}>https://www.scorekeepersmusic.com</a></td>
                                </tr>                                
                            </table>
                        </Flex>
                    </Flex>                
                </Flex>
            </Dialog>
        )
    }
}

export default ContactUsDialog;