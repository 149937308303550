const EventEmitter = require('events');

const GLOBAL_AUDIO_PLAYER_EVENTS = { 
}


let registeredEvents = [];

// This function will determine which players the users system supports
const determineAudioType = () => {
  return 1;
}

const createPlayEvent = (event) => {
  return {
    type: 'PLAY',
    currentTime: event.target.currentTime,
    totalTime: event.target.duration
  }
}

const createTimeUpdateEvent = (event) => {
  return {
    type: 'TIME_UPDATE',
    currentTime: event.target.currentTime,
    totalTime: event.target.duration
  };
}

const createPausedEvent = (event) => {
  return {
    type: 'PAUSED',
    currentTime: event.target.currentTime,
    totalTime: event.target.duration
  }
}

const createStoppedEvent = (event) => {
  return {
    type: 'STOPPED',
    currentTime: event.target.currentTime,
    totalTime: event.target.duration
  }
}

const getRegisteredEvents = (id) => {
  return registeredEvents.find(e => e.id === id);
}

const notifyEvents = (id, eventDetails) => {
  const event = getRegisteredEvents(id);
  const eventRecipients = registeredEvents.find(e => e.id == id);

  eventRecipients.targets.forEach(target => {
    target.handleEventCallback(eventDetails);
  })
}

const registerForEvents = (id, target) => {
  const event = registeredEvents.find(e => e.id == id);

  if (event == null) registeredEvents.push({ id: id, targets: [ target ] });
  else event.targets.push(target);
}

const unregisterForEvents = (id, target) => {
  const event = registeredEvents.find(e => e.id == id);

  if (event) {
    const index = event.targets.findIndex(t => t === target);

    if (index >= 0) {
      event.targets.splice(index, 1);
    }
  }        
}


class GlobalAudioPlayer extends EventEmitter {
  constructor(options) {
    super();
    
    const _this = this;
    const audioType = determineAudioType();

    if (audioType === 0) this._audioPlayer = new WebAudioContext(options);
    if (audioType === 1) this._audioPlayer = new HtmlAudioContext(options);

    this.track = null;

    this.registerForEvents = registerForEvents;
    this.unregisterForEvents = unregisterForEvents;

    this._audioPlayer.on('timeupdate', (e) => {
      const p = (e.target.currentTime / e.target.duration) * 100 + '%';
      const v = document.querySelectorAll(`.wft-${this.track.Id} > .indicator`);
      const r = document.querySelectorAll(`:not(.wft-${this.track.Id}) > .indicator`);

      v.forEach(el => { el.style.display='block'; el.style.left=p; });
      r.forEach(el => { el.style.display='none'; });
    });

    this._audioPlayer.on('stopped', (e) => {
      notifyEvents(this.track.Id, createStoppedEvent(e)); 

      const r = document.querySelectorAll(`.indicator-path > .indicator`);
      r.forEach(el => { el.style.display='none' });
    });      
    
    // this._audioPlayer.on('timeupdate', (e) => { notifyEvents(_this.track.Id, createTimeUpdateEvent(e)) });
    // this._audioPlayer.on('stopped', (e) => {notifyEvents(this.track.Id, createStoppedEvent(e)); });      
    this._audioPlayer.on('pause', (e) => {notifyEvents(this.track.Id, createPausedEvent(e)) });     
    this._audioPlayer.on('play', (e) => {notifyEvents(this.track.Id, createPlayEvent(e)) });     
  }

  get duration() {
    return this._audioPlayer.duration;
  }

  get currentTime() {
    return this._audioPlayer.currentTime;
  }

  set currentTime(position) {
    this._audioPlayer.currentTime = position;
  }

  get isPlaying() {
    return this._audioPlayer.isPlaying;
  }

  // pauses the current track 
  pause() { 
    this._audioPlayer.pause();
  }

  // plays the current track from the current location
  play(track) {  
    if (track != this.track) {
      if (this.track) {
        this.emit('stopped');
        this._audioPlayer.stop();
      }

      this.track = track;
      this._audioPlayer.load(Common.GetPreviewUrl(track));      
      this._audioPlayer.seek(0);
    }

    this._audioPlayer.play();
  }



  // moves the playhead
  seek(position) {    
    this._audioPlayer.seek(position);
  }

  seekPlay(track, posAsPercent) {
    if (track != this.track) {
      if (this.track) {
        this.emit('stopped');
        this._audioPlayer.stop();
      }

      this.track = track;
      this._audioPlayer.load(Common.GetPreviewUrl(track));                  
    }

    if (this._audioPlayer.isPlaying) {
      this._audioPlayer.seek(this._audioPlayer.duration * posAsPercent);      
    } else {
      this._audioPlayer.play(posAsPercent);
    }
  }

  // stops the current track and sets the playhead back to the beginning
  stop() {    
    this._audioPlayer.stop();
  }
}

class WebAudioContext extends EventEmitter {
  constructor(options) {
    super();
    EventEmitter.EventEmitter.call(this);  
    this._audioPlayer = new Audio();    
    this._options = options;    
  }

  get duration() {
    return this._audioPlayer.duration;
  }

  get currentTime() {
    return this._audioPlayer.currentTime;
  }

  set currentTime(position) {
    this._audioPlayer.currentTime = position;
  }

  load(url = '', autoPlay = false) {
    // stops any current playback
    this.stop();    
    this._audioPlayer.src = url;

    if (autoPlay) this.play()
  }

  // pauses the current track 
  pause() { 
    this._audioPlayer.pause();
  }

  // plays the current track from the current location
  play() {    
    this._audioPlayer.play();
  }

  // moves the playhead
  seek(position) {    
    this._audioPlayer.currentTime = position;
  }

  // stops the current track and sets the playhead back to the beginning
  stop() {   
    this.seek(0);
    this.pause();
  }
}

class HtmlAudioContext extends EventEmitter {
  constructor(options) {
    super();    
    EventEmitter.EventEmitter.call(this);  
    
    const _this = this;

    this._audioPlayer = new Audio();
    this._options = options;
    this._startMarkerAsPercent = 0;
    
    this._audioPlayer.addEventListener('canplay', (e) => {
      if (this._startMarkerAsPercent > 0) {
        const position = this._startMarkerAsPercent * this._audioPlayer.duration;
        this._startMarkerAsPercent = 0;        
        this._audioPlayer.currentTime = position;
      }
    });

    this._audioPlayer.addEventListener('error', (e) => _this.emit('error', e));
    this._audioPlayer.addEventListener('ended', (e) => _this.emit('ended', e));
    this._audioPlayer.addEventListener('pause', (e) => _this.emit('pause', e));
    this._audioPlayer.addEventListener('play', (e) => _this.emit('play', e));
    this._audioPlayer.addEventListener('playing', (e) => _this.emit('playing', e));
    this._audioPlayer.addEventListener('seeked', (e) => _this.emit('seeked', e));
    this._audioPlayer.addEventListener('timeupdate', (e) => _this.emit('timeupdate', e));
    this._audioPlayer.addEventListener('volumechange', (e) => _this.emit('volumechange', e));
  }

  get duration() {
    return this._audioPlayer.duration;
  }

  get currentTime() {
    return this._audioPlayer.currentTime;
  }

  set currentTime(position) {
    this._audioPlayer.currentTime = position;
  }

  get isPlaying() {
    return (this._audioPlayer.currentTime > 0 &&
            this._audioPlayer.paused == false &&
            this._audioPlayer.ended == false);
  }

  load(url = '', autoPlay = false) {
    this._audioPlayer.src = url;

    if (autoPlay) this.play()
  }

  // pauses the current track 
  pause() { 
    this._audioPlayer.pause();
  }

  // plays the current track from the current location
  play(posAsPercent = null) {   
    if (posAsPercent != null) {
      this._startMarkerAsPercent = posAsPercent;
    }

    this._audioPlayer.play();
  }

  // moves the playhead
  seek(position) {    
    this._audioPlayer.currentTime = position;
  }

  // stops the current track and sets the playhead back to the beginning
  stop() {
    if (this.isPlaying) {
      this.pause();
      this.seek(0);
      this.emit('stopped', { target: this })
    }    
  }
}

export { GlobalAudioPlayer }
