import React from 'react'
import { Provider } from 'react-redux'
import { Router, Route, Switch } from 'react-router'
import { store, history } from 'store'
import { render } from 'react-dom'
import { closeContextMenu } from 'components/ui/context-menu/context-menu';

import 'utility/extensions'
import 'appearance/normalize.css'
import 'appearance/main.scss'

const GlobalLayout = require('./components/common/layouts/global').default;
const HomeContainer = require('./components/views/home/' + (process.env.HOME_PAGE)).default;
import SharedPlaylist from './components/views/sharedPlaylist/sharedPlaylist';

import { getCookie } from 'utility/utility'

// Load the CommonAPI for the Environment
const CommonAPI = require((process.env.API_CLASS)).default;
window.Common = new CommonAPI(store);
window.GetCookie = getCookie;
window.Environment = process.env.HOME_PAGE;

window.onerror = Common.ErrorHandler;

window.Common.LoadGenreArtCache();

window.addEventListener('keydown', (e) => {
  
  switch (e.target.tagName.toLowerCase()) {
    case "input":
    case "textarea":
      return true;
      break;
    default: 
      if (e.which === 32) {
        e.preventDefault();
        e.stopPropagation();        
        return false;
      }
      if (e.which === 13) {
        e.preventDefault();
        e.stopImmediatePropagation();        
        closeContextMenu();

        if (Common.Dialogs.GetOpenDialog() == null) {
          Common.Search.AdvancedSearch(null, 0, Common.GetSearchSize(), true);
        }
      }
      if (e.which === 82) { // 'r' key pressed. 
        e.preventDefault();
        e.stopPropagation(); 
        if (Common.TrackPlayer.IsPlaying) Common.TrackPlayer.Stop();
        Common.UserInterface.ClearQuickSearch();
        Common.UserInterface.ClearAdvancedSearch();
        Common.UserInterface.ClearPlaylistFilters();
  
        return false;
      }
  }
});

window.addEventListener('keyup', (e) => {
  switch (e.target.tagName.toLowerCase()) {
    case "input":
    case "textarea":
      return true;
    default: {
      switch (e.key) {
        case " ": 
          e.preventDefault();
          e.stopPropagation(); 
          if (Common.TrackPlayer.IsPlaying) {
            Common.TrackPlayer.Pause();
          } else {
            Common.TrackPlayer.Play(Common.TrackPlayer.track);       
          }
          return false;        
        case "r": 
          e.preventDefault();
          e.stopPropagation(); 
          if (Common.TrackPlayer.IsPlaying) Common.TrackPlayer.Stop();
          Common.UserInterface.ClearQuickSearch();
          Common.UserInterface.ClearAdvancedSearch();
          Common.UserInterface.ClearPlaylistFilters();
          return false;
        case "ArrowLeft": 
          Common.TrackPlayer.Rewind()
          return false;
        case "ArrowRight": 
          Common.TrackPlayer.FastForward()
          return false;
      }
    }

    return false;
  }
})


/**
 * The main App component
 */
const App = () => (
  <Provider store = { store }>
    <Router history={history}>
      <Switch>
        <Route path='/shared_playlist' component={SharedPlaylist}></Route>
        <Route component={DefaultPage}></Route>
      </Switch>
    </Router>
  </Provider>
)

const DefaultPage = () => {
  return (
    <GlobalLayout>
      <HomeContainer />
    </GlobalLayout>
  )
}

/**
 * Mount the application to the DOM
 */
render(<App />, document.getElementById('app'))


/**
 * Apply hot module reload if webpack plugin is available
 */
if (module.hot)
  module.hot.accept()