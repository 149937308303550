import {Map} from 'immutable'
import * as Actions from './actions'

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

const CreateEmptyCart = () => {
    const cartGuid = localStorage.getItem('cartGuid') || EMPTY_GUID
    
    return {
        CartGuid: cartGuid,
        Tracks: [],
    };
}

/**
 * Initial reducer state
 */
export const initialCartState = CreateEmptyCart();


/**
 * Home reducer
 */
export const cartReducer = {
    [Actions.UPDATE_CART]: (state, action) => {        
        localStorage.setItem('cartGuid', action.cartGuid)

        return {
            ...state, 
            CartGuid: action.cartGuid, 
            Tracks: action.tracks
        }
    }
};
