import React from 'react';
import { setUserDetails } from 'store/userInterface/actions';
import SurveyPage1 from './survey-page-1';
import SurveyPage2 from './survey-page-2';
import SurveyPage3 from './survey-page-3';
import './survey.scss';

/*****************************************************************************
 * Questions:
 *   - What is the title of the dialog or is there one?
 *****************************************************************************/

import { Button, Dialog, Flex, ScrollWindow } from 'components/ui';

const dialogName = 'SurveyDialog';

export default class SurveyDialog extends React.Component {
    constructor() {
        super(...arguments);

        this.onKeyUp = (event) => {
            if (event.which == 27) {
                Common.Dialogs.ShowDialog(dialogName, false);
            }

            if (event.which == 13) {
                this.handleOK();
            }
        }

        this.dialogPage = null;

        this.state = {
            code: null,
            trialPeriod: 0,
            currentPage: 1,
            productionType: null,
            productionTypeValue: "",
            jobType: null,
            jobTypeValue: "",
            struggleValue: "",
            currentProvider: "",
            name: "",
            emailAddress: "",
            username: "",
            password: "",
            showProcessingMessage: false,
        }
    }

    get onClose() {
        const {
            onClose = () => {}
        } = this.props;

        return onClose;
    }

    componentDidMount() {
        this.state = {
            ...this.state,
            code: Common.GetState().Dialogs.toJS().Options.SurveyDialog.code,
            trialPeriod: Common.GetState().Dialogs.toJS().Options.SurveyDialog.trialPeriod
        }
    }

    getDialogHeight = () => {
        switch (this.state.currentPage) {
            case 1: return 500;
            case 2: return 700;
            case 3: return 600;
        }
    }

    getDialogTitle = () => {
        switch (this.state.currentPage) {
            case 1: return 'Step 1 of 3';
            case 2: return 'Step 2 of 3';
            case 3: return 'Step 3 of 3';
        }
    }

    getDialogPageBackButton = () => {
        switch (this.state.currentPage) {
            case 1: return null;
            case 2: return (<Button type='default' size='md' width='120px' onClick={() => this.handleBackPage()}>Back</Button>)
            case 3: return (<Button type='default' size='md' width='120px' onClick={() => this.handleBackPage()}>Back</Button>)
        }
    }

    getDialogPageNextButton = () => {
        switch (this.state.currentPage) {
            case 1: return (<Button type='default' size='md' width='120px' onClick={() => this.handleNextPage()}>I Agree</Button>)
            case 2: return (<Button type='default' size='md' width='120px' onClick={() => this.handleNextPage()}>Next</Button>)
            case 3: return (<Button type='default' size='md' width='260px' onClick={() => this.handleNextPage()}>Enjoy your free trial!</Button>)
        }
    }

    getDialogPage = () => {
        switch (this.state.currentPage) {
            case 1: return (<SurveyPage1 ref={(i) => this.dialogPage = i } state={this.state} />);
            case 2: return (<SurveyPage2 ref={(i) => this.dialogPage = i } state={this.state} />);
            case 3: return (<SurveyPage3 ref={(i) => this.dialogPage = i } state={this.state} />);
        }
    }

    handleBackPage() {
        switch (this.state.currentPage) {
            case 2:
                this.setState({
                    ...this.state,
                    ...this.dialogPage.getDialogResponse(),
                    currentPage: 1,
                });
                break;
            case 3:
                this.setState({
                    ...this.state,
                    ...this.dialogPage.getDialogResponse(),
                    currentPage: 2,
                });
                break;
        }
    }

    handleNextPage() {
        switch (this.state.currentPage) {
            case 1:
                if (this.dialogPage.validatePage()) {
                    this.setState({
                        ...this.state,
                        currentPage: 2
                    });
                }
                break;
            case 2:
                if (this.dialogPage.validatePage()) {
                    this.setState({
                        ...this.state,
                        ...this.dialogPage.getDialogResponse(),
                        currentPage: 3,
                    });
                }
                break;
            case 3:
                if (this.dialogPage.validatePage()) {
                    this.state = {
                        ...this.state,
                        ...this.dialogPage.getDialogResponse(),
                        currentPage: 3
                    };

                    this.handleOK();
                }
                break;
        }
    }

    handleClose = () => {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleOK = () => {
        this.setState({ showProcessingMessage: true });
        Common.UserInterface.CreateTrialUser({
            code: this.state.code,
            name: this.state.name,
            emailAddress: this.state.emailAddress,
            username: this.state.username,
            password: this.state.password,
            productionType: this.state.productionType.id !== 'Other' ? this.state.productionType.text : this.state.productionTypeValue,
            jobType: this.state.jobType.id !== 'Other' ? this.state.jobType.text : this.state.jobTypeValue,
            struggle: this.state.struggleValue,
            currentProvider: this.state.currentProvider
        })
        .then(json => {
            if (json.success) {
                Common.GetStore().dispatch(setUserDetails(json.userDetails));
                this.handleClose();
            }
        })
        .catch((err) => {
            Common.Dialogs.ShowAlertMessage({ title: 'ERROR', message: err.message });
        });
    }

    maybeProcessingMessage = () => {
        if (this.state.showProcessingMessage) {
            return (
                <div className='processing-message'>
                    Processing New Account...
                </div>
            );
        }
    }

    render() {
        const dialogPage = this.getDialogPage();


        return (
            <Dialog title={this.getDialogTitle()} name={dialogName} width={800} height={this.getDialogHeight()} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleClose.bind(this)} onEscape={this.handleClose.bind(this)}>
                <Flex block className='survey-dialog' position='top'>
                    <Flex className='content'>
                        <Flex position='top-left'>
                            { dialogPage }
                        </Flex>
                    </Flex>
                    <Flex row fixed className='footer'>
                        <Flex row position='left'>
                            {this.maybeProcessingMessage()}
                        </Flex>
                        <Flex row position='right'>
                            { this.getDialogPageBackButton() }
                            { this.getDialogPageNextButton() }
                        </Flex>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}
