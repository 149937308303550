import React from 'react';
import classNames from 'classnames';
import style from './new-cue-sheet.scss';

import { Button, Dialog, Flex, TextField } from 'components/ui';

const dialogName = 'NewCueSheet';

export default class NewCueSheetDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            title: ''
        }
    }

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    componentDidMount() {
        const title = Common.CueSheet.GetTitle();
        this.setState({ title: title });

        setTimeout(() => {
            this.input.focus();
            this.input.select();
        }, 20);
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose()        
    }

    handleOK() {
        Common.CueSheet.Save();
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose()        
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        return (
            <Dialog title='New Cue Sheet Warning' name={dialogName} width={600} height={240} modal={true} position='top' style={{top: '15%'}} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleEscape.bind(this)}>
                <Flex className='new-cue-sheet-dialog'>
                    <Flex position='left'>                        
                        <div className='warning'>Warning!</div>
                        <div className='message'>You have unsaved data on the current Cue Sheet.  Would you like to save before continuing?</div>
                    </Flex>
                    <Flex></Flex>
                    <Flex row height='30px' position='right'>
                        <Flex row width='200px'>
                            <Button type='success' size='md' width='100px' onClick={this.handleOK.bind(this)}>Yes</Button>
                            <Button type='danger' size='md' width='100px' onClick={this.handleCancel.bind(this)}>No</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}