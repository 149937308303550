import React from 'react';
import PropTypes from 'prop-types';
import './track-detail-edits.scss';

import { TrackResults } from '../../track-results.js';

export class TrackDetailEditsPanel extends React.Component {
    static propTypes = {
        track: PropTypes.object.isRequired,
        tracksLoaded_cb: PropTypes.func
    }

    constructor() {
        super(...arguments);
    }

    componentDidMount() {
        const tracks = this.props.track.EditTracks;
        const {tracksLoaded_cb} = this.props;

        if (!tracks) {
            Common.UserInterface.LoadEditTracks(this.props.track)
            .then(tracks => {
                if (tracksLoaded_cb)
                    tracksLoaded_cb(this, tracks);
                Common.Search.SetEditTracks(this.props.track, tracks);
                setTimeout(() => { this.forceUpdate() }, 1);
            })
            .catch(err => {
                console.error('ERROR: ', err);
            });
        } else {
            if (tracksLoaded_cb)
                tracksLoaded_cb(this, tracks);
        }
    }

    render() {
        const {
            track,
            hideAuditionIcon = false,
            hideDownloadIcon = false,
            hideMoreLikeThisIcon = false,
            hideTrackInfoLinks = false,
            state,
            show
        } = this.props;

        const display = show
            ? { display: 'block' }
            : { display: 'none' };

        const trackList = track.EditTracks || [];

        return (
            <div className='track-detail-edits' style={display}>
                <TrackResults 
                    tracks={trackList} 
                    isDrawer={true} 
                    source={state.source} 
                    hideToggle={true} 
                    onDownloadTrack={state.onDownloadTrack}
                    hideAuditionIcon={hideAuditionIcon} 
                    hideAltIcon={true} 
                    hideInfoIcon={true} 
                    hideDownloadIcon={hideDownloadIcon} 
                    hideMoreLikeThisIcon={hideMoreLikeThisIcon} 
                    hideTrackInfoLinks={hideTrackInfoLinks} />
            </div>
        )
    }
}


