import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { showContextMenu, closeContextMenu } from 'components/ui/context-menu/context-menu';
import {
  Button,
  Flex,
  FontIcon,
  FontIconButton,
  ScrollWindow,
  TextField,
} from 'components/ui';
import './basic-search.scss';
import { getTranslation } from 'utility/common';

const getFormattedDuration = (value) => {
  if (!value) return '';
  return Common.ConvertToHHMMSS(value);
}

export class BasicSearch extends React.Component {
  constructor() {
    super(...arguments);
  }

  get AdvancedSearch() {
    return this.UserInterface.AdvancedSearch;
  }

  get BasicSearch() {
    return this.UserInterface.BasicSearch;
  }

  get SelectedContextMenuItems() {
    return this.AdvancedSearch.SelectedContextMenuItems
  }

  get UserInterface() {
    const { UserInterface } = this.props;
    return UserInterface ? UserInterface.toJS() : {};
  }

  handleClearSearch() {
    if (Common.TrackPlayer.IsPlaying) Common.TrackPlayer.Stop();
    Common.UserInterface.ClearQuickSearch();
    Common.Search.ClearSearchResults();
    Common.UserInterface.ClearAdvancedSearch();
    let selectedMultiplePlaylistIds = localStorage.getItem("playlistIds");
    let showMultiplePlaylist = localStorage.getItem('ShowMultiplePlaylist');
    let selectedPlaylist = localStorage.getItem("selectedPlaylist");
    
    const auditionedTracks = localStorage.getItem('audition-history')

    localStorage.clear();
    localStorage.setItem("audition-history", auditionedTracks)
    localStorage.setItem("selectedPlaylist",selectedPlaylist);
    localStorage.setItem("playlistIds", selectedMultiplePlaylistIds)
    localStorage.setItem("ShowMultiplePlaylist", showMultiplePlaylist)
  }

  handleClearAllItems(type) {
    Common.UserInterface.ClearAllSelectedItems(type);
    this.handleSearch();
  }

  handleContextMenuSelect(type, item, checked = true) {
    Common.UserInterface.ClearPreviousTrackDuration();
    Common.UserInterface.SetAdvancedSearchSelectedContextMenuItem(type, item, checked);

    setTimeout(() => {
      this.handleSearch(null, true);
    }, 1)

  }

  handleDurationFieldValueChanged(fieldType, fieldValue) {
    let value = 0;

    if (fieldValue.indexOf(':') >= 0) {
      value = Common.ConvertToSeconds(fieldValue).toString();
    } else {
      value = fieldValue ? fieldValue : this[fieldType].value;
    }

    Common.UserInterface.UpdateAdvancedSearchField(fieldType, value);
    this.handleSearch();
  }

  handleFieldEnter(fieldType, fieldValue, e) {
    let value = 0;

    if (fieldValue.indexOf(':') >= 0) {
      value = Common.ConvertToSeconds(fieldValue).toString();
    } else {
      value = fieldValue ? fieldValue : this[fieldType].value;
    }

    Common.UserInterface.UpdateAdvancedSearchField(fieldType, value);
    this.handleSearch();
  }

  handleRemoveSelectedItem(type, index) {
    const item = this.AdvancedSearch.SelectedContextMenuItems[type][index];
    this.handleContextMenuSelect(type, item, false);
    this.handleSearch();
  }

  handleSearch = async (e, ignoreSearchText = false) => {
    if (Common.UserInterface.HasAdvancedSearchFiltersSelected()) {
      Common.Search.AdvancedSearch(this.UserInterface.AdvancedSearch, 0, Common.GetSearchSize(), true);
      localStorage.setItem('searchData', JSON.stringify(this.UserInterface.AdvancedSearch));
    } else {
      this.handleClearSearch();
    }
  }

  handleTogglePanel(panelName, toggled) {
    Common.UserInterface.ToggleBasicSearchPanel(panelName, toggled);
  }

  saveBasicSearch() {
    const basicSearch = {
      adTimingContextMenu: JSON.stringify(this.SelectedContextMenuItems.AdTiming),
      genreContextMenu: JSON.stringify(this.SelectedContextMenuItems.Genre),
      moodContextMenu: JSON.stringify(this.SelectedContextMenuItems.Mood),
      tempoContextMenu: JSON.stringify(this.SelectedContextMenuItems.Tempo),
      energyContextMenu: JSON.stringify(this.SelectedContextMenuItems.Energy),
      soundsContextMenu: JSON.stringify(this.SelectedContextMenuItems.SoundsLike),
      looksContextMenu: JSON.stringify(this.SelectedContextMenuItems.LooksLike),
      DurationMin: JSON.stringify(this.AdvancedSearch.DurationMin),
      DurationMax: JSON.stringify(this.AdvancedSearch.DurationMax)
    };
    localStorage.setItem('basicSearch', JSON.stringify(basicSearch));
  }

  render() {
    const selectedItems = [];

    selectedItems['AdTiming'] = getSelectedItemsElement(this.SelectedContextMenuItems.AdTiming, this.handleRemoveSelectedItem.bind(this, 'AdTiming'), this.handleClearAllItems.bind(this, 'AdTiming'));
    selectedItems['Genre'] = getSelectedItemsElement(this.SelectedContextMenuItems.Genre, this.handleRemoveSelectedItem.bind(this, 'Genre'), this.handleClearAllItems.bind(this, 'Genre'));
    selectedItems['Mood'] = getSelectedItemsElement(this.SelectedContextMenuItems.Mood, this.handleRemoveSelectedItem.bind(this, 'Mood'), this.handleClearAllItems.bind(this, 'Mood'));
    selectedItems['Tempo'] = getSelectedItemsElement(this.SelectedContextMenuItems.Tempo, this.handleRemoveSelectedItem.bind(this, 'Tempo'), this.handleClearAllItems.bind(this, 'Tempo'));
    selectedItems['Energy'] = getSelectedItemsElement(this.SelectedContextMenuItems.Energy, this.handleRemoveSelectedItem.bind(this, 'Energy'), this.handleClearAllItems.bind(this, 'Energy'));
    selectedItems['SoundsLike'] = getSelectedItemsElement(this.SelectedContextMenuItems.SoundsLike, this.handleRemoveSelectedItem.bind(this, 'SoundsLike'), this.handleClearAllItems.bind(this, 'SoundsLike'));
    selectedItems['LooksLike'] = getSelectedItemsElement(this.SelectedContextMenuItems.LooksLike, this.handleRemoveSelectedItem.bind(this, 'LooksLike'), this.handleClearAllItems.bind(this, 'LooksLike'));

    this.saveBasicSearch();

    return (
      <Flex className='basic-search show-y-scrollbar'>
        <ScrollWindow className='no-scroll-events'>
          <BasicSearchSection title={getTranslation("Genre")} toggled={this.SelectedContextMenuItems.Genre.length} contextMenuName='Genre'
            multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'Genre')}>
            <Flex className='section-row'>
              {selectedItems['Genre']}
            </Flex>
          </BasicSearchSection>
          <BasicSearchSection title={getTranslation("Mood")} toggled={this.SelectedContextMenuItems.Mood.length} contextMenuName='Mood'
            multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'Mood')}>
            <Flex className='section-row'>
              {selectedItems['Mood']}
            </Flex>
          </BasicSearchSection>
          <BasicSearchSection title={getTranslation("Tempo")} toggled={this.SelectedContextMenuItems.Tempo.length} contextMenuName='Tempo'
            multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'Tempo')}>
            <Flex className='section-row'>
              <Flex block>{selectedItems['Tempo']}</Flex>
            </Flex>
          </BasicSearchSection>
          <BasicSearchSection title={getTranslation("Energy")} toggled={this.SelectedContextMenuItems.Energy.length}
            contextMenuName='Energy' multiSelect={true}
            onSelect={this.handleContextMenuSelect.bind(this, 'Energy')}>
            <Flex className='section-row'>
              <Flex block>{selectedItems['Energy']}</Flex>
            </Flex>
          </BasicSearchSection>
          <BasicSearchSection title={getTranslation("scenes")} titleStyle={{whiteSpace: 'break-spaces'}} icon='looks-like' toggled={this.SelectedContextMenuItems.LooksLike.length}
            contextMenuName='LooksLike' multiSelect={true}
            onSelect={this.handleContextMenuSelect.bind(this, 'LooksLike')}>
            <Flex className='section-row'>
              {selectedItems['LooksLike']}
            </Flex>
          </BasicSearchSection>
          <BasicSearchSection title={getTranslation("Sounds Like")} icon='sounds-like'
            toggled={this.SelectedContextMenuItems.SoundsLike.length} contextMenuName='SoundsLike'
            multiSelect={true} onSelect={this.handleContextMenuSelect.bind(this, 'SoundsLike')}>
            <Flex className='section-row'>
              {selectedItems['SoundsLike']}
            </Flex>
          </BasicSearchSection>
          <BasicSearchSection title={getTranslation("Ad Timing")} toggled={this.SelectedContextMenuItems.AdTiming.length}
            contextMenuName='AdTiming' multiSelect={true}
            onSelect={this.handleContextMenuSelect.bind(this, 'AdTiming')}>
            <Flex className='section-row'>
              {selectedItems['AdTiming']}
            </Flex>
          </BasicSearchSection>
          <BasicSearchSection hideToggle={true}>
            <Flex row height={50}>
              <Flex row className='title'>{getTranslation("Duration")}</Flex>
              <Flex row className='section-row'>
                <Flex width='75px'>
                  <TextField placeholder='Min' fontSize={12} height={30} width={71}
                    ref={(i) => { this.DurationMin = i }}
                    value={getFormattedDuration(this.AdvancedSearch.DurationMin)}
                    showClearIcon={false}
                    onTextChanged={this.handleDurationFieldValueChanged.bind(this, 'DurationMin')}
                    onEnter={this.handleFieldEnter.bind(this, 'DurationMin')} /></Flex>
                <Flex width='5px' style={{ margin: '0 5px' }}>
                  <FontIcon name='caret-right' color='#888888' size={10} />
                </Flex>
                <Flex width='75px'>
                  <TextField placeholder='Max' fontSize={12} height={30} width={71}
                    ref={(i) => { this.DurationMax = i }}
                    value={getFormattedDuration(this.AdvancedSearch.DurationMax)}
                    showClearIcon={false}
                    onTextChanged={this.handleDurationFieldValueChanged.bind(this, 'DurationMax')}
                    onEnter={this.handleFieldEnter.bind(this, 'DurationMax')} />
                </Flex>
              </Flex>
            </Flex>
          </BasicSearchSection>
        </ScrollWindow>
        <Flex height='60px'>
          <Flex row className='footer'>
            <Flex position='left'>
              <Button type='success' onClick={this.handleSearch.bind(this)}>{getTranslation("Search")}</Button>
            </Flex>
            <Flex position='right'>
              <Button type='secondary' onClick={this.handleClearSearch.bind(this)}>{getTranslation("Reset")}</Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

class BasicSearchSection extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      openMenu: false
    }
  }

  get isLoggedIn() {
    const userDetails = Common.GetState().UserInterface.toJS().UserDetails;
    return (userDetails != null && userDetails.UserId != null);
  }

  handleCloseMenu() {
    this.setState({ openMenu: false });
  }

  handleShowContextMenu(e) {
    const { value = null } = this.props;
    const {
      contextMenu, contextMenuName = null, multiSelect = false, onSelect = () => {
      }
    } = this.props;

    if (contextMenuName != null) {
      let contextMenuData = contextMenuName ? Common.UserInterface.GetContextMenuData(contextMenuName) : contextMenu; // previously const
      const rect = ReactDOM.findDOMNode(this.toggleIcon).getBoundingClientRect();
      const selectedItems = value ? [value] : Common.UserInterface.GetAdvancedSearchSelectedContextMenuItems(this.props.contextMenuName);

      this.setState({ openMenu: true });

      if (contextMenuName.toLowerCase() === 'tempo') {
        var tempoContextMenu = [];
        tempoContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'slow') {
            return item;
          }
        }));
        tempoContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'medium slow') {
            return item;
          }
        }));
        tempoContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'mid tempo') {
            return item;
          }
        }));
        tempoContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'medium fast') {
            return item;
          }
        }));
        tempoContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'fast') {
            return item;
          }
        }));
        tempoContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'very fast') {
            return item;
          }
        }));
        contextMenuData = tempoContextMenu;
      } else if (contextMenuName.toLowerCase() === 'energy') {
        var energyContextMenu = [];
        energyContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'low') {
            return item;
          }
        }));
        energyContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'medium') {
            return item;
          }
        }));
        energyContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'high') {
            return item;
          }
        }));
        energyContextMenu.push(contextMenuData.find(function (item) {
          if (item.Label.toLowerCase() === 'building') {
            return item;
          }
        }));
        contextMenuData = energyContextMenu;
      } else if (contextMenuName.toLowerCase() === "soundslike") {
        if (Common.IsAudiofficina() && !this.isLoggedIn) {
          contextMenuData = contextMenuData.filter(x => x.Label !== "Bands & Artists" && x.Label !== "Composers");
        }
      }

      showContextMenu(rect.right, rect.top, contextMenuData, selectedItems, {
        multiSelect: multiSelect,
        owner: this.contextOwner,
      }, onSelect, null, this.handleCloseMenu.bind(this));
    }
  }

  handleContextMenu(e) {
    if (e.target.className.indexOf('toggle') !== -1) return;

    if (this.props.toggleOnClick) {
      this.props.onToggle(!this.props.toggled);
      return;
    }

    closeContextMenu();
    this.handleShowContextMenu(0);
  }

  handleMouseEnter(e) {
    closeContextMenu();
    this.handleShowContextMenu();
  }

  handleMouseLeave(e) {
    const target = e.relatedTarget;
    const context = document.getElementById("context-menu");
    const scrollbar1 = document.querySelector(".basic-search .scroll-window > div:last-child");
    const scrollbar2 = document.querySelector(".basic-search .scroll-window > div:last-child > div:first-child");

    try {
      // if we are NOT going to the context menu, close the context menu
      if (context && !context.contains(target) && target !== scrollbar1 && target !== scrollbar2) {
        closeContextMenu();
      }
    } catch(ex) {}
  }

  render() {
    const {
      title = '',
      icon = null,
      toggled = false,
      hideToggle = false,
      children = null,
      titleStyle = null,
      onToggle = () => {
      }
    } = this.props;

    const sectionHeaderClass = classNames('section-header', { 'open-menu': this.state.openMenu });

    const toggledIcon = toggled
      ? (<div className='basic-search-untoggled' style={{ width: 48, height: 48 }} />)
      : (<div className='basic-search-untoggled' style={{ width: 48, height: 48 }} />);

    const sectionClass = classNames({
      'section': true,
      'no-toggle': hideToggle
    })

    const contentClass = classNames({
      'section-content': true,
      'toggled': toggled || hideToggle
    });

    const toggleIcon = !hideToggle
      ? (<Flex className='toggle' fixed ref={(i) => {
        this.toggleIcon = i
      }}>{toggledIcon}</Flex>)
      : '';

    const sectionIcon = icon
      ? (
        <Flex className='section-icon'>
          <FontIcon name={icon} />
        </Flex>
      )
      : '';

    return (
      <div className={sectionClass} ref={(i) => {
        this.contextOwner = i
      }}>
        <Flex row className={sectionHeaderClass} onClick={this.handleContextMenu.bind(this)}
          onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)}>
          {sectionIcon}
          <Flex className='title' style={this.props.titleStyle} ref={(i) => {
            this.title = i
          }}>{title}</Flex>
          {toggleIcon}
        </Flex>
        <Flex className={contentClass}>
          {children}
        </Flex>
      </div>
    )
  }
}

class SelectedItem extends React.Component {
  constructor() {
    super(...arguments);
  }

  render() {
    const {
      index = null,
      item = null,
      label = null,
      onRemoveSelectedItem = () => {
      }
    } = this.props;

    return (
      <Flex row className='item' onClick={onRemoveSelectedItem.bind(this, index)}>
        <Flex width='90%' position='left' className='ellipsis'>{label}</Flex>
        <Flex width='10%'><FontIconButton name='close-thin' size={12} /></Flex>
      </Flex>
    )
  }
}

class SelectedItemsClearAll extends React.Component {
  constructor() {
    super(...arguments);
  }

  render() {
    const {
      onClearAllItems = () => {
      }
    } = this.props;

    return (
      <Flex row className='item-clear-all' onClick={onClearAllItems}>
        <Flex width='90%' position='right' className='ellipsis'>Clear All</Flex>
        <Flex width='10%'><FontIconButton name='close-thin' size={12} /></Flex>
      </Flex>
    )
  }
}

const getSelectedItemsElement = (selectedItems = [], onRemoveSelectedItem, onClearAllItems) => {
  if (selectedItems.length == 0) return '';

  const items = selectedItems.map((m, i) => (
    <SelectedItem key={i} index={i} label={m.Label} item={m} onRemoveSelectedItem={onRemoveSelectedItem} />));
  const clearAllButton = selectedItems.length > 1 ? (<SelectedItemsClearAll onClearAllItems={onClearAllItems} />) : '';

  return (
    <Flex className='selected-items' width='100%'>
      <div style={{ width: '100%' }}>
        {clearAllButton}
        {items}
      </div>
    </Flex>
  )
}

export default connect(state => {
  return {
    UserInterface: state.UserInterface
  }
})(BasicSearch);