import React from 'react';
import classNames from 'classnames';
import './track-hider.scss';

import { Button, Checkbox, Dialog, Flex, FontIcon, ScrollWindow, TextField, Typewriter } from 'components/ui';
import { setAdvancedSearchFieldValue } from '../../../../../store/userInterface/actions';

const dialogName = 'TrackHider';

export default class TrackHider extends React.Component {
    constructor() {
        super(...arguments);

        this.onKeyUp = (event) => {
            if (event.which == 27) {
                Common.Dialogs.ShowDialog(dialogName, false);
            }

            if (event.which == 13) {
                this.handleOK();
            }
        }

        this.state = {
            errorMessage: '',
            message: null,
            filter: '',
            playlists: [],
            selectedPlaylists: [],
        }
    }

    get filteredPlaylists() {
        return this.state.playlists.filter(p => p.text.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0);
    }

    get onClose() {
        const {
            onClose = () => {}
        } = this.props;

        return onClose;
    }

    componentWillMount() {
        this.state.message = 'This will hide from search results any tracks associated with the selected playlists.';

        Common.Playlist.GetPlaylistsAsync()
            .then(playlists => {
                this.state.playlists = playlists.map(p => { return { id: p.Id, text: p.Title }});
                this.forceUpdate();
            })
            .catch(err => {
                Common.Dialogs.ShowAlertMessage({ title: 'Track Hider Error', message: 'Unable to get the list of user playlists.' });
            });

        Common.Playlist.GetTrackHiderPlaylistsAsync()
            .then(playlists => {
                this.state.selectedPlaylists = playlists.map(p => { return { id: p }});
                this.forceUpdate();
            })
            .catch(err => {
                Common.Dialogs.ShowAlertMessage({ title: 'Track Hider Error', message: 'Unable to get the selected list of excluded playlists.' });
            });

        document.addEventListener('keyup', this.onKeyUp);

        setTimeout(() => {
            this.searchField.focus();
        }, 200);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.onKeyUp);
    }

    get dispatch() {
        return Common.GetStore().dispatch;
    }

    get UserInterface() {
        return Common.GetState().UserInterface ? Common.GetState().UserInterface.toJS() : {};
    }

    handleFilterTextChanged(value) {
        this.setState({ filter: value });
    }

    handleCancel = () => {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleClearAll = () => {
        this.setState({ selectedPlaylists: [] });
    }

    handleOK = () => {
        Common.Playlist.UpdateSearchTrackExclusionList(this.state.selectedPlaylists.map(p => p.id))
            .then(() => {
                this.dispatch(setAdvancedSearchFieldValue("ExcludePlaylistTracks", this.state.selectedPlaylists.length > 0));

                if (this.UserInterface.AdvancedSearch.ExcludePlaylistTracks) {
                    if (Common.UserInterface.HasAdvancedSearchFiltersSelected()) {
                        Common.Search.AdvancedSearch(this.UserInterface.AdvancedSearch, 0, Common.GetSearchSize(), true);
                    }
                }

                this.handleCancel();
            })
            .catch((err) => {
                Common.Dialogs.ShowAlertMessage({ title: 'Error Saving Search Exclusion List', message: err.message });
            });
    }

    handleSelectAll = () => {
        const selectedPlaylists = this.state.selectedPlaylists;
        this.state.playlists.forEach(p => {
            if (!selectedPlaylists.find(x => x.id === p.id)) {
                selectedPlaylists.push(p);
            }
        });

        this.setState({ selectedPlaylists: selectedPlaylists });
    }

    handleSelectPlaylist(playlist, isSelected = false) {
        const newSelectedPlaylists = [...this.state.selectedPlaylists];

        if (isSelected && !newSelectedPlaylists.find(x => x.id == playlist.id)) {
            newSelectedPlaylists.push(playlist);
        }

        if (!isSelected && newSelectedPlaylists.find(x => x.id == playlist.id)) {
            newSelectedPlaylists.splice(newSelectedPlaylists.findIndex(x => x.id == playlist.id), 1);
        }

        this.setState({ selectedPlaylists: newSelectedPlaylists });
    }

    handleTextChanged(value) {
        this.setState({ title: value });
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        const playlistItems = this.filteredPlaylists.map((p, i) => {
             var isSelected = (this.state.selectedPlaylists.find(x => x.id == p.id) != null);

            return (
                <TrackHiderItem key={i}
                                playlist={p}
                                selected={isSelected}
                                onSelect={this.handleSelectPlaylist.bind(this)} />
            );
        })

        return (
            <Dialog title='Track Hider' name='TrackHider' width={800} height={620} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleCancel.bind(this)}>
                <Flex block className='track-hider' position='top'>
                    <Flex row className='header'>
                        <FontIcon name='inspector' />
                        <Flex position='left'><Typewriter message={ this.state.message } /></Flex>
                    </Flex>
                    <Flex row className='header-search' position='top-left'>
                        <Flex className='search-filter-title' width={100}>Search: </Flex>
                        <Flex><TextField ref={(i) => { this.searchField = i; }} placeholder='Search name' value={this.state.filter} height={35} onChange={this.handleFilterTextChanged.bind(this)} /></Flex>
                    </Flex>
                    <Flex className='content'>
                        <ScrollWindow showscrollIndicator={true}>
                            <Flex position='left'>
                                {playlistItems}
                            </Flex>
                        </ScrollWindow>
                    </Flex>
                    <Flex row fixed className='footer' position='right'>
                        <Flex className='left-buttons' row position='left'>
                            <Button type='default' size='md' width='180px' onClick={this.handleSelectAll.bind(this)}>Select All</Button>
                            <Button type='default' size='md' width='180px' onClick={this.handleClearAll.bind(this)}>Clear All</Button>
                        </Flex>
                        <Flex className='right-buttons' row position='right'>
                            <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                            <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}

class TrackHiderItem extends React.Component {
    constructor() {
        super(...arguments);
        this.delay = 400;
        this.hasClicked = false;
    }

    handleSelectPlaylist(item) {
        const {
            selected = false,
            onSelect = () => {},
            onDoubleClick = () => {}
        } = this.props;

        onSelect(item, !selected);
    }

    render() {
        const {
            playlist = null,
            selected = false,
            onSelect = () => {}
        } = this.props;

        const trackHiderItemClass = classNames('track-hider-item', { selected: selected });

        return (
            <div className={trackHiderItemClass} onClick={this.handleSelectPlaylist.bind(this, playlist)}>
                <Checkbox value={selected} />{playlist.text}
            </div>
        )
    }
}