import React from "react";
import { fetchGet, fetchMultiPart, fetchPost } from "utility/fetchHelpers";
import { showDialog } from "store/dialogs/actions";

import { addTrackToPlaylist, addTrackListToPlaylist, clearPlaylist, deletePlaylist, removeTrackFromPlaylist, setPlaylist } from "store/playlist/actions";

import { setSharePlaylist } from "store/sharePlaylist/actions";

import { fetchAuth } from "utility/fetchHelpers.js";
import { clearPlaylistFilters, setUserPlaylists, setUserSharedPlaylists, setIsSearching } from "store/userInterface/actions";
import { showWorkingAlert, closeWorkingAlert } from "components/ui/alert/working";
import { updatePreviousPlaylistTrack } from "store/previousPlaylistTrack/actions";

const BaseUrl = process.env.WEBAPI_URL;
let store = null;
let dispatch = null;

// Gets the Current Playlist state
const getCurrentPlaylist = () => {
  return store.getState().Playlist ? store.getState().Playlist.toJS() : {};
};

// Gets the Current Share Playlist state
const getCurrentSharePlaylist = () => {
  return store.getState().SharePlaylist ? store.getState().SharePlaylist.toJS() : {};
};

class Playlist {
  constructor(_store) {
    store = _store;
    dispatch = _store.dispatch;
  }

  GetPlaylistId() {
    const playlist = getCurrentPlaylist();
    return playlist.Id;
  }

  AddAltTracks(trackId, type) {
    const playlist = getCurrentPlaylist();

    showWorkingAlert({
      content: "Please wait while we are loading the tracks into the playlist",
      contentClass: "warning-alert",
      title: "Adding Tracks to Playlist",
      height: 185,
      width: 480,
    });
    fetchPost(`/api/playlist/addAltTracks`, {
      playlistId: playlist.Id,
      trackId: trackId,
      type: type,
    })
      .then((json) => {
        this.SetPlaylist(json);
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      })
      .finally(() => {
        closeWorkingAlert();
      });
  }

  AddSearchResults(options, playlist = null) {
    dispatch(setIsSearching(true));

    setTimeout(() => {
      if (playlist == null) playlist = getCurrentPlaylist();

      if (playlist.Id == null) {
        Common.AddTracksFromSearchResults = options;
        Common.Dialogs.ShowDialog("CreatePlaylist", true);
        return;
      }

      Common.UserInterface.SetSelectedPlaylistTab(0);

      fetchPost(`/api/playlist/addSearchResults`, {
        PlaylistId: playlist.Id,
        IncludeMainTracks: options.includeMainTracks,
        IncludeAltTracks: options.includeAltTracks,
        IncludeEditTracks: options.includeEditTracks,
      })
        .then((json) => {
          this.SetPlaylist(json);
        })
        .catch((err) => {
          console.error("ERROR: ", err);
        })
        .finally(() => {
          dispatch(setIsSearching(false));
        });
    }, 1);
  }

  openNewPlaylistDialog(track) {
    Common.NewTracks = [track.Id];
    Common.Dialogs.ShowDialog("CreatePlaylist", true);
    return;
  }

  AddTrack(track, playlistId, isNewPlaylist) {
    const playlist = getCurrentPlaylist();
    if (playlistId === undefined) {
      if (isNewPlaylist) {
        this.openNewPlaylistDialog(track);
        return;
      } else {
        if (playlist.Id == null) {
          this.openNewPlaylistDialog(track);
          return;
        } else {
          playlistId = playlist.Id;
        }
      }
    }

    Common.UserInterface.SetSelectedPlaylistTab(0);
    fetchPost(`/api/playlist/tracks/add`, {
      PlaylistId: playlistId,
      TrackId: track.Id,
    })
      .then(() => {
        store.dispatch(addTrackToPlaylist(track, playlistId));
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }

  AddTrackList(tracks) {
    // var statusShowDialogAddTrack = false;

    setTimeout(() => {
      const playlist = getCurrentPlaylist();
      const trackList = tracks.map((t) => t.Id);

      if (playlist.Id == null) {
        Common.NewTracks = trackList;
        Common.Dialogs.ShowDialog("CreatePlaylist", true);
        return;
      }

      Common.UserInterface.SetSelectedPlaylistTab(0);

      fetchPost(`/api/playlist/tracks/add`, {
        PlaylistId: playlist.Id,
        TrackList: trackList,
      })
        .then(() => {
          // statusShowDialogAddTrack = true;
          store.dispatch(addTrackListToPlaylist(tracks));
        })
        .catch((err) => {
          console.error("ERROR: ", err);
        })
        .finally(() => {
          dispatch(setIsSearching(false));
        });
    }, 1);
  }

  CreatePlaylist(title) {
    return new Promise((resolve, reject) => {
      fetchPost(`/api/playlist/create`, {
        Title: title,
        Tracks: Common.NewTracks || [],
      })
        .then((playlist) => {
          if (Common.AddTracksFromSearchResults == null) {
            delete Common.NewTracks;
            Common.UserInterface.SetSelectedPlaylistTab(0);
            store.dispatch(setPlaylist(playlist));
          } else {
            this.AddSearchResults(Common.AddTracksFromSearchResults, playlist);
            delete Common.AddTracksFromSearchResults;
          }

          Common.Dialogs.ShowDialog("CreatePlaylist", false);
          resolve(playlist);
        })
        .catch((err) => {
          console.error("ERROR: ", err);
          reject(err);
        });
    });
  }

  ClearPlaylist(playlistId) {
    if (Common.TrackPlayer.AudioSource === "playlist") Common.TrackPlayer.Stop();

    Common.Playlist.SetPlaylist({
      Id: Common.NewGuid(),
      Title: "Untitled",
      Tracks: [],
    });
  }

  DeletePlaylist(playlistId) {
    fetchPost(`/api/playlist/delete`, {
      PlaylistId: playlistId,
    })
      .then((json) => {
        Common.Dialogs.ShowDialog("DeletePlaylist", false);
        store.dispatch(deletePlaylist());

        Common.UserInterface.SetSelectedPlaylistTrack(null);
        Common.UserInterface.SetSelectedPlaylistTab(0);
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }

  DownloadPlaylistTracks(audioFormat = "aif") {
    fetchAuth()
      .then((value) => {
        const playlist = Common.Playlist.GetPlaylist();

        fetchPost("/api/playlist/download", {
          PlaylistId: playlist.Id,
          PlaylistTitle: playlist.Title,
          AudioFormat: audioFormat,
        })
          .then((json) => {
            window.location = json.Url;
          })
          .catch((err) => {
            console.error("ERROR: ", err);
          });
      })
      .catch((err) => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  DownloadTracks(playlistId, playlistTitle, audioFormat = "aif") {}

  DuplicatePlaylist(playlistId, title) {
    const playlist = this.GetPlaylist();

    fetchPost(`/api/playlist/duplicate`, {
      PlaylistId: playlistId,
      Title: title,
    })
      .then((json) => {
        Common.Dialogs.ShowDialog("DuplicatePlaylist", false);

        playlist.Id = json.Id;
        playlist.Title = json.Title;

        store.dispatch(setPlaylist(playlist));
        Common.UserInterface.SetSelectedPlaylistTab(0);
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }

  ExportPlaylistAsXML(playlistId = "", playlistType = "playlist") {
    if (playlistType === 'shared') {
      const playlist = this.GetPlaylist();

      let link = document.createElement("a");
      document.body.appendChild(link);
      link.id = "download-link";
      link.download = true;
      link.href = `/api/playlist/export/playlist/${playlistId}/${playlistType}`;

      link.click();
      document.body.removeChild(link);
    } else {
      fetchAuth()
        .then((value) => {          
          const playlist = this.GetPlaylist();

          let link = document.createElement("a");
          document.body.appendChild(link);
          link.id = "download-link";
          link.download = true;
          link.href = playlistType === "playlist" 
            ? `/api/playlist/export/playlist/${playlist.Id}/${playlistType}` 
            : `/api/playlist/export/playlist/${playlistId}/${playlistType}`;

          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error("ExportPlaylistAsXML", err);
          Common.Dialogs.ShowDialog("LoginDialog", true);
        });
    }
  }
  

  ExportPlaylistAsAvid(videoFormat, frameRate) {
    return new Promise((resolve, reject) => {
      fetchAuth()
        .then((value) => {
          const playlist = this.GetPlaylist();

          let link = document.createElement("a");
          document.body.appendChild(link);
          link.id = "download-link";
          link.download = true;
          link.href = `/api/playlist/export/avid/${playlist.Id}/${videoFormat}/${frameRate}`;
          link.click();
          document.body.removeChild(link);

          resolve();
        })
        .catch((ex) => {
          Common.Dialogs.ShowDialog("LoginDialog", true);
          reject(ex.message);
        });
    });
  }

  ExportPlaylistAsFinalCut() {
    fetchAuth()
      .then((value) => {
        const playlist = this.GetPlaylist();

        let link = document.createElement("a");
        document.body.appendChild(link);
        link.id = "download-link";
        link.download = true;
        link.href = `/api/playlist/export/finalcut/${playlist.Id}`;
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  ExportPlaylistAsText() {
    fetchAuth()
      .then((value) => {
        const playlist = this.GetPlaylist();

        let link = document.createElement("a");
        document.body.appendChild(link);
        link.id = "download-link";
        link.download = true;
        link.href = `/api/playlist/export/text/${playlist.Id}`;
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  // ----- TODO: Copy this functionality to the Electron API -----
  GetContextMenu(type) {
    const playlist = this.GetPlaylist();
    const tracks = playlist.Tracks;

    switch (type) {
      case "mood":
        return tracks
          .filter(
            (track, index, self) =>
              self.findIndex((t) => {
                return t.Mood.Id === track.Mood.Id;
              }) === index
          )
          .map((t) => t.Mood);
      case "genre":
        return tracks
          .filter(
            (track, index, self) =>
              self.findIndex((t) => {
                return t.Genre.Id === track.Genre.Id;
              }) === index
          )
          .map((t) => t.Genre);
      case "tempo":
        return tracks
          .filter(
            (track, index, self) =>
              self.findIndex((t) => {
                return t.Tempo.Id === track.Tempo.Id;
              }) === index
          )
          .map((t) => t.Tempo);
      case "energy":
        return tracks
          .filter(
            (track, index, self) =>
              self.findIndex((t) => {
                return t.Energy.Id === track.Energy.Id;
              }) === index
          )
          .map((t) => t.Energy);
      case "soundsLike":
        return tracks
          .filter(
            (track, index, self) =>
              self.findIndex((t) => {
                return t.SoundsLike.Id === track.SoundsLike.Id;
              }) === index
          )
          .map((t) => t.SoundsLike);
      case "looksLike":
        return tracks
          .filter(
            (track, index, self) =>
              self.findIndex((t) => {
                return t.LooksLike.Id === track.LooksLike.Id;
              }) === index
          )
          .map((t) => t.LooksLike);
    }
  }

  GetPlaylist() {
    return store.getState().Playlist.toJS();
  }

  GetPlaylists() {
    return new Promise((resolve, reject) => {
      fetchPost("/api/playlist/list")
        .then((playlists) => {
          dispatch(setUserPlaylists(playlists));          
          resolve(playlists);
        })
        .catch((err) => {
          console.error("ERROR: ", err);
          reject(err);
        });
    });
  }

  GetSharedPlaylists() {
    return new Promise((resolve, reject) => {
      fetchPost("/api/playlist/shared/list")
        .then((playlists) => {
          dispatch(setUserSharedPlaylists(playlists));          
          resolve(playlists);
        })
        .catch((err) => {
          console.error("ERROR: ", err);
          reject(err);
        });
    });
  }

  GetSharedPlaylistUsers() {
    return new Promise((resolve, reject) => {
      fetchGet("/api/playlist/users")
        .then((users) => {
          //dispatch(setUserSharedPlaylists(playlists));
          resolve(users);
        })
        .catch((err) => {
          console.error("ERROR: ", err);
          reject(err);
        });
    });
  }

  GetPlaylistsByTrackId(trackId) {
    return new Promise((resolve, reject) => {
      fetchPost("/api/playlist/list", { trackId: trackId })
        .then((playlists) => {
          if (Common.UserInterface.GetShowMultiplePlaylist()) {
            let playlistIds = localStorage.getItem("playlistIds");
            let selectedPlaylists = JSON.parse(playlistIds);
            let playlistItems = [];
            playlists.map((p, i) => {
              if (selectedPlaylists == null) return;
              selectedPlaylists.map((x) => {
                if (x === p.Id) {
                  playlistItems.push(p);
                }
              });
            });
            dispatch(setUserPlaylists(playlistItems));
            resolve(playlistItems);
          } else {
            dispatch(setUserPlaylists(playlists));
            resolve(playlists);
          }
        })
        .catch((err) => {
          console.error("ERROR: ", err);
          reject(err);
        });
    });
  }

  GetPlaylistsAsync() {
    return new Promise((resolve, reject) => {
      fetchPost("/api/playlist/list")
        .then((playlists) => {
          resolve(playlists);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  ImportPlaylist(file) {
    var data = new FormData();
    data.append("fileToUpload", file);
    data.append("FileType", file.type);
    data.append("FileName", file.name.replace(".csv", ""));

    showWorkingAlert({
      content: "Your tracks are loading. This may take a minute or two depending on the number of tracks in your list",
      contentClass: "warning-alert",
      title: "Adding Tracks to Playlist",
      height: 185,
      width: 680,
    });

    fetchMultiPart("/api/playlist/import", data)
      .then((playlist) => {
        Common.Playlist.SetPlaylist(playlist);
        Common.UserInterface.SetSelectedPlaylistTab(0);
        Common.Dialogs.ShowDialog("ImportPlaylist", false);
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      })
      .finally(() => {
        closeWorkingAlert();
      });
  }

  MergePlaylist(primaryPlaylistId, secondaryPlaylistId, title) {
    fetchPost(`/api/playlist/merge`, {
      PrimaryPlaylistId: primaryPlaylistId,
      SecondaryPlaylistId: secondaryPlaylistId,
      Title: title,
    })
      .then((playlist) => {
        Common.Dialogs.ShowDialog("MergePlaylist", false);
        store.dispatch(setPlaylist(playlist));
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }

  IsTrackInPlaylist(track) {
    const playlist = getCurrentPlaylist();
    return playlist.Tracks.findIndex((t) => t.Id == track.Id) >= 0;
  }

  SetPlaylistTrackToAdminPage(playlistData) {
    let trackIds = [];
    playlistData.Tracks.forEach((subItem) => {
      trackIds.push(subItem.Id);
    });
    localStorage.setItem('SearchedTrackIds', trackIds);
  }

  LoadPlaylist(id, status) {
    if (id == "null") return;
    
    const playlistTrackToAdminStatus = store.getState().UserInterface.toJS().ShowPlaylistExtension;
    return new Promise((resolve, reject) => {
      const jsonData = { PlaylistId: id };
      if (!status) {
        showWorkingAlert({
          content: "Please wait while the playlist is being loaded.",
          contentClass: "warning-alert",
          title: "Loading Playlist",
          height: 185,
          width: 480,
        });
      }

      fetchPost(`/api/playlist/load`, jsonData)
        .then((playlistData) => {
          if (Common.TrackPlayer.AudioSource === "playlist") {
            const track = Common.UserInterface.GetSelectedPlaylistTrack();
            Common.TrackPlayer.Stop();
            Common.UserInterface.SetSelectedPlaylistTrack(null);
          }

          if (playlistTrackToAdminStatus) {
            this.SetPlaylistTrackToAdminPage(playlistData);
          }

          dispatch(setPlaylist(playlistData));
          dispatch(setSharePlaylist(playlistData));
          dispatch(clearPlaylistFilters());
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          closeWorkingAlert();
        });
    });
  }

  OpenPlaylist(id) {
    const playlist = getCurrentPlaylist();

    Common.UserInterface.SetSelectedPlaylistTab(0);

    fetchPost(`/api/playlist/load`, {
      PlaylistId: playlist.Id,
    })
      .then((playlistData) => {
        // store.dispatch(addTrackToPlaylist(track));
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }

  RemoveAltTracks(trackId, type) {
    const playlist = getCurrentPlaylist();

    fetchPost(`/api/playlist/removeAltTracks`, {
      playlistId: playlist.Id,
      trackId: trackId,
      type: type,
    })
      .then((json) => {       
        json.Tracks.find(x => x.Id == trackId).AltTracks = playlist.Tracks.find(x => x.Id == trackId).AltTracks;
        this.SetPlaylist(json);
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }

  RenamePlaylist(playlistId, title) {
    fetchPost(`/api/playlist/rename`, {
      PlaylistId: playlistId,
      Title: title,
    })
      .then((playlist) => {
        Common.Dialogs.ShowDialog("RenamePlaylist", false);
        store.dispatch(setPlaylist(playlist));
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }

  RemoveTrack(track) {
    const playlist = getCurrentPlaylist();
    const selectedPlaylistTrack = store.getState().UserInterface.toJS().SelectedPlaylistTrack;

    if (!track) return;

    if (Common.TrackPlayer.AudioSource === "playlist" && Common.TrackPlayer && Common.TrackPlayer.track.Id == track.Id) Common.TrackPlayer.Stop();

    fetchPost(`/api/playlist/tracks/remove`, {
      PlaylistId: playlist.Id,
      TrackId: track.Id,
    })
      .then(() => {        
        store.dispatch(removeTrackFromPlaylist(track));
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }

  SetPlaylist(playlist) {
    dispatch(setPlaylist(playlist));
  }

  SharePlaylist(playlistMessage, disableDownloads, playlistType = 'User', selectedUsers = []) {
    return new Promise((resolve, reject) => {
      const sharePlaylist = getCurrentSharePlaylist();
      const tracks = sharePlaylist.Tracks.map((track) => ({ Id: track.Id }));

      const postData = {
        playlistId: sharePlaylist.Id,
        playlistMessage: playlistMessage,
        disableDownloads: disableDownloads,
        playlistType: playlistType,
        tracks: tracks,
        selectedUsers: selectedUsers
      };

      fetchPost(`/api/playlist/shared/create`, postData)
        .then((response) => {
          if (response.Success) {
            resolve(response);
          } else {
            throw new Error("An error occurred while sharing the playlist.");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  ShowCreatePlaylistWindow() {
    fetchAuth()
      .then((value) => {
        Common.Dialogs.ShowDialog("CreatePlaylist", true);
      })
      .catch(() => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  ShowDeletePlaylistWindow() {
    fetchAuth()
      .then((value) => {
        Common.Dialogs.ShowDialog("DeletePlaylist", true);
      })
      .catch(() => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  ShowDuplicatePlaylistWindow() {
    fetchAuth()
      .then((value) => {
        Common.Dialogs.ShowDialog("DuplicatePlaylist", true);
      })
      .catch(() => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  ShowImportPlaylistWindow() {
    fetchAuth()
      .then((value) => {
        Common.Dialogs.ShowDialog("ImportPlaylist", true);
      })
      .catch((e) => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  ShowMergePlaylistWindow() {
    fetchAuth()
      .then((value) => {
        Common.Dialogs.ShowDialog("MergePlaylist", true);
      })
      .catch(() => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  ShowOpenPlaylistWindow() {
    fetchAuth()
      .then((value) => {
        Common.Dialogs.ShowDialog("OpenPlaylist", true);
      })
      .catch(() => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  ShowRenamePlaylistWindow() {
    fetchAuth()
      .then((value) => {
        Common.Dialogs.ShowDialog("RenamePlaylist", true);
      })
      .catch(() => {
        Common.Dialogs.ShowDialog("LoginDialog", true);
      });
  }

  GetTrackHiderPlaylistsAsync() {
    return new Promise((resolve, reject) => {
      fetchGet("/api/playlist/searchExclusions").then((response) => {
        if (response.Success) {
          resolve(response.PlaylistIds);
        } else {
          throw new Error("An error occurred while getting the list of search exclusions.");
        }
      });
    });
  }

  UpdateSearchTrackExclusionList(playlistIds = []) {
    return new Promise((resolve, reject) => {
      const postData = {
        playlistIds: playlistIds,
      };

      fetchPost(`/api/playlist/searchExclusions`, postData)
        .then((response) => {
          if (response.Success) {
            resolve(response);
          } else {
            throw new Error("An error occurred while updating the search exclusions.");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  UpdateMultiplePlaylistItems(playlistIds = []) {
    return new Promise((resolve, reject) => {
      localStorage.setItem("playlistIds", JSON.stringify(playlistIds));
      Common.UserInterface.ToggleMultiplePlaylist(true);
      resolve(playlistIds);
    });
  }

  GetMultiplePlaylistsAsync() {
    return new Promise((resolve, reject) => {
      let playlistIds = [];
      if (localStorage.getItem("playlistIds")) {
        playlistIds = localStorage.getItem("playlistIds");
        resolve(JSON.parse(playlistIds));
      }
    });
  }

  LoadPreviousPlayList() {
    if (Common.GetState().PreviousPlaylistTracks.size != 0) {
      let lastPlaylistTrack = Common.GetState().PreviousPlaylistTracks._tail.array[Common.GetState().PreviousPlaylistTracks._tail.array.length - 1];

      if (lastPlaylistTrack != null) {
        Common.Playlist.AddTrack(lastPlaylistTrack);
      }

      let previousPlaylistTracks = Common.GetState().PreviousPlaylistTracks.pop();
      Common.Playlist.UpdatePreviousPlaylistTracks(previousPlaylistTracks);
    }
  }

  UpdateTrackIsPlayed(postData) {
    return new Promise((resolve, reject) => {
      fetchPost(`/api/playlist/updateTrackIsPlayed`, postData)
        .then((response) => {
          if (response.Success) {
            resolve(response);
          } else {
            throw new Error("An error occurred while updating the shared playlist track.");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  UpdatePreviousPlaylistTracks(playlist) {
    store.dispatch(updatePreviousPlaylistTrack(playlist));
  }

  SetSharePlaylist(playlist) {
    store.dispatch(setSharePlaylist(playlist));
  }

  SetDefaultSharePlaylist() {
    const playlist = getCurrentPlaylist();
    store.dispatch(setSharePlaylist(playlist));
  }
}

export default Playlist;
