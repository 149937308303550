import React from 'react';
import classNames from 'classnames';
import { Flex, FontIcon } from 'components/ui';
import Styles from './menu-drop-down.scss';


export class MenuDropDown extends React.Component {
    constructor() {
        super(...arguments);
    }

    handleMouseOver(e, item) {
        // TODO: if item has children -- show child menu
    }

    handleMouseOut(e, item) {        
        // TODO: if item has children -- remove child menu
    }

    render() {
        const {
            x = 0,
            y = 0,
            menu = [],
            onCloseMenuDropDown = () => {}
        } = this.props;

        return (
            <div className='menu-drop-down' onClick={onCloseMenuDropDown.bind(this)} onContextMenu={onCloseMenuDropDown.bind(this)}>
                <MenuDropDownWindow root={this} x={x} y={y} menu={menu} />
            </div>
        )
    }
}

class MenuDropDownWindow extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        const {
            x = 0,
            y = 0,
            menu = [],
            level = 1, 
            root = null
        } = this.props;

        const menuStyle = {
            left: x,
            top: y
        };

        const menuItems = menu.map((item, index) => {
            return (<MenuDropDownItem key={index} root={root} item={item} onItemSelected={root.handleMenuItemSelected} onMouseOver={root.handleMouseOver} onMouseOut={root.handleMouseOut} />)
        });

        return (
            <div style={menuStyle} className='menu-drop-down-window'>{menuItems}</div>
        )
    }
}

class MenuDropDownItem extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        const {
            item = {},
            root = null
        } = this.props;   

        const onClick = item.onClick ? item.onClick : () => {};

        if (item.Label != '-') {
            return (
                <div className='menu-drop-down-item' 
                    onClick={onClick} 
                    onMouseOver={root.handleMouseOver.bind(this, item)}
                    onMouseOut={root.handleMouseOut.bind(this, item)}>{item.Label}</div>
            )
        } else {
            return (
                <div className='menu-drop-down-seperator' />
            )
        }        

    }
}
