import React, { useEffect, useMemo, useState } from 'react'
import './playlist-dropdown.scss'
import {Badge} from 'material-ui'
import {Flex, RenderIf} from 'components/ui'
import {Tab, Tabs} from '../../../ui/tabs/tabs'

const PlaylistDropDown = () => {
  const [tab, setTab] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [savedPlaylists, setSavedPlaylists] = useState([])
  const [sharedPlaylists, setSharedPlaylists] = useState([])
  
  useEffect(() => {    
    const loadPlaylists = async () => {
      const savedPlaylists = await Common.Playlist.GetPlaylists()
      const sharedPlaylists = await Common.Playlist.GetSharedPlaylists()
      setSavedPlaylists(savedPlaylists)
      setSharedPlaylists(sharedPlaylists)
      setIsLoading(false)
    } 

    loadPlaylists()
  }, [])

  const handleLoadPlaylist = async (playlist) => {
    await Common.Playlist.LoadPlaylist(playlist.Id, false);    
    await Common.UserInterface.LoadSharedPlaylistsForUser();
  }

  // const sharedPlaylistTabLabel = <div className='tab-header'>{getTranslation('Your Playlists')}</div>  
  const sharedPlaylistTabLabel = (
    <div style={{position: 'relative'}}>
      <div style={{position: 'relative'}}>Shared Playlists</div>
      <RenderIf condition={sharedPlaylists.filter(x => !x.HasBeenViewed).length > 0}>
        <Badge className='badge' badgeContent={sharedPlaylists.filter(x => !x.HasBeenViewed).length} style={{position: 'absolute', zIndex: 1, top: -5, right: -40}} badgeStyle={{backgroundColor: 'red', color: 'white', fontSize: '110%'}} />
      </RenderIf>
    </div>
  )

  return (
    <div className='playlist-drop-down'>
      <Tabs>
          <Tab label={'Your Playlists'} selected={tab == 0} onTabSelect={() => setTab(0)}>
              <Flex hide={tab !== 0} className='container'>                
                <RenderIf condition={!isLoading}>
                  <div style={{width: '100%', height: '100%'}}>
                  {savedPlaylists.map(s => {
                    return (                      
                      <div className='playlist-link' onClick={() => handleLoadPlaylist(s)}>{s.Title}</div>
                    )
                  })}
                  </div>
                </RenderIf>
                <RenderIf condition={isLoading}>
                  <div style={{height: 500}}>Loading playlists...</div>
                </RenderIf>
              </Flex>
          </Tab>
          <Tab label={sharedPlaylistTabLabel} selected={tab == 1} onTabSelect={() => setTab(1)}>
              <Flex hide={tab !== 1} className='container scollable-control'>                
                <RenderIf condition={!isLoading}>
                  <div style={{width: '100%', height: '100%'}}>
                  {sharedPlaylists.map(s => {
                      return (
                        <div className='playlist-link' onClick={() => handleLoadPlaylist(s)}>{s.Title}</div>
                      )
                    })}
                    </div>
                </RenderIf>
                <RenderIf condition={isLoading}>
                <div style={{height: 500}}>Loading playlists...</div>
                </RenderIf>
              </Flex>
          </Tab>
      </Tabs>

    </div>
  )
}

export default PlaylistDropDown