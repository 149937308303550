import { getCookie } from './utility'

const translations = {
  "start your music search here": {
      "en": "Start your music search here",
      "it": "Scrivi una o più keywords per iniziare la tua ricerca",
      "es": "Escribe una o más palabras clave para iniciar tu búsqueda"
  },
  "contact us": {
      "en": "Contact us",
      "it": "Menù Audiofficina/TheFirm",
      "es": "Contáctanos"
  },
  "quick tip videos": {
      "en": "Quick Tip Videos",
      "it": "Menù Audiofficina/TheFirm",
      "es": "Vídeos de consejos rápidos"
  },
  "logout": {
      "en": "Logout",
      "it": "Logout",
      "es": "Logout"
  },
  "reset": {
      "en": "Reset",
      "it": "Reset",
      "es": "Reiniciar"
  },
  "basic filter": {
      "en": "Basic Filter",
      "it": "Ricerca Semplice",
      "es": "Búsqueda básica"
  },
  "advanced filter": {
      "en": "Advanced Filter",
      "it": "Ricerca Avanzata  ",
      "es": "Búsqueda avanzada"
  },
  "genre": {
      "en": "Genre",
      "it": "Genere",
      "es": "Género"
  },
  "genres": {
    "en": "Genres",
    "it": "Generi",
    "es": "Géneros"
  },
  "mood": {
      "en": "Mood",
      "it": "Umore",
      "es": "Estado de ánimo"
  },
  "moods": {
    "en": "Moods",
    "it": "Stati d'animo",
    "es": "Estados de ánimo"
  },  
  "tempo": {
      "en": "Tempo",
      "it": "Tempo",
      "es": "Tempo"
  },
  "energy": {
      "en": "Energy",
      "it": "Energia",
      "es": "Energía"
  },
  "scene": {
      "en": "Scene/Curated Playlist",
      "it": "Scene",
      "es": "Escenas"
  },
  "scenes": {
    "en": "Scenes/Curated Playlists",
    "it": "Scene",
    "es": "Escenas"
  },  
  "scenes_short": {
    "en": "Scenes",
    "it": "Scene",
    "es": "Escenas"
  },
  "looks like": {
    "en": "Scene/Curated Playlist",
    "it": "Scene",
    "es": "Escenas"
  },
  "looks likes": {
    "en": "Scenes/Curated Playlists",
    "it": "Scene",
    "es": "Escenas"
  },
  "sounds_like": {
    "en": "Inspired by",
    "it": "Ispirato a",
    "es": "Inspirado por"
  },  
  "sounds like": {
      "en": "Inspired by",
      "it": "Ispirato a",
      "es": "Inspirado por"
  },
  "sounds likes": {
    "en": "Inspired by",
    "it": "Ispirato a",
    "es": "Inspirado por"
  },
  "ad timing": {
      "en": "Ad Timing",
      "it": "ADV & Spot",
      "es": "Anuncios"
  },
  "duration": {
      "en": "Duration",
      "it": "Durata",
      "es": "Duración"
  },
  "duration range": {
    "en": "Duration Range",
    "it": "Range di durata",
    "es": "Rango de duración"
  },
  "search": {
      "en": "Search",
      "it": "Cerca",
      "es": "Búsqueda"
  },
  "active playlist": {
      "en": "Active Playlist",
      "it": "Playlist attiva",
      "es": "Playlist activa"
  },
  "saved playlists": {
      "en": "Saved Playlists",
      "it": "Playlist Salvate",
      "es": "Playlists guardadas"
  },
  "your playlists": {
    "en": "Your Playlists",
    "it": "Le tue playlist",
    "es": "Tus listas de reproducción"
  },
  "empty playlist": {
      "en": "Empty Playlist",
      "it": "Nessuna Playlist",
      "es": "Ninguna playlist"
  },
  "playlist options": {
      "en": "Playlist options",
      "it": "Opzioni della playlist",
      "es": "Opciones de playlist"
  },
  "new playlist": {
      "en": "New Playlist",
      "it": "Nuova Playlist",
      "es": "Nueva playlist"
  },
  "open saved playlist": {
      "en": "Open Saved Playlist",
      "it": "Apri  Playlist Salvate",
      "es": "Abrir playlist guardada"
  },
  "import playlist xml": {
      "en": "Import Playlist XML",
      "it": "Importa Playlist",
      "es": "Importar playlist"
  },
  "export playlist xml": {
      "en": "Export Playlist XML",
      "it": "Esporta Playlist",
      "es": "Exportar playlist"
  },
  "rename": {
      "en": "Rename",
      "it": "Rinomina",
      "es": "Renombrar"
  },
  "merge": {
      "en": "Merge",
      "it": "Unisci",
      "es": "Fusionar"
  },
  "duplicate": {
      "en": "Duplicate",
      "it": "Duplica",
      "es": "Duplicar"
  },
  "delete playlist": {
      "en": "Delete",
      "it": "Cancella Playlist",
      "es": "Borrar playlist"
  },
  "export as avid ale": {
      "en": "Export as Avid ALE",
      "it": "Esporta come Avid ALE",
      "es": "Exportar como Avid ALE"
  },
  "export as csv": {
      "en": "Export as CSV",
      "it": "Esporta come CSV",
      "es": "Exportar como CSV"
  },
  "track hider": {
      "en": "Track Hider",
      "it": "Nascondi Tracce",
      "es": "Ocultar tracks"
  },
  "multiple playlists": {
      "en": "Multiple Playlists",
      "it": "Playlist Multiple",
      "es": "Playlist múltiple"
  },
  "share playlist": {
      "en": "Share Playlist",
      "it": "Condividi Playlist",
      "es": "Compartir playlist"
  },
  "download all tracks": {
      "en": "Download All Tracks",
      "it": "Scarica tutte le tracce",
      "es": "Descargar todas las tracks"
  },
  "playlist view": {
      "en": "Playlist view",
      "it": "Visualizza Playlist",
      "es": "Ver playlist"
  },
  "delete": {
      "en": "Delete",
      "it": "Cancella",
      "es": "Borrar"
  },
  "back to search view": {
      "en": "Back to search view",
      "it": "Torna alla Ricerca",
      "es": "Volver a la búsqueda"
  },
  "tracks": {
      "en": "Tracks",
      "it": "Tracce",
      "es": "Tracks"
  },
  "follow us": {
      "en": "Follow us",
      "it": "Footer Audiofficina/TheFirm",
      "es": "Síguenos"
  },
  "privacy statement": {
      "en": "Privacy Statement",
      "it": "Footer Audiofficina/TheFirm",
      "es": "Política de privacidad"
  },
  "cue sheet maker": {
      "en": "Cue Sheet Maker",
      "it": "Cue Sheet Maker",
      "es": "Cue Sheet Maker"
  },
  "similar genres": {
      "en": "Similar Genres",
      "it": "Generi Simili",
      "es": "Géneros similares"
  },
  "audition history": {
      "en": "Audition History",
      "it": "Cronologia",
      "es": "Historial"
  },
  "add all to playlist": {
      "en": "Add all to Playlist",
      "it": "Aggiungi tutto alla playlist",
      "es": "Añadir todo a la playlist"
  },
  "clear audition history": {
      "en": "Clear audition history",
      "it": "Cancella Cronologia",
      "es": "Borrar historial"
  },
  "sort by": {
      "en": "Sort by",
      "it": "Ordina per",
      "es": "Ordenar por"
  },
  "relevant": {
      "en": "Relevant",
      "it": "Rilevanza",
      "es": "Relevancia"
  },
  "newest": {
      "en": "Newest",
      "it": "Novità",
      "es": "Novedad"
  },
  "random": {
      "en": "Random",
      "it": "Casuale",
      "es": "Al azar"
  },
  "alts": {
      "en": "Alts",
      "it": "Alt",
      "es": "Alt"
  },
  "edits": {
      "en": "Edits",
      "it": "Mix",
      "es": "Mezclas"
  },
  "description": {
      "en": "Description",
      "it": "Descrizione",
      "es": "Descripción"
  },
  "writer/publishers": {
      "en": "Writer/Publishers",
      "it": "Autori/Editori",
      "es": "Compositores/Editores"
  },
  "import edl": {
      "en": "Import Edl",
      "it": "Importa EDL",
      "es": "Importar EDL"
  },
  "insert row": {
      "en": "Insert Row",
      "it": "Inserisci Riga",
      "es": "Insertar fila"
  },
  "delete row": {
      "en": "Delete Row",
      "it": "Cancella Riga",
      "es": "Eliminar fila"
  },
  "cue": {
      "en": "Cue",
      "it": "N.",
      "es": "Cue"
  },
  "track title": {
      "en": "Track Title ",
      "it": "Titolo Traccia",
      "es": "Título Track"
  },
  "time code in/out": {
      "en": "Time Code In/Out",
      "it": "Time Code In/Out",
      "es": "Time Code In/Out"
  },
  "length": {
      "en": "Length",
      "it": "Durata",
      "es": "Duración"
  },
  "use": {
      "en": "Use",
      "it": "Utilizzo",
      "es": "Uso"
  },
  "composers": {
      "en": "Composers",
      "it": "Autori",
      "es": "Compositores"
  },
  "publishers": {
      "en": "Publishers",
      "it": "Editori",
      "es": "Editores"
  },
  "society": {
      "en": "Society",
      "it": "Società",
      "es": "Sociedad"
  },
  "music source": {
      "en": "Music Source",
      "it": "Tipo di musica",
      "es": "Tipo de música"
  },
  "track(s)": {
      "en": "Track(s)",
      "it": "Tracce",
      "es": "Tracks"
  },
  "forgot my password": {
      "en": "Forgot My Password",
      "it": "Password Dimeticata",
      "es": "Olvidé mi contraseña"
  },
  "remember me ": {
      "en": "Remember Me ",
      "it": "Ricordami",
      "es": "Recuérdame "
  },
  "yes": {
      "en": "Yes",
      "it": "Sì",
      "es": "Sí"
  },
  "no": {
      "en": "No",
      "it": "No",
      "es": "No"
  },
  "back-undo": {
      "en": "Back-Undo",
      "it": "Indietro-Annulla",
      "es": "Atrás-Deshacer"
  },
  "refine results": {
      "en": "Refine Results",
      "it": "Affina la ricerca",
      "es": "Afinar resultados"
  },
  "name": {
      "en": "Name",
      "it": "Nome",
      "es": "Nombre"
  },
  "file ": {
      "en": "File ",
      "it": "File",
      "es": "Archivo"
  },
  "current name": {
      "en": "Current Name",
      "it": "Nome Attuale",
      "es": "Nombre actual"
  },
  "video format": {
      "en": "Video Format",
      "it": "Formato Video",
      "es": "Formato vídeo"
  },
  "new name": {
      "en": "New Name",
      "it": "Nuovo Nome",
      "es": "Nuevo nombre"
  },
  "with": {
      "en": "With",
      "it": "Con",
      "es": "Con"
  },
  "frame rate": {
      "en": "Frame Rate",
      "it": "Frame Rate",
      "es": "Frame Rate"
  },
  "instruments": {
      "en": "Instruments",
      "it": "Strumenti",
      "es": "Instrumentos"
  },
  "instrumentation": {
    "en": "Instrumentation",
    "it": "Strumenti",
    "es": "Instrumentos"  
  },
  "keywords": {
      "en": "Keywords",
      "it": "Keywords",
      "es": "Palabras clave"
  },
  "message": {
      "en": "Message",
      "it": "Messaggio",
      "es": "Mensaje"
  },
  "composer": {
      "en": "Composer",
      "it": "Compositore",
      "es": "Compositor"
  },
  "publisher": {
      "en": "Publisher",
      "it": "Editore",
      "es": "Editor"
  },
  "enter track prefix": {
      "en": "Enter Track Prefix",
      "it": "Prefisso traccia",
      "es": "Prefijo de pista"
  },
  "new as of": {
    "en": "New as of",
    "it": "Nuove uscite da",
    "es": "Nuevo a partir de"
  },
  "clear": {
    "en": "Clear",
    "it": "Cancella",
    "es": "Clear"
  }, 
  "month": {
    "en": "Month",
    "it": "Mese",
    "es": "Mes"
  },
  "day": {
    "en": "Day",
    "it": "Giorno",
    "es": "Día"
  },
  "year": {
    "en": "Year",
    "it": "Anno",
    "es": "Año"
  },
  "exclude": {
    "en": "Exclude",
    "it": "Escludere",
    "es": "Excluir"
  },
  "collection": {
    "en": "Collection",
    "it": "Collection",
    "es": "Colección"
  },
  "collections": {
    "en": "Collections",
    "it": "Collections",
    "es": "Colección"
  }  
}

const getTranslation = (text, lang = "en", uppercase = false) => {
  const countryCode = getCookie("countryCode") || 'US';
  const translation = translations[text.toLowerCase()];

  if (translation) {
    let tempText;

    // // --- temporary block --- Temporary until we can fix the search issue for non-english 
    // return uppercase ? translation["en"].toUpperCase() : translation["en"];
    // // --- end temporary block ---

    switch (countryCode.toLowerCase()) {
      case "it":
        tempText = translation["it"]; // Italian
        break;
      case "es":
        tempText = translation["es"]; // Spanish
        break;
      default: 
        tempText = translation["en"];
        break;
    }

    tempText = tempText ? tempText : text;

    return uppercase ? tempText.toUpperCase() : tempText;
  }

  return text;
}

module.exports = {
  getTranslation
}