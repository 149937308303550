import React from 'react';
import classNames from 'classnames';
import style from './howto.scss';

import { setUserDetails } from 'store/userInterface/actions';
import { Button, Checkbox, Dialog, Flex, TextField } from 'components/ui';

class HowToDialog extends React.Component {
    constructor() {
        super(...arguments);
    }

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog('HowToDialog', false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog('HowToDialog', false);
        this.onClose()        
    }

    handleOK() {
        Common.UserInterface.ShowDialog('HowToDialog', false);
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;
        
        return (
            <Dialog title='How To' name='HowToDialog' width={600} height={300} modal={true} onClose={onClose.bind(this)} onEscape={this.handleEscape.bind(this)}>
                <Flex className='how-to-dialog'>
                    <Flex position='center'>
                        How To - Not Implemented Yet
                    </Flex>                
                </Flex>
            </Dialog>
        )
    }
}

export default HowToDialog;