import { List, Map, fromJS } from 'immutable'

import * as Actions from './actions'

/**
 * Initial reducer state
 */
export const initialPreviousPlaylistTrackState = List();


/**
 * Home view reducer
 */
export const previousPlaylistTrackReducer = {
  
  [Actions.UPDATE_PREVIOUS_PLAYLIST_TRACK]: (state, action) => {
    return List(action.tracks);
  }
}