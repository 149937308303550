import {getTranslation} from './translations'
import jwtDecode from 'jwt-decode'
var _ = require('lodash');

const basicFieldSorter = (fieldName) => {
  return (A, B) => {
    if (A[fieldName] < B[fieldName]) return -1;
    if (A[fieldName] > B[fieldName]) return 1;
    return 0;
  }
}

const difference = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

const objectSort = () => {
  var args = arguments,
    array = args[0],
    case_sensitive, keys_length, key, desc, a, b, i;

  if (typeof arguments[arguments.length - 1] === 'boolean') {
    case_sensitive = arguments[arguments.length - 1];
    keys_length = arguments.length - 1;
  } else {
    case_sensitive = false;
    keys_length = arguments.length;
  }

  return array.sort(function (obj1, obj2) {
    for (i = 1; i < keys_length; i++) {
      key = args[i];
      if (typeof key !== 'string') {
        desc = key[1];
        key = key[0];
        a = obj1[args[i][0]];
        b = obj2[args[i][0]];
      } else {
        desc = false;
        a = obj1[args[i]];
        b = obj2[args[i]];
      }

      if (case_sensitive === false && typeof a === 'string') {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (!desc) {
        if (a < b) return -1;
        if (a > b) return 1;
      } else {
        if (a > b) return -1;
        if (a < b) return 1;
      }
    }
    return 0;
  });
}

const fieldSorter = (fields) => {
  return function (a, b) {
    return fields
      .map(function (o) {
        var dir = 1;
        if (o[0] === '-') {
          dir = -1;
          o = o.substring(1);
        }
        if (a[o] > b[o]) return dir;
        if (a[o] < b[o]) return -(dir);
        return 0;
      })
      .reduce(function firstNonZeroValue(p, n) {
        return p ? p : n;
      }, 0);
  };
}

const sortPredicate = () => {
  var fields = [],
    n_fields = arguments.length,
    field, name, reverse, cmp;

  var default_cmp = function (a, b) {
    if (a === b) return 0;
    return a < b ? -1 : 1;
  },
    getCmpFunc = function (primer, reverse) {
      var dfc = default_cmp,
        // closer in scope
        cmp = default_cmp;
      if (primer) {
        cmp = function (a, b) {
          return dfc(primer(a), primer(b));
        };
      }
      if (reverse) {
        return function (a, b) {
          return -1 * cmp(a, b);
        };
      }
      return cmp;
    };

  // preprocess sorting options
  for (var i = 0; i < n_fields; i++) {
    field = arguments[i];
    if (typeof field === 'string') {
      name = field;
      cmp = default_cmp;
    } else {
      name = field.name;
      cmp = getCmpFunc(field.primer, field.reverse);
    }
    fields.push({
      name: name,
      cmp: cmp
    });
  }

  // final comparison function
  return function (A, B) {
    var a, b, name, result;
    for (var i = 0; i < n_fields; i++) {
      result = 0;
      field = fields[i];
      name = field.name;

      result = field.cmp(A[name], B[name]);
      if (result !== 0) break;
    }
    return result;
  };
}

const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const downloadLink = (url) => {
  const link = document.createElement('a');
  link.download = true;
  link.href = url;

  document.body.appendChild(link);
  link.click();

  setTimeout(() => { document.removeChild(link); });
}

const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed)

  return result
}

const toolTipsNotes = (label) => {
  switch (label) {
    case "GENRE(S)":
    case "MOOD(S)":
    case "TEMPO(S)":
    case "ENERGY(S)":
    case "SCENES":
    case "SOUND LIKES":
    case "COLLECTION(S)":
    case "COMPOSER(S)":
    case "AD TIMING":
      return `EXCLUDE SELECTED ${label} FROM YOUR SEARCH`;

    case "SEARCH-FILTER-BUTTON":
      return "SEARCH BASED ON SELECTIONS MADE IN FILTERS (OR HIT ENTER KEY)";
    case "RESET-FILTER-BUTTON":
      return "RESETS ALL FILTERS AND CLEARS SEARCH RESULTS (KEYBOARD SHORTCUT: R)";

    case "ADD-ALL-PLAYLIST":
      return "ADD ALL SEARCH RESULTS TO ACTIVE PLAYLIST";

    case "CLEAR-AUDITION-HISTORY":
      return "CLEARS THE HEADPHONE ICON THAT INDICATES YOU'VE PLAYED A GIVEN TRACK (AUDITION HISTORY)";

    case "RESET-SEARCH-BUTTON":
      return "RESETS SEARCH PARAMETERS (KEYBOARD SHORTCUT: R)";

    case "PLAYLIST-VIEW":
      return "VIEW TRACKS IN YOUR ACTIVE PLAYLIST IN THE MAIN WINDOW";
    case "SEARCH-TEXT-FIELD":
      return "TYPE TO SEARCH CLICK ON SUGGESTIONS (OR HIT ENTER FOR KEYWORD SEARCH)";
      case "TRACK-TOGGLE":
      return "SEE OTHER VERSIONS OF THIS TRACK(ALTS, EDITS) ";
    default:
      return;
  }

}

const isDiscoveryUser = () => {
  try {
    var isDiscoveryUser = Common.GetState().UserInterface.toJS().UserDetails.IsDiscoveryUser;
    return isDiscoveryUser;
  } catch (ex) {}

  return false;
}

const getTrackPublisherSplit = (track) => {
  const publisherRegex = /^(.+)(, )(.+)( )(.+)%$/

  const publishers = track.IsPublicDomain ? "Publisher Unknown"
    : track.PublicDomainArrangement ? track.Publisher.Name
        : track.Publisher.Name

  if (!isDiscoveryUser()) {
    return publishers
  }
  
  const mainPublishers = []
  const publisherList = publishers.split('/')
  
  publisherList.forEach(publisher => {
    const p = publisherRegex.exec(publisher)
    if (p) {
      const name = p[1]
      const pro = p[3]
      const split = p[5]
      const pp = mainPublishers.find(p => p.name === name && p.pro === pro)
      if (pp) {
        pp.split += parseFloat(split) / 2
      } else {
        mainPublishers.push({name, pro, split: parseFloat(split) / 2})
      }
    }
  })

  const discPublishers = mainPublishers.map(publisher => {    
    switch (publisher.pro.toUpperCase()) {
      case "ASCAP":
        return {name: 'Discovery Trademark Holding Company Inc', pro: publisher.pro, split: publisher.split}
      case "BMI":
        return {name: 'Discovery Networks Music Publishing', pro: publisher.pro, split: publisher.split}
      default:
        return {name: 'Discovery World Television', pro: 'SESAC', split: publisher.split}
    }    
  })

  return mainPublishers.map(p => `${p.name} (${p.pro}) ${p.split}%`).join('/') + '/' + discPublishers.map(p => `${p.name} (${p.pro}) ${p.split}%`).join('/')
}

const getTrackTitle = (track, discovery = false) => {
  return isDiscoveryUser() || discovery ? "DLSSK_" + track.Title : track.Title;
}

const getTrackComposerName = (track) => {
  const composerRegex = /^(.+)(, )(.+)( )(.+)%$/

  const composers = track.IsPublicDomain ? track.PublicDomainComposer
    : track.PublicDomainArrangement ? `PD as arr by ${track.Composer.Name}`
        : track.Composer.Name

  if (!isDiscoveryUser()) {
    return composers;
  }

  const mainComposers = []
  const composerList = composers.split('/')
  
  composerList.forEach(composer => {
    const p = composerRegex.exec(composer)
    if (p) {
      const name = p[1]
      const pro = p[3]
      const split = p[5]
      const pp = mainComposers.find(p => p.name === name && p.pro === pro)
      if (pp) {
        pp.split += parseFloat(split)
      } else {
        mainComposers.push({name, pro, split: parseFloat(split)})
      }
    }
  })
  
  return mainComposers.map(p => `${p.name} (${p.pro}) ${p.split}%`).join('/')
} 

const getTrackPublisherName = (track) => {
  // TODO: For Discovery, split with Scorekeepers
  return track.IsPublicDomain ? "Publisher Unknown"
    : track.PublicDomainArrangement ? track.Publisher.Name
        : track.Publisher.Name
}

module.exports = {
  basicFieldSorter,
  debounce,
  difference,
  fieldSorter,
  getTrackComposerName,
  getTrackPublisherName,
  getTrackPublisherSplit,
  getTrackTitle,
  getTranslation,  
  isDiscoveryUser,
  objectSort,
  sortPredicate,
  reorderList,
  toolTipsNotes
}
