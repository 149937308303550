import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import { initialCartState, cartReducer } from './cart/reducer';
import { initialCueSheetState, cueSheetReducer } from './cueSheet/reducer';
import { initialDialogsState, dialogsReducer } from './dialogs/reducer';
import { initialPlaylistState, playlistReducer } from './playlist/reducer';
import { initialPreviousSearchesState, previousSearchesReducer } from './previousSearches/reducer';
import { initialSearchState, searchReducer } from './search/reducer';
import { initialUserInterfaceState, userInterfaceReducer } from './userInterface/reducer';
import { initialPreviousPlaylistTrackState, previousPlaylistTrackReducer } from './previousPlaylistTrack/reducer';
import { initialSharePlaylistState, sharePlaylistReducer } from "./sharePlaylist/reducer";


const NOOP_ACTION = 'NOOP'

const noOp = {
  type: NOOP_ACTION
}


/**
 * Common reducer mapping
 */
const mapReducers = (state, action, map) =>
  map[action.type] ? map[action.type](state, action) : state


/**
 * Mapped view state and reducers
 */
// View Reducers
const Cart = (
  state = initialCartState,
  action = noOp
) => mapReducers(state, action, cartReducer);

const CueSheet = (
  state = initialCueSheetState,
  action = noOp
) => mapReducers(state, action, cueSheetReducer);

const Dialogs = (
  state = initialDialogsState,
  action = noOp
) => mapReducers(state, action, dialogsReducer);

const Playlist = (
  state = initialPlaylistState,
  action = noOp
) => mapReducers(state, action, playlistReducer);

const PreviousSearches = (
  state = initialPreviousSearchesState,
  action = noOp
) => mapReducers(state, action, previousSearchesReducer);

const Search = (
  state = initialSearchState,
  action = noOp
) => mapReducers(state, action, searchReducer);

const UserInterface = (
  state = initialUserInterfaceState,
  action = noOp
) => mapReducers(state, action, userInterfaceReducer);

const PreviousPlaylistTracks = (
  state = initialPreviousPlaylistTrackState,
  action = noOp
) => mapReducers(state, action, previousPlaylistTrackReducer);

const SharePlaylist = (
  state = initialSharePlaylistState,
  action = noOp
) => mapReducers(state, action, sharePlaylistReducer);

export default combineReducers({
  routing: routerReducer,
  Cart: Cart,
  CueSheet: CueSheet,
  Dialogs: Dialogs,
  Playlist: Playlist,
  SharePlaylist: SharePlaylist,
  PreviousSearches: PreviousSearches,
  Search: Search,
  UserInterface: UserInterface,
  PreviousPlaylistTracks: PreviousPlaylistTracks
})
