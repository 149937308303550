import React from 'react';
import classNames from 'classnames';
import style from './export-cue-sheet.scss';

import { Button, Dialog, Flex, TextField } from 'components/ui';

const dialogName = 'ExportCueSheet';

export default class ExportCueSheetDialog extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
            filename: 'Untitled.csv'
        }
    }

    componentDidMount() {
        const title = Common.CueSheet.GetCueSheet().Title;
        this.setState({ filename: `${title || 'Untitled'}.csv`})

        setTimeout(() => {
            this.textField.select();
        }, 10);
    }

    get onClose() {
        const {
            onClose = () => {}
        } = this.props;

        return onClose;
    }

    handleCancel() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose()
    }

    handleOK() {
        const cueSheet = Common.GetState().CueSheet.toJS();

        if (cueSheet !== null && this.state.filename.trim() !== '') {
            Common.CueSheet.GenerateCueSheetCSV(cueSheet, this.state.filename);
            Common.Dialogs.ShowDialog(dialogName, false);
            this.onClose();
        }
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        return (
            <Dialog title='Export Cue Sheet' name={dialogName} width={500} height={200} modal={true} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleEscape.bind(this)}>
                <Flex className='export-cue-sheet-dialog'>
                    <Flex column position='center'>
                        <Flex row>
                            <Flex className={'export-cue-sheet-dialog--filename-label'} width={60} position='right'>Filename: </Flex>
                            <Flex position='left'>
                                <TextField ref={(i) => this.textField = i} onChange={(value) => this.setState({filename: value})} value={this.state.filename} />
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex></Flex>
                    <Flex row height='30px' position='right'>
                        <Flex row width='200px'>
                            <Button type='default' size='md' width='100px' disabled={this.state.filename.trim() === ''} onClick={this.handleOK.bind(this)}>OK</Button>
                            <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}