import React from 'react';
import './preferences.scss';

import { Button, Dialog, DropDown, Flex, FontIconButton, TextField } from 'components/ui';

const dialogName = 'Preferences';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

export default class Preferences extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            Source: 'Web',
            Location: '',
            Format: 'MP3',
            FileFormat: 'WAV'
        }
    }

    componentDidMount() {        
        this.setState(Common.UserInterface.GetPreferences());

        document.addEventListener('keyup', onKeyUp);
    }    

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    }

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose()        
    }

    handleOK() {
        const folder = (this.state.Location || '').trim();

        if (this.state.Source == 'Local') {
            if (Common.UserInterface.IsValidFolder(folder)) {
                Common.UserInterface.SetPreferences(this.state);
                Common.Dialogs.ShowDialog(dialogName, false);
                this.onClose();  
            } else {
                alert('Please specify a valid track folder');
            }
        } else {
            Common.UserInterface.SetPreferences(this.state);
            Common.Dialogs.ShowDialog(dialogName, false);
            this.onClose();  
        }
    }

    handleDropDownFormatSelection(value) {
        this.setState({ Format: value.id });
    }

    handleDropDownSourceSelection(value) {
        this.setState({ Source: value.id });
    }

    handleDropDownFileFormatSelection(value) {
        this.setState({ FileFormat: value.id });
    }    

    handleFilePathChanged(newVal, oldVal) {
        this.setState({ Location: newVal + '/' });
    }

    handleSelectFolder() {
        Common.UserInterface.ShowOpenFolder()
            .then(filepath => {
                if (filepath.length == 0) return;
                this.setState({ Location: filepath[0] || '' })
            });
    }

    handleUploadLogs = () => {
        try {
            Common.Helpers.UploadLogs();
        } catch (err) {
            Common.Dialogs.ShowAlertMessage({ title: 'Upload Logs', message: 'An error occurred while uploading logs to Scorekeepers. Please verify you are online.' });
        }
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        const sourceOptions = [
            { id: 'Local', text: 'Local Drive/Server' },
            { id: 'Web', text: 'Web' }
        ]; 

        const formatOptions = [
            { id: 'AIF', text: 'AIF' },
            { id: 'WAV', text: 'WAV' },
            { id: 'MP3', text: 'MP3' }
        ]; 

        const fileFormatOptions = [
            { id: 'AIF', text: 'AIF' },
            { id: 'WAV', text: 'WAV' },
            { id: 'MP3', text: 'MP3' }
        ];                  

        const locationStyle = this.state.Source == 'Web' ? { display: 'none' } : {};  
        const formatStyle = this.state.Source == 'Local' ? { display: 'none' } : {};  

        const selectedSourceOption = sourceOptions.find(v => v.id.toLowerCase() == this.state.Source.toLowerCase()) || { id: 'Web', text: 'Web' };
        const selectedFormatOption = formatOptions.find(v => v.id.toLowerCase() == this.state.Format.toLowerCase()) || { id: 'MP3', text: 'MP3' };
        const selectedFileFormatOption = formatOptions.find(v => v.id.toLowerCase() == this.state.FileFormat.toLowerCase()) || { id: 'WAV', text: 'WAV' };

        return (
            <Dialog title='Preferences' name={dialogName} width={600} height={310} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)}>
                <Flex className='preferences-dialog'>
                    <Flex>
                        <Flex>
                            <Flex row height={40}>
                                <Flex position='left' width={210}>Location to audition tracks from: </Flex>
                                <Flex position='left'><DropDown width={140} items={sourceOptions} value={selectedSourceOption} onSelect={this.handleDropDownSourceSelection.bind(this)} /></Flex>
                            </Flex>
                        </Flex>
                        <Flex style={locationStyle}>
                            <Flex row height={30}>
                                <Flex width='95%'>
                                    <TextField ref={(i) => { this.FolderPath = i }} value={this.state.Location} onTextChanged={this.handleFilePathChanged.bind(this)} />
                                </Flex>
                                <Flex position='right'>
                                    <FontIconButton name='ellipsis' size={20} onClick={this.handleSelectFolder.bind(this)} />
                                </Flex>
                            </Flex>                    
                        </Flex>
                        <Flex style={locationStyle}>
                            <Flex row height={40}>
                                <Flex position='left' width={210}>Local File Format: </Flex>
                                <Flex position='left'><DropDown width={80} items={fileFormatOptions} value={selectedFileFormatOption} onSelect={this.handleDropDownFileFormatSelection.bind(this)} /></Flex>
                            </Flex>                    
                        </Flex>                        
                        <Flex style={formatStyle}>
                            <Flex row height={40}>
                                <Flex position='left' width={210}>Download File Format: </Flex>
                                <Flex position='left'><DropDown width={80} items={formatOptions} value={selectedFormatOption} onSelect={this.handleDropDownFormatSelection.bind(this)} /></Flex>
                            </Flex>                    
                        </Flex>
                    </Flex>
                    <Flex row height='70px'>
                        <Flex row position='bottom-right'>
                            <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                            <Button type='default' size='md' width='100px' onClick={onClose.bind(this, 'Preferences')}>Cancel</Button>
                        </Flex>
                    </Flex>                    
                </Flex>
            </Dialog>
        )
    }
}
