import React from 'react';
import classNames from 'classnames';
import style from './account.scss';

import { setUserDetails } from 'store/userInterface/actions';
import { Button, Checkbox, Dialog, Flex, TextArea, TextField } from 'components/ui';

import { fetchGet } from 'utility/fetchHelpers'

class AccountDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            formData: {
                firstName: '',
                middleName: '',
                lastName: '',
                companyName: '',            
                emailAddress: '',         
                username: '',
                password: '',
                confirmPassword: ''
            },
            validation: [{
                username: { required: true, isValid: false },
                firstName: { required: true, isValid: false },
                middleName: { required: false, isValid: false },
                lastName: { required: true, isValid: false },
                companyName: { required: false, isValid: false },            
                emailAddress: { required: true, isValue: false },
                password: { required: false, isValid: false },
                confirmPassword: { required: false, isValid: false }
            }]
        }
    }

    componentDidMount() {
        const _this = this;

        fetchGet(`/api/account/userProfile`)
            .then(json => {
                _this.setState({
                    formData: {
                        username: json.username,
                        firstName: json.firstName,
                        middleName: json.middleName,
                        lastName: json.lastName,
                        companyName: json.companyName,
                        emailAddress: json.emailAddress,
                        password: '',
                        confirmPassword: ''
                    } 
                });
            })
            .catch(err => {
                console.error('ERROR: ', err);
            })
    }

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog('AccountDialog', false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog('AccountDialog', false);
        this.onClose()        
    }

    handleFieldChange(fieldName, value) {
        const state = this.state;
        state.formData[fieldName] = value;
        this.setState(state);
    }

    handleOK() {
        Common.UserInterface.ShowDialog('AccountDialog', false);
    }

    handleChangePassword() {
        Common.Dialogs.ShowDialog('AccountDialog', false);
        Common.Dialogs.ShowDialog('ChangePasswordDialog', true);
    }

    handleSubmitForm() {
        if (this.validateForm()) {
            // alert("Success");
            // Common.UserInterface.SignUp(this.state.formData)
            //     .then((val) => {
            //         if (val.Success) {
            //             Common.Dialogs.ShowDialog('AccountDialog', false);
            //         } else {
            //             if (val.AlertType == 1) this.setState({ invalidUsername: true });
            //         }
            //     })
            //     .catch(err => {});
        } else {
            console.error('Validation Error - need to display error here');
        }
    }

    validateForm() {
        var isValid = true;

        if ((this.state.formData.password.trim() + this.state.formData.confirmPassword.trim()) != '') {
            if (this.state.formData.password  != this.state.formData.confirmPassword) {
                isValid = false;
                alert('Passwords do not match');
                return;
            }
        }

        for (var key in this.state.validation) {
            if (key.required) {
                isValid = isValid && this.state.formData[key].trim() != '';
            }
        }

        return isValid;
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        const usernameNotification = this.state.AlertType == 1 ? 'Invalid Username / Password' : '';

        return (
            <Dialog title='Account Maintenance' name='AccountDialog' width={900} height={420} modal={true} onClose={onClose.bind(this)} onEscape={this.handleEscape.bind(this)}>
                <Flex className='account-dialog' row>
                    <Flex block position='top'>
                        {/* <Flex block fixed className='title'>ACCOUNT MAINTENANCE</Flex>
                        <Flex block fixed wrap className='details'>With a user account you can create and manage playlists, or favorites. Fill out the form below and click the sign-up button to get started.</Flex> */}
                        <Flex className='form' position='top'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='username-field'>Username</td>
                                        <td className='username-value'>
                                            <TextField value={this.state.formData.username} readonly={true} onChange={this.handleFieldChange.bind(this, 'username')} showClearIcon={false} />
                                        </td>
                                        <td colSpan='5' className='username-alert'>{usernameNotification}</td>                                        
                                    </tr>     
                                    <tr>
                                        <td className='first-name-field'>First Name</td>
                                        <td className='first-name-value'><TextField value={this.state.formData.firstName} onChange={this.handleFieldChange.bind(this, 'firstName')} showClearIcon={false} /></td>
                                        <td className='middle-name-field'>MI</td>
                                        <td className='middle-name-value'><TextField value={this.state.formData.middleName} onChange={this.handleFieldChange.bind(this, 'middleName')} showClearIcon={false} /></td>
                                        <td className='last-name-field'>Last Name</td>
                                        <td className='last-name-value'><TextField value={this.state.formData.lastName} onChange={this.handleFieldChange.bind(this, 'lastName')} showClearIcon={false} /></td>
                                    </tr>
                                    <tr>
                                        <td className='company-field'>COMPANY</td>
                                        <td className='company-value' colSpan='5'><TextField value={this.state.formData.companyName} onChange={this.handleFieldChange.bind(this, 'companyName')} showClearIcon={false} /></td>
                                    </tr>                                                            
                                    <tr>
                                        <td className='email-field'>EMAIL ADDRESS</td>
                                        <td className='email-value' colSpan='5'><TextField value={this.state.formData.emailAddress} onChange={this.handleFieldChange.bind(this, 'emailAddress')} showClearIcon={false} /></td>
                                    </tr>    
                                    <tr>
                                        <td colSpan='6'>
                                            <Flex height={190} row>      
                                                <Flex row position='left'>
                                                    <Button onClick={this.handleChangePassword.bind(this)}>Change Password</Button>
                                                </Flex>                                                                                  
                                                <Flex row position='right'>
                                                    <Button onClick={this.handleSubmitForm.bind(this)} type='success'>Update</Button>
                                                    <Button onClick={this.handleCancel.bind(this)}>Cancel</Button>                                        
                                                </Flex>
                                            </Flex>
                                        </td>
                                    </tr>
                                </tbody>                            
                            </table>
                        </Flex>
                    </Flex>                        
                </Flex>
            </Dialog>
        )
    }
}

export default AccountDialog;