import {
    closeAlert, 
    showAlert
} from 'components/ui/alert/alert';

import {
    showDialog
} from 'store/dialogs/actions'

import {
    Button,
    Flex,
    FontIcon,
    Typewriter
} from 'components/ui';

import React from 'react';
import { CommunicationNoSim } from 'material-ui/svg-icons';

let store = null;

class Dialogs {
    constructor(_store) {        
        store = _store;
    }
    
    GetDialogOptions(dialogName) {
        return store.getState().Dialogs.toJS().Options[dialogName];
    }

    GetOpenDialog() {
        var dialogs = store.getState().Dialogs.toJS();

        for (var key in dialogs) {
            if (dialogs[key]  === true) return key;
        }
    
        return null;
    }

    ShowAlertMessage(options) {
        if (options.title === undefined) {
            options.title = 'Alert';
        }

        if (options.content === undefined) {
            options.content = (
                <Flex row height={80} position='left'>
                    <FontIcon name='inspector' />
                    <div className='message'><Typewriter message={options.message} speed={options.speed} /></div>
                </Flex>
            );
        }

        if (options.buttons === undefined) {
            options.buttons = [
                (<Button key={0} width={80} onClick={() => { closeAlert(); }}>OK</Button>)
            ]
        }

        showAlert(options);
    }

    ShowDialog(type, visible, options) {
        store.dispatch(showDialog(type, visible, options));
    }

    ShowSearchFilterWarning = () => {
        const handleProceed = () => {
            closeAlert();
            Common.UserInterface.ClearAdvancedSearch();
            Common.Search.ClearSearchResults();
            Common.UserInterface.SetSelectedSearchTab(0);
        }

        const handleStay = () => {
            closeAlert();
        }

        this.ShowAlertMessage({ 
            title: 'Advanced Filter Options', 
            message: 'You have advanced filters selected that are not in the basic filter set.  Continuing will clear all of your search parameters.',
            buttons: [
                (<Button key={0} width={100} onClick={handleProceed}>Proceed</Button>),
                (<Button key={0} width={240} onClick={handleStay}>Stay on Advanced Filter</Button>)
            ]
        });
    }
}

export default Dialogs;