import React from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { showAlert, closeAlert } from 'components/ui/alert/alert'
import PlaylistResults from "../../../../../components/views/home/playlistExtension/playlistResults/playlistResults"

import { Button, Checkbox, Dialog, DropDown, Flex, FontIcon, RenderIf, TextArea, TextField, Typewriter } from 'components/ui';
import './share-playlist.scss';
import { SharedPlaylistUserSelection } from './share-playlist-user-list';

const playlistTypes = [
    { id: 'User', text: 'Shared' },
    { id: 'Marketing', text: 'Marketing' }
]

const dialogName = 'SharePlaylist';

const onKeyUp = (event) => {
    if (event.which == 27) {
        Common.Dialogs.ShowDialog(dialogName, false);
    }
}

class SharePlaylist extends React.Component {
    constructor() {
        super(...arguments);        

        const load = async () => {
            const userList = await Common.Playlist.GetSharedPlaylistUsers()
            this.state.userList = userList.map(x => ({ id: x.UserId, text: `${x.FirstName} ${x.LastName}`}))
            this.forceUpdate()
        }

        this.state = {
            message: '',
            disableDownloads: false,
            dialogMessage: 'So you want to share a playlist, huh?  Well... just type in a small message and I will take care of the rest.',
            playlistType: playlistTypes[0],
            selectedUsers: [],
            userList: []
        }

        load()
    }

    get isAdmin() {
        return this.props.UserInterface.toJS().UserDetails.IsAdmin;
    }

    get isGroupUser() {
        return this.props.UserInterface.toJS().UserDetails.IsGroupUser;
    }

    get onClose() {
        const {
            onClose = () => { }
        } = this.props;

        return onClose;
    }

    componentWillMount() {
        document.addEventListener('keyup', onKeyUp);

        setTimeout(() => {
            // this.emailAddressField.focus();
        }, 200);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUp);
    }

    handleKeyUp(event) {
        if (event.which == 13) {
            this.handleOK();
        }
    }

    handleCancel() {
        Common.Playlist.SetDefaultSharePlaylist();
        Common.Dialogs.ShowDialog(dialogName, false);
        this.onClose();
    }

    handleOK() {
        const {
            message,
            disableDownloads,
            playlistType,
            selectedUsers
        } = this.state;

        Common.Playlist.SharePlaylist(message, disableDownloads, playlistType.id, selectedUsers.map(su => su.id))
            .then(response => {
                Common.Dialogs.ShowDialog('SharePlaylist', false);

                var textField = null;
                const baseUrl = window.location.protocol + "//" + window.location.host;
                const content = (
                    <Flex row height={100} position='left'>
                        <div className='message'>
                            <div>Playlist shared successfully. The link below will grant access to view the playlist.</div>
                            <TextField ref={e => textField = e} className='shared-playlist-link' readonly={true} showClearIcon={false} value={`${baseUrl}/shared_playlist?id=${response.Payload.SharedPlaylistId}`} />
                        </div>
                    </Flex>
                )

                const handleOK = () => {
                    closeAlert();
                }

                const buttons = [
                    <Button key={0} width={80} onClick={handleOK.bind(this)}>OK</Button>,
                ]

                showAlert({
                    contentClass: 'share-playlist-alert',
                    title: 'SHARED PLAYLIST',
                    width: 675,
                    height: 225,
                    content: content,
                    buttons: buttons
                });

                setTimeout(() => {
                    textField.select();
                }, 40);
            })
            .catch(err => {
                console.error('ERROR: ', err);
            })

        this.onClose();
    }
    
    handleDisableDownloads(value) {        
        this.setState({disableDownloads: value})
    }

    handleSelectPlaylistType(value) {
        this.setState({playlistType: value})
    }   
    
    handleShareWithUsers() {
        let selectedUsers = []

        const content = (
            <Flex row height={100} position='left'>
                <div className='message'>
                    <SharedPlaylistUserSelection userList={this.state.userList} onSelectedUsersChanged={(su) => selectedUsers = su} />
                </div>
            </Flex>
        )

        const handleOK = () => {
            this.setState({...this.state, selectedUsers })
            closeAlert();
        }

        const handleCancel = () => {
            closeAlert();
        }

        const buttons = [
            <Button key={0} width={80} onClick={handleOK.bind(this)}>OK</Button>,
            <Button key={0} width={80} onClick={handleCancel.bind(this)}>Cancel</Button>,
        ]

        showAlert({
            contentClass: 'share-playlist-users',
            title: 'SELECT USERS TO SHARE PLAYLIST WITH',
            width: 700,
            height: 755,
            content: content,
            buttons: buttons
        });
    }

    render() {
        const {
            onClose = () => { }
        } = this.props;
        
        return (
            <Dialog title='Share Playlist' name='SharePlaylist' width={800} height={865} modal={true} hideTitleIcon={true} closeText='' onClose={this.handleCancel.bind(this)} onEscape={this.handleCancel.bind(this)}>
                <Flex block className='share-playlist' position='top'>
                    <Flex row className='header'>
                        <FontIcon name='inspector' />
                        <Flex position='left'><Typewriter message={this.state.dialogMessage} /></Flex>
                    </Flex>
                    <Flex className='content'>
                        <Flex column className='shared-playlist-message'>
                            <div className='field-name' style={{display: 'flex', alignItems: 'center'}}>
                                <span className='message-label'>Message:</span>                                
                                <RenderIf condition={this.isAdmin}>
                                    <span className='playlist-type'>
                                        <Flex row>
                                            <Flex style={{marginRight: 10}} width={100} position='right'>Playlist Type: </Flex>
                                            <Flex width={140}><DropDown placeholder='Playlist Type' items={playlistTypes} value={this.state.playlistType} onSelect={this.handleSelectPlaylistType.bind(this)} /></Flex>
                                        </Flex>
                                    </span>
                                </RenderIf>
                                <RenderIf condition={this.isAdmin || this.isGroupUser}>
                                    <span className='playlist-type'>
                                        <Flex row>
                                            <Button onClick={this.handleShareWithUsers.bind(this)}>Share with Users...</Button>
                                        </Flex>
                                    </span>
                                </RenderIf>
                                <span className='prevent-downloads'>
                                    <Checkbox label={'Disable Downloads'} value={this.state.disableDownloads} onCheck={this.handleDisableDownloads.bind(this)} />
                                </span>
                            </div>
                            <div className='field-value'>
                                <TextArea
                                    height={100}
                                    width={'100%'}
                                    onChange={val => this.setState({ message: val })}
                                    value={this.state.message} />
                            </div>
                        </Flex>
                    </Flex>
                    <Flex row className="playlist-view">
                        <PlaylistResults isVisible={true} isDraggable={true} owner='SharedPlaylist'/>
                    </Flex>
                    <Flex row fixed className='footer' position='right'>                        
                        <Button type='default' size='md' width='100px' onClick={this.handleOK.bind(this)}>OK</Button>
                        <Button type='default' size='md' width='100px' onClick={this.handleCancel.bind(this)}>Cancel</Button>
                    </Flex>
                </Flex>
            </Dialog>
        )
    }
}

export default connect((state) => {
    return {
      UserInterface: state.UserInterface,
    }
  })(withRouter(SharePlaylist));