import fs from "fs"
import bswap from 'bswap';

window.byteSwap = bswap;

class AiffReader {
    constructor(arrayBuffer) {
        this.dataView = new DataView(arrayBuffer);
        this._pos = 0;
        this._byteOffset = 0;
    }

    get byteOffset() {
        return this._byteOffset;
    }

    set byteOffset(offset) {
        this._byteOffset = offset;
    }

    get byteLength() {
        return this.dataView.buffer.byteLength;
    }

    get buffer() {
        return this.dataView.buffer;
    }

    getInt8Array = (length) => {
        const data = new Int8Array(this.dataView.buffer, this._byteOffset, length);
        this._byteOffset += length;
        return data;
    };

    getInt16Array = (length, littleEndian = false) => {
        const data = new Int16Array(this.dataView.buffer, this._byteOffset, length);
        this._byteOffset += (length * 2);

        if (littleEndian) {
            for (let i = 0; i < data.length; i++) {
                data[i] = this.swapInt16(data[i]);
            }
        }

        return data;
    };

    getInt32Array = (length) => {
        const data = new Int32Array(this.dataView.buffer, this._byteOffset, length);
        this._byteOffset += (length * 4);
        return data;
    };

    getChar = () => {
        const data = this.dataView.getUint8(this._byteOffset);
        this._byteOffset += 1;
        return data;
    };

    getFloat32 = () => {
        const data = this.dataView.getFloat32(this._byteOffset);
        this._byteOffset += 4;
        return data;
    };

    getFloat64 = () => {
        const data = this.dataView.getFloat64(this._byteOffset);
        this._byteOffset += 8;
        return data;
    };

    getInt8 = () => {
        if (this._byteOffset + 1 > this.byteLength) throw "Length of bytes retrieved will exceed the length of data available.";
        const data = this.dataView.getInt8(this._byteOffset);
        this._byteOffset++;
        return data;
    };

    getInt16 = () => {
        const data = this.dataView.getInt16(this._byteOffset);
        this._byteOffset += 2;
        return data;
    };

    getInt32 = () => {
        const data = this.dataView.getInt32(this._byteOffset);
        this._byteOffset += 4;
        return data;
    };

    getUint8 = () => {
        const data = this.dataView.getUint8(this._byteOffset);
        this._byteOffset++;
        return data;
    };

    getUint16 = () => {
        const data = this.dataView.getUint16(this._byteOffset);
        this._byteOffset += 2;
        return data;
    };

    getUint32 = () => {
        const data = this.dataView.getUint32(this._byteOffset);
        this._byteOffset += 4;
        return data;
    };

    getUint8Array = (length) => {
        const data = new Uint8Array(this.dataView.buffer, this._byteOffset, length);
        this._byteOffset += length;
        return data;
    };

    getUint16Array = (length) => {
        const data = new Uint16Array(this.dataView.buffer, this._byteOffset, length);
        this._byteOffset += (length * 2);
        return data;
    };

    getUint32Array = (numBytes) => {
        const data = new Uint32Array(this.dataView.buffer, this._byteOffset, length);
        this._byteOffset += (length * 4);
        return data;
    };

    getPascalString = () => {
        let c = "";
        let data = "";

        while ((c = String.fromCharCode(this.getInt8())) != '\0') {
            data += c;
        }

        return data;
    };

    getString = (numBytes) => {
        let data = "";

        for (var i = 0; i < numBytes; i++) {
            data += String.fromCharCode(this.getInt8());
        }

        return data;
    };

    swapInt16 = (data) => {
        return ((data & 0xFF) << 8)
            | ((data >> 8) & 0xFF);
    };

    swapInt32 = (data) => {
        return ((data & 0xFF) << 24)
            | ((data & 0xFF00) << 8)
            | ((data >> 8) & 0xFF00)
            | ((data >> 24) & 0xFF);
    };
}

const convertToArrayBuffer = (buffer) => {
    var ab = new ArrayBuffer(buffer.length);
    var view = new Uint8Array(ab);
    for (var i = 0; i < buffer.length; ++i) {
        view[i] = buffer[i];
    }
    return ab;
};

const getAiffChunk = (aiffByteArray) => {
    let data = [];

    let id = aiffByteArray.getString(4);
    let size = aiffByteArray.getInt32();

    size = size + (size % 2);

    if ((aiffByteArray.byteOffset + size) > aiffByteArray.byteLength)
        size = aiffByteArray.byteLength - aiffByteArray.byteOffset;

    data = aiffByteArray.getUint8Array(size);

    aiffByteArray.byteOffset -= size;

    switch (id) {
        case "COMM":
            var chunk = getCommChunk(aiffByteArray);
            chunk.ID = id;
            chunk.Size = size;
            chunk.Data = data;
            return chunk;
        case "APPL":
            var chunk = getApplChunk(aiffByteArray, size);
            chunk.ID = id;
            chunk.Size = size;
            chunk.Data = data;
            return chunk;
        case "INST":
            var chunk = getInstChunk(aiffByteArray, size);
            chunk.ID = id;
            chunk.Size = size;
            chunk.Data = data;
            return chunk;
        case "MARK":
            var chunk = getMarkChunk(aiffByteArray, size);
            chunk.ID = id;
            chunk.Size = size;
            chunk.Data = data;
            return chunk;
        case "SSND":
            var chunk = getSsndChunk(aiffByteArray, size);
            chunk.ID = id;
            chunk.Size = size;
            chunk.Data = data;
            return chunk;
        default:
            var chunk = {};
            chunk.ID = id;
            chunk.Size = size;
            chunk.Data = data;
            aiffByteArray.byteOffset += size;
            return chunk;
    }
}

const getApplChunk = (aiffByteArray, length) => {
    const chunk = {};

    chunk.osType = aiffByteArray.getString(4);
    chunk.osData = aiffByteArray.getInt8Array(length - 4);

    return chunk;
}

const getCommChunk = (aiffByteArray, length) => {
    const chunk = {};
    chunk.numChannels = aiffByteArray.getInt16();
    chunk.numSampleFrames = aiffByteArray.getUint32();
    chunk.bitDepth = aiffByteArray.getInt16();

    // Determine SampleRate using 80-bit
    var lsb = aiffByteArray.getInt16() - 16398;
    var as1 = aiffByteArray.getUint8();
    var as2 = aiffByteArray.getUint8();
    chunk.sampleRate = ((as1 << 8) << lsb) + as2;

    // Skip the rest of the unused bytes
    aiffByteArray.byteOffset += 6;

    return chunk;
}

const getInstChunk = (aiffByteArray, length) => {
    const chunk = {};

    chunk.baseNote = aiffByteArray.getChar();
    chunk.detune = aiffByteArray.getChar();
    chunk.lowNote = aiffByteArray.getChar();
    chunk.highNote = aiffByteArray.getChar();
    chunk.lowVelocity = aiffByteArray.getChar();
    chunk.highVelocity = aiffByteArray.getChar();
    chunk.gain = aiffByteArray.getInt16();
    chunk.sustainLoop = {
        playMode: aiffByteArray.getInt16(),
        beginLoop: aiffByteArray.getInt16(),
        endLoop: aiffByteArray.getInt16()
    };
    chunk.releaseLoop = {
        playMode: aiffByteArray.getInt16(),
        beginLoop: aiffByteArray.getInt16(),
        endLoop: aiffByteArray.getInt16()
    };

    return chunk;
}

const getMarkChunk = (aiffByteArray, length) => {
    const chunk = {};
    chunk.markers = [];

    chunk.numMarkers = aiffByteArray.getUint16();
    for (var i = 0; i < chunk.numMarkers; i++) {
        chunk.markers.push({
            id: aiffByteArray.getInt16(),
            position: aiffByteArray.getUint32(),
            markerName: aiffByteArray.getPascalString()
        });
    }

    return chunk;
}

const getSsndChunk = (aiffByteArray, length) => {
    var chunk = {};

    chunk.SoundOffset = aiffByteArray.getUint32();
    chunk.BlockSize = aiffByteArray.getUint32();
    chunk.SoundData = aiffByteArray.getUint8Array(length - 8);

    return chunk;
}

class AifAudioParser {
    constructor(input) {
        let arrayBuffer;

        if (typeof input === 'string' || input instanceof String) {
            // TODO: Check to see if the file exists
            // TODO: Convert to asynchronous function
            arrayBuffer = convertToArrayBuffer(fs.readFileSync(input));
        }
        else if (input instanceof Buffer) {
            arrayBuffer = convertToArrayBuffer(input);
        }
        else if (input instanceof ArrayBuffer) {
            arrayBuffer = input;
        }
        else {
            throw new Error("Da")
        }

        // private variables
        var fileReader = new AiffReader(arrayBuffer);

        this.FileId = fileReader.getString(4);
        this.FileLength = fileReader.getInt32();
        this.FileType = fileReader.getString(4);

        this.APPL = [];
        this.UnknownParts = [];

        if (this.FileId != "FORM") throw new Error("Invalid AIFF file format");
        if (this.FileType != "AIFF") throw new Error("Unknown AIFF file id type");

        while (fileReader.byteOffset < fileReader.byteLength) {
            let chunk = getAiffChunk(fileReader);

            switch (chunk.ID) {
                case "COMM":
                    this.COMM = chunk;
                    break;
                // case "APPL":
                //     this.APPL.push(chunk);
                //     break;
                case "SSND":
                    this.SSND = chunk;
                    break;
                // case "INST":
                //     this.Instrument = chunk;
                //     break;
                // case "MARK":
                //     this.Marker = chunk;
                //     break;
                // default:
                //     this.UnknownParts.push(chunk);
                //     break;
            }
        }

        // TODO: Do we need to actually capture the APPL, INST, MARK and UnknownParts anymore?
        // TODO: Need to do something (such as report data) for the UnknownParts
    }

    get wavBuffer() {
        // Convert the sound bytes to 16-bit array and byteswap them for endianess
        var soundData = new Buffer(this.SSND.SoundData.length + 8);
        soundData.write("data", 0);
        soundData.writeInt32LE(this.SSND.SoundData.length, 4);
        var soundBytes = new Uint16Array((new Uint8Array(this.SSND.SoundData)).buffer);
        bswap(soundBytes);
        soundData.set(new Uint8Array(soundBytes.buffer), 8);

        var headerData = new Buffer(28);
        headerData.write("WAVE", 0);
        headerData.write("fmt ", 4);
        headerData.writeUInt32LE(16, 8);
        headerData.writeUInt16LE(1, 12);
        headerData.writeUInt16LE(2, 14);
        headerData.writeUInt32LE(this.COMM.sampleRate, 16);
        headerData.writeUInt32LE(this.COMM.sampleRate * this.COMM.numChannels * this.COMM.bitDepth / 8, 20);
        headerData.writeUInt16LE(this.COMM.numChannels * this.COMM.bitDepth / 8, 24);
        headerData.writeUInt16LE(16, 26);

        var fileData = new Buffer(8 + headerData.length + soundData.length);
        fileData.write("RIFF");
        fileData.writeInt32LE(headerData.length + soundData.length, 4);

        var pos = 8;
        for (var i = 0; i < headerData.length; i++) {
            fileData.writeUInt8(headerData[i], pos++);
        }

        for (var i = 0; i < soundData.length; i++) {
            fileData.writeUInt8(soundData[i], pos++);
        }

        return fileData;
    }

    get audioBuffer() {
        return convertToArrayBuffer(this.wavBuffer);
    }
}

module.exports = AifAudioParser;