import React from 'react';
import classNames from 'classnames';
import style from './change-password.scss';

import { setUserDetails } from 'store/userInterface/actions';
import { Button, Checkbox, Dialog, Flex, TextField } from 'components/ui';

import { fetchGet } from 'utility/fetchHelpers'

class ChangePasswordDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            oldPassword: '',
            password: '',
            confirm: ''
        };
    }

    get onClose() {
        const { 
            onClose = () => {} 
        } = this.props;

        return onClose;
    }

    handleCancel() { 
        Common.Dialogs.ShowDialog('ChangePasswordDialog', false);
        this.onClose();
    }

    handleEscape() {
        Common.Dialogs.ShowDialog('ChangePasswordDialog', false);
        this.onClose()        
    }

    handleFieldChange(fieldName, value) {
        const state = this.state;
        state[fieldName] = value;
        this.setState(state);
    }

    handleOK() {
        Common.Dialogs.ShowDialog('ChangePasswordDialog', false);
    }

    render() {
        const {
            onClose = () => {}
        } = this.props;

        const usernameNotification = this.state.AlertType == 1 ? 'Invalid Username / Password' : '';

        return (
            <Dialog title='Change Password' name='ChangePasswordDialog' zIndex={100} width={700} height={420} modal={true} onClose={onClose.bind(this)} onEscape={this.handleEscape.bind(this)}>
                <Flex className='change-password-dialog' row>
                    <Flex position='top' width='600px'>
                        <Flex block wrap position='top' height={80}>
                            In order to change your password, you will need to provide your current password, as well as your new password and a confirmation of your new password.  You must enter a valid password of at least 7 characters and include at least one number.
                        </Flex>

                        <Flex height={60} position='top'>
                            <Flex height={30}>
                                <TextField placeholder='Old Password' type='password' value={this.state.oldPassword} onChange={this.handleFieldChange.bind(this, 'oldPassword')} showClearIcon={false} />
                            </Flex>
                        </Flex>
                        <Flex height={40}>
                            <Flex height={30}>
                                <TextField placeholder='New Password' type='password' value={this.state.password} onChange={this.handleFieldChange.bind(this, 'password')} showClearIcon={false} />
                            </Flex>
                        </Flex>
                        <Flex height={40}>
                            <Flex height={30}>
                                <TextField placeholder='Confirm Password' type='password' value={this.state.confirm} onChange={this.handleFieldChange.bind(this, 'confirm')} showClearIcon={false} />
                            </Flex>
                        </Flex>

                        <Flex height={150} row>                                        
                            <Flex className='buttons' row position='right'>
                                <Button onClick={this.handleOK.bind(this)} type='success'>Update</Button>
                                <Button onClick={this.handleCancel.bind(this)}>Cancel</Button>                                        
                            </Flex>
                        </Flex>
                    </Flex>                        
                </Flex>
            </Dialog>
        )
    }
}

export default ChangePasswordDialog;