import React from "react";
import { connect } from "react-redux";
import { FontIcon } from "components/ui/font-icon/font-icon";
import { WaveformPlayer } from "components/ui/waveform-player/waveform-player";
import { showContextMenu, closeContextMenu } from "components/ui/context-menu/context-menu";
import classNames from "classnames";
import { updatePreviousSearches } from "store/previousSearches/actions";
import { store } from "store";
import { getTrackTitle } from "utility/common";

const convertToHHMMSS = (duration) => {
  const h = parseInt(duration / 3600);
  const m = parseInt(duration / 60);
  const s = parseInt(duration % 60);

  return (h > 0 ? h.padLeft(2) + ":" : "") + (m > 0 ? m.padLeft(1) + ":" : "0:") + s.padLeft(2);
};

class InnerTrackPlayer extends React.Component {
  constructor() {
    super(...arguments);

    this.handleEventCallback = (event) => {
      if (this.state) {
        switch (event.type) {
          case "TIME_UPDATE": {
            break;
          }
          case "PLAY": {
            this.setState({ isPlaying: true });
            break;
          }
          case "PAUSED":
          case "STOPPED": {
            this.setState({ isPlaying: false });
            break;
          }
        }
      }
    };

    this.state = {
      isPlaying: false,
      trackDuration: 0,
    };
  }

  componentDidMount() {
    this.showTrackDuration(this.props.track.Id);
    Common.AudioPlayer.registerForEvents(this.props.track.Id, this);
  }

  componentWillUnmount() {
    Common.AudioPlayer.unregisterForEvents(this.props.track.Id, this);
  }

  componentWillReceiveProps(nextProps) {
    this.showTrackDuration(nextProps.track.Id);
  }
  handleAddTrack() {
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === "home") {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    const { track } = this.props;
    Common.Playlist.AddTrack(track);
    //for alt tab playlist indicator
    this.props.state.source == "drawer" ? this.props.onTrackAdded(true) : null;
    // Common.Playlist.LoadPlaylist(Common.Playlist.GetPlaylistId());
  }

  handlePauseTrack(e) {
    Common.AudioPlayer.pause();
  }

  handlePlayTrack(e) {
    const { track } = this.props;

    try {
      Common.UserInterface.SetAuditionedStatus(track);
      Common.AudioPlayer.play(track);
    } catch (ex) {
      alert(ex.message);
    }
  }

  handleRemoveTrack(e) {
    const { track } = this.props;
    Common.Playlist.RemoveTrack(track);
    //Common.Playlist.LoadPlaylist(Common.Playlist.GetPlaylistId());
    //for alt tab playlist indicator
    this.props.state.source == "drawer" ? this.props.onTrackAdded(false) : null;

    if (this.props.state.source === "playlist") {
      // const previousPlaylistTracks = Common.GetState().PreviousPlaylistTracks.push(this.props.track);
      // store.dispatch(updatePreviousPlaylistTrack(previousPlaylistTracks.toJS()));
      setTimeout(() => {
        let data = {
          type: "playlist",
          playlist: this.props.track,
          playListId: Common.Playlist.GetPlaylistId(),
        };
        const previousSearches = Common.GetState().PreviousSearches.push(data);
        store.dispatch(updatePreviousSearches(previousSearches.toJS()));
      }, 1000);
    }
  }

  /*
  handleWaveformClick() function is not being used
  */
  handleWaveformClick(track, e) {
    var rect = e.target.getBoundingClientRect();
    var x = e.pageX - rect.left;
    var posAsPercent = x / rect.width;

    Common.AudioPlayer.seekPlay(track, posAsPercent);
  }

  handleTrackPlayerClick = (event, track) => {
    event.preventDefault();
    //Ctrl+click pressed
    if (event.ctrlKey === true) {
      if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === "home") {
        Common.Dialogs.ShowDialog("SignUpDialog", true);
        return;
      }
      this.showPlaylists(event, track);
    }
  };

  handleTrackPlayerContextMenu = (event, track) => {    
    event.preventDefault();
    if ((this.UserDetails.UserId === undefined || this.UserDetails.UserId === null) && window.Environment === "home") {
      Common.Dialogs.ShowDialog("SignUpDialog", true);
      return;
    }
    this.showPlaylists(event, track);
  };

  handleCloseMenu(e) {}

  handleContextMenuSelect(e) {
    if (e.Value.Id == 0) {
      const { track } = this.props;
      Common.Playlist.AddTrack(track, undefined, true);
    } else {
      const { track } = this.props;
      const playlistId = e.Value.Id;
      Common.Playlist.AddTrack(track, e.Value.Id);
    }
  }

  showPlaylists(event, track) {
    let screenX = event.pageX;
    let screenY = event.pageY;

    // to get the playlist
    Common.Playlist.GetPlaylistsByTrackId(track.Id)
      .then((playlists) => {
        const { multiSelect = false } = this.props;

        let contextMenuData = this.createContextMenuData(playlists);

        if (contextMenuData) {
          const selectedItems = null;
          const fromTrackPlayer = true;
          closeContextMenu();
          showContextMenu(
            screenX,
            screenY,
            contextMenuData,
            selectedItems,
            {
              multiSelect: multiSelect,
              owner: this.contextOwner,
            },
            this.handleContextMenuSelect.bind(this),
            null,
            this.handleCloseMenu.bind(this),
            fromTrackPlayer
          );
        }
      })
      .catch((err) => {
        alert("ERROR: " + err.message);
      });
  }

  createContextMenuData(playlists) {
    let contextMenuData = [];
    let subMenu = [];
    const newPlaylist = {
      Label: "New Playlist...",
      Value: {
        Id: 0,
        Name: "New Playlist...",
      },
      Selected: null,
    };
    subMenu.push(newPlaylist);

    playlists.forEach((element) => {
      const playlist = {
        Label: element.Title,
        Value: {
          Id: element.Id,
          Name: element.Title,
        },
        Selected: element.IsInTrack,
      };
      subMenu.push(playlist);
    });

    contextMenuData = [
      {
        Label: "Add to Selections List",
        Value: {
          Id: 0,
          Name: "Add to Selections List",
        },
        Selected: null,
        SubMenu: subMenu,
      },
    ];

    return contextMenuData;
  }

  get UserDetails() {
    return Common.GetState().UserInterface.toJS().UserDetails || {};
  }
  showTrackDuration(trackId) {
    let track_play_history = localStorage.getItem("track_play_history");
    if (track_play_history) {
      track_play_history = JSON.parse(track_play_history);
      track_play_history.find((value) => {
        if (value.id === trackId) {
          if (value.trackDuration === undefined || value.trackDuration === -1) {
            this.setState({ trackDuration: 0 });
          } else {
            if (this.props.track.Duration <= Math.round(value.trackDuration)) {
              this.setState({ trackDuration: 0 });
            } else {
              this.setState({ trackDuration: parseInt(value.trackDuration) });
            }
          }
        }
      });
    } else {
      this.setState({ trackDuration: 0 });
    }
  }
  onTrackPlayed(trackId) {
    this.showTrackDuration(trackId);
  }

  get UserDetails() {
    return Common.GetState().UserInterface.toJS().UserDetails || {};
  }

  editTrackModal = (trackId) => {
    if (this.UserDetails.IsAdmin) {
      Common.Dialogs.ShowDialog("EditTrackDialog", true, trackId);
    }
  };

  render() {
    const { track, state, hideToggle = false } = this.props;
    const isTrackInPlaylist = Common.Playlist.IsTrackInPlaylist(track);
    const hasAuditionedTrack = Common.UserInterface.HasAuditionedTrack(track);
    
    const playlistButton = !state.hidePlaylistIcon 
      ? isTrackInPlaylist 
        ? <FontIcon className="active" name="circle-cross" size={17} /> 
        : <FontIcon name="circle-plus" size={17} /> 
      : "";

    const playlistButtonAction = !state.hidePlaylistIcon 
      ? isTrackInPlaylist 
          ? this.handleRemoveTrack.bind(this) 
          : this.handleAddTrack.bind(this)
      : () => {};
    
    const headphoneIcon = !state.hideAuditionIcon ? hasAuditionedTrack ? <FontIcon name="headphones" /> : "" : "";    
    const trackTitle = track.Mix ? getTrackTitle(track, state.discovery) + " (" + track.Mix + ")" : getTrackTitle(track, state.discovery);
    const waveformUrl = Common.GetWaveformUrl(track);

    const sectionClass = classNames({
      section: true,
      "no-toggle": hideToggle,
    });

    let trackExclusive = "";
    if (this.UserDetails && this.UserDetails.IsAdmin) {
      trackExclusive = track.Exclusive ? "EX" : "";
    }

    let imgStar;

    if (this.UserDetails.IsAdmin) {
      switch (track.Rating) {
        case 1:
          imgStar = "track-rating-1";
          break;
        case 2:
          imgStar = "track-rating-2";
          break;
        case 3:
          imgStar = "track-rating-3";
          break;
        case 4:
          imgStar = "track-rating-4";
          break;
        case 5:
          imgStar = "track-rating-5";
          break;
      }
    }

    const contextMenuAction = state.disableContextMenu ? () => {} : (event) => this.handleTrackPlayerContextMenu(event, track)
    
    return (
      <div
        className={sectionClass}
        ref={(i) => {
          this.contextOwner = i;
        }}
      >
        <div className="track-player" onClick={(event) => this.handleTrackPlayerClick(event, track)} onContextMenu={contextMenuAction}>
          <div className="top">
            <div className="playlist-button action-button" onClick={playlistButtonAction}>
              {playlistButton}
            </div>
            <div className={"playlist-button-space"}></div>
            <div className="track-title" onDoubleClick={this.editTrackModal.bind(this, track.Id)}>
              {trackTitle}
            </div>
            <div className={imgStar} />
            <div className="track-duration">
              {convertToHHMMSS(this.state.trackDuration)}/{convertToHHMMSS(track.Duration)}
            </div>
            <div className="track-exclusive">{trackExclusive}</div>
          </div>
          <div className="bottom">
            <WaveformPlayer onTrackPlayed={this.onTrackPlayed.bind(this)} track={track} state={state} />
            <span className="audition-indicator">{headphoneIcon}</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Playlist: state.Playlist, // NOTE: need playlist to update track results based on playlist
    UserInterface: state.UserInterface,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const TrackPlayer = connect(mapStateToProps, mapDispatchToProps)(InnerTrackPlayer);

export { TrackPlayer };
