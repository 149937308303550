import React from 'react';
import {connect} from 'react-redux';
import {List} from 'immutable';
import './more-like-this.scss';

import {TrackResults} from 'components/common/track-results/track-results';
import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  FontIcon,
  LoadingPanel,
  RenderIf
} from 'components/ui';

const dialogName = 'MoreLikeThisDialog';

const onKeyUp = (event) => {
  if (event.which == 27) {
    Common.Dialogs.ShowDialog(dialogName, false);
  }
}

// export default class AlbumInfo extends React.Component {
class MoreLikeThisDialog extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      canLoadMore: false,
      isLoading: true,
      isLoadingMore: false,
      isLoadingOptions: true,
      page: 0,
      size: 30,
      filters: {
        collection: false,
        composer: false,
        energy: false,
        genre: true,
        mood: true,
        tempo: false
      },
      moreLikeThisOptions: {
        moodParent: ''
      },
      track: {},
      tracks: List(),
      trackIsAlreadyPlaying: false,
      totalCount: 0
    };
  }

  get onClose() {
    const {
      onClose = () => {
      }
    } = this.props;
    return onClose;
  }

  get Search() {
    return this.props.Search ? this.props.Search.toJS() : {};
  }

  componentWillMount() {
    this.state.trackIsAlreadyPlaying = Common.TrackPlayer.IsPlaying;
    this.state.track = this.props.Dialogs.toJS().Options[dialogName].track

    document.addEventListener('keyup', onKeyUp);

    const loadData = async () => {
      await this.handleLoadTracks(this.state.filters, this.state.page, this.state.size);
      await this.handleLoadMoreLikeThisOptions(this.state.track.Id)
    }
    
    loadData();
  }

  componentWillUnmount() {
    if (Common.TrackPlayer.AudioSource === null) {
      Common.TrackPlayer.Stop();
    }

    document.removeEventListener('keyup', onKeyUp);
  }

  updateComponent() {
    this.TrackResults.ScrollableList.List.forceUpdateGrid();
    this.forceUpdate();
  }

  handleLoadMoreLikeThisOptions(trackId) {
    this.setState({isLoadingOptions: true})

    Common.Search.GetMoreLikeThisOptions(trackId)
      .then(result => {
        this.setState({moreLikeThisOptions: result, isLoadingOptions: false});
      })
      .catch(err => console.error('ERROR: ', err));
  }

  handleLoadTracks(filters, page, size) {
    this.setState({isLoading: true});

    Common.Search.GetMoreLikeThis(this.state.track, {
      Page: 0,
      Size: 30,
      SortType: Common.UserInterface.GetSearchSortType(),
      SearchQuery: {
        CollectionId: filters.collection ? this.state.track.Collection.Id : null,
        ComposerId: filters.composer ? this.state.track.Composer.Id : null,
        EnergyId: filters.energy ? this.state.track.Energy.Id : null,
        GenreId: filters.genre ? this.state.track.Genre.Id : null,
        MoodId: filters.mood ? this.state.track.Mood.Id : null,
        TempoId: filters.tempo ? this.state.track.Tempo.Id : null
      }
    })
      .then(results => {
        const tracks = List(results.Tracks);
        const canLoadMore = (results.Page * results.Size) < results.Total;
        this.setState({
          canLoadMore: canLoadMore,
          isLoading: false,
          page: results.Page,
          size: results.Size,
          totalCount: results.Total,
          tracks: tracks
        });
      })
      .catch(err => console.error('ERROR: ', err));
  }

  handleLoadMoreTracks(filters, page, size) {
    this.setState({isLoadingMore: true});

    Common.Search.GetMoreLikeThis(this.state.track, {
      Page: page,
      Size: size,
      SortType: Common.UserInterface.GetSearchSortType(),
      SearchQuery: {
        CollectionId: filters.collection ? this.state.track.Collection.Id : null,
        ComposerId: filters.composer ? this.state.track.Composer.Id : null,
        EnergyId: filters.energy ? this.state.track.Energy.Id : null,
        GenreId: filters.genre ? this.state.track.Genre.Id : null,
        MoodId: filters.mood ? this.state.track.Mood.Id : null,
        TempoId: filters.tempo ? this.state.track.Tempo.Id : null
      }
    })
      .then(results => {
        const tracks = this.state.tracks.concat(results.Tracks);
        const canLoadMore = (results.Page * results.Size) < results.Total;
        this.setState({
          canLoadMore: canLoadMore,
          isLoading: false,
          isLoadingMore: false,
          page: results.Page,
          size: results.Size,
          totalCount: results.Total,
          tracks: tracks
        });
      })
      .catch(err => console.error('ERROR: ', err));
  }

  handleDownloadTrack(track, event) {
    const rect = event.target.getBoundingClientRect();
    Common.UserInterface.ShowDownloadTrack(track, rect);
  }

  handleKeyUp(event) {
    if (event.which === 13) {
      this.handleOK();
    }
  }

  handleCancel() {
    Common.Dialogs.ShowDialog(dialogName, false);
    this.onClose();
  }

  handleCheck(filterType, value) {
    const state = this.state;
    state.filters[filterType] = value;
    this.setState(state);

    this.handleRefineList();
  }

  handleLoadAlbumInfo(genreId) {
    Common.Search.ShowAlbumInfo(genreId);
    this.setState({isLoading: true, tracks: List(), page: 0, size: this.state.size});
    this.handleLoadTracks(genreId, 0, this.state.size);
  }

  handleRefineList = () => {
    var isChecked = this.state.filters.collection |
      this.state.filters.composer |
      this.state.filters.energy |
      this.state.filters.genre |
      this.state.filters.mood |
      this.state.filters.tempo;

    if (isChecked)
      this.handleLoadTracks(this.state.filters, 0, 30);
  }

  handleScrollFrame(values) {
    if (values.top > 0.75 && !this.state.isLoadingMore) {
      if ((this.state.page * this.state.size) < this.state.totalCount) {
        this.handleLoadMoreTracks(this.state.filters, this.state.page, this.state.size);
      }
    }
  }

  onAddAllToPlaylistClick = () => {
    Common.Playlist.AddTrackList(this.state.tracks.toJS());
  }

  render() {
    const {
      onClose = () => {
      }
    } = this.props;

    const title = (<span className='album-info-title'>MORE LIKE: <span>{this.state.track.Title}</span></span>)
    const loadingBar = this.isLoadingMore ? (<div className='album-info-loading-bar'/>) : '';

    const resultsList = !this.state.isLoading
      ? (
        <TrackResults
          ref={(i) => (this.TrackResults = i)}
          tracks={this.state.tracks.toJS()}
          hideAlbumLink={true}
          hideMoreLikeThisIcon={true}
          hideFilterLinks={true}
          hideDrawerLinks={true}
          hideTrackInfoLinks={true}
          keepToggleIndex={true}
          onScroll={this.handleScrollFrame.bind(this)}/>
      )
      : (<LoadingPanel width={150} height={150}/>)

    return (
      <Dialog title={title} name='AlbumInfo' width={1200} height={800} modal={true} hideTitleIcon={true} closeText=''
              onClose={this.handleCancel.bind(this)} onEscape={this.handleCancel.bind(this)}>
        <Flex className='more-like-this' position='top'>
          <Flex row fixed className='header' position='top'>
            <RenderIf condition={!this.state.isLoadingOptions}>
            <Flex fixed row className='results-filter'>
              <Flex width='2/12' position='left'>REFINE RESULTS</Flex>              
              <Flex width='2/12' height={30}>
                <Flex width='100%' position='left'><Checkbox label={this.state.track.Genre.Name}
                                                             value={this.state.filters.genre}
                                                             onCheck={this.handleCheck.bind(this, 'genre')}/></Flex>
                <Flex width='100%' position='left'><Checkbox label={this.state.track.Tempo.Name}
                                                             value={this.state.filters.tempo}
                                                             onCheck={this.handleCheck.bind(this, 'tempo')}/></Flex>
              </Flex>
              <Flex width='2/12' height={30}>
                <Flex width='100%' position='left'><Checkbox label={this.state.moreLikeThisOptions.MoodParent}
                                                             value={this.state.filters.mood}
                                                             onCheck={this.handleCheck.bind(this, 'mood')}/></Flex>
                <Flex width='100%' position='left'><Checkbox label={`${this.state.track.Energy.Name} Energy`}
                                                             value={this.state.filters.energy}
                                                             onCheck={this.handleCheck.bind(this, 'energy')}/></Flex>
              </Flex>
              <Flex width='2/12' height={30}>
                <Flex width='100%' position='left'><Checkbox label={this.state.track.Collection.Name + ' Collection'}
                                                             value={this.state.filters.collection}
                                                             onCheck={this.handleCheck.bind(this, 'collection')}/></Flex>
                <Flex width='100%' position='left'><Checkbox label={'This Composer'}
                                                             value={this.state.filters.composer}
                                                             onCheck={this.handleCheck.bind(this, 'composer')}/></Flex>
              </Flex>
              <Flex width='2/12'>&nbsp;</Flex>
              {/* Hide the Refine List button as per SUPERVISOR-59 */}
              {/* <Flex width='1/12'><Button onClick={this.handleRefineList.bind(this)}>Refine List</Button></Flex> */}
            </Flex>
              </RenderIf>
          </Flex>
          <Flex className='content'>
            {resultsList}
          </Flex>
          <Flex row fixed className='footer' position='bottom-right'>
            <Flex className='more-like-this-buttons'>
              <Button className='add-to-playlist-button' height={20} type='default' onClick={this.onAddAllToPlaylistClick}
                disabled={this.state.isLoading}>
                <Flex row>
                  <FontIcon name='circle-plus'/> &nbsp; Add All to Playlist</Flex>
              </Button>
            </Flex>
            <Flex className='more-like-this-track-count'>Found {this.state.totalCount} Tracks</Flex>
          </Flex>
        </Flex>
      </Dialog>
    )
  }
}

export default connect(state => {
  return {
    Dialogs: state.Dialogs,
    Search: state.Search
  }
})(MoreLikeThisDialog)
