import React from 'react';
import { connect } from 'react-redux';
import { Flex, FontIcon } from 'components/ui';
import './nav-menu.scss';

import { fetchAuth, fetchUserDetails } from 'utility/fetchHelpers';
import { setUserDetails } from 'store/userInterface/actions';
import ReactTooltip from 'react-tooltip';

class NavigationMenu extends React.Component {
    constructor() {
        super(...arguments);
         const toolTipMessage = {
             "quickTip" :"VIEW SHORT HELP VIDEO",
             "cueSheet" : "IMPORT .EDL FILE TO CREATE CUE SHEET DELIVERABLE"
         }

        this.state = {
            isValid: false,
            toolTip : toolTipMessage
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;

        fetchAuth()
            .then(value => {
                if (value) {
                    fetchUserDetails()
                        .then(userDetails => {
                            dispatch(setUserDetails(userDetails));
                            this.setState({ isValid: true });
                        })
                        .catch(err => {
                            throw err;
                        });
                } else {
                    this.setState({ isValid: false });
                    dispatch(setUserDetails(null))
                }

            })
            .catch((err) => {
                this.setState({ isValid: false });
                dispatch(setUserDetails(null))
            });
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.UserInterface.toJS().UserDetails != null && !this.state.isValid) {
            fetchAuth()
                .then(value => {
                    this.setState({ isValid: value });
                })
                .catch(() => {
                    // TODO: display error message
                    console.error(err);
                })
        }
    }

    get PreviousSearches() {
        const { PreviousSearches } = this.props;
        return PreviousSearches ? PreviousSearches.toJS() : [];
    }

    get PreviousPlaylistTracks() {
        const { PreviousPlaylistTracks } = this.props;
        return PreviousPlaylistTracks ? PreviousPlaylistTracks.toJS() : [];
    }

    get UserInterface() {
        return this.props.UserInterface ? this.props.UserInterface.toJS() : {};
    }

    handleAccount() {
        //return;     // 11.12.2018 - Temporarily removed Account maintenance via application
        Common.Dialogs.ShowDialog('EditProfileDialog', true);
    }

    handleContactUs() {
        Common.Dialogs.ShowDialog('ContactUsDialog', true);
    }

    handleCueSheet() {
        Common.Dialogs.ShowDialog('CueSheet', true);
    }

    handleHowTo() {
        Common.Dialogs.ShowDialog('HowToDialog', true);
    }

    handleLogin() {
        localStorage.removeItem('playlistIds');
        Common.Dialogs.ShowDialog('LoginDialog', true);
    }

    handleLogout() {
        const { dispatch } = this.props;

        this.setState({ isValid: false });
        Common.Playlist.ClearPlaylist();
        Common.UserInterface.ToggleMultiplePlaylist(false);        
        Common.UserInterface.Logout();
        dispatch(setUserDetails(null));
        Common.Search.UpdatePreviousSearches(null);
    }

    handleSignup() {
        Common.Dialogs.ShowDialog('SignUpDialog', true);
    }

    handleUndo() {
        Common.Search.LoadPreviousSearch();
    }

    handleQuickTips() {
        Common.Dialogs.ShowDialog('QuickTipsDialog', true);
    }

    handleAdminClick = () => {
        localStorage.setItem("wentToAdminPage", true);
        localStorage.removeItem("trackListFilter");
    }

    maybeShowAdminLink() {
        try {
            if (this.UserInterface.UserDetails) {
                if (this.UserInterface.UserDetails.IsAdmin) {
                    localStorage.setItem('EmailAddress', this.UserInterface.UserDetails.EmailAddress);
                    return (<a href='/admin#/admin' onClick={this.handleAdminClick}>Admin</a>);
                }
            }
        }
        catch (ex) { }
    }

    render() {
        if (Common.IsAudiofficina()) {
            return this.renderAudiofficina()
        } else {
            return this.renderScorekeepers()
        }
    }

    renderScorekeepers() {
        const undoLink = null;

        const menu = this.state.isValid
            ? (
                <Flex row>
                    <Flex row position='left'>
                        {undoLink}
                    </Flex>
                    <ReactTooltip id="tool-nav" className="tooltip-background" type="light" place="bottom" multiline={true} effect="solid" delayHide={1000}></ReactTooltip>
                    <Flex row position='right'>
                        <a href='#' onClick={this.handleAccount.bind(this)} className='cursor-default'>{this.props.UserInterface.toJS().UserDetails.Username}</a>
                        {this.maybeShowAdminLink()}
                        <a href='#' onClick={this.handleContactUs.bind(this)}>Contact Us</a>
                        <a href='#' onClick={this.handleLogout.bind(this)}>Logout</a>
                    </Flex>
                </Flex>)
            : (
                <Flex row>
                    <Flex row position='left'>
                        {/* { undoLink } */}
                    </Flex>
                    <Flex row position='right'>
                        <a href='#' onClick={this.handleContactUs.bind(this)}>Contact Us</a>
                        <a href='#' onClick={this.handleSignup.bind(this)}>Sign Up</a>
                        <a href='#' onClick={this.handleLogin.bind(this)}>Login</a>
                    </Flex>
                </Flex>
            )

        return (
            <Flex row className='nav-menu'>{menu}</Flex>
        )
    }

    renderAudiofficina() {
        const undoLink = null;

        const menu = this.state.isValid
            ? (
                <Flex row>
                    <Flex row position='left'>
                        {undoLink}
                    </Flex>
                    <ReactTooltip id="tool-nav" className="tooltip-background" type="light" place="bottom" multiline={true} effect="solid" delayHide={1000}></ReactTooltip>
                    <Flex row position='right'>
                        {/* <a href='#' onClick={() => {}}><FontIcon size={20} name='euro-coin' /></a> */}
                        <a href='#' onClick={this.handleAccount.bind(this)} className='cursor-default'>{this.props.UserInterface.toJS().UserDetails.Username}</a>
                        <a href='#' onClick={this.handleLogout.bind(this)}>Logout</a>
                    </Flex>
                </Flex>)
            : (
                <Flex row>
                    <Flex row position='right'>
                        {/* <a href='#' onClick={() => {}}><FontIcon size={20} name='euro-coin' /></a> */}
                        <a href='#' onClick={this.handleLogin.bind(this)}>Login</a>
                    </Flex>
                </Flex>
            )

        return (
            <Flex row className='nav-menu'>{menu}</Flex>
        )
    }
}

export default connect(state => {
    return {
        PreviousSearches: state.PreviousSearches,
        UserInterface: state.UserInterface,
        PreviousPlaylistTracks: state.PreviousPlaylistTracks
    }
})(NavigationMenu);