import React from 'react';
import { Flex } from 'components/ui';
import Styles from './playlistExtension.scss';

import PlaylistPanel from '../playlist/playlist';
import PlaylistFilters from './playlistFilters/playlistFilters';
import PlaylistResults from './playlistResults/playlistResults';

class PlaylistExtension extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        return (
            <Flex row className='playlist-extension'>
                <PlaylistFilters />
                <PlaylistResults isVisible={this.props.isVisible} />
                <PlaylistPanel />
            </Flex>
        )
    }
}

export default PlaylistExtension;