import React from 'react';
import classNames from 'classnames';
import style from './date-drop-down.scss';

import { Button, DropDown, Flex } from 'components/ui';

export class DateDropDown extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            month: null,
            day: null,
            year: null
        }
    }

    get onChange() {
        const { onChange = () => {} } = this.props;
        return onChange;
    }    

    get value () {
        const { month, day, year } = this.state;
        
        if (month && day && year) {
            return (month.id < 10 ? "0" + month.id : month.id) + '/' +
                (day.id < 10 ? "0" + day.id : day.id) + '/' +
                (year.id);
        }
        
        return null;
    }

    clear() {
        this.handleReset();
    }

    getMonths() {
        return [
            { id: 1, text: 'Jan' },
            { id: 2, text: 'Feb' },
            { id: 3, text: 'Mar' },
            { id: 4, text: 'Apr' },
            { id: 5, text: 'May' },
            { id: 6, text: 'Jun' },
            { id: 7, text: 'Jul' },
            { id: 8, text: 'Aug' },
            { id: 9, text: 'Sep' },
            { id: 10, text: 'Oct' },
            { id: 11, text: 'Nov' },
            { id: 12, text: 'Dec' }
        ];
    }

    getDays() {
        const days = [];
        for (var x = 1; x < 31; x++) {
            days.push({ id: x, text: x });
        }

        return days;
    }

    getYears() {
        var year = (new Date()).getFullYear();
        var yearList = [];

        for (var i = 2009; i <= year; i++) {
            yearList.push({id: i, text: i});
        }

        return yearList;
    }

    handleDay (value) {
        this.state.day = value;
        this.setState({ day: value });
        this.onChange(this.value);  
    }

    handleMonth (value) {
        this.state.month = value;
        this.setState({ month: value });
        this.onChange(this.value);  
    }

    handleYear (value) {        
        this.state.year = value;
        this.setState({ year: value });
        this.onChange(this.value);  
    }        

    handleReset () {
        if (!this.props.disabled) {
            this.setState({
                month: null,
                day: null,
                year: null
            });

            this.onChange(null);
        }
    }

    render() {
        const {
            disabled = false,
        } = this.props;

        const dateDropDownClass = classNames({
            'date-drop-down': true,
            'disabled': disabled
        });

        return (
            <Flex className={dateDropDownClass} row>
                <DropDown width='70px' disabled={disabled} placeholder='Month' size={12} items={this.getMonths()} value={this.state.month} ref={(input) => { this.month = input }} onSelect={this.handleMonth.bind(this)} />
                <Flex width='5px' />
                <DropDown width='60px' disabled={disabled} placeholder='Day' size={10} items={this.getDays()} value={this.state.day} ref={(input) => { this.day = input }} onSelect={this.handleDay.bind(this)} />
                <Flex width='5px' />
                <DropDown width='70px' disabled={disabled} placeholder='Year' size={12} items={this.getYears()} value={this.state.year} ref={(input) => { this.year = input }} onSelect={this.handleYear.bind(this)} />
                <Button className='clear-date-button' type='text' disabled={this.props.disabled} size='md' onClick={this.handleReset.bind(this)}>Clear</Button>
            </Flex>
        )
    }
}