import React from 'react';
import {getTrackComposerName, getTrackPublisherSplit, getTranslation} from 'utility/common';
import './track-detail-writers.scss';

export class TrackDetailWritersPanel extends React.Component {
    constructor() {
        super(...arguments);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.track != nextProps.track ||
            this.props.show != nextProps.show
        );
    }

    handleAddFilter(filterType, filter) {
        filter.FilterType = filterType;
        Common.UserInterface.AddSearchFilter(filter, false);
    }       

    render() {
        const {
            track,
            state,
            show,
            onPlayerAction
        } = this.props;

        const display = show 
            ? { display: 'block' }
            : { display: 'none' }; 

        return (
            <div className='track-detail-writers-panel' style={display}>
                <div className='row'>
                    <div className='info composer'>
                        <div className='type'>{getTranslation("Composer")}:</div>
                        <div>{getTrackComposerName(track)}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='info publisher'>
                         <div className='type'>{getTranslation("Publisher")}:</div>
                         <div className='value'>{getTrackPublisherSplit(track)}</div>
                    </div>                
                </div>
            </div>            
        )
    }
}
