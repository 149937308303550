import React from 'react';
import ReactDOM from 'react-dom';
import DOM from 'react-dom-factories';

export class RenderInBody extends React.Component {
  constructor () {
    super(...arguments);
  }

  componentDidMount () {
    this.element = document.createElement('div');
    document.body.appendChild(this.element);
    this.renderLayer();
  }

  componentDidUpdate () {
    this.renderLayer();
  }

  componentWillUnmount () {
    ReactDOM.unmountComponentAtNode(this.element);
    document.body.removeChild(this.element);
  }

  renderLayer () {
    ReactDOM.render(this.props.children, this.element);
  }

  render () {
    return DOM.div(this.props);    
  }
}

export default RenderInBody;
