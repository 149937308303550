import React from 'react';
import classNames from 'classnames';
import { Flex } from 'components/ui';
import Styles from './tabs.scss';

const getTabElements = (tabs) => {
    const tabElements = [];

    if (Array.isArray(tabs)) {
        tabs.forEach(tab => {
            tabElements.push(tab);
        });
    } else {
        tabElements.push(tabs);
    }

    return tabElements;
}

const getTabContent = (tabs) => {
    let tabContent = null;

    if (Array.isArray(tabs)) {
        tabs.forEach(tab => {
            if (tab.props.selected) 
                tabContent = tab.props.children;
        })
        //return tabContent;
        return tabs.map(t => t.props.children);
    } else {
        return tabs.props.children;
    }
}

export class Tabs extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        const {
            type = 'primary',            
            width = null,
            height = null,
            children = null,
            style = {},
            className = null
        } = this.props;    

        const componentStyle = Object.assign({}, style, {
            width: width,
            height: height
        });

        const componentClass = classNames({
            'tabs': true,
            'primary': type.equalsIgnoreCase('primary')
        }, className);

        const tabElements = getTabElements(children);
        const tabContent = getTabContent(children);



        return (
            <Flex className={componentClass} style={componentStyle} position='top'>
                <Flex className='tab-header'>{tabElements}</Flex>
                <Flex className='tab-content'>{tabContent}</Flex>
            </Flex>
        )
    }
}

export class Tab extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        const {
            label = '',
            noSelect = false,
            selected = false,
            textAlign = 'center',
            width = null,
            height = null,
            children = null,
            style = {},
            className = null,
            onTabSelect = () => {}
        } = this.props;

        const componentStyle = Object.assign({}, style, {
            width: Common.Helpers.GetPercentage(width),
            height: Common.Helpers.GetPercentage(height),            
        });
        
        const componentClass = classNames({
            'tab': true,
            'no-select': noSelect,
            'selected': selected,
            'title-left': textAlign.equalsIgnoreCase('left'),
            'title-right': textAlign.equalsIgnoreCase('right'),
        }, className);

        return (
            <Flex className={componentClass} style={componentStyle}>
                <Flex className='tab-indicator' fixed></Flex>
                <Flex className='tab-title' onClick={onTabSelect.bind(this)}>{label}</Flex>
            </Flex>
        )
    }
}

class TabContent extends React.Component {
    constructor() {
        super(...arguments);
    }

    render() {
        const {
            width = null,
            height = null,
            children = null,
            style = {},
            className = null
        } = this.props;

        const componentStyle = Object.assign({}, style, {
            width: width,
            height: height
        });
        
        const componentClass = classNames({
            'tab-content': true
        }, className);

        return (
            <Flex>{children}</Flex>
        )
    }
}
