import React from 'react';
import ReactDOM from 'react-dom';
import {AutoSizer, List} from 'react-virtualized';
import {VirtualScrollBars} from 'components/ui';
import './virtual-context-menu.scss';

const safeComponentClasses = [
    'virtual-context-menu',
    'virtual-context-item',
]

export const ShowContextMenu = (x = 0, y = 0, items = [], selectedItems = [], options = {}) => {
    try {
        // TODO: We need to be able to access this dynamically or through a parameter passed in
        const app = document.getElementById("app");
        const virtualContextMenu = document.createElement("div");
        virtualContextMenu.id = 'virtual-context-menu';
        // virtualContextMenu.owner = options.owner;
        virtualContextMenu.owner = (options.owner instanceof React.Component) ? ReactDOM.findDOMNode(options.owner) : options.owner;

        ReactDOM.render(<VirtualContextMenu x={x} y={y} items={items} selectedItems={selectedItems} options={options} width={options.width} height={options.height} />, virtualContextMenu);

        app.appendChild(virtualContextMenu);
    } catch (ex) {
        console.error('ERROR: ', ex);
    }
}

export const CloseContextMenu = () => {
    try {
        // TODO: We need to be able to access this dynamically or through a parameter passed in
        const app = document.getElementById("app");
        const virtualContextMenu = document.getElementById("virtual-context-menu");

        if (virtualContextMenu != null) {
            app.removeChild(virtualContextMenu);
        }
    } catch (ex) {
        console.error('ERROR: ', ex);
    }
}

class VirtualContextMenu extends React.Component {
    constructor() {
        super(...arguments);

        this.filterComponent = React.createRef();
        this.state = {
            filter: '',
            selectedItems: []
        }
    }

    get filteredItems() {
        const searchRegexStr = '(.*)' + this.state.filter.split(' ').map(s => `(${s})`).join('(.*?)') + '(.*)';
        const searchRegex = new RegExp(searchRegexStr, 'i');    
        return this.props.items.filter(x => searchRegex.test(x.Label));   
    }

    componentDidMount = () => {
        this.setState({ selectedItems: this.props.selectedItems });
    }

    componentWillUnmount = () => {
    }   
    
    handleMouseLeave = (e) => {
        const target = event.relatedTarget;
        const virtualContextMenu = document.getElementById("virtual-context-menu");

        if (virtualContextMenu && !virtualContextMenu.contains(target) && !virtualContextMenu.owner.contains(target)) {
            setTimeout(CloseContextMenu, 1000);
        }
    }

    handleSelection = (item, e) => {
        let selectedItems = this.state.selectedItems;
        let onSelect = this.props.options.onSelect || (() => {});

        const itemIndex = selectedItems.findIndex(x => x === item);
        if (itemIndex >= 0) {
            selectedItems = selectedItems.filter(x => x !== item);
        } else {
            selectedItems.push(item);
        }

        this.setState({ selectedItems: selectedItems });

        onSelect(selectedItems);
    }

    render() {
        const {
            x = 0,
            y = 0,
            width = 500,
            height = 600
        } = this.props;

        const windowStyle = {
            left: x,
            top: y,
        };

        const filteredItems = this.filteredItems;
        const contextListHeight = filteredItems.length > 10 ? 10 * 30 : filteredItems.length * 30;

        const rowRenderer = ({key, index, style}) => {
            const item = this.filteredItems[index];

            if (item) {                
                const isSelected = this.state.selectedItems.filter(x => x === item).length > 0;
                const virtualContextItemClass = isSelected 
                    ? 'virtual-context-item selected'
                    : 'virtual-context-item'

                return (
                    <div style={style} key={key} className={virtualContextItemClass} onClick={(e) => this.handleSelection(item, e)}>
                        <span><span className='checkbox'></span></span> {item.Label}
                    </div>
                )
            }

            return null;
        }        

        return (
            <div style={windowStyle} className='virtual-context-window' onMouseLeave={this.handleMouseLeave}>
                <div ref={this.filterComponent} className='virtual-context-filter'>
                    <input type='text' value={this.state.filter} onChange={(e) => this.setState({ filter: e.target.value })} placeholder='Type here to search...' />
                </div>
                <div className='virtual-context-list' style={{width: width, height:contextListHeight}}>
                    <AutoSizer>
                        {({height, width}) => {
                            return (
                                <VirtualScrollBars>
                                    <List width={width}
                                        height={height}
                                        rowHeight={30}
                                        rowCount={this.filteredItems.length}
                                        rowRenderer={rowRenderer} />
                                </VirtualScrollBars>                                      
                            )
                        }}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}

export {
    VirtualContextMenu  
} 