import React, { Component } from 'react'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
//import BaseTheme from 'appearance/material-ui/themes/base'

/**
 * The global site container
 * @TODO: actually make this global, if possible.  should be close
 *        (header in container layouts)
 */
class GlobalContainer extends Component {

  constructor() {
    super(...arguments); 
    
    const origin = Common.IsAudiofficina()
      ? 'audiofficina'
      : ''

    this.state = {          
      open: false,
      origin: origin
    }
  }

  render () {
    var {
      children
    } = this.props; 
    
    const className = `global-application-view ${this.state.origin}`

    return (
      <MuiThemeProvider>
        <div className={className}>
          { children }
        </div>
      </MuiThemeProvider>
    )
  }
}

export default GlobalContainer
