const PLAYLIST_DROP_DOWN_ELEMENT_HEIGHT = 34;
const PLAYLIST_DROP_DOWN_ELEMENT_PADDING = 20;

import React from 'react';
import { List } from 'immutable';
import { ScrollableList } from 'components/ui'

class PlaylistDropDown extends React.Component {
    constructor() {
      super(...arguments);

      this.state = {
        playlists: List(),
        sharedPlaylists: List()
      }
    }

    componentDidMount() {
      Common.Playlist.GetPlaylists()
        .then(playlists => {
          this.setState({ playlists: List(playlists) })
          this.forceUpdate();
        })
        .catch(err => {
          alert('ERROR: ' + err.message);
        })

      Common.Playlist.GetSharedPlaylists()
        .then(playlists => {
          this.setState({ sharedPlaylists: List(playlists) })
          this.forceUpdate();
        })
        .catch(err => {
          alert('ERROR: ' + err.message);
        })
    }

    handleCloseMenu() {
      this.props.onCloseMenu();
    }

    handleLoadPlaylist(playlistId) {
      Common.Playlist.LoadPlaylist(playlistId, false);
      localStorage.setItem("selectedPlaylist", playlistId)
      this.props.onCloseMenu();
    }

    calculatePlaylistTitleWidth(textArray) {
      const span = document.createElement("span");
      span.id = "playlist-track-calculation-span";

      let textHtml = '';

      textArray.forEach(text => {
        textHtml += text + '<br />';
      })

      span.innerHTML = textHtml;
      document.body.appendChild(span);

      const width = span.getBoundingClientRect().width + 40;

      document.body.removeChild(span);

      return width;
    }

    calculateWidth = () => {
      return Math.max(
        document.querySelector('.playlist-tab').getBoundingClientRect().width,
        this.calculatePlaylistTitleWidth(this.state.playlists.map(x => x.Title))
      )
    }

    calculateHeight = () => {
      return Math.min(
        this.state.playlists.size * PLAYLIST_DROP_DOWN_ELEMENT_HEIGHT,
        document.querySelector('.playlist-results').clientHeight - PLAYLIST_DROP_DOWN_ELEMENT_PADDING
      );
    }

    render() {
      const {
        onCloseMenu = () => {
        }
      } = this.props;

      const width = this.calculateWidth();
      const height = this.calculateHeight();
      const componentStyle = { width, height };

      return (
        <div className='playlist-drop-down-list' style={componentStyle} onMouseLeave={onCloseMenu}>
          <ScrollableList
            ref={(i) => (this.ScrollableList = i)}
            rowRenderer={(p) => {
              return (
                <div key={p.key} index={p.index} style={p.style} className={'saved-playlist-item'}
                  onClick={this.handleLoadPlaylist.bind(this, this.state.playlists.get(p.index).Id)}>
                  <span>{this.state.playlists.get(p.index).Title}</span>
                </div>
              )
            }}
            rowCount={this.state.playlists.size || 0}
            rowHeight={PLAYLIST_DROP_DOWN_ELEMENT_HEIGHT}
            height={height}
            width={width}
          />
        </div>
      )
    }
  }

  export {
      PlaylistDropDown
  }