(function() {

    'use strict';

    const webPlayer = () => {        
        const audioPlayer = new Audio();

        const obj = {
            trackUrl: '',
            element: null,
            isPlaying: false,
            registeredStopEvents: [],
            loadCallback: () => {},
            playCallback: () => {},
            pauseCallback: () => {},
            stopCallback: () => {},
            markerCallback: () => {}
        }

        const onEnded = () => {
            obj.isPlaying = false;
            obj.stopCallback();

            notifyStopCallbacks();
        }

        const onPlay = () => {
            obj.isPlaying = true;
            obj.playCallback();
        }

        const onPause = () => {
            obj.pauseCallback();
        }

        const onTimeUpdate = () => {
            const position = (audioPlayer.currentTime / audioPlayer.duration);  
            obj.markerCallback(position);
        }

        const notifyStopCallbacks = () => {
            obj.registeredStopEvents.forEach(event => event.callback());
        }

        const playTrack = (trackUrl, element, type) => { 
            if (obj.trackUrl != trackUrl) {              
                stopTrack();
            } else {
                if (!obj.isPlaying) audioPlayer.play();
                if (element == obj.element) return;
                stopTrack();
            }

            if (element == null) return;

            obj.element = element;
            obj.trackUrl = trackUrl;
            obj.type = type;
            obj.playCallback = element.state.onPlay;
            obj.pauseCallback = element.state.onPause;
            obj.markerCallback = element.state.onUpdate;
            obj.stopCallback = element.state.onStop;
            obj.loadCallback = element.state.onLoad;

            audioPlayer.src = trackUrl;
            audioPlayer.play();
        }

        const reset = () => {
            obj.element = null;
            obj.playCallback = () => {};
            obj.pauseCallback = () => {};
            obj.markerCallback = () => {};
            obj.stopCallback = () => {};
            obj.loadCallback = () => {};
        }        

        const stopTrack = () => {
            audioPlayer.src = '';
            audioPlayer.pause();
            audioPlayer.currentTime = 0;
            obj.stopCallback();

            notifyStopCallbacks();

            reset();
        }

        const pauseTrack = () => {
            obj.isPlaying = false;
            audioPlayer.pause();
        }

        const seekTrack = (position) => {
            if (!isNaN(position))
                audioPlayer.currentTime = position;
        }        

        const onProgress = (a, b, c) => {
            if (audioPlayer.buffered.length > 0)
                obj.loadCallback(audioPlayer.buffered.end(0) / audioPlayer.duration);
        }

        // *** Add Player Events ***
        audioPlayer.addEventListener("play", onPlay)
        audioPlayer.addEventListener("pause", onPause)
        audioPlayer.addEventListener("ended", onEnded);
        audioPlayer.addEventListener("timeupdate", onTimeUpdate);
        audioPlayer.addEventListener("progress", onProgress);

        return {
            get element() {
                return obj.element;
            },
            get isPlaying() {
                return obj.isPlaying;
            },
            get trackUrl() {
                return obj.trackUrl;
            },

            registerStopEvent: (eventName, callback) => {
                if (obj.registeredStopEvents.findIndex(event => event.eventName == eventName) == -1) 
                    obj.registeredStopEvents.push({eventName: eventName, callback: callback});
            },            

            pause: () => { 
                pauseTrack(); 
            },
            play: (trackUrl, element, type) => {
                playTrack(trackUrl, element, type);
            },
            stop: () => { 
                stopTrack(); 
            },
            seek: (position) => {
                seekTrack(audioPlayer.duration * position); 
            },
            toggle: () => {
                if (obj.isPlaying) {
                    obj.isPlaying = false;
                    audioPlayer.pause();
                }
                else 
                {
                    obj.isPlaying = true;
                    audioPlayer.play();
                };
                    
            },
            unregisterStopEvent: (eventName) => {
                const eventIndex = obj.registeredStopEvents.findIndex(event => event.eventName == eventName);
                
                if (eventIndex >= 0) 
                    obj.registeredStopEvents.splice(eventIndex);
            }            
        }
    }

    module.exports = webPlayer;

}());