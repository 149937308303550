import React from 'react';
import {getTranslation} from 'utility/common';
import {RenderIf} from 'components/ui';
import './track-detail-description.scss';

export class TrackDetailDescriptionPanel extends React.Component {
    constructor() {
        super(...arguments);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.track != nextProps.track ||
            this.props.show != nextProps.show
        );
    }

    render() {
        const {
            track,
            state,
            show,
            onPlayerAction
        } = this.props;
        
        const display = show 
            ? { display: 'block' }
            : { display: 'none' };    

        const soundsLike = track.SoundsLike.map((sl, i) => state.hideDrawerLinks
            ? (<span key={i}>{sl.Name}</span>)
            : (<span key={i} className='link' onClick={() => onPlayerAction({ type: 'AddFilter', filterType: 'SoundsLike', filterValue: sl })}>{sl.Name}</span>)
        );
        const looksLike = track.LooksLike.map((ll, i) => state.hideDrawerLinks 
            ? (<span key={i}>{ll.Name}</span>)
            : (<span key={i} className='link' onClick={() => onPlayerAction({ type: 'AddFilter', filterType: 'LooksLike', filterValue: ll })}>{ll.Name}</span>)
        );
        const genre = state.hideDrawerLinks ? (<span>{track.Genre.Name}</span>) : (<span className='link' onClick={() => onPlayerAction({ type: 'AddFilter', filterType: 'Genre', filterValue: track.Genre})}>{track.Genre.Name}</span>);
        const mood = state.hideDrawerLinks ? (<span>{track.Mood.Name}</span>) : (<span className='link' onClick={() => onPlayerAction({ type: 'AddFilter', filterType: 'Mood', filterValue: track.Mood})}>{track.Mood.Name}</span>);
        const tempo = state.hideDrawerLinks ? (<span>{track.Tempo.Name}</span>) : (<span className='link' onClick={() => onPlayerAction({ type: 'AddFilter', filterType: 'Tempo', filterValue: track.Tempo})}>{track.Tempo.Name}</span>);
        const energy = state.hideDrawerLinks ? (<span>{track.Energy.Name}</span>) : (<span className='link' onClick={() => onPlayerAction({ type: 'AddFilter', filterType: 'Energy', filterValue: track.Energy})}>{track.Energy.Name}</span>);

        return (
            <div className='track-detail-description-panel' style={display}>
                <div className='row'>
                    <div className='info genre'>
                        <div className='type'>{getTranslation("Genre")}:</div>
                        <div>{ genre }</div>
                    </div>
                    <div className='info mood'>
                        <div className='type'>{getTranslation("Mood")}:</div>
                        <div>{ mood }</div>
                    </div>
                    <div className='info tempo'>
                        <div className='type'>{getTranslation("Tempo")}:</div>
                        <div>{ tempo}</div>
                    </div>
                    <div className='info energy'>
                        <div className='type'>{getTranslation("Energy")}:</div>
                        <div>{ energy }</div>
                    </div>
                    <div className='info looks-like'>
                        <div className='type'>{getTranslation("Scenes")}:</div>
                        <div className='value'>{looksLike}</div>
                    </div>
                    <div className='info sounds-like'>
                        <div className='type'>{getTranslation("Sounds Like")}:</div>
                        <div className='value'>{soundsLike}</div>
                    </div>
                    <div className='info track-id'>
                        <div className='type'>{getTranslation("Id")}:</div>
                        <div className='value'>{track.Id}</div>
                    </div>                  
                </div>
                <div className='row'>
                    <div className='info instrumentation'>
                         <div className='type'>{getTranslation("Instrumentation")}:</div>
                         <div className='value'>{track.Instrumentation}</div>
                    </div>   
                    <RenderIf condition={track.ISWCNumber}>   
                        <div className='info iswc'>
                            <div className='type'>{getTranslation("ISWC")}:</div>
                            <div className='value'>{track.ISWCNumber}</div>
                        </div>           
                    </RenderIf>
                </div>
                <div className='row'>
                    <div className='info'>
                        <div className='type'>{getTranslation("Keywords")}:</div>
                        <div className='value'>{track.Keywords}</div>                
                    </div>
                </div>            
            </div>            
        )
    }
}
