import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Flex } from 'components/ui';
import './playlistResults.scss';
import { reorderList } from "../../../../../utility/common"

import { TrackResults } from 'components/common/track-results/track-results';

class PlaylistResults extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
            currentRow: 0
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isVisible && (nextProps.isVisible != this.props.isVisible)) {
            setTimeout(() => { try { this.onResize(); } catch (ex) { } }, 10);
        }
    }

    get SharePlaylist() {
        const { SharePlaylist } = this.props;
        return SharePlaylist ? SharePlaylist.toJS() : {};
    }

    get ShareTracks() {
        return this.SharePlaylist.Tracks
    }

    get ShareFilteredTracks() {
        const tracks = this.ShareTracks.map(track => {
            return { ...track, AltTracks: [], EditTracks: [], AltTrackCount: 0, EditTrackCount: 0, }
        });
        return tracks
    }

    get FilteredTracks() {
        const tracks = [];
        const trackList = [];
        const filters = this.PlaylistFilters;

        let filterCount = 0;

        // Genres
        if (filters.Genre.length > 0) {
            var list = [];
            filterCount++;

            filters.Genre.forEach(genre => {
                this.Playlist.Tracks.forEach(track => {
                    if (track.Genre.Id == genre.Value.Id) {
                        if (list.find(t => t === track) == null) {
                            list.push(track);
                        }
                    }
                });
            });

            list.forEach(track => {
                var index = tracks.findIndex(t => t.track === track);

                if (index == -1) tracks.push({ track: track, count: 1 });
                else tracks[index].count++;
            });
        }

        // Moods
        if (filters.Mood.length > 0) {
            var list = [];
            filterCount++;

            filters.Mood.forEach(mood => {
                this.Playlist.Tracks.forEach(track => {
                    if (track.Mood.Id == mood.Value.Id) {
                        if (list.find(t => t === track) == null) {
                            list.push(track);
                        }
                    }
                });
            });

            list.forEach(track => {
                var index = tracks.findIndex(t => t.track === track);

                if (index == -1) tracks.push({ track: track, count: 1 });
                else tracks[index].count++;
            });
        }

        // Tempo
        if (filters.Tempo.length > 0) {
            var list = [];
            filterCount++;

            filters.Tempo.forEach(tempo => {
                this.Playlist.Tracks.forEach(track => {
                    if (track.Tempo.Id == tempo.Value.Id) {
                        if (list.find(t => t === track) == null) {
                            list.push(track);
                        }
                    }
                });
            });

            list.forEach(track => {
                var index = tracks.findIndex(t => t.track === track);

                if (index == -1) tracks.push({ track: track, count: 1 });
                else tracks[index].count++;
            });
        }

        // Energy
        if (filters.Energy.length > 0) {
            var list = [];
            filterCount++;

            filters.Energy.forEach(energy => {
                this.Playlist.Tracks.forEach(track => {
                    if (track.Energy.Id == energy.Value.Id) {
                        if (list.find(t => t === track) == null) {
                            list.push(track);
                        }
                    }
                });
            });

            list.forEach(track => {
                var index = tracks.findIndex(t => t.track === track);

                if (index == -1) tracks.push({ track: track, count: 1 });
                else tracks[index].count++;
            });
        }

        // Duration Range
        if (filters.DurationMin != null || filters.DurationMax != null) {
            var list = [];
            var durationMin = parseInt(filters.DurationMin || 0);
            var durationMax = parseInt(filters.DurationMax || 1000);
            filterCount++;

            this.Playlist.Tracks.forEach(track => {
                if (track.Duration >= durationMin && track.Duration <= durationMax) {
                    if (list.find(t => t === track) == null) {
                        list.push(track);
                    }
                }
            });

            list.forEach(track => {
                var index = tracks.findIndex(t => t.track === track);

                if (index == -1) tracks.push({ track: track, count: 1 });
                else tracks[index].count++;
            });
        }

        // AdTiming
        if (filters.AdTiming.length > 0) {
            var list = [];
            filterCount++;

            filters.AdTiming.forEach(adTiming => {
                this.Playlist.Tracks.forEach(track => {
                    if (track.AdTiming == adTiming.Value.Id) {
                        if (list.find(t => t === track) == null) {
                            list.push(track);
                        }
                    }
                });
            });

            list.forEach(track => {
                var index = tracks.findIndex(t => t.track === track);

                if (index == -1) tracks.push({ track: track, count: 1 });
                else tracks[index].count++;
            });
        }

        if (filters.LooksLike.length > 0) {
            var list = [];
            filterCount++;

            filters.LooksLike.forEach(looksLike => {
                this.Playlist.Tracks.forEach(track => {
                    if (track.LooksLike.findIndex(ll => ll.Id == looksLike.Value.Id) >= 0) {
                        if (list.find(t => t === track) == null) {
                            list.push(track);
                        }
                    }
                });
            });

            list.forEach(track => {
                var index = tracks.findIndex(t => t.track === track);

                if (index == -1) tracks.push({ track: track, count: 1 });
                else tracks[index].count++;
            });
        }

        // SoundLike
        if (filters.SoundsLike.length > 0) {
            var list = [];
            filterCount++;

            filters.SoundsLike.forEach(soundsLike => {
                this.Playlist.Tracks.forEach(track => {
                    if (track.SoundsLike.findIndex(sl => sl.Id == soundsLike.Value.Id) >= 0) {
                        if (list.find(t => t === track) == null) {
                            list.push(track);
                        }
                    }
                });
            });

            list.forEach(track => {
                var index = tracks.findIndex(t => t.track === track);

                if (index == -1) tracks.push({ track: track, count: 1 });
                else tracks[index].count++;
            });
        }

        tracks.forEach(track => {
            if (track.count >= filterCount) trackList.push(track.track);
        })

        return (filterCount == 0)
            ? this.Playlist.Tracks
            : trackList;
    }

    get Playlist() {
        const { Playlist } = this.props;
        return Playlist ? Playlist.toJS() : {};
    }


    get PlaylistFilters() {
        return this.UserInterface.PlaylistFilters;
    }

    get UserInterface() {
        const { UserInterface } = this.props;
        return UserInterface ? UserInterface.toJS() : {};
    }

    get SharePlaylist() {
        const { SharePlaylist } = this.props;
        return SharePlaylist ? SharePlaylist.toJS() : {};
    }

    handleDownloadTrack(track, event) {
        const rect = event.target.getBoundingClientRect();
        Common.UserInterface.ShowDownloadTrack(track, rect);
    }

    handleToggleTrack(track) {
        Common.UserInterface.TogglePlaylistTrack(track);
    }

    getTrackIndex = (Id) => this.ShareFilteredTracks.findIndex((track) => track.Id == Id)

    handleDragEnd = (result) => {
        if (!result || !result.startId || !result.endId) {
            return;
        }

        if (result.startId === result.endId) {
            return;
        }

        let startIndex = this.getTrackIndex(result.startId);
        let endIndex = this.getTrackIndex(result.endId);
        let orderedList = reorderList(this.ShareFilteredTracks, startIndex, endIndex);
        if (orderedList) {
            Common.Playlist.SetSharePlaylist({ ...this.SharePlaylist, Tracks: orderedList })
        }
    }

    handleScrollFrame(values) {
        this.setState({ currentRow: this.PageRow });
    }

    get PageRow() {
        return this.TrackResults
            ? !isNaN(this.TrackResults.ScrollableList.List.Grid._renderedRowStopIndex) ? this.TrackResults.ScrollableList.List.Grid._renderedRowStopIndex + 1 : 0
            : 0;
    }

    render() {
        let tracks = [];        
        if (!this.UserInterface.ShowPlaylistExtension && !this.props.isDraggable) {            
            tracks = this.FilteredTracks;
        } else if (this.props.isDraggable) {            
            tracks = this.ShareFilteredTracks;
        }
        else {            
            tracks = this.FilteredTracks;
        }

        // 08/04/2024 - Requested by Eric - Only show the main/initial tracks - Only show the main/initial tracks in the playlist. Show secondary playlist tracks inside the drawer.
        tracks = [...new Set(tracks.map(x => x.TrackGroup))].map(x => tracks.filter(y => y.TrackGroup === x).sort((a, b) => {
            if (a.TrackSortTitle < b.TrackSortTitle) return -1
            if (a.TrackSortTitle > b.TrackSortTitle) return 1
            return 0
        })[0])
         
        const trackTotal = (tracks.length);
        const trackCountElement = trackTotal > 0
            ? (<div>TRACKS: { this.state.currentRow == 0 ? this.PageRow : this.state.currentRow } / {trackTotal}</div>)
            : ''
            
        const { isDraggable = false } = this.props
        const t = new Map(tracks).toJS();
        const trackCountClass = this.props.owner && this.props.owner == 'SharedPlaylist' ? 'track-count-l track-count-wrapper' : 'flex-r track-count-wrapper';
        const autoSortAZ = this.props.owner && this.props.owner == 'SharedPlaylist' ? false : true;

        return (
            <Flex className='playlist-results'>
                <Flex>
                    <TrackResults
                        ref={(i) => { this.TrackResults = i }}
                        owner={this}
                        source={'playlist'}
                        tracks={tracks}
                        autoSortAtoZ={autoSortAZ}
                        disableLinks={true}
                        hideMoreLikeThisIcon={false}
                        hideFilterLinks={true}
                        hideDrawerLinks={true}
                        hideMoreLikeThis={true}
                        hideTrackInfoLinks={true}
                        isDraggable={isDraggable}
                        onDragEnd={this.handleDragEnd}
                        onScroll={this.handleScrollFrame.bind(this)}
                    />
                    <Flex row fixed className='footer'>
                        <Flex className={trackCountClass}>
                            {trackCountElement}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        )
    }
}

export default connect(state => {
    return {
        SharePlaylist: state.SharePlaylist,
        Playlist: state.Playlist,
        UserInterface: state.UserInterface
    }
})(PlaylistResults);
